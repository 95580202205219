import React, { useState } from 'react';
import { useHarmoniaDispatch, deleteCourseStudent, deleteCourseTA, deleteCourseInstructor, removeCourseInvitation } from '../../redux';
import {
  HarmoniaButton,
  HarmoniaPaper,
  Text,
  useToggle
} from "@harmonia-front-end/shared";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import {SelectedCourseStateType} from '../../redux/selectedCourse';
import style from '../../pages/course/PeoplePage.module.scss';
import { CourseInstructorTA, CourseStudent, CourseInvitation, CourseDetailedInfo } from '../../types';
import CreateCourseInvitationModal from './CreateCourseInvitationModal';

export enum PersonnelType {
  INSTRUCTOR, TA, STUDENT
}

export type PersonnelData = {
  type: PersonnelType,
  personnel: CourseInstructorTA[] | CourseStudent[] | null | undefined,
  inviteButtonText: string,
  personnelRole: 'instructor' | 'ta' | 'student',
  displayRole: 'Instructor'| 'Teaching Assistant'| 'Student',
  invitations: CourseInvitation[] | null | undefined,

}
const {coursePeopleContainer, coursePeople, coursePeopleItem, emailName, coursePeopleIcons,remove, peopleTop, peopleIcon,  empty,email, invites} = style;

type CoursePeopleProps = {
  selectedCourse: SelectedCourseStateType,
  personnel: PersonnelType,
  invitations: CourseInvitation[],
  userID: number,
  isTA: boolean
}
const CoursePeople = ({selectedCourse, personnel, invitations, userID, isTA}: CoursePeopleProps) => {
  const dispatch = useHarmoniaDispatch();
  // This component is used for each type of course personnel (instructors, tas and students). The only thing that is different between them is the functionality of the add and delete functions which we take care of conditionally via another prop (personnel) passed in. The entire course is passed in (rather than just the relevant array of personnel) so we can have access to all personnel throughout the entire component and avoid any annoying typing errors.

  // deconstruct course info and any personnel from selectedCourse
  const {courseInformation, instructors, students, tas} = selectedCourse;

  /* INVITING SOMEONE TO COURSE */

  // local state for opening/closing modal for inviting to join a course.
  const [createInvitationModalOpen, toggleCreateInvitationModal] = useToggle();

  // local state for storing list of entered emails as "chips"; made on this component and passed to create modal so if user closes out of modal before submitting, emails in invitation list are not lost
  const [invitationEmails, setInvitationEmails] = useState([] as string[]);

  // function for opening prompt to email personnel.

  const emailCoursePersonnel = (email:string) => () => {
    window.location.href = `mailto:${email}`;
  }

  // each object in this array has all the relevant data we need to render this component properly according to each personnel type (instructor, ta, student)
  const personnelTypeToData: PersonnelData[] = [
    {
      type: PersonnelType.INSTRUCTOR,
      personnel: instructors,
      inviteButtonText: 'Invite Instructor',
      personnelRole: 'instructor',
      displayRole: 'Instructor',
      invitations: invitations?.filter(invitation => invitation.join_as === 'instructor'),
    },
    {
      type: PersonnelType.TA,
      personnel: tas,
      inviteButtonText: 'Invite Teaching Assistant',
      personnelRole: 'ta',
      displayRole: 'Teaching Assistant',
      invitations: invitations?.filter(invitation => invitation.join_as === 'ta'),
    },
    {
      type: PersonnelType.STUDENT,
      personnel: students,
      inviteButtonText: 'Invite Student',
      personnelRole: 'student',
      displayRole: 'Student',
      invitations: invitations?.filter(invitation => invitation.join_as === 'student'),
    },
  ];

  // REMOVING INVITATION

  const [removeInvitationModalOpen, toggleRemoveInvitationModal] = useToggle();

  const [invitationToRemove, setInvitationToRemove] = useState({}) as [CourseInvitation, React.Dispatch<React.SetStateAction<{}>>];

  const showInvitationRemoveModal = (invitation: CourseInvitation) => {
    toggleRemoveInvitationModal();
    setInvitationToRemove(invitation);
  };

  const confirmRemoveInvitation = (invitation: CourseInvitation) => {
    toggleRemoveInvitationModal();
    dispatch(removeCourseInvitation(invitation)).finally(() => setInvitationToRemove({}));
  }

  // DELETING PERSONNEL

  // local state for opening/closing delete modal
  const [deleteDialogOpen, toggleDeleteDialog] = useToggle();

  // local state for user to be deleted. The course personnel (and the corresponding delete icons) are rendered via a map function but the modal to delete is rendered once separately. In order to access the relevant personnel info for prompts and deleting, we create local state (as a course personnel object) so it can be set with each person individually and passed in to the modal/delete functions accordingly.
  const [personnelToDelete, setPersonnelToDelete] = useState({}) as [CourseStudent | CourseInstructorTA, React.Dispatch<React.SetStateAction<{}>>];

  // function to show delete modal. opens the modal and sets local state for deleting with relevant personnel info.
  const showDeleteModal = (personnel: CourseStudent | CourseInstructorTA) => {
    toggleDeleteDialog();
    setPersonnelToDelete(personnel);
  }

  // handler for confirming deletion of personnel: closes delete modal, makes API request according to personnel type and resets local personnel-delete state.
  const confirmDeletePersonnel = (user: CourseStudent | CourseInstructorTA) => {
    if(personnel === PersonnelType.STUDENT){
      toggleDeleteDialog();
      dispatch(deleteCourseStudent({course: courseInformation as CourseDetailedInfo, user})).finally(() => setPersonnelToDelete({}));
    }
    if(personnel === PersonnelType.TA){
      toggleDeleteDialog();
      dispatch(deleteCourseTA({course: courseInformation as CourseDetailedInfo, user})).finally(() => setPersonnelToDelete({}));
    }
    if(personnel === PersonnelType.INSTRUCTOR){
      toggleDeleteDialog();
      dispatch(deleteCourseInstructor({course: courseInformation as CourseDetailedInfo, user})).finally(() => setPersonnelToDelete({}));
    }
  };

  // finding the right data obj according to the personnel prop (instructor, ta, student) passed into this component
  const data= personnelTypeToData.find(elem => elem.type === personnel) as PersonnelData;

// this function takes in a personal data obj (as defined above) in order to render array of course personnel with all relevant attributes.
  const renderPersonnel = (data: PersonnelData) => {

    return (
      <HarmoniaPaper className={coursePeopleContainer}>
        <>
        <div className={peopleTop}>
          <Text color="harmoniaGray" variant="dashboardContentHeading">{`${data.displayRole}s`}</Text>
          {(selectedCourse.courseInformation?.status === 'ACTIVE' || (selectedCourse.courseInformation?.status === 'PLANNING' && personnel !== PersonnelType.STUDENT)) && !isTA &&
              <HarmoniaButton variant="primary" dest="app" icon="add-to" iconPos="left" onClick={toggleCreateInvitationModal}>{data.inviteButtonText}</HarmoniaButton>
          }
        </div>
         {/* Show prompt if personnel type is empty */}
        {data.personnel?.length === 0 &&
          <Text variant="dashboardWidgetHeading" color="dark" fontStyle="italic" className={empty}>{`There are currently no ${data.displayRole}s in this course.`}</Text>
        }
          {(selectedCourse.courseInformation?.status === 'PLANNING' && personnel === PersonnelType.STUDENT) &&
              <Text variant="dashboardWidgetHeading" color="dark" fontStyle="italic" className={empty}>You must activate the course first to invite students.</Text>
          }

        {data.personnel && data.personnel?.length > 0 &&
        <div className={coursePeople}>
          {data.personnel?.map((person, index) => {
            const {first_name, last_name} = person;
            return (
              <div key={index}className={coursePeopleItem}>
                <div className={emailName}>
                <Text color="dark">{`${last_name}, ${first_name}`}</Text>
                </div>
                 {/* a user should not be allowed to email or attempt to remove themselves from course */}
                {
                userID && userID !== person.user_id &&
                <div className={coursePeopleIcons}>
                  <div className={`${peopleIcon} ${email}`} onClick={emailCoursePersonnel(person.email)}>
                    <FontAwesomeIcon icon={faEnvelope}/>
                    <Text color="dark">Email</Text>
                  </div>
                    {!isTA && (
                        <div className={`${peopleIcon} ${remove}`} onClick={() => {showDeleteModal(person)}}>
                            <FontAwesomeIcon icon={faTrashAlt} />
                            <Text>Remove</Text>
                        </div>
                    )}
                </div>
          }
              </div>
            )
          })}
        </div>
        }
        {/* if there are any invitations, show them */}
        {data.invitations && data.invitations.length > 0 &&
        <>
        <Text variant="dashboardWidgetHeading" color="harmoniaGray">Invitations</Text>
        <div className={invites}>
          {data.invitations?.map((invitation, index) => {
            return (
              <div key={index} className={coursePeopleItem}>
                <div>
                  <Text color="dark">{invitation.invitee_email}</Text>
                </div>
                <div className={coursePeopleIcons}>
                  <div className={`${peopleIcon} ${email}`}>
                    <FontAwesomeIcon icon={faEnvelope} onClick={emailCoursePersonnel(invitation.invitee_email)}/>
                    <Text color="dark">Email</Text>
                  </div>
                  { !isTA && (
                      <div className={`${peopleIcon} ${remove}`} onClick={() => {showInvitationRemoveModal(invitation)}}>
                        <FontAwesomeIcon icon={faTrashAlt} />
                        <Text>Remove</Text>
                      </div>
                  )}
                </div>
              </div>
            )
          })}
        </div>
        </>
        }
        </>
      </HarmoniaPaper>
    )
  }
    // finding the right data obj according to the personnel prop (instructor, ta, student) passed into this component
    // const data= personnelTypeToData.find(elem => elem.type === personnel) as PersonnelData;

    // console.log('data in CoursePeople is: ', data);

    return(
    <>
    {
    renderPersonnel(data)
    }
    {/* CREATE INVITATION MODAL */}

    <CreateCourseInvitationModal personnelData={data} invitationEmails={invitationEmails} setInvitationEmails={setInvitationEmails} courseID={courseInformation?.id as number} createInvitationModalOpen={createInvitationModalOpen} toggleCreateInvitationModal={toggleCreateInvitationModal}/>


            {/* REMOVE INVITATION MODAL */}
    <Modal isOpen={removeInvitationModalOpen}>
      <ModalHeader>Remove Invitation</ModalHeader>
      <ModalBody>
        <Text color="dark">
          {`Are you sure to want to remove the invitation to ${invitationToRemove.invitee_email}?`}
        </Text>
      </ModalBody>
      <ModalFooter>
        <HarmoniaButton variant="secondary" dest="app" onClick={toggleRemoveInvitationModal}>Cancel</HarmoniaButton>
        <HarmoniaButton variant="primary" dest="app" onClick={() => {confirmRemoveInvitation(invitationToRemove)}} >Remove Invitation</HarmoniaButton>
      </ModalFooter>
    </Modal>

    {/* DELETE PERSONNEL MODAL */}
    <Modal isOpen={deleteDialogOpen}>
      <ModalHeader>
        {`Remove ${data.displayRole}`}
      </ModalHeader>
      <ModalBody>
          <Text color="dark">
            {
            `Are you sure you want to remove ${personnelToDelete.first_name} ${personnelToDelete.last_name} from this course?`
            }
          </Text>
      </ModalBody>
      <ModalFooter>
        <HarmoniaButton variant="secondary" dest="app" onClick={toggleDeleteDialog}>Cancel</HarmoniaButton>
        <HarmoniaButton variant="primary" dest="app" onClick={ ()=> {confirmDeletePersonnel(personnelToDelete)}} >Remove</HarmoniaButton>
      </ModalFooter>
    </Modal>
    </>

  )
}

export default CoursePeople;
