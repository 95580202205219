import React, {ReactNode} from 'react';
import HarmoniaSelectStyle from './HarmoniaSelect.module.scss';

const {select, focus, after} = HarmoniaSelectStyle;

type HarmoniaSelectProps = {
  name: any,
  value: any,
  onChange: React.ChangeEventHandler<HTMLSelectElement> | undefined,
  children?: ReactNode
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLSelectElement>, HTMLSelectElement>;

const HarmoniaSelect = (props: HarmoniaSelectProps) => {
  const {name, value, onChange, children, className, ...rest} = props;
  const classList = className ? className.split(' ') : [];
  classList.push(select);
  classList.push(focus);

  return (
    <div className={select}>
      <select value={value} name={name} onChange={onChange} {...rest}>
        {children}
      </select>
      <span className={focus}></span>
    </div>
  )
}
export default HarmoniaSelect;
