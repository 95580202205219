import React from 'react';
import { Text } from '@harmonia-front-end/shared';

function DiscussionPage() {
  return (
    <Text>
    </Text>
  );
}

export default DiscussionPage;
