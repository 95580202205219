import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  HarmoniaInput, MiniTemplate, HarmoniaButton, Text,
} from '@harmonia-front-end/shared';
import { pushErrorNotification, pushNotification, useHarmoniaDispatch } from '../../redux';
import { API, passwordResetErrorMessage } from '../../utilities';
import UserFormStyle from './UserAuth.module.scss';

const {
  content, form, formInputs, formButton, input,
} = UserFormStyle;

/**
 * User reset password request page for the Harmonia Dashboard, routed to the '/reset-password'.
 *
 * The page would collect the user's reset password request.
 * It send the user's email to the backend, and send a reset password link to the user's email.
 *
 * @see requestResetPasswordThunk The workflow for handling a user's password reset request.
 */
export default function ResetPasswordPage() {
  const dispatch = useHarmoniaDispatch();
  const [submitted, setSubmitted] = React.useState(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const passwordObj = {
      email: formData.get('email'),
      // password: formData.get('password'),
      password: null // server expects this to be null!
    };
    API.post('/api/users/password-reset', passwordObj)
      .then(() => {
        dispatch(pushNotification({
          severity: 'success',
          message: 'Password reset request submitted! Please check your email for further actions',
        }));
      })
      .catch((err) => dispatch(pushErrorNotification(passwordResetErrorMessage(err.data))));
    setSubmitted(true);
  };

  return (
    <MiniTemplate>
      <div className={content}>
        <Text variant="h1" color="grey" align="center" gutterBottom>
          <FormattedMessage id="user.reset_password.title" defaultMessage="Reset Password" />
        </Text>
        {!submitted &&
            <form className={form} onSubmit={handleSubmit}>
              <div className={formInputs}>
                <HarmoniaInput className={input} type="email" placeholder="Email Address" name="email" />
                <HarmoniaInput className={input} honeypot type="password" placeholder="Confirm Password" name="password" />
              </div>
              <HarmoniaButton className={formButton} variant="primary" dest="web" icon="next" type="submit">
                <FormattedMessage id="general.submit" defaultMessage="Submit" />
              </HarmoniaButton>
            </form>}
        {submitted &&
            <Text style={{paddingLeft: '12px', paddingRight: '12px', paddingBottom: '8px'}} variant="dashboardContentHeading" color="dark" align="center">
              <FormattedMessage id="user.confirmation_email_sent" defaultMessage="An e-mail message has been sent to the address you provided."/>
            </Text>
        }
      </div>
    </MiniTemplate>
  );
}
