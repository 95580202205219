import React from 'react';
import {HarmoniaPaper, Text} from '@harmonia-front-end/shared';
import DashboardTitleBarStyle from './DashboardTitleBar.module.scss';

const {
    paper, paperContent, title,
} = DashboardTitleBarStyle;

type DashboardTitleBarProps = React.PropsWithChildren<{
    /** HTML classes to be applied to the inner HTML div element where content is rendered. */
    contentClassName?: string;

    /** The title of the page. */
    h1: React.ReactNode,

    /** The subtitle of the page, useful to display content details. */
    h2?: React.ReactNode,

    /** The sub-subtitle of the page, inline with h2, useful to display semesters. */
    h2_no2?: React.ReactNode,

    h2_no3?: React.ReactNode,
}>;

function DashboardTitleBar(props: DashboardTitleBarProps) {
    const {children, contentClassName, h1, h2, h2_no2, h2_no3} = props;

    return (
        <HarmoniaPaper className={paper}>
            <div className={paperContent}>
                <div className={title}>
                    <span>
                        {h2 && <Text inline color="dark-grey" variant="h2">{h2}</Text>}
                        {h2 && h2_no2 && <Text inline color="dark-grey" variant="h2"> - </Text>}
                        {h2_no2 && <Text inline color="grey" variant="h2">{h2_no2}</Text>}
                        {h2_no2 && h2_no3 && <Text inline color="dark-grey" variant="h2"> - </Text>}
                        {h2_no3 && <Text inline color="dark-grey" variant="h2">{h2_no3}</Text>}
                    </span>
                    <Text color="dark" variant="dashboardHeading">{h1}</Text>
                </div>
                <div className={contentClassName}>
                    {children}
                </div>
            </div>
        </HarmoniaPaper>
    );
}

export default DashboardTitleBar;
