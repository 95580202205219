import React from 'react';
import HarmoniaPaperStyle from './HarmoniaPaper.module.scss';

const { paper, paperRounded } = HarmoniaPaperStyle;

type HarmoniaPaperProps = {
  rounded?: boolean,
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const HarmoniaPaper = React.forwardRef(
  (props: HarmoniaPaperProps, ref?: React.Ref<HTMLDivElement> | undefined) => {
    const {
      rounded, className, children, ...rest
    } = props;
    const classList = className ? className.split(' ') : [];
    classList.push(paper);
    if (rounded) classList.push(paperRounded);
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <div ref={ref} className={classList.join(' ')} {...rest}>{children}</div>;
  },
);

HarmoniaPaper.defaultProps = {
  rounded: false,
};

export default HarmoniaPaper;
