import React from 'react';

import style from "./HarmoniaChevron.module.scss";

const {chevron, right, left, up, down} = style;

type HarmoniaChevronProps = {
  /** Which direction chevron is facing. */
  direction: 'right' | 'left' | 'up' | 'down',
  /** Additional HTML classes to apply. */
  className?: string,
  /** Additional inline CSS properties (if any). */
  style?: React.CSSProperties,
  // onClick?: () => void
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>
const HarmoniaChevron = ({direction, className, style}: HarmoniaChevronProps) => {
  const classList = className ? className.split(' ') : [];

  classList.push(chevron);

  if(direction === 'right') classList.push(right);
  if(direction === 'left') classList.push(left);
  if(direction === 'up') classList.push(up);
  if(direction === 'down') classList.push(down);

  const finalClassName = classList.join(' ');

  return <i className={finalClassName}></i>
}
export default HarmoniaChevron;
