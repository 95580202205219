import {useLocation} from 'react-router';
import {ApiError, CourseDetailedInfo, UserType, UserTypeInfo} from '../types';
import {format} from 'date-fns'

/**
 * Helper function to get a string value from a map with a given key.
 *
 * Compared to the default `Map.prototype.get()`, which may return `undefined`
 * or any other types of value depends on the type of the map, this function guarantees
 * to return a string.
 *
 * If the queried value is undefined, this function would directly return the fallback value.
 * If the queried value is a string, this function would directly return it.
 * If the queried value is concrete but not a string, this function would check
 * whether we want to force convert it to a string. If not, the fallback value would be returned.
 * By default, the fallback value is an empty string, and it wouldn't force convert
 * non-string value. All those behavior can be tweaked.
 *
 * @param map The map object that would be queried.
 * @param key The key to search in the map.
 * @param fallback Fallback value to be returned if the queried value is undefined or not a string,
 * if we do not wish to force convert it. By default it's an empty string.
 * @param forceConvert If the flag is true, the function would force convert the non-literal value
 * to a string, or directly return the fallback value. By default it would not force convert.
 */
export function getMapValueAsString<K, V>(
  map: Map<K, V>,
  key: K,
  fallback: string = '',
  forceConvert: boolean = false
): string {
  const value = map.get(key);
  if (value !== undefined) {
    if (forceConvert) return String(value);
    if (typeof value === 'string') return value;
  }
  return fallback;
}

// TODO: should I keep this function?
export function generateErrorMessage(apiError: ApiError) {
  let ret = 'Error ';
  if (apiError.code) ret += `${apiError.code}: `;
  if (apiError.message) ret += apiError.message;
  else if (apiError.messages) ret += apiError.messages.join(' ');
  return ret;
}

/**
 * Password validator for password input.
 * A password must contain at least one of uppercase letter, lowercase letter, and digit.
 *
 * @param password the password to check.
 */
export function validatePassword(password: string) {
  const upper = Boolean(password.match(/[A-Z]/g));
  const lower = Boolean(password.match(/[a-z]/g));
  const digit = Boolean(password.match(/[0-9]/g));
  return {
    upper,
    lower,
    digit,
  };
}

export function parseQueryParams() {
  const params = useLocation().search;

  // first make sure the string is not empty and the begining is '?'
  if (params.length == 0 || params.charAt(0) !== '?') {
    return {};
  }

  // transform to array params.
  const queries = params
    .substring(1)
    .split('&')
    .map((item) => item.split('='));

  // iterate through queries and add them to result
  const result = {};
  for (const item of queries) {
    result[item[0]] = item[1];
  }

  return result;
}

/** These are helper functions to use for when React re-renders with data from API. Putting logic in here so that the component files are cleaner */

export const renderAssignment = (assignment) => {
  return assignment === undefined ? {} : assignment;
};

export const renderAssignmentDocuments = (documents) => {
  return documents === undefined ? [] : documents;
};

/** Function to convert unix timestamp to human readable time */

export const convertTimestampToDate = (timestamp: number) => {
  const date = new Date(timestamp * 1000);

  const timeObj = {
    year: date.getUTCFullYear(),
    month: date.getUTCMonth(),
    day: date.getUTCDay(),
    hours: date.getUTCHours(),
    minutes: date.getUTCMinutes(),
  };
  return timeObj;
};
// helper to figure out maximum length for student free access (enrollment_end_date): the minimum of 2 weeks after course start or 1/3 of the course length.
export const calculateMaxTimeForEnrollmentEndDate = (startDate: number, endDate: number) => {
  // Calculate 2 weeks after course start date.
  const twoWeeksAfterCourseStart = (startDate + 1209600) * 1000;
  // Calculate the total course length (time difference between the start_date and end_date) divided by 3, and use it to find the proper date after the start_date.
  const thirdOfCourseLength = (endDate - startDate) / 3;
  const thirdOfLengthAfterCourseStart = (startDate + thirdOfCourseLength) * 1000;
  // Find the minimum of 2 weeks after course start vs. 1/3 of course length, and use resulting timestamp to set the maxDate on datePicker for enrollment_end_date. Whew!
  const maxTimeForEnrollmentEnd = Math.min(twoWeeksAfterCourseStart, thirdOfLengthAfterCourseStart);
  // const oldDate = new Date(maxTimeForEnrollmentEnd);
  // const newDate = new Date(maxTimeForEnrollmentEnd - 86400);
  return maxTimeForEnrollmentEnd + 86400 * 1000;
}

const unix_timestamp = 1549312452;
const date = new Date(unix_timestamp * 1000);
const year = date.getFullYear();
const month = date.getMonth();
const day = date.getUTCDate();
const dayOfWeek = date.getUTCDay();
const hours = date.getHours();
const minutes = `${date.getMinutes()}`;
const seconds = `0${date.getSeconds()}`;

// Will display time in 10:30:23 format
const formattedTime = hours + ':' + minutes + ':' + seconds.substr(-2);


export const userTypeInfo: UserTypeInfo[] = [
  {userType: UserType.STUDENT, text: 'Student'},
  {userType: UserType.INSTRUCTOR, text: 'Instructor'}
];

// Converting timestamps to dates in two formats: 'MMMM-DD-YY' and 'MM/DD/YYYY'

const formatAMPM = (date: Date) => {
  let hours = date.getHours();
  let minutes: number | string = date.getMinutes();
  const am_pm = hours >= 12 ? 'pm' : 'am'; // past 12 is 'pm';
  hours = hours % 12;
  hours = hours ? hours : 12; // the '0 hour should be '12';
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  const time = `${hours}:${minutes} ${am_pm}`;
  return time;
}
type DateStringType = 'YYYY-MM-DD' | 'MM/DD/YYYY' | 'YYYY-MM-DD hh:mm' | 'Pp'
export const showDateAsFormattedString = (timestamp: number, fmt: DateStringType) => {
  const date = new Date(timestamp * 1000);
  if (fmt === 'Pp') return format(date, 'Pp');
  // Year.
  let year = date.getFullYear();
  // Month (if 1-9, should be 01, 02, etc).
  let month = `${date.getMonth() + 1}`;
  if(month.length < 2) month = `0${month}`;
  // Day (if 1-9, should be 01, 02, etc).
  let day = `${date.getDate()}`;
  if(day.length < 2) day = `0${day}`;
  if(fmt === 'YYYY-MM-DD') return `${year}-${month}-${day}`;
  if(fmt === 'MM/DD/YYYY') return `${month}/${day}/${year}`;
  if(fmt === 'YYYY-MM-DD hh:mm') {
    const time = formatAMPM(date);
    return `${year}-${month}-${day} ${time}`;
  }
  else {
    return 'INVALID FORMAT';
  }
};

// uses/renders indices for doc nums: 1.1, 1.2, 1.3, etc.
export const renderAssignmentDocNum = (assIndex: number, docIndex: number) => {
  return `${assIndex + 1}.${docIndex + 1}`;
};
 // the name value for students on the GradeBook tables are in the order of 'last_name, first_name'. We want to display it in the removeSubmissionModals as 'first_name last_name'.
export const formatStudentName = (name: string) => {
  const formattedName = name.split(',');
  return `${formattedName[1]} ${formattedName[0]}`;
};
// This function takes an average and formats it with up to 2 decimals.
export const convertAverageToProperFloat = (prevNum: number, currNum: number) => {
  let average = (prevNum + currNum) / 2;
  average = Math.round((average + Number.EPSILON) * 100) / 100;
  return average;
};

export const stringCaseFormat = (str: string) => {
  const arr = str.split(" ");

  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
  }

  return arr.join(" ");
};

/**
 * escapes and quotes a string to be inserted into a CSV file
 *
 * @param string
 */
export const makeCSVString = (str: any) => {
    str = str.toString().replace('"', '""');
    if (str.match(/[",\n]|^\s|\s$/))
        str = '"' + str + '"';
    return str;
};

