import React, {useState} from 'react';
import { useHarmoniaDispatch, deleteUserCourseInvitation, acceptUserCourseInvitation } from "../../../redux";
import {
  HarmoniaButton,
  HarmoniaPaper,
  Text,
  useToggle
} from "@harmonia-front-end/shared";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import CourseInvitationEntry from './CourseInvitationEntry';
import { CourseInvitation} from "../../../types";
import CourseManagementStyle from './CourseManagement.module.scss';

const {top, container, header, secondHeader, tableContainer, table} = CourseManagementStyle;

type CourseInvitationsProps = {
  invitations: CourseInvitation[]
}
const CourseInvitations = ({invitations}: CourseInvitationsProps) => {

  const dispatch = useHarmoniaDispatch();

  /*
    Accepting an invitation: state and handlers for opening accept modal with proper invitation info and confirming accepting invitation.
  */

  const [acceptInvitationModalOpen, toggleAcceptInvitationModalOpen] = useToggle();
  const [invitationToAccept, setInvitationToAccept] = useState({} as CourseInvitation);

  const showAcceptInvitationModal = (invitation: CourseInvitation) => {
    toggleAcceptInvitationModalOpen();
    setInvitationToAccept(invitation);
  };

  const confirmAcceptInvitation = (invitation: CourseInvitation) =>() => {
    console.log('invitationID is: ', invitation.id);
    dispatch(acceptUserCourseInvitation(invitation)).finally(() => {
      toggleAcceptInvitationModalOpen();
      setInvitationToAccept({} as CourseInvitation);
    })
  };


  /*
     Deleting/canceling an invitation: state and handlers for opening delete modal with proper invitation info and confirming deleting/canceling invitation.
  */

  const [deleteInvitationModalOpen, toggleDeleteInvitationModalOpen] = useToggle();
  const [invitationToDelete, setInvitationToDelete] = useState({} as CourseInvitation);

  const showDeleteInvitationModal = (invitation: CourseInvitation) => {
      toggleDeleteInvitationModalOpen();
      setInvitationToDelete(invitation);
  };
  const confirmDeleteInvitation = (invitation: CourseInvitation) => () => {
      dispatch(deleteUserCourseInvitation(invitation)).finally(() => {
          toggleDeleteInvitationModalOpen();
          setInvitationToDelete({} as CourseInvitation);
      })
  };


  return (
    <>
    <HarmoniaPaper className={container}>
      <div className={top}>
        <Text variant="h2" color="dark">
          Invitations
        </Text>
      </div>
      <div className={tableContainer}>
        <table className={table}>
          <thead>
            <tr className={header}>
              <th>Course</th>
              <th>School</th>
              <th>Dates</th>
              <th>Role</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {invitations?.map((invitation, index) => {
              return <CourseInvitationEntry key={index} invitation={invitation}
              showAcceptInvitationModal={showAcceptInvitationModal} showDeleteInvitationModal={showDeleteInvitationModal} />
            })}
          </tbody>
        </table>
      </div>
    </HarmoniaPaper>
    {/* pop-up modal for accepting a course invitation */}
    {invitationToAccept.id > -1 &&
    <Modal isOpen={acceptInvitationModalOpen}>
      <ModalHeader>Accept Course Invitation</ModalHeader>
      <ModalBody>
        <Text color="dark">
          {`Do you want to join ${invitationToAccept.course_info.title} at ${invitationToAccept.course_info.school.school}?`}
        </Text>
        <ModalFooter>
        <HarmoniaButton variant="secondary" dest="app" onClick={toggleAcceptInvitationModalOpen}>
          Cancel
        </HarmoniaButton>
        <HarmoniaButton variant="primary" dest="app" onClick={confirmAcceptInvitation(invitationToAccept)} data-cy="confirm-accept-invite">
          Accept Invitation
        </HarmoniaButton>
        </ModalFooter>
      </ModalBody>
    </Modal>
    }
    {/* pop-up modal for removing a course invitation; using "Decline" for user instead of "Remove/delete" */}
    {invitationToDelete.id > -1 &&
    <Modal isOpen={deleteInvitationModalOpen}>
      <ModalHeader>Decline Course Invitation</ModalHeader>
      <ModalBody>
        <Text color="dark">
          {`Are you sure you want to decline your invitation to join ${invitationToDelete.course_info.title} at ${invitationToDelete.course_info.school.school} as a ${invitationToDelete.join_as}? `}
        </Text>
        <Text>
          This action cannot be undone.
        </Text>
      </ModalBody>
      <ModalFooter>
        <HarmoniaButton variant="secondary" dest="app" onClick={toggleDeleteInvitationModalOpen}>
          Cancel
        </HarmoniaButton>
        <HarmoniaButton variant="primary" dest="app" onClick={confirmDeleteInvitation(invitationToDelete)} data-cy="confirm-decline-invite">
         Decline Invitation
        </HarmoniaButton>
      </ModalFooter>
    </Modal>
    }
    </>
  )
}

export default CourseInvitations;
