// import libraries
import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {DragDropContext, Droppable, DropResult} from 'react-beautiful-dnd';
import {
    useHarmoniaSelector,
    useHarmoniaDispatch,
    getCourseDetail,
    getCourseAssignments,
    updateCourseAssignmentOrderLocally,
    updateCourseAssignmentOrder,
    createAssignment,
    getAssignmentDocumentImagePreviewURL,
} from '../../redux';
import {AssignmentListingItem} from '../../components/course';
import {Assignment} from '../../types';
import {DashboardContent, DashboardTitleBar} from '../../components/template';
import {DocImagePreviewModal} from '../../components/assignment';
import LoadingPage from '../../components/LoadingPage';
import AssignmentsPageStyle from './AssignmentsPage.module.scss';
import {HarmoniaButton, HarmoniaPaper, Text} from '@harmonia-front-end/shared';
import {useRedirect} from '../../hooks';

const {assignmentsContainer, addAssignments, drag, empty} = AssignmentsPageStyle;


const AssignmentsPage = () => {
    const dispatch = useHarmoniaDispatch();

    // Grab courseId from params and convert it to number (courseID).
    const {courseId} = useParams<{courseId: string}>();
    const courseID = parseInt(courseId, 10);

    // local state for adding loading page for courseInfo on TitleBar and assignments.
    const [courseDetailsLoading, setCourseDetailsLoading] = useState(true);
    const [courseAssignmentsLoading, setCourseAssignmentsLoading] = useState(true);

    // local state for showing draggable text
    const [dragPromptShow, setDragPromptShow] = useState(true);

    // Determine whether or not a user is an instructor once the courses load
    const courses = useHarmoniaSelector(state => state.courses)
    let isTA = false;
    let foundCourse = courses?.course_instructors?.find(course => course.id === courseID);
    if (!foundCourse) {
        isTA = true;
        foundCourse = courses?.course_tas?.find(course => course.id === courseID);
    }
    let needToRedirect = foundCourse ? false : !Object.keys(courses).length ? false : true;
    const [waiting, setWaiting] = useState(false);

    // grabbing courseInfo and courseAssignments and setting loading.
    useEffect(() => {
        if (foundCourse) {
            dispatch(getCourseDetail(courseID)).finally(() => setCourseDetailsLoading(false));
        }
    }, [dispatch, foundCourse]);

    useEffect(() => {
        if (foundCourse) {
            dispatch(getCourseAssignments(courseID)).finally(() => setCourseAssignmentsLoading(false));
        }
    }, [dispatch, foundCourse]);

    // redirect once either the instructors/students have been loaded and no course is found
    useRedirect(needToRedirect, '/', {
        severity: "error",
        message: "You are not an instructor of this course."
    });

    // Grabbing selected course and then courseInfo and assignments from Redux store.


    const selectedCourse = useHarmoniaSelector(state => state.selectedCourse);

    const courseInfo = selectedCourse.courseInformation;
    const assignments = selectedCourse.assignments || [] as Assignment[];


    /** DOCUMENT IMAGE PREVIEW */
    // almost all of the logic/state for the preview modal on this page is practically identical to that on the AssignmentDetailPage.

    // TO-DO: there is an import error when trying to import the useModal function from its directory. for now, the useModal functionality is recreated here but we will probably need to fix this.
    const useModal = () => {
        const [modalShow, setModal] = useState(false);
        const openModal = () => {
            setModal(true);
            document.body.classList.add('modal-open');
        };
        const closeModal = () => {
            setModal(false);
            document.body.classList.remove('modal-open');
        };
        return [modalShow, openModal, closeModal];
    }

    // setting up state (show/hide) and functionality for doc image preview modal
    const [showDocPreviewModal, openDocPreviewModal, closeDocPreviewModal] = useModal();
    // local state used for selecting a document by ID for image preview
    const [docID, setDocID] = React.useState(0);
    // state to have loading animation while awaiting api call for image preview.
    const [urlLoading, setURLLoading] = React.useState(false);

    // this function is very similar to its counterpart on the AssignmentDetailPage, except it deals with a nested array of assignments and dispatches an action to get the previewURL on the courseReducer instead of assignmentReducer. It sets local state (docID) of this component, which forces a re-render and sets the selectedDoc object for image preview.
    const getDocumentImagePreviewOnAssignmentsPage = (assignmentIndex: number, documentIndex: number) => {
        const documentID = assignments[assignmentIndex].documents[documentIndex].id;
        setDocID(documentID);
        setURLLoading(true);
        console.log('docID in preview is: ', documentID);
        // dispatch(getAssignmentDocumentImagePreviewURLThunk(assignmentIndex,id)).finally(() => setURLLoading(false));
        dispatch(getAssignmentDocumentImagePreviewURL({assignmentIndex, documentID})).finally(() => {
            setURLLoading(false);
        });
    };
    // memomized selected document value. use the local state (docID) to select that document, which is used to load the imageURL. useMemo optimizes performance (each time a image loads, the URL is cached so it loads faster if selected again). see: https://reactjs.org/docs/hooks-reference.html#usememo

    const selectedDoc = React.useMemo(() => {
        // looping through nested arrays of assignments and assignment-documents. using the found variable as well as array.some() we can return early as soon as we find the correct document.
        let correctDoc;
        let found = false;
        for (let i = 0; i < assignments.length; i++) {
            const assignment = assignments[i];
            if (assignment.documents?.some(doc => {
                if (doc.id === docID) {
                    correctDoc = {...doc};
                    return true;
                }
            })) break;
        }
        return correctDoc;
    }, [docID, assignments]);

    // console.log('selectedDoc is: ', selectedDoc);


    // DRAG N' DROP

    const onDragEnd = (result: DropResult) => {
        setDragPromptShow(false);
        const {destination, source, draggableId} = result;
        if (!destination) return;
        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }
        // workflow:
        // 1. update local redux state and kick off api call to update server
        // 2. if the thunk throw error, reverse local state back to original.
        const originalOrder = assignments?.map((assignment) => assignment.id) as number[];
        const newOrder = Array.from(originalOrder);
        newOrder.splice(source.index, 1);
        newOrder.splice(destination.index, 0, Number(draggableId.substring(10)));
        const newAssignments = [];
        for (let i = 0; i < newOrder.length; i++) {
            for (let j = 0; j < assignments.length; j++) {
                if (newOrder[i] == assignments[j].id) {
                    newAssignments.push(assignments[j]);
                }
            }
        }
        // assignments for restoring local state if needed,
        // newOrder for updating server.

        dispatch(updateCourseAssignmentOrderLocally(newAssignments));

        dispatch(updateCourseAssignmentOrder({courseID, assignments, newOrder}));
    };

    // Function to create an assignment. it makes API call to create new assignment, dispatches action to update Redux store and upon success will redirect to the info page for the new assignment.
    const makeNewAssignment = (courseID: number) => () => {
        dispatch(createAssignment(courseID));
        setWaiting(true);
    };

    return (

        <>
            {(courseDetailsLoading || courseAssignmentsLoading) && <DashboardContent><LoadingPage /></DashboardContent>}
            {courseInfo &&
                <DashboardTitleBar h1="Assignment Sets" h2={courseInfo?.title} h2_no2={`${courseInfo?.academic_term} ${courseInfo?.year}`}>
                    {!isTA && (
                        <HarmoniaButton className={addAssignments} variant="primary" dest="app" icon="add-to" iconPos="left" onClick={makeNewAssignment(courseID)}>
                            Add Assignment Set
                        </HarmoniaButton>
                    )}
                </DashboardTitleBar>
            }

            {waiting && <DashboardContent><LoadingPage /></DashboardContent>}
            {!waiting && !courseAssignmentsLoading &&
                <DashboardContent>

                    {/* Show if user does not have any assignments */}
                    {!Boolean(assignments.length) && (
                        <Text className={empty} variant="dashboardHeading" color="black">
                            This course doesn't have any assignment sets yet.
                        </Text>
                    )}

                    {/* Show draggable text only if user has not dragged anything */}
                    <div className={drag}>
                        {
                            dragPromptShow && Boolean(assignments.length) && !isTA && (
                                <Text variant="dashboardList" color="dark-grey" gutterBottom>
                                    You can reorder assignment sets by dragging the arrows.
                                </Text>
                            )}</div>

                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="assignment-list">
                            {(provided) => (
                                <div ref={provided.innerRef} {...provided.droppableProps} data-cy="assignmentsList">
                                    {assignments?.map((assignment, index) => (
                                        <AssignmentListingItem
                                            assignment={assignment}
                                            index={index}
                                            key={assignment.id}
                                            openDocPreviewModal={openDocPreviewModal}
                                            getDocumentImagePreview={getDocumentImagePreviewOnAssignmentsPage}
                                            modalShow={showDocPreviewModal}
                                            isTA={isTA}
                                        />
                                    ))}{' '}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </DashboardContent>
            }
            <DocImagePreviewModal selectedDoc={selectedDoc} closeDocPreviewModal={closeDocPreviewModal} show={showDocPreviewModal} assignmentDocuments={assignments as Assignment[]} getDocumentImagePreview={getDocumentImagePreviewOnAssignmentsPage} urlLoading={urlLoading} page="Assignments" />

        </>
    );
}

export default AssignmentsPage;
