import React, { useState, useEffect } from 'react';
import {useHistory, useLocation, Route} from 'react-router-dom';
import { useHarmoniaSelector, useHarmoniaDispatch, getCourseAssignmentDocuments, getSubmissionsByCourseID} from '../../../redux';
import { Submission, Assignment, CourseStudent } from '../../../types';
import StudentGradeBookTable from './StudentGradeBookTable';
import LoadingPage from '../../LoadingPage';
import {showDateAsFormattedString, makeCSVString} from '../../../utilities';
import { useQueryParams } from '../../../hooks/hooks';
import {saveAs} from 'file-saver';
import { HarmoniaButton } from "@harmonia-front-end/shared/component";

type Student = {
  first_name: string,
  last_name: string,
  user_id: number
}

type SubmissionByStudentProps = {
  urlPath: string[],
  courseID: number,
  assignments: Assignment[],
  students: CourseStudent[]
}


const SubmissionByStudent = (props: SubmissionByStudentProps) => {
  const dispatch = useHarmoniaDispatch();
  const {urlPath, courseID, assignments, students} = props;
  // Local state variables for loading and selecting a student.
  const[submissionsLoading, setSubmissionsLoading] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState({name: '', id: 0});
  

  // Once we have the assignments for the course from API, we need to grab and append the documents for each.
  // Later, we will amend API to grab assignments and documents in a single call.
  useEffect(() => {
    if(assignments) {
      assignments.forEach(assignment => {
        dispatch(getCourseAssignmentDocuments(assignment.id));
      })
    }
  }, [dispatch]);

  // Handler for selecting a student. We get the student's ID from the value of the select element and grab the text of the select element which gives us the student's name. Then, we set the selectedStudent state with it and make an API call to grab their submissions.
  const handleStudentChange:React.ChangeEventHandler<HTMLSelectElement> = (ev) => {
    const studentName = ev.currentTarget.selectedOptions[0].text;
    const userID = parseInt(ev.currentTarget.value, 10);
    setSelectedStudent({name: studentName, id: userID});
    setSubmissionsLoading(true);
    dispatch(getSubmissionsByCourseID({userID, courseID})).finally(() => setSubmissionsLoading(false));
  };

  // Function that will find submissions (if any) for a single document by the selected student and return an array of 0, 1 or 1+ submissions, each with a new field (formattedTime) appended to properly display submission time.
  const getStudentSubmissions = (docID: number, submissions: Submission[]) => {
    // Find student's submissions for that document.
      const documentSubmissions = submissions?.filter(sub => sub.document === docID);
      // Return early if no submissions or only 1 submission;
      if(documentSubmissions?.length === 0){
       // Return an empty array if there are no submissions; we check the length of the array when rendering.
        return [];
      } else if (documentSubmissions?.length === 1){
        const formattedTime = showDateAsFormattedString(documentSubmissions[0].submit_time, 'YYYY-MM-DD hh:mm');
        const singleSubWithFormattedTime = {...documentSubmissions[0], formattedTime};
        return [singleSubWithFormattedTime];
      } else {
        // Return the submissions as an array, sorted by ascending submission time and with submission time in readable format.
        const sortedSubmissions = documentSubmissions.sort((a,b) => {
          return b.points - a.points
        }).map(submission => {
          const formattedTime = showDateAsFormattedString(submission.submit_time, 'YYYY-MM-DD hh:mm');
          return {...submission, formattedTime}
        });
        return sortedSubmissions;
      }
  };

  // Grabbing a student's submissions (once selected) from Redux store.
  const {submissionsByUser} = useHarmoniaSelector(state => state.selectedCourse);

    const exportCSV = (assignments:any, submissionsByStudent:any, getStudentSubmissions:any, collapsed:boolean) => {
        let csv = 
        ['Document,Submissions,Highest Grade,Submission #,Submission Time,Score,%,Grade'].concat(
            assignments?.map((assignment:Assignment, assIndex:number) => {
                return assignment.documents?.map((document, docIndex) => {
                    const submissions = getStudentSubmissions(document.id, submissionsByStudent);
                    const assignmentDocNum = `${assIndex + 1}.${docIndex + 1}`;
                    if (!submissions.length)
                        return makeCSVString(`${assignmentDocNum} - ${document.title}`) + ',,,,,,,';
                    let s = collapsed ? submissions.slice(0, 1) : submissions;
                    return s.map((sub:any, index:number) => {
                        return [
                            index ? '' : makeCSVString(`${assignmentDocNum} - ${document.title}`),
                            index ? '' : makeCSVString(submissions.length),
                            index ? '' : makeCSVString(sub.grade),
                            makeCSVString(index + 1),
                            makeCSVString(sub.formattedTime),
                            makeCSVString(sub.points),
                            makeCSVString(sub.percent),
                            makeCSVString(sub.grade)
                        ].join(',');
                    }).join('\n');
                }).join('\n')
            })
        ).join('\n');
        // console.log(csv);
        saveAs(new Blob([csv], {type: 'text/csv;charset=utf-8'}), "gradebook_data.csv");
    };

  return(
    <div>
      <select style={{minWidth: '250px'}} name="student" onChange={handleStudentChange} value={selectedStudent.id}>
        {/* Only show top option with "--" if no student has been selected */}
        {!selectedStudent.name.length && <option value="0">--</option> }
        {students && students.map((student, index) => {
          const {first_name, last_name, user_id} = student;
          return <option value={user_id} key={index}>
            {`${last_name}, ${first_name}`}
          </option>
        })}
      </select>
      {/* Show loading spinner while grabbing submissions */}
      {submissionsLoading && <LoadingPage/>}
      {/* Only show SubmissionsByStudentTable if submissions have been loaded/put on Redux store and if a student has been selected */}
      {
      selectedStudent.name.length > 0 && submissionsByUser && !submissionsLoading &&
        <StudentGradeBookTable selectedStudent={selectedStudent} assignments={assignments} submissionsByStudent={submissionsByUser} getStudentSubmissions={getStudentSubmissions} exportCSV={exportCSV} />
      }
    </div>
  )
}
export default SubmissionByStudent;
