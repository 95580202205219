import React, {useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';
import {Draggable} from 'react-beautiful-dnd';
import {Collapse} from 'reactstrap';
import {HarmoniaPaper} from '@harmonia-front-end/shared';
import {useHarmoniaDispatch} from '../../redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleDown, faAngleRight, faCaretUp, faCaretDown, faEdit} from '@fortawesome/free-solid-svg-icons';
import LoadingPage from '../../components/LoadingPage';
import AssignmentListingItemStyle from './AssignmentListingItem.module.scss';
import {Assignment} from '../../types';
import {showDateAsFormattedString} from '../../utilities';

const {assignmentListingItem, assignmentWrapper, assignmentTop, assignmentTopLeft, set, draggableArrows, docsCollapseArrow, edit, assignmentDates, assignmentBottom, assignedDue, documentsCollapseWrapper, docPreviewLink} = AssignmentListingItemStyle;

type AssignmentListingItemProps = {
    assignment: Assignment,
    index: number,
    openDocPreviewModal: any,
    getDocumentImagePreview: (assignmentIndex: number, documentIndex: number) => void,
    modalShow: boolean | (() => void),
    isTA: boolean
}
const AssignmentListingItem = (props: AssignmentListingItemProps) => {

    const {assignment, index, openDocPreviewModal, getDocumentImagePreview, modalShow, isTA} = props;
    const dispatch = useHarmoniaDispatch();
    const [docsLoading, setDocsLoading] = useState(false);


    const launchDocumentModal = (assignmentIndex: number, documentIndex: number) => {
        if (!modalShow) {
            openDocPreviewModal();
        }
        getDocumentImagePreview(assignmentIndex, documentIndex);

    }
    // local state for handling collapse to view documents
    const [docsCollapseOpen, setDocsCollapseOpen] = useState(false);
    const docsCollapseIcon = docsCollapseOpen ? faAngleDown : faAngleRight;
    const toggleDocsCollapse = () => setDocsCollapseOpen(prevState => !prevState);

    return (
        <Draggable draggableId={'draggable-' + assignment.id} index={index} isDragDisabled={isTA}>
            {(provided) => (
                <HarmoniaPaper
                    id={'assignment-listing-item-' + assignment.id}
                    className={assignmentListingItem}
                    {...provided.draggableProps}

                    ref={provided.innerRef}
                    data-cy="assignment-item"
                >
                    <div className={assignmentWrapper}>
                        <div className={assignmentTop}>
                            <div className={assignmentTopLeft}>
                                <div className={draggableArrows} {...provided.dragHandleProps}>
                                    {!isTA ? <FontAwesomeIcon icon={faCaretUp} /> : <div style={{width: '1px', height: '16px'}} />}
                                    <div className={set}>{`Set ${index + 1}`}</div>
                                    {!isTA && <FontAwesomeIcon icon={faCaretDown} />}
                                </div>

                                <FontAwesomeIcon className={docsCollapseArrow} icon={docsCollapseIcon} onClick={toggleDocsCollapse} />

                                <div className="assignmentInfo">
                                    <div className="assignmentTitle">
                                        {assignment.title}
                                    </div>
                                </div>
                            </div>
                            {!isTA && (
                                <div className="assignmentTopRight">
                                    <Link to={`/course/${assignment.course}/assignment/${assignment.id}`}>
                                        <FontAwesomeIcon icon={faEdit} className={edit} />
                                    </Link>
                                </div>
                            )}
                        </div>
                        <div className={assignmentBottom}>
                            <div className={assignmentDates}>
                                <div className="assignmentDate">
                                    <span className={assignedDue}>Assigned:</span>
                                    <span>{showDateAsFormattedString(assignment.released_at, 'YYYY-MM-DD')}</span>
                                </div>
                                <div className="assignmentDate">
                                    <span className={assignedDue}>Due:</span>
                                    <span>{showDateAsFormattedString(assignment.due_at, 'YYYY-MM-DD')}</span>
                                </div>
                            </div>
                        </div>

                        <Collapse isOpen={docsCollapseOpen}>
                            {docsLoading && <LoadingPage />}
                            {!docsLoading && assignment.documents &&
                                <div className={documentsCollapseWrapper}>
                                    {assignment.documents?.map((doc, docIndex) => {
                                        return <div className={docPreviewLink} key={docIndex}>
                                            <span>{`${docIndex + 1}.`}</span>
                                            <span onClick={() => {
                                                launchDocumentModal(index, docIndex)
                                            }}> {doc.title}</span>
                                        </div>
                                    })}
                                </div>
                            }
                        </Collapse>
                    </div>
                </HarmoniaPaper>
            )}
        </Draggable>
    );
}
export default AssignmentListingItem;

