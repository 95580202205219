/* eslint-disable no-param-reassign */

/**
 * @param min the minimum number
 * @param max the maximum number
 * @return a random integer between min (inclusive) and max (inclusive).
 */
export function randInt(min: number, max: number): number {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

/**
 * @param arr the target array to choose from
 * @return a random element in the array
 */
export function arrayChoice<T>(arr: T[]): T | never {
  const len = arr.length;
  if (len === 0) throw Error('Input array is empty.');
  const idx = randInt(0, len);
  return arr[idx];
}

export function stringIterSearch(
  target: string | undefined, source: string | undefined,
): boolean {
  if (!source) return true;
  if (!target) return false;
  let s = 0;
  for (let t = 0; t < target.length; t += 1) {
    if (target[t] === source[s]) s += 1;
    if (s === source.length) return true;
  }
  return false;
}

export * from './hooks';
