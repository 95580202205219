import React, {useState} from 'react';
import { useHarmoniaDispatch, updateAssignment } from '../../redux';
import DatePicker from 'react-datepicker';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Assignment, AssignmentDateType, AssignmentEditableFields } from '../../types';
import { HarmoniaButton, Text } from '@harmonia-front-end/shared';
import { showDateAsFormattedString } from '../../utilities';
import style from './AssignmentDetailEditDate.module.scss';
const { dateContainer,dateText,dateInput } = style;
// this component contains the date picker and all select options for changing either assigned date or due date of an assignment

type AssignmentDetailEditDateProps = {
  dateType: AssignmentDateType,
  courseStartDate: number
  courseEndDate: number,
  timestamp: number,
  assignment: Assignment
}
const AssignmentDetailEditDate = ({courseStartDate, courseEndDate,timestamp, dateType, assignment}: AssignmentDetailEditDateProps) => {

  const dispatch = useHarmoniaDispatch();
  // used for messaging in modal when assignment due/release dates are misaligned with course start/end dates.
  const [dateWarning, setDateWarning] = useState({title: '', message: ''});
  const [showWarningModal, setShowWarningModal] = useState(false);
  // used simply to have access to target date in confirm button for warning modal.
  const [newDate, setNewDate] = useState({} as Date)

  // constructing dates to put in datePicker

  // Initialize date variables. All 3 are used in date picker, but these dates will have different values depending on the dateType prop.
  const selectedDate = new Date(timestamp * 1000);
  let minDate: Date | undefined;
  let maxDate: Date | undefined;

  /** ASSIGNED/RELEASE DATE */

  const oneYear = 86400 * 365;

  if(dateType === AssignmentDateType.ASSIGNED) {
   // range for assignment release date is in between onear year before (for now) course start date and assignment due date.
    minDate = new Date((courseStartDate - oneYear) * 1000);
    maxDate = new Date((assignment.due_at - 60) * 1000);
  }

   /** DUE DATE */

  if(dateType === AssignmentDateType.DUE){
    // range for assignment due date is in between assignment release date and one year after (for now) course end date.
    minDate = new Date((assignment.released_at + 60) * 1000);
    maxDate = new Date((courseEndDate + oneYear) * 1000);
  }

  // this function is necessary so date can be changed via confirm button in warning modal.
  const confirmDateChange = (date:Date) => {
    const timestamp = Math.min(Math.max(date.getTime(), minDate.getTime()), maxDate.getTime()) / 1000;
    const dateField = dateType === AssignmentDateType.ASSIGNED ? 'released_at' : 'due_at';
    const payload = {...assignment,[dateField]: timestamp};
    const field = dateType === AssignmentDateType.ASSIGNED ? AssignmentEditableFields.DUE_AT : AssignmentEditableFields.RELEASED_AT;
    dispatch(updateAssignment({assignment: payload, field}));
    setShowWarningModal(false);
  }

  // Change handler on datePicker. Checks if dates are misaligned and if so will warn user but still give them opportunity to change dates if they choose.

  const handleDateChange = (date:Date) => {
    const timestamp = date.getTime() / 1000;
    const formattedCourseStartDate = showDateAsFormattedString(courseStartDate, 'YYYY-MM-DD hh:mm');
    const formattedCourseEndDate = showDateAsFormattedString(courseEndDate, 'YYYY-MM-DD hh:mm');
    const formattedSelectedDate = showDateAsFormattedString(timestamp, 'YYYY-MM-DD hh:mm');
    // assigned date before course start date
    if (timestamp < courseStartDate && dateType === AssignmentDateType.ASSIGNED) {
      setDateWarning({title: 'Assigned Date Before Course Start Date', message: `The selected Assigned Date of ${formattedSelectedDate} will occur before the course start date of ${formattedCourseStartDate}.`});
      setNewDate(date);
      setShowWarningModal(true);
    }
    // assigned date after course start date
     else if (timestamp > courseEndDate && dateType === AssignmentDateType.ASSIGNED) {
      setDateWarning({title: 'Assigned Date After Course End Date', message: `The selected Assigned Date of ${formattedSelectedDate} will occur after the course end date of ${formattedCourseEndDate}.`});
      setNewDate(date);
      setShowWarningModal(true);
    }
    // due date before course start date
    else if (timestamp < courseStartDate && dateType === AssignmentDateType.DUE) {
      setDateWarning({title: 'Due Date Before Course Start Date', message: `The selected Due Date of ${formattedSelectedDate} will occur before the course start date of ${formattedCourseStartDate}.`});
      setNewDate(date);
      setShowWarningModal(true);
    }
    // due date after course start date
    else if(timestamp > courseEndDate && dateType === AssignmentDateType.DUE) {
      setDateWarning({title: 'Due Date After Course End Date', message: `The selected Due Date of ${formattedSelectedDate} will occur after the course end date of ${formattedCourseEndDate}.`});
      setNewDate(date);
      setShowWarningModal(true);
      // no date warning
    } else {
      confirmDateChange(date);
    }
  };

  const text = dateType === AssignmentDateType.ASSIGNED ? 'Assigned' : 'Due';

  return (
    <>
      <Modal isOpen={showWarningModal}>
        <ModalHeader>{dateWarning.title}</ModalHeader>
        <ModalBody>
          <Text>{dateWarning.message}</Text>
          <Text fontStyle="bold">Do you wish to proceed anyway?</Text>
        </ModalBody>
        <ModalFooter>
          <HarmoniaButton variant="secondary" dest="app" onClick={() => setShowWarningModal(false)}>Cancel</HarmoniaButton>
          <HarmoniaButton variant="primary" dest="app" onClick={() =>{
            confirmDateChange(newDate)
          }}>Change Date</HarmoniaButton>
        </ModalFooter>
      </Modal>
      <div className={dateContainer} >
        <div className={dateText}>{`${text} Date:`}</div>
        <div >
              <DatePicker
              minDate={minDate as Date}
              selected={selectedDate}
              maxDate={maxDate as Date}
              onChange={handleDateChange}
              showTimeSelect
              dateFormat="Pp"
              className={dateInput}
              filterTime={(t: any) => { return (minDate === undefined || t >= minDate) && (maxDate === undefined || t <= maxDate); }}
              // customInput={<CustomInput/>}
              />
        </div>
      </div>
    </>
  )
}
export default AssignmentDetailEditDate;
