import React, { useEffect} from 'react';
import { HarmoniaButton, useToggle } from "@harmonia-front-end/shared";
import { Collapse } from 'reactstrap';
import GradeBookPageStyle from './GradeBookPage.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretRight, faTrash} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { Submission, Document } from '../../../types';
const {submissionEntry, row, studentTableRow, subCollapse, collapsibleCell, mobileHide, noEntries} = GradeBookPageStyle;

type StudentGradeBookTableEntryProps = {
  assignmentDocNum: string,
  document: Document,
  submissions: Submission[],
  expandAll: boolean,
  showSubmissionRemoveModal: (documentTitle: string, documentID: number) => void,
  showSubmissionRemoveByIDModal: (documentTitle: string, submissionID: number, indexToRemove: number) => void,
  courseID: number
}
const StudentGradeBookTableEntry = (props: StudentGradeBookTableEntryProps) => {
  const {assignmentDocNum, document, submissions, expandAll,  showSubmissionRemoveModal, showSubmissionRemoveByIDModal, courseID} = props;
  const [isExpanded, toggleExpanded] = useToggle();
  const caret = isExpanded ? faCaretDown : faCaretRight;

  // Listening for any change in the parent expand state on the StudentGradeBookTable. The local expand (for each individual entry) should toggle to match the parent whenever it is changed on the StudentGradeBookTable itself.
  useEffect(() => {
    if(expandAll !== isExpanded) {
      toggleExpanded();
    }
  }, [expandAll])
  // will render empty submission.
  const NoSubmissionEntry = () => {
    return (
      <>
        <article className={`${row} ${studentTableRow}`}>
          <section className={noEntries}>
            <article>{assignmentDocNum + ' - ' + document.title}</article>
            <article>0</article>
            <article></article>
          </section>
          <section className={mobileHide}>
            <article></article>
            <article></article>
            <article></article>
          </section>
        </article>
      </>
    )
  };
// will render single submission (or first row if multiple).
  const TopSubmissionRow = (props: {sub: Submission, isOnlyRow: boolean}) => {
    const {sub, isOnlyRow} = props;
    return (
      <section className={`${row} ${studentTableRow}`}>
        <section>
          <article>{assignmentDocNum + ' - ' + document.title}</article>

          <article>
            {submissions.length} &nbsp;
            {submissions.length > 1 &&
              <FontAwesomeIcon cursor='pointer' icon={caret} onClick={toggleExpanded} />
            }
          </article>
          <article>{sub.grade}</article>
        </section>

        <section>
          <article>#1 | {sub.formattedTime}</article>
          <article>{sub.points + " | " + sub.percent + "% | " + sub.grade}</article>
          <article>
            <Link to={`/course/${courseID}/submission/${sub.id}`}>
              View
            </Link> &nbsp;
            <FontAwesomeIcon cursor='pointer' icon={faTrash} onClick={isOnlyRow ? () => showSubmissionRemoveModal(document.title, document.id) : () => showSubmissionRemoveByIDModal(document.title, sub.id, 1)} />
          </article>
        </section>
      </section>
    )
  };
// will render remaining submissions (if multiple).
  const CollapsibleSubmissionRow = (props: {sub: Submission, index: number}) => {
    const {sub, index} = props;
    // console.log(sub)
    return (
      <section className={`${row} ${studentTableRow} ${submissionEntry}`}>
        <section className={mobileHide}>
          <article></article>
          <article></article>
          <article></article>
        </section>
        <section>
          <article className={collapsibleCell}>#{index + 2} | {sub.formattedTime}</article>
          <article className={collapsibleCell}>{sub.points + ' | ' + sub.percent + '% | ' + sub.grade}</article>
          <article className={collapsibleCell}>
            <FontAwesomeIcon cursor='pointer' icon={faTrash} onClick={() => showSubmissionRemoveByIDModal(document.title, sub.id, index + 2)} />
          </article>
        </section>
      </section>
    )
  }
  return (
    <section className={submissionEntry}>
      {/* 0 submissions */}
      {!submissions.length && <NoSubmissionEntry/>}
      {/* 1 submission */}
      {submissions.length === 1 && <TopSubmissionRow sub={submissions[0]} isOnlyRow={true}/>}
      {/* 1+ submissions */}
      {submissions.length > 1 &&
      <>
      <TopSubmissionRow sub={submissions[0]} isOnlyRow={!isExpanded}/>
      <Collapse isOpen={isExpanded} className={subCollapse}>
        {submissions.slice(1).map((sub, index) => {
          return <CollapsibleSubmissionRow sub={sub} key={index} index={index}/>
        })}
      </Collapse>
      </>
      }
    </section>
  )
}
export default StudentGradeBookTableEntry;
