import React, {useState} from 'react';
import { useHarmoniaDispatch, createUserCourseEnrollmentRequest, deleteUserCourseEnrollmentRequest } from "../../../redux";
import {
  HarmoniaButton,
  HarmoniaInput,
  HarmoniaPaper,
  Text,
  useToggle
} from "@harmonia-front-end/shared";
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import EnrollmentRequestEntry from './EnrollmentRequestEntry';
import { CourseEnrollmentRequest} from "../../../types";
import CourseManagementStyle from './CourseManagement.module.scss';

const {top, container, header, courseEnrollHeader, codeInput, inviteLabel, tableContainer, table} = CourseManagementStyle;

type EnrollmentRequestsProps = {
    enrollmentRequests: CourseEnrollmentRequest[];
}

const EnrollmentRequests = ({enrollmentRequests}:EnrollmentRequestsProps) => {
    const dispatch = useHarmoniaDispatch();

    /*
    Making an enrollment request: state and handlers for opening join modal, inputting invitation code and making join request.
    */
    const [joinModalOpen, toggleJoinModalOpen] = useToggle();
    const [invitationCode, setInvitationCode] = useState('');
    const [invitationError, setInvitationError] = useState(false);
    const handleInvitationCodeInput = (event) => {
        setInvitationCode(event.currentTarget.value);
    }
    const submitInvitationCode = () => {
        if(invitationCode.length !== 6) {
            setInvitationError(true);
        } else {
            dispatch(createUserCourseEnrollmentRequest(invitationCode)).finally(()=>{
                toggleJoinModalOpen();
                setInvitationCode('');
            });
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && e.shiftKey == false) {
            submitInvitationCode();
        }
    };

    /*
     Deleting an enrollment request: state and handlers for opening delete modal with proper request info and making delete request.
     */

    const [deleteModalOpen, toggleDeleteModalOpen] = useToggle();
    const [requestToDelete, setRequestToDelete] = useState({} as CourseEnrollmentRequest);
    const showDeleteRequestModal = (request: CourseEnrollmentRequest) => {
        toggleDeleteModalOpen();
        setRequestToDelete(request);
    };
    const confirmDeleteEnrollmentRequest = (request: CourseEnrollmentRequest) => () => {
        dispatch(deleteUserCourseEnrollmentRequest(request)).finally(() => {
            toggleDeleteModalOpen();
            setRequestToDelete({} as CourseEnrollmentRequest);
        })
    };
    // Note: it is better to render delete modal only once in this parent component and pass down the relevant state and handlers defined above to each entry component


    return (
        <>
            <HarmoniaPaper className={container}>
                <div className={top}>
                    <Text variant="h2" color="dark">
                        Enrollment Requests
                    </Text>
                    <HarmoniaButton type="button" icon="next" iconPos="left" variant="primary" dest="app"
                    onClick={()=>{toggleJoinModalOpen()}} data-cy="join-course">
                        Join A Course
                    </HarmoniaButton>
                </div>
                {/* only show table with enrollment requests if there are any to show */}
              {enrollmentRequests && enrollmentRequests.length > 0 &&
                <div className={tableContainer}>
                    <table className={table}>
                        <thead>
                            <tr className={header}>
                                <th>Course</th>
                                <th>School</th>
                                <th>Dates</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {enrollmentRequests?.map((request, index) => {
                                return <EnrollmentRequestEntry enrollmentRequest={request}
                                showDeleteRequestModal={showDeleteRequestModal} key={index}/>
                            })}
                        </tbody>
                    </table>
                </div>
              }
            </HarmoniaPaper>
            {/* pop-up modal for making an enrollment request */}
            <Modal isOpen={joinModalOpen}>
                <ModalHeader>
                    <Text>
                        Join a Course
                    </Text>
                </ModalHeader>
                <ModalBody>
                    <HarmoniaInput onChange={handleInvitationCodeInput} data-cy="join-field" className={codeInput} maxLength={6}placeholder="Enter 6-digit invitation code" onKeyPress={handleKeyPress}>
                    </HarmoniaInput>
                    {/* show error message if error */}
                    {invitationError && <Text color="red" style={{margin: 4}}>Invitation code must be 6 digits</Text>}
                </ModalBody>
                <ModalFooter>
                    <HarmoniaButton variant="secondary" dest="app" onClick={toggleJoinModalOpen}>Cancel</HarmoniaButton>
                    <HarmoniaButton variant={invitationCode.length < 6 ? 'disabled' : 'primary'} dest="app" type="submit" onClick={()=>{submitInvitationCode()}} data-cy="join-submit" disabled={invitationCode.length < 6}>Submit</HarmoniaButton>
                </ModalFooter>
            </Modal>
            {/* pop-up modal for removing an enrollment request*/}
            {requestToDelete.id > 0 &&
            <Modal isOpen={deleteModalOpen}>
                <ModalBody>
                    <Text color="dark">{`Are you sure you want to remove your request to join ${requestToDelete.course.title} at ${requestToDelete.course.school.school}?`}</Text>
                </ModalBody>
                <ModalFooter>
                    <HarmoniaButton variant="secondary" dest="app" onClick={toggleDeleteModalOpen}>
                        Cancel
                    </HarmoniaButton>
                    <HarmoniaButton variant="primary" dest="app" onClick={confirmDeleteEnrollmentRequest(requestToDelete)}>
                        Confirm Removal
                    </HarmoniaButton>
                </ModalFooter>
            </Modal>
            }
        </>
    );
}
export default EnrollmentRequests;
