import { useState, useEffect } from 'react';
import { Redirect, Route, Switch, useParams, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useHarmoniaSelector, getCourseDetail, useHarmoniaDispatch, getCourseStudents, getCourseAssignments } from '../../redux';
import { DashboardContent, DashboardTitleBar } from '../../components/template';
import { useQueryParams } from '../../hooks';
import LoadingPage from '../../components/LoadingPage';
import {
  HarmoniaButton,
  HarmoniaInput,
  HarmoniaPaper,
  Text
} from "@harmonia-front-end/shared";

import { SubmissionByAssignment, SubmissionByStudent} from '../../components/course/GradeBook';
import { Assignment,CourseStudent } from '../../types';
import CoursePagesStyle from './CoursePages.module.scss';
// CSS classes used for this component.
const {gradeBookContainer} = CoursePagesStyle;

// Typing for viewing gradebook.
export enum ViewByStudentOrAssignmentType {
  NONE, STUDENT, ASSIGNMENT
}
import { useRedirect } from '../../hooks';

/** GradeBookPage walkthrough/rundown

   We split viewing submissions into 2 separate components, the rendering of which is controlled by local state (viewByStudentOrAssignment) on the GradeBookPage component:
    1. SubmissionByStudent: for viewing all submissions by a single student for every document. The respective state for this on the Redux store is the 'submissionsByUser' key on the selectedCourse reducer.
    2. SubmissionByAssignment: for viewing all submissions for every student for a single assignment. The respective state for this on the Redux store is the 'submissionsByAssignment' key on the selectedCourse reducer.


    When the GradeBookPage mounts, we make API calls to grab both the students and assignments for a course for use in either component.
    Both these components are very similar:

      1. They have a dropdown for selecting a student/assignment, and when one is selected an API call is made to grab the respective submissions and render a table(StudentGradeBookTable or AssignmentGradeBookTable) to display them.
      2. They revolve around local state (selectedStudent/selectedAssignment and use this state for any sorting/view options to change what is displayed by the table.
      3. They each have a function (getStudentSubmissions or getDoucmentSubmissions) which performs the logic to grab the correct student submission(s) for each document, sorting them by descending score.
      4. Only 2 API calls are made: one when selecting a student/assignment and initially rendering the table (i.e. grabbing the proper submissions), and another for deleting any submissions. The delete functions make the exact same API call, but dispatch different Redux actions (updating submissionsByUser vs. submissionsByAssignment on the selectedCourse reducer).
      5. They both then render styled tables with the relevant submission info. A key difference is that the SubmissionByAssignment component can actually render one of two tables:
        1. AssignmentGradeBookTable: shows a general overview of a submission by every student for each assignment document in a static table. It only shows one selectable value (points, percent or grade) for each submission. The "Overview" option is the default, and this table is rendered when an Assignment is first selected or if the "Overview" option is re-selected after having selected a document.
        2. DocumentGradeBookTable: shows document submissions by every student in a level of detail and function (dropdowns to see any multiple submissions) similar to the table on SubmissionByStudent (called StudentGradeBookTable). Both of these tables render a table entry component for each submission. Each entry contains the delete submission functionality. Selecting a document sets a local state variable called 'selectedDocument' which is used to render each document submission in detail and for switching between rendering the overview AssignmentGradeBookTable or DocumentGradeBookTable.

 */


const GradeBookPage = () => {
  const dispatch = useHarmoniaDispatch();
  const history = useHistory();
  const location = useLocation();

  const urlPath = location.pathname.split('/')

  // Local state for displaying by student or assignment, based off of enum type,
  // rendering different table components based on URL
  const [viewByStudentOrAssignment, setViewByStudentOrAssignment] = useState(
    urlPath[urlPath.length - 1] == "assignment" ? ViewByStudentOrAssignmentType.ASSIGNMENT :
    urlPath[urlPath.length - 1] == "student" ? ViewByStudentOrAssignmentType.STUDENT :
    ViewByStudentOrAssignmentType.NONE);

  // Grab courseId from params, convert to number (courseID).
  const { courseId } = useParams<{courseId: string}>();
  const courseID = parseInt(courseId, 10);

  // state variables for tracking loading. will probably use later.
  const [courseDetailsLoading, setCourseDetailsLoading] = useState(true);
  const [studentsLoading, setStudentsLoading] = useState(true);
  const [assignmentsLoading, setAssignmentsLoading] = useState(true);

  // Determine whether or not a user is an instructor once the courses load
  const courses = useHarmoniaSelector(state => state.courses)
  let isTA = false;
  let foundCourse = courses?.course_instructors?.find(course => course.id === courseID);
  if (!foundCourse) {
     isTA = true;
     foundCourse = courses?.course_tas?.find(course => course.id === courseID);
  }
  let needToRedirect = foundCourse ? false : !Object.keys(courses).length ? false : true;

  // API call for courseDetails, courseAssignments, and courseStudents
  useEffect(() => {
    if (foundCourse) {
      dispatch(getCourseDetail(courseID)).finally(() => {setCourseDetailsLoading(false)});
      dispatch(getCourseAssignments(courseID)).finally(() => setAssignmentsLoading(false));
      dispatch(getCourseStudents(courseID)).finally(() => setStudentsLoading(false));
    }
  }, [courseId, dispatch, foundCourse]);


  // Redirect for non-assocation in course
  useRedirect(needToRedirect, '/', {
    severity: "error",
    message: "You are not an instructor of this course."
  });

  // Changing viewing by student or assignment.
  const changeViewBy = (ev) => {
    const viewSetting = ev.currentTarget.value;
    const setting = ev.currentTarget.value == 1 ? "student" : "assignment";
    const changeFrom = ev.currentTarget.value == 1 ? "assignment" : "student";
    setViewByStudentOrAssignment(viewSetting);
    if (urlPath[urlPath.length - 1] == "grade-book") {
      history.push("grade-book/" + setting)
    } else {
      history.push(setting)
    }
  }

  // Grabbing all relevant course stuff from Redux.
  const {selectedCourse} = useHarmoniaSelector((state) => state);
  const {courseInformation, students, assignments} = selectedCourse;
  
  return (
    <>
      {courseDetailsLoading && <DashboardContent><LoadingPage/></DashboardContent>}
      {!courseDetailsLoading && 
        <>
        <DashboardTitleBar h1="Gradebook" h2={courseInformation?.title} h2_no2={`${courseInformation?.academic_term} ${courseInformation?.year}`} />
        <DashboardContent>
          <HarmoniaPaper className={gradeBookContainer}>
            <div>
              <label htmlFor="viewByStudent">View By Student</label>
              <input
                name="viewBy"
                type="radio"
                value={ViewByStudentOrAssignmentType.STUDENT}
                onChange={changeViewBy}
                checked={viewByStudentOrAssignment == ViewByStudentOrAssignmentType.STUDENT ? true : false}
              />
              <label htmlFor="viewByAssignment">View By Assignment</label>
              <input
                name="viewBy"
                type="radio"
                value={ViewByStudentOrAssignmentType.ASSIGNMENT}
                onChange={changeViewBy}
                checked={viewByStudentOrAssignment == ViewByStudentOrAssignmentType.ASSIGNMENT ? true : false}
              />
            </div>
            
            <Route path="/course/:courseID/grade-book/student">
              <SubmissionByStudent urlPath={urlPath} courseID={courseID} assignments={assignments as Assignment[]} students={students as CourseStudent[]} />
            </Route>
            <Route path="/course/:courseID/grade-book/assignment">
              <SubmissionByAssignment courseID={courseID} assignments={assignments as Assignment[]} students={students as CourseStudent[]} />
            </Route>
          </HarmoniaPaper>
        </DashboardContent>
        </>
      }
    </>
  );

}

export default GradeBookPage;
