import React, { useState, useEffect } from "react";
import { AllCourses } from "../components/dashboard/courses";
import {
  EnrollmentRequests,
  CourseInvitations,
} from "../components/dashboard/course-management";
import { DashboardTitleBar, DashboardContent } from "../components/template";
import { HarmoniaButton } from "@harmonia-front-end/shared";
import { useUser } from "../hooks";
import {
  useHarmoniaDispatch,
  useHarmoniaSelector,
  createCourse,
  getUserInfo,
  pushNotification,
} from "../redux";
import LoadingPage from "../components/LoadingPage";
import { CourseEnrollmentRequest } from "../types";

type HomePageProps = {
  courseArchived: boolean;
};

export default function HomePage(props: HomePageProps) {
  const dispatch = useHarmoniaDispatch();
  const { courseArchived } = props;
  // grab user info
  // const [user, userLoading] = useUser();
  const [userLoading, setUserLoading] = useState(true);
  const [waiting, setWaiting] = useState(false);

  useEffect(() => {
    dispatch(getUserInfo()).finally(() => {
      setUserLoading(false);
    });
  }, [dispatch]);

  // make API call for grabbing user info: courses, invitations and enrollment requests

  // grab enrollment requests and invitations from Redux store
  const {
    enrollmentRequests,
    invitations,
    course_instructors,
    course_students,
    course_tas,
  } = useHarmoniaSelector((state) => state.courses);

  const { user } = useHarmoniaSelector((state) => state.user);

  // Handler for creating new course
  const createNewCourse = () => () => {
    dispatch(createCourse());
  };
  return (
    <>
      {/* if we're waiting for a course to dulicate, pretend we're at "Edit Course Settings" and show the page loading circle.
          duplicating a course takes a while and takes 10 secs. or so to redirect. */}
      <DashboardTitleBar h1={"Courses"}>
        {/* only instructors can create new course */}
        {user?.user_type === 2 && !waiting && (
          <HarmoniaButton
            icon="add-to"
            iconPos="left"
            variant="primary"
            dest="app"
            onClick={createNewCourse()}
          >
            Create New Course
          </HarmoniaButton>
        )}
      </DashboardTitleBar>
      {waiting && <DashboardContent><LoadingPage/></DashboardContent>}
      {!waiting && <DashboardContent>
        {/* this component should always render because it contains "Join A Course" button */}
        <EnrollmentRequests
          enrollmentRequests={enrollmentRequests as CourseEnrollmentRequest[]}
        />
        {/* only render Course Invitations component if there are any invitations to show */}
        {invitations && invitations.length > 0 && (
          <CourseInvitations invitations={invitations} />
        )}
        {course_instructors && course_students && course_tas && (
          <AllCourses
            courseArchived={courseArchived}
            course_instructors={course_instructors}
            course_tas={course_tas}
            course_students={course_students}
            setWaiting={(waiting: boolean) => {setWaiting(waiting);}}
          />
        )}
      </DashboardContent>}
    </>
  );
}
