/* eslint-disable @typescript-eslint/naming-convention, react/jsx-props-no-spreading */
import React, {useState} from 'react';
import {
  HarmoniaButton, HarmoniaInput, HarmoniaPaper, Order, Text, useToggle,
} from '@harmonia-front-end/shared';
import { Link } from 'react-router-dom';
import {
  Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';
import {
  faArrowRight,
  faCaretUp,
  faCaretDown,
  faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Draggable } from 'react-beautiful-dnd';
import { MediaCollectionWithFiles } from '../../types';
import { deleteMediaCollectionByID, updateMediaCollectionTitleByID, useHarmoniaDispatch } from '../../redux';
import styles from './MediaCollectionDisplay.module.scss';

const { mediaItem, mediaItemTop, itemTopLeft, arrows, mediaTitle, titleInput, deleteMedia, mediaItemBottom, filesLink } = styles;

type MediaCollectionDisplayProps = {
  mediaCollection: MediaCollectionWithFiles;
  index: number;
};

function MediaCollectionDisplay({ mediaCollection, index }: MediaCollectionDisplayProps) {
  const { collection_title, id } = mediaCollection;
  const titleRef = React.useRef<HTMLInputElement>(null);
  const dispatch = useHarmoniaDispatch();

  // React.useEffect(() => {
  //   if (titleRef.current) titleRef.current.value = collection_title;
  // }, [collection_title]);

  const [collectionTitle, setCollectionTitle] = useState(collection_title);

  const [deleteDialogOpen, toggleDeleteDialog] = useToggle();

  // handler for text input in collection title field
  const handleTextInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setCollectionTitle(e.currentTarget.value);
  };

  const changeCollectionNameOnBlur: React.FocusEventHandler<HTMLInputElement> = (ev) => {
    ev.preventDefault();
    const trimmedNewTitle = ev.currentTarget.value.trim();
    if (trimmedNewTitle && trimmedNewTitle !== collection_title) {
      dispatch(updateMediaCollectionTitleByID({
        mediaCollectionID: id,
        newTitle: trimmedNewTitle,
      }));
    }
  };
  const changeCollectionNameOnReturn: React.KeyboardEventHandler<HTMLInputElement> = (ev) => {
    if (ev.key === 'Enter') ev.currentTarget.blur();
  };

  const deleteMediaCollection = () => {
    toggleDeleteDialog();
    dispatch(deleteMediaCollectionByID(id));
  };

  const titleInputWidth = `${collectionTitle.length * 1}%`;

  const width = collectionTitle.length > 40 ? `${collectionTitle.length}%` : '40%';


  return (
    <>
      <Draggable draggableId={`${id}`} index={index}>
        {(draggableProvided) => (
          <HarmoniaPaper
            ref={draggableProvided.innerRef}
            {...draggableProvided.draggableProps}
            {...draggableProvided.dragHandleProps}
            className={mediaItem}
          >
            <div className="content">
              <div className={mediaItemTop}>
                <div className={itemTopLeft}>
                  <div className={arrows}>
                    <FontAwesomeIcon icon={faCaretUp}/>
                    <FontAwesomeIcon icon={faCaretDown}/>
                  </div>
                  <div className={mediaTitle}>
                    <label>
                      <Text color="dark">Collection Title:</Text>
                    </label>
                    <input
                      style={{'width': width}}
                      ref={titleRef}
                      type="text"
                      value={collectionTitle}
                      onChange={handleTextInput}
                      onBlur={changeCollectionNameOnBlur}
                      onKeyDown={changeCollectionNameOnReturn}
                      className={titleInput}
                    />
                  </div>
                </div>
                <div className="collectionTopRight">
                  <div className={deleteMedia} onClick={toggleDeleteDialog}>
                    <FontAwesomeIcon icon={faTrashAlt}/>
                    {/* <HarmoniaButton variant="icon" dest="app" icon="delete" onClick={toggleDeleteDialog} /> */}
                  </div>
                </div>
              </div>
              <div className={mediaItemBottom}>
                <Link to={`/media-library/${id}`} className={filesLink}>
                  <FontAwesomeIcon icon={faArrowRight}/>
                  <Text color="lightBlue" variant="body">
                    See media files
                  </Text>
                </Link>
              </div>

              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}

              {/* <label>
                <Text color="dark">Media Collection Name:</Text>
              </label>
              <HarmoniaInput
                  ref={titleRef}
                  onBlur={changeCollectionNameOnBlur}
                  onKeyDown={changeCollectionNameOnReturn}
                />
              <Link to={`/media-library/${id}`}>
                <Text color="lightBlue" variant="body">
                  See media files
                </Text>
              </Link> */}

            </div>
            {/* <div className={right}>
              <HarmoniaButton variant="icon" dest="app" icon="delete" onClick={toggleDeleteDialog} />
            </div> */}
          </HarmoniaPaper>
        )}
      </Draggable>

      <Modal isOpen={deleteDialogOpen} toggle={toggleDeleteDialog}>
        <ModalHeader>
          <Text color="dark" variant="h2">Delete Media Collection</Text>
        </ModalHeader>
        <ModalBody>
          <Text color="dark" variant="body">
            {`Are you sure you want to delete media collection "${collection_title}"?`}
          </Text>
          <Text color="dark" variant="body">
            This action cannot be undone.
          </Text>
        </ModalBody>
        <ModalFooter>
          <HarmoniaButton variant="secondary" dest="app" onClick={toggleDeleteDialog}>
            Cancel
          </HarmoniaButton>
          <HarmoniaButton variant="primary" dest="app" onClick={deleteMediaCollection}>
            Delete
          </HarmoniaButton>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default MediaCollectionDisplay;
