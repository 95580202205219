// import libraries
import React, {useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import {
  useHarmoniaSelector,
  useHarmoniaDispatch,
  getCourseDetail, makeFastspringPurchase
} from "../../redux";
import { DashboardTitleBar, DashboardContent } from "../../components/template";
import { HarmoniaPaper, HarmoniaButton } from "@harmonia-front-end/shared/component";
import { faExclamationCircle, faInfoCircle, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { CourseDetailedInfo, User } from "../../types";
import { showDateAsFormattedString } from "../../utilities";
import LoadingPage from "../../components/LoadingPage";
import style from '../course/CoursePages.module.scss';
import StudentCourseInfoStyle from './StudentPages.module.scss';
import { addStoreBuilderLibrary,checkoutFastSpring } from "../../utilities/fastspring";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRedirect } from '../../hooks';

const {courseInfoContainer, courseInfo, invitationCodeLink} = style;
const {paymentPrompt} = StudentCourseInfoStyle;

export default function StudentCourseInformationPage() {
  const dispatch = useHarmoniaDispatch();

  // Grab courseId (string) from params and convert it to a number (courseID).
  const { courseId } = useParams<{courseId: string}>();
  const courseID = parseInt(courseId, 10);

  // Local state for adding loading page when grabbing course info.
  const [courseDetailsLoading, setCourseDetailsLoading] = useState(true);

  // Determine whether or not a user is an instructor once the courses load
  const courses = useHarmoniaSelector(state => state.courses)
  // let isTA = true;
  let foundCourse = courses?.course_students?.find(course => course.id === courseID);
  // if (!foundCourse) {
  //     isTA = false;
  //     foundCourse = courses?.course_students?.find(course => course.id === courseID);
  // }
  let needToRedirect = foundCourse ? false : !Object.keys(courses).length ? false : true;

  // Making API call to grab course detail and set/unset loading.
  useEffect(() => {
    if (foundCourse) {
      dispatch(getCourseDetail(courseID)).finally(() => {
        setCourseDetailsLoading(false);
      });
    }
  }, [dispatch, foundCourse]);
  
  useRedirect(needToRedirect, '/', {
    severity: "error",
    message: "You are not a student of this course."
  });

  // Grabbing course information from Redux.
  const selectedCourse = useHarmoniaSelector((state) => state.selectedCourse);
  const user = useHarmoniaSelector(state => state.user);
  const courseInformation = selectedCourse.courseInformation as CourseDetailedInfo || {};
  // Destructuring relevant course info.
  const {academic_term, cadential_64, course_product, course_product_price, end_date, enrollment_end_date, fastspring_product_path, instructors, invitation_code, neapolitan_6, payment_type, payment_id, school, start_date, status, title, year, lms_type  } = courseInformation;

  const {first_name, last_name, email} = user.user as User || {};
  // Determing what text to display regarding the fee paid for the course; the keys in this object refer to the payment_type on the course details object.
  const course_fee_paid_by = {
    0: 'Fee Paid:',
    1: 'Fee Paid:',
    2: 'Fee Paid By Students:',
    3: 'Fee Paid By School:'
  }
// Functions to 'fee paid/fee paid by': ${price}. Unavoidable Typescript 'error' due to using variable for object key; just ignore!
  const renderCoursePaidBy = (paymentType: number) => {
    return course_fee_paid_by[paymentType];
  }
  const renderCourseFee = (paymentType: number) => {
    // unpaid course
    if(paymentType === 0){
      return 'This is an unpaid course';
    }
    // free course
    if(paymentType === 1){
      return 'This course is free to join';
    }
    // fastspring or site license
    else {
      return `$${course_product_price} USD per student (${course_product})`;
    }
  }
  // Building FastSpring store instance. First, we define our function that will run when the pop up storefront is closed, and then pass that to our store builder function to create the it (see utilities/fastspring.ts).
  useEffect(() => {
    const dataPopupClosed = (orderRef: {id: string, reference: string}) => {
        if(orderRef) {
          console.log('orderRef: ', orderRef);
          dispatch(makeFastspringPurchase({orderID: orderRef.id, reference: orderRef.reference, courseID }));
          console.log('the order ref is: ', orderRef);
        } else {
          console.log('no order ID');
        }
    };
    addStoreBuilderLibrary(dataPopupClosed);
  }, []);
  // Handler for opening pop-up storefront. We pre-populate certain fields with info from course and user.
  const launchCheckout = () => {
    const checkoutInfo = {
      path: fastspring_product_path,
      email,
      firstName: first_name,
      lastName: last_name
    }
    checkoutFastSpring(checkoutInfo);
  }

  // color coding course status

  // console.log('state is: ', useHarmoniaSelector(state => state));

  return (
    <>
    {courseDetailsLoading && <DashboardContent><LoadingPage/></DashboardContent>}
    {!courseDetailsLoading && <DashboardTitleBar h1="Course Information">
      </DashboardTitleBar>}
      <DashboardContent>
      <HarmoniaPaper className={courseInfoContainer}>
        {!courseDetailsLoading &&
        <>
    {/* {payment_id === 0 &&
    <button onClick={launchCheckout}>CHECKOUT</button>
} */}
    <div className={courseInfo}>
      {
      (!payment_id /* && !isTA */) &&
      <div className={paymentPrompt}>
        <div onClick={launchCheckout}>
          <span><FontAwesomeIcon icon={faInfoCircle}/></span>
          <span>Click here to pay your course fee.</span>
        </div>
      </div>
}
      <div>
        <span>Course Title: </span>
        <span>{title}</span>
      </div>
      <div>
        <span>Instructors: </span>
        <span>{instructors?.map(instructor => `${instructor.last_name}, ${instructor.first_name}`).join('; ')}</span>
      </div>
      <div>
        <span>Institute: </span>
        <span>{school}</span>
      </div>
      <div>
        <span>Academic Term: </span>
        <span>{`${academic_term} ${year? year : ''}`}</span>
      </div>
      <div>
        <span>Course Period: </span>
        <span>{`${showDateAsFormattedString(start_date, 'Pp')} - ${showDateAsFormattedString(end_date, 'Pp')}`}</span>
      </div>
      <div>
        <span>Enrollment End Date: </span>
        <span>{showDateAsFormattedString(enrollment_end_date, 'Pp')}</span>
      </div>
      <div>
        <span>Invitation Code: </span>
        <span>{invitation_code}</span>
        {/* PLACEHOLDER LINK FOR NOW */}
        <a href='https://harmonia.illiacsoftware.com/support?article=119' target="_blank">
        <span className={invitationCodeLink}>[What is this?]</span>
        </a>
      </div>
      {/* if course_product/course_product_id is null, don't show */}
      {course_product && course_product_price &&
      <div>
        <span>{renderCoursePaidBy(payment_type)}</span>
        <span>{renderCourseFee(payment_type)}</span>
      </div>
      }
      <div>
        <span>Cadential 64 in this course is labeled as: </span>
        <span>{cadential_64 === 'V' ? 'V64' : 'I64'}</span>
      </div>
      <div>
        <span>Neapolitan 6 in this course is labeled as: </span>
        <span>{neapolitan_6 === 'N' ? 'N6' : 'bII6'}</span>
      </div>
      {process.env.REACT_APP_HARMONIA_ENV !== 'production' &&
          <div>
              <span>LMS Integration: </span>
              <span>{lms_type === 1 ? "Google Classroom" : lms_type === 2 ? "LTI Plugin" : "None"}</span>
          </div>
      }
      <div>
        <span>Status: </span>
        <span>{status}</span>
      </div>
    </div>
    </>
}
    </HarmoniaPaper>
    </DashboardContent>
    </>
  );
}
