/* eslint-disable react/jsx-props-no-spreading */
import React, {useState} from 'react';
import { Spinner} from 'reactstrap';
import ReactDOM  from 'react-dom';
import {
  HarmoniaButton,
  Text
} from '@harmonia-front-end/shared';
import { addDocumentsFromContentLibrary, getContentLibraryDocumentPreviewURL, useHarmoniaDispatch } from '../../redux';
import ModalDocCollection from './ModalDocCollection';
import LoadingPage from '../LoadingPage';
import AddDocFromContentLibModalStyle  from './AddDocFromContentLibModal.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Document, Assignment,  ContentLibrary } from '../../types';
import { faEye } from '@fortawesome/free-solid-svg-icons';

const {modalContainer, modalContent, modalImagePreview, modalDocumentSidebar, sidebarDocContainer, sidebarDocCollection, docCollectionTitle,  sidebarAdd, addToAssignmentSet,  cancel, docImageTitle, docPreviewImageWrapper, clickOnDoc, docPreviewImage, close} = AddDocFromContentLibModalStyle;

type AddDocFromContentLibModalProps = {
  contentLibrary: ContentLibrary | null | undefined,
  show: boolean | (() => void)
  closeModal: any,
  assignment: Assignment
}

const AddDocFromContentLibModal = (props: AddDocFromContentLibModalProps) => {
  // deconstruct props
  const {contentLibrary, show, assignment, closeModal} = props;
  // trigger re-render if contentLibrary isn't loaded;
  if(!show || !contentLibrary) return null;
  // local state used for selecting a document for image preview
  const [docID, setDocID] = React.useState(0);
  // local state to have loading animation while awaiting api call for image preview
  const [urlLoading, setURLLoading] = React.useState(false);
  // local state to have loading animation on button while awaiting api call for adding documents
  const [addDocsLoading, setDocsLoading] = React.useState(false);
  const dispatch = useHarmoniaDispatch();
  // local state for array of selected documents to add to assignment set. actions include adding single doc, set of docs (array), removing a single doc and removing all docs (to be done after api call)
  const initialDocsToAdd:Document[] = [];
  const [documentsToAdd, setDocumentsToAdd] = useState(initialDocsToAdd);

  // function to select a new document into array of documents to add to assignment set. using concat so original array is not mutated per rules of React state.
  const selectDocumentToAdd = (newDoc: Document) => {
    // checking if the document has already been selected to add
    if(!addDocsLoading){
     if (documentsToAdd.filter((doc: Document) => doc.id === newDoc.id).length === 0){
       const newDocsToAdd: Document[] = [...documentsToAdd, newDoc];
       setDocumentsToAdd(newDocsToAdd)
     }
    }
   };
   // function to select all documents in a set to add.
  const selectDocumentSetToAdd = (documents: Document[]) => {
    if(!addDocsLoading){
      const newDocsToAdd = [...documentsToAdd, ...documents];
      setDocumentsToAdd(newDocsToAdd)
    }
  };
// function to remove a single document from set to add. using filter to not mutate original array.
  const removeDocumentToAdd = (removedDoc: Document) => {
    if(!addDocsLoading){
      const documents = documentsToAdd.filter(doc => doc.id !== removedDoc.id)
      setDocumentsToAdd(documents);
    }
  };
  // this function fires after api call (after a doc/docs is added to assignment set, the array to add should be cleared )
  const removeAllDocumentsToAdd = () => {
    if(!addDocsLoading){
      setDocumentsToAdd([]);
    }
  };
  // function to make api call to add all selected documents from content library to assignment set.
  const addSelectedDocumentsToAssignment = () => {
    if(!addDocsLoading){
    if(documentsToAdd.length > 0){
      setDocsLoading(true);
      dispatch(addDocumentsFromContentLibrary({assignmentID: assignment.id,newDocuments: documentsToAdd} )).finally(() =>{
        removeAllDocumentsToAdd();
        setDocsLoading(false);
        closeModal();
      });
      ;
    }
   }
  };

  // getting document image preview URL
  const getDocumentImagePreview = (id: number) => () => {
    setDocID(id);
    setURLLoading(true);
    dispatch(getContentLibraryDocumentPreviewURL(id))
    .finally(() => setURLLoading(false));
  }
  const selectedDoc = React.useMemo(() => contentLibrary?.find(
    (lib) => lib.documents.some((doc) => doc.id === docID),
  )?.documents.find((doc) => doc.id === docID), [docID, contentLibrary]);

 // modal component(s) rendered on separate root div than body so background effects (blur and fade) can be added.
 const modalRoot = document.getElementById('modal-root') as HTMLDivElement;

 // We pass this function as an onClick to both modalContainer (backdrop) and modalContent (pop-up) divs so we the modal when the backdrop is clicked.

  const closeOnBackDrop = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    const className = e.currentTarget.className;
    if(className === modalContainer) {
      closeModal();
    }
  };
  // Text for heading above documentsToAdd container.
  const documentAddText = documentsToAdd.length === 1 ? 'Document to Add' : 'Documents to Add';

  return ReactDOM.createPortal (

    <div className={modalContainer} onClick={closeOnBackDrop}>
      <div className={modalContent} onClick={closeOnBackDrop}>
        <div className={modalDocumentSidebar}>
          <Text variant="dashboardContentHeading" color="altBlack">
            Content Library
          </Text>
          <div className={`${sidebarDocContainer} ${sidebarDocCollection}`}>
        {/* {go through contentLibrary to render each doc collection} */}

        {contentLibrary.map((documentCollection, index) => {
          return (
            /* this component includes all the info ( doc collection title and a collapse that shows all the documents in each set) for each document collection, as well as the buttons to add docs. It is a separate component b/c each one needs to have its own local state that controls its collapse (setting individual states via a map function is a no-go in React) . */
            <ModalDocCollection key={index} docCollection={documentCollection} selectDocumentToAdd={selectDocumentToAdd} selectDocumentSetToAdd={selectDocumentSetToAdd} getDocumentImagePreview={getDocumentImagePreview} />
          )
        })}
          </div>
          {documentsToAdd.length > 0 &&
            <>
              <Text variant="dashboardContentHeading" color="altBlack">{`${documentsToAdd.length} ${documentAddText}`}</Text>
              <div className={`${sidebarDocContainer} ${sidebarAdd}`}>
                {documentsToAdd.map((document, index) => {
                  return (
                    <div className={docCollectionTitle} key={index}>
                      <span>{document.title}</span>
                      <FontAwesomeIcon onClick ={() => {removeDocumentToAdd(document)}} icon={faTrashAlt}/>
                    </div>
                  )
                })}
              </div>
            </>
            }
            <div>
              {/* show prompt if no documents have been added yet */}
              {
              documentsToAdd.length === 0 &&
                <Text variant="dashboardContentHeading" color="altBlack" fontStyle="italic">No Documents Selected</Text>
              }
              {/* show normal add button only if at least one document is selected and if we aren't loading adding */}
              {documentsToAdd.length > 0 && !addDocsLoading &&
                <HarmoniaButton variant="primary" dest="app" icon="add-to" iconPos="right" className={addToAssignmentSet} onClick={() => {addSelectedDocumentsToAssignment()}}>
                  {/* {addDocsLoading && <span><Spinner size="sm"/></span>} */}
                  <span>Add to Assignment Set</span>
                </HarmoniaButton>
              }
              {
                addDocsLoading &&
                <Text variant="dashboardContentHeading" color="altBlack" fontStyle="italic">Adding Document(s)...</Text>
              }
            <div >
              {!addDocsLoading &&
            <HarmoniaButton variant="primary" dest="app" icon="back"  iconPos="left" className={cancel}
            onClick={closeModal}
            >Cancel</HarmoniaButton>}
            </div>
            </div>
        </div>
        <div className={modalImagePreview}>
          <div className={docImageTitle}>
            <div>
              {selectedDoc ? selectedDoc.title : ''}
            </div>
            <div className={close}>
              <div onClick={closeModal}>
              <Text>X</Text>
              </div>
            </div>
          </div>
          <div className={docPreviewImageWrapper}>
            {urlLoading && <LoadingPage/>}
            {!urlLoading && !selectedDoc && <div className={clickOnDoc}>Click on the <FontAwesomeIcon icon={faEye} onClick={getDocumentImagePreview(document.id)}/> icon to show a preview image.</div>}
            {!urlLoading && selectedDoc && (!selectedDoc.previewURL ? (
              <>
              <Text variant="body" color="dark" inline>
                Failed to load image preview. Please try again.
              </Text>
              <HarmoniaButton variant="primary" dest="app" onClick={getDocumentImagePreview(docID)}>
                  Reload
                </HarmoniaButton>
                </>
            ) : (<img src={selectedDoc.previewURL} alt={selectedDoc.title} className={docPreviewImage}/>)
            )}
          </div>
        </div>
      </div>
    </div>,
    modalRoot

  )
}


export default AddDocFromContentLibModal;




