import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch } from 'react-redux';
import {updateDocument } from '../../redux';
import { Document, DocumentEditableFields} from '../../types';
import '../../../../node_modules/react-datepicker/dist/react-datepicker.css';
import style from './DocumentListingItem.module.scss';

const {dateInput} = style;

type DocumentItemShowGradeOnDateProps = {
  assignmentDueDate: number,
  courseEndDate: number,
  document: Document
}

const DocumentItemShowGradeOnDate = (props: DocumentItemShowGradeOnDateProps) => {
  const { document, assignmentDueDate, courseEndDate} = props;
  const show_grade_on_date = document.show_grade_on_date as number;

  // converting from seconds to milliseconds
  const selectedDate = new Date(show_grade_on_date * 1000);
  const minDate= new Date(assignmentDueDate * 1000);
  const maxDate = new Date(courseEndDate * 1000);
  const dispatch = useDispatch();
  const dateChange = (date: Date) => {
    if(date){
        // convert date to timestamp
        // send in payload with changed show_grade_on_date
        const timestamp = date.getTime() / 1000;
        const newPayload = { ...document, show_grade_on_date: timestamp };
        dispatch(updateDocument({ document: newPayload,field: DocumentEditableFields.DELAY_DATE }));
    }
  };
  return (
    <div>
      <DatePicker
        selected={selectedDate}
        onChange={dateChange}
        minDate={minDate}
        maxDate={maxDate}
        showTimeSelect
        dateFormat="Pp"
        className={dateInput}
        fixedHeight
        filterTime={(t: any) => { return (minDate === undefined || t >= minDate) && (maxDate === undefined || t <= maxDate); }}
      />
    </div>
  );
};
export default DocumentItemShowGradeOnDate;
