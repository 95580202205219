/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { HarmoniaButton, Text, useFlag } from '@harmonia-front-end/shared'; // imports all styling and components from shared from the shared folder
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { Progress } from 'reactstrap';
import { DashboardContent, DashboardTitleBar } from '../../components/template';
import { useRedirect, useUser, useUserMediaCollectionsAndFiles } from '../../hooks';
import LoadingPage from '../../components/LoadingPage';
import MediaFileDisplay from '../../components/media/MediaFileDisplay';
import { createMediaFile, reorderMediaFiles, useHarmoniaDispatch } from '../../redux';
import MediaCollectionPageStyle from './MediaCollectionPage.module.scss';

const { drag, empty, progress } = MediaCollectionPageStyle;

function MediaCollectionPage() {
  const { id } = useParams<{ id: string }>();
  const [mediaCollections, mediaCollectionLoading] = useUserMediaCollectionsAndFiles();
  const [user, userLoading] = useUser();
  const dispatch = useHarmoniaDispatch();
  const inputElementRef = React.useRef<HTMLInputElement>(null);
  const [fileUploading, setFileUploading] = React.useState(0);
  const [dragLoading, setDragLoadingOn, setDragLoadingOff] = useFlag();

  // local state for showing draggable text
  const [dragPromptShow, setDragPromptShow] = useState(true);

  const mediaCollectionID = parseInt(id, 10);
  const mediaCollection = mediaCollections?.find((col) => col.id === mediaCollectionID);

  const onMediaFilesDragEnd = (result: DropResult) => {
    setDragPromptShow(false);
    const { destination, source } = result;
    if (!destination || destination.index === source.index) return;
    if (!mediaCollection) return;
    const { mediaFiles } = mediaCollection;
    if (!mediaFiles || mediaFiles.length === 0) return;

    setDragLoadingOn();
    const mediaFilesOrder = mediaFiles.map(({ id: fileID }) => fileID);
    const i1 = destination.index;
    const i2 = source.index;
    [mediaFilesOrder[i1], mediaFilesOrder[i2]] = [mediaFilesOrder[i2], mediaFilesOrder[i1]];
    dispatch(reorderMediaFiles({ mediaCollectionID, mediaFilesOrder })).finally(setDragLoadingOff);
  };
  const openFileChooser = () => { if (inputElementRef.current) inputElementRef.current.click(); };
  const uploadMediaFile: React.ChangeEventHandler<HTMLFormElement> = (ev) => {
    ev.preventDefault();
    const formData = new FormData(ev.currentTarget);
    setFileUploading((p) => p + 1);
    dispatch(createMediaFile({ mediaCollection: mediaCollectionID, formData }))
      .finally(() => setFileUploading((p) => p - 1));
    ev.currentTarget.reset();
  };

  useRedirect(!userLoading && user?.user_type !== 2, '/my-profile', {
    severity: 'error',
    message: 'Only instructors can use the media library feature.', // TODO: refactor this to React Intl Node.
  });
  useRedirect(!mediaCollectionLoading && !mediaCollection, '/media-library', {
    severity: 'error',
    message: `Requested media collection does not exist.`, // TODO: refactor this to React Intl Node.
  });

  if (mediaCollectionLoading || userLoading) return <DashboardContent><LoadingPage /></DashboardContent>;
  if (!user || !mediaCollection) return null;

  return (
    <>
      <DashboardTitleBar h1={mediaCollection.collection_title} h2="Media Collection">
        <form onChange={uploadMediaFile} style={{ display: 'none' }}>
          <input name="mediafile" ref={inputElementRef} type="file" accept="audio/*,video/*" />
        </form>
        <HarmoniaButton variant="primary" dest="app" icon="upload" iconPos="left" onClick={openFileChooser}>
          Upload media file
        </HarmoniaButton>
      </DashboardTitleBar>
      <DashboardContent>

      {/* Show if user does not have any files */}
      {!Boolean(mediaCollection?.mediaFiles?.length) && (
        <Text className={empty} variant="dashboardHeading" color="black">
          This media collection doesn't have any files yet.
        </Text>
      )}

        {/* Show draggable text only if user has not dragged anything */}
        <div className={drag}>
          {dragPromptShow && Boolean(mediaCollection.mediaFiles?.length) && (
            <Text variant="dashboardList" color="dark-grey" gutterBottom>
            You can reorder media files by dragging the arrows.
            </Text>
          )}
        </div>

        {/* dragLoading && (
        <Progress color="info" animated value={100}>
          <Text>Reordering...</Text>
        </Progress>
        ) */}
        <DragDropContext onDragEnd={onMediaFilesDragEnd}>
          <Droppable droppableId="media-files" isDropDisabled={dragLoading || fileUploading > 0}>
            {(droppableProvided) => (
              <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                {mediaCollection.mediaFiles?.map((file, index) => (
                  <MediaFileDisplay mediaFile={file} key={file.id} index={index} />
                ))}
                {droppableProvided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {fileUploading > 0 && (
        <Progress color="info" animated value={100} className={progress}>
          <Text>New Media File Uploading...</Text>
        </Progress>
        )}
        {/* {(mediaCollection.mediaFiles?.length === 0) && (
          <Text variant="dashboardContentHeading" color="grey" gutterBottom>
            Click &apos;Add media file&apos; button to create a new media collection.
          </Text>
        )} */}
      </DashboardContent>
    </>
  );
}

export default MediaCollectionPage;
