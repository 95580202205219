/* Any helper/builder functions for integrating FastSpring into Dashboard goes here */

/* Adding the Store Builder Library script. FastSpring integrates with a site via a script element. We add this programatically so we only generate it we need it. We pass it a callback function which FastSpring will run when the pop up storefront is closed.

We are basically creating something like this:
<script
  id="fsc-api"
  src="https://d1f8f9xcsvx3ha.cloudfront.net/sbl/0.8.6/fastspring-builder.min.js"
  type="text/javascript"
  data-storefront="illiacsoftware.test.onfastspring.com/popup-dev"
  data-debug=false
  data-popup-closed="dataPopupClosed"
  >
</script>
*/

export const addStoreBuilderLibrary = (dataPopupClosed: (data:any) => void) => {
  const scriptID = "fsc-api";
  const existingScript = document.getElementById(scriptID);
  // logging environment and storefront variables to see in production environment
  console.log('process.env.HARMONIA_ENV: ', process.env.REACT_APP_HARMONIA_ENV);
  console.log('process.env.REACT_APP_HARMONIA_ENV: ', process.env.REACT_APP_HARMONIA_ENV);
  if(!existingScript) {
    // use placeholder variable depending on environment.
    const storefront =
      process.env.REACT_APP_HARMONIA_ENV === 'production' ? "illiacsoftware.onfastspring.com/popup-production" :
      (process.env.REACT_APP_HARMONIA_ENV === 'staging' ?
          "illiacsoftware.test.onfastspring.com/popup-staging" :
          "illiacsoftware.test.onfastspring.com/popup-dev"
      );

    console.log('FastSpring storefront is: ', storefront);

    const script = document.createElement("script");
    script.id = scriptID;
    script.src = "https://d1f8f9xcsvx3ha.cloudfront.net/sbl/0.8.6/fastspring-builder.min.js";
    script.type = "text/javascript";
    script.dataset.storefront = storefront;
    window.dataPopupClosed = dataPopupClosed;
    console.log('window.dataPopupClosed is: ', window.dataPopupClosed);
    script.setAttribute("data-popup-closed", "dataPopupClosed");
    document.body.appendChild(script);
  }
};
 /* Creating the instance for checking out in the pop up storefront  */
export const checkoutFastSpring = (checkoutInfo: {path: string, email: string, firstName: string, lastName: string}) => {
  const {path, email, firstName, lastName} = checkoutInfo;
  window.fastspring.builder.push({
    products: [
      {
        path,
        quantity: 1
      }
    ],
    paymentContact: {
      email,
      firstName,
      lastName
    },
    checkout: true
  })
};
