/** This file contains essential Redux actions and reducers to handle assignment data in the store. */
import { createAsyncThunk, createSlice, createSelector} from '@reduxjs/toolkit';
import API from '../utilities/API';
import { pushErrorNotification, pushNotification } from './notification';
import {Assignment, AssignmentInitialState, Document, DueDateExtension, AssignmentEditableFields, DueDateExtensionAction, DocumentEditableFields} from '../types';
import { errorMessage } from '../utilities';

type SelectedAssignmentInitialState = {
  assignment?: Assignment | null,
  documents?: Document[] | null,
  dueDateExtensions?: DueDateExtension[] | null
}
const initialState: SelectedAssignmentInitialState = {};

/*  ACTIONS/THUNKS  */

export const getAssignmentAndDocuments = createAsyncThunk
<Assignment, number, {rejectValue: void}>
('assignments/getAssignmentDetail', async(assignmentID, {dispatch, rejectWithValue}) => {
  try{
    const response = await API.get(`/api/assignments/${assignmentID}`);
    return response.data.data as Assignment;
  }catch(e){
    dispatch(pushErrorNotification(errorMessage(e.data)));
    return rejectWithValue();
  }
})

export const getAssignmentDocuments = createAsyncThunk<Document[], number, {rejectValue: void}>('assignments/getAssignmentDocuments', async(assignmentID, {dispatch, rejectWithValue}) => {
  try{
    const response = await API.get(`/api/assignments/${assignmentID}/documents`);

    const documents = response.data.data as Document[];
    // documents.forEach(doc => dispatch(getDocumentDownloadLink(doc.id)))
    return documents;
  }catch(e){
    dispatch(pushErrorNotification(errorMessage(e.data)));
    return rejectWithValue();
  }
})
// React Beautiful DnD has a bug where after dragging an item and firing an API call to change the order, the item briefly 'snaps' back to its original spot for a split second before moving permanently to new spot. It looks a bit jarring, so our solution is to update the state locally first (without an API call) while waiting for the API call to finish.
export const updateAssignmentDocumentOrderLocally = createAsyncThunk
<Document[], {reorderedDocs: Document[]}, {rejectValue: void}>
('assignment/updateAssignmentDocumentOrderLocally', async({reorderedDocs}, {dispatch, rejectWithValue}) => {
  try{
    return reorderedDocs;
  }catch(e){
    dispatch(pushErrorNotification(errorMessage(e.data)));
    return rejectWithValue();
  }
})

export const updateAssignmentDocumentOrder = createAsyncThunk
<Document[], {assignmentID: number | any, newOrder: number[]}, {rejectValue: void}>
('assignment/updateAssignmentDocumentOrder', async({assignmentID, newOrder}, {dispatch, rejectWithValue}) => {
  try{
    const response = await API.post(`/api/assignments/${assignmentID}/documents/weight`, {documents: newOrder});

    // dispatch(pushNotification({
    //   severity: 'success',
    //   message: 'New document order saved.'
    // }))

    return response.data.data as Document[];
  }catch(e){
    dispatch(pushErrorNotification(errorMessage(e.data)));
    return rejectWithValue();
  }
})

export const updateDocument = createAsyncThunk<Document, {document: Document, field: DocumentEditableFields}, {rejectValue: void}>('assignment/updateDocument', async({ document, field }, {dispatch, rejectWithValue}) => {
  try{
    const response = await API.put(`api/documents/${document.id}`, document);

    const updateDocumentFieldMessage = {
      [DocumentEditableFields.TITLE]: "Title updated.",
      [DocumentEditableFields.PRACTICE]: "Practice setting changed.",
      [DocumentEditableFields.MULTI_SUB]: "Multiple submissions setting changed.",
      [DocumentEditableFields.DELAY_SHOW_GRADE]: "Delayed grading setting changed.",
      [DocumentEditableFields.DELAY_DATE]: "View date for delayed grading changed.",
      [DocumentEditableFields.TIMED_BOOL]: "Timed setting changed.",
      [DocumentEditableFields.INCREMENT]: "Incremental grading setting changed",
    };

    // dispatch(pushNotification({
    //   severity: "success",
    //   message: updateDocumentFieldMessage[field as keyof typeof updateDocumentFieldMessage]
    // }))

    return response.data.data as Document;
  }catch(e){
    dispatch(pushErrorNotification(errorMessage(e.data)));
    return rejectWithValue();
  }
})

export const updateAssignment = createAsyncThunk<Assignment, { assignment: Assignment, field: AssignmentEditableFields }, {rejectValue: void}>('assignment/updateAssignment', async({ assignment, field }, {dispatch, rejectWithValue}) => {
  try {
    const response = await API.put(`/api/assignments/${assignment.id}`, assignment);
    // Dispatch update notification for title/due/release

    const updateAssignmentFieldMessage = {
      [AssignmentEditableFields.TITLE]: "Title",
      [AssignmentEditableFields.DUE_AT]: "Due date",
      [AssignmentEditableFields.RELEASED_AT]: "Release date",
    };

    // dispatch(pushNotification({
    //   severity: 'success',
    //   message: `${updateAssignmentFieldMessage[field as keyof typeof updateAssignmentFieldMessage]} updated.`
    // }))

    return response.data.data as Assignment;
  }catch(e){
    dispatch(pushErrorNotification(errorMessage(e.data)));
    return rejectWithValue();
  }
});
/* Deleting an assignment should bring the user back to the AssignmentsPage. In order to perform this redirect without having any errors in Redux (namely the Redux state trying to grab the documents/dueDateExtensions of something that is now undefined), we have to completely clear the Assignments state so that it is identical (empty) everytime the Assignments Page loads. */
export const clearAssignmentStateOnDelete = createAsyncThunk<{}, {}, {rejectValue: void}>('assignment/clearAssignmentStateOnDelete', async({}, {dispatch, rejectWithValue}) => {
  try {
    return {};
  }catch(e){
    dispatch(pushErrorNotification(errorMessage(e.data)));
    return rejectWithValue();
  }
})

// the actions/thunks of addDocuments & uploadDocument make the same exact API call and have the same result: getting an array of doc(s) after API call and then adding it to an assignment's documents. they are separated into two separate actions/thunks to be more explicit and to make the logic easier.

export const addDocumentsFromContentLibrary = createAsyncThunk
<Document[], {assignmentID: number, newDocuments: Document[]}, {rejectValue: void}>
('assignment/addDocumentsFromContentLibrary', async({assignmentID, newDocuments}, {dispatch , rejectWithValue}) => {
  try{
    const docIDs: number[] = newDocuments.map(doc => doc.id);
    const response = await API.post(`api/assignments/${assignmentID}/documents`, {document_ids: docIDs});
    let messageConfirm = (docIDs.length > 1 ? `${docIDs.length} Documents` : "Documents").concat(" added to course!");
    dispatch(pushNotification({
      severity: "success",
      message: messageConfirm
    }))
    return response.data.data as Document[];
  }catch(e){
    dispatch(pushErrorNotification(errorMessage(e.data)));
    return rejectWithValue();
  }
})

export const uploadDocument = createAsyncThunk
<Document[], {assignmentID: number, formData: FormData}, {rejectValue: void}>
('assignment/uploadDocument', async({assignmentID, formData}, {dispatch, rejectWithValue}) => {
  try{
const response = await API.post(`/api/assignments/${assignmentID}/documents`, formData);
dispatch(pushNotification({
  severity: "success",
  message: "Document uploaded!"
}))

return response.data.data;
  }catch(e){
    dispatch(pushErrorNotification(errorMessage(e.data)));
    return rejectWithValue();
  }
})

export const deleteDocument = createAsyncThunk<number, Document, {rejectValue: void}>('assignments/getAssignmentDocuments2', async(document, {dispatch, rejectWithValue}) => {
  try{
    const response = await API.delete(`/api/documents/${document.id}`);
    console.log("Response in deleteDocument is ", response)

    dispatch(pushNotification({
      severity: "success",
      message: `"${document.title}" deleted.`
    }))
    return document.id;
  }catch(e){
    dispatch(pushErrorNotification(errorMessage(e.data)));
    return rejectWithValue();
  }
})


/* NOTE: the download URLs for a document are stored separately (in AWS bucket) than the rest of a document's info. Therefore, we can't just wrap a button in an  <a> tag because when the documents have loaded on the page we don't have any of their URLs to use as an href. But we still want the user to be able to download a document with just one click. We simulate this by dispatching this action to grab the URL, then running a simple script that creates an <a> tag with the proper href and auto-clicks it. This also prevents dealing with any listeners for tricky/complicated re-rendering once we have the URL. We also shouldn't save the URL as it will change over time, hence dispatching this action every time the download button is clicked. */

export const downloadDocumentWithLink = createAsyncThunk<void, Document, {rejectValue: void}>('assignment/downloadDocumentWithLink', async(doc, {dispatch, rejectWithValue}) => {
  try {
    const response = await API.get(`/api/documents/${doc.id}/file-url`);
    const downloadURL = response.data.data.url as string;
    const a = document.createElement('a');
    a.href = downloadURL;
    a.download = doc.title;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    // return downloadURL;
  }catch(e){
    dispatch(pushErrorNotification(errorMessage(e.data)));
    return rejectWithValue();
  }
});

export const getDocumentPreviewURL = createAsyncThunk<
string, number, { rejectValue: void }
>(
  'assignment/getDocumentPreviewURL',
  async (docID, { dispatch, rejectWithValue }) => {
    try {
      const response = await API.get(`/api/documents/${docID}/preview-url`);
      return response.data.data.url as string;
    } catch (e) {
      dispatch(pushErrorNotification(e.data));
      return rejectWithValue();
    }
  },
);

/**DUE DATE EXTENSIONS */

export const listAssignmentDueDateExtensions = createAsyncThunk<DueDateExtension[], number, {rejectValue: void}  >('assignment/listAssignmentDueDateExtensions', async (assignmentID, {dispatch, rejectWithValue}) => {
  try{
    const response = await API.get(`/api/assignments/${assignmentID}/due-day-extensions`);
    return response.data.data as DueDateExtension[];
  }catch(e){
    dispatch(pushErrorNotification(e.data));
    return rejectWithValue();
  }
});

export const createOrUpdateAssignmentDueDateExtension = createAsyncThunk<DueDateExtension, {assignmentID: number, studentID: number, timestamp: number, action: DueDateExtensionAction}, {rejectValue:void}>('assignment/createOrUpdateAssignmentDueDateExtension', async({assignmentID, studentID, timestamp, action}, {dispatch, rejectWithValue}) => {
  try{
    const response = await API.post(`/api/assignments/${assignmentID}/due-day-extensions/${studentID}`, {'extension_due_at': timestamp});

    // const createOrUpdate = action === DueDateExtensionAction.CREATE ? "created" : "updated";
    // if(action === DueDateExtensionAction.CREATE) {
    //   dispatch(pushNotification({
    //     severity: 'success',
    //     message: `Due date extension ${createOrUpdate}!`
    //   }))
    // }


    const responseData: DueDateExtension = response.data.data;
    return responseData;
  } catch(e){
    dispatch(pushErrorNotification(e.data));
    return rejectWithValue();
  }
})

export const deleteAssignmentDueDateExtension = createAsyncThunk('assignment/deleteDueDateExtension', async({assignmentID, studentID} : {assignmentID: number, studentID: number}, {dispatch}) => {
  try{
    const response = await API.delete(`/api/assignments/${assignmentID}/due-day-extensions/${studentID}`);

    dispatch(pushNotification({
      severity: 'success',
      message: 'Due date extension removed.'
    }))
    return studentID;
  }catch(e){
    dispatch(pushErrorNotification(errorMessage(e.data)));
  }
});


/*
  add a reducer for course details. the key of each course detail is the course id.
*/
const selectedAssignmentSlice = createSlice({
  name: 'assignments',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(
      getAssignmentAndDocuments.fulfilled,
      ((state, {payload}) => {
        // assignmentDetail is everything but documents; documents kept on separate key in state obj to keep things simpler.
        const {course, documents, due_at, id, released_at, set_key, show_after_due, title, weight} = payload;
        state.assignment = {course, due_at, id, released_at, set_key, show_after_due, title, weight};
        state.documents = documents;
      })
      )
      .addCase(getAssignmentDocuments.fulfilled, ((state, {payload}) => {
        state.documents = payload;
      }))
      .addCase(updateAssignmentDocumentOrderLocally.fulfilled, ((state, {payload}) => {
        state.documents = payload;
      }))
      .addCase(updateAssignmentDocumentOrder.fulfilled, ((state, {payload}) => {
        state.documents = payload;
      }))
      .addCase(uploadDocument.fulfilled, ((state, {payload}) => {
        state.documents = [...state.documents as Document[], ...payload];
      }))
      .addCase(addDocumentsFromContentLibrary.fulfilled, ((state, {payload}) => {
        state.documents = [...state.documents as Document[], ...payload];
      }))
      .addCase(deleteDocument.fulfilled, ((state, {payload}) => {
        const documentsWithoutDeleted = state.documents?.filter(doc => doc.id !== payload) as Document[];
        state.documents = documentsWithoutDeleted;
      }))
      .addCase(updateDocument.fulfilled, ((state, {payload}) => {
        const documentsWithUpdate = state.documents?.map((doc) => {
          return doc.id !== payload.id ? doc : payload
        }) as Document[];
        state.documents = documentsWithUpdate;
      }))
      .addCase(updateAssignment.fulfilled, ((state, {payload}) => {
        state.assignment = payload;
      }))
      // .addCase(downloadDocumentWithLink.fulfilled, ((state, {payload, meta}) => {
      //   const document = state.documents?.find(doc => doc.id === meta?.arg.id);
      //   if(document !== undefined)
      //   document.downloadURL = payload;
      // }))
      .addCase(getDocumentPreviewURL.fulfilled, (state, {payload, meta}) => {
        state.documents?.forEach(doc => {
          if(doc.id === meta.arg){
            doc.previewURL = payload;
          }
        })
      })
      .addCase(listAssignmentDueDateExtensions.fulfilled, ((state, {payload}) => {
        state.dueDateExtensions = payload;
      }))
      .addCase(createOrUpdateAssignmentDueDateExtension.fulfilled, ((state, {payload}) => {
        if(!state.dueDateExtensions) state.dueDateExtensions = [];
        // checking if student already has a due date extension
        const i = state.dueDateExtensions.findIndex(ext => ext.student == payload.student);
        // if due date extension exists, then update it; otherwise, make new due date extension
        i > -1 ? state.dueDateExtensions[i].extension_due_at = payload.extension_due_at : state.dueDateExtensions = [...state.dueDateExtensions, payload];
      }))
      .addCase(deleteAssignmentDueDateExtension.fulfilled, ((state, {payload}) => {
        const dueDateExtensions = state.dueDateExtensions?.filter(ext => ext.student !== payload );
        state.dueDateExtensions = dueDateExtensions;
      }))
      .addCase(clearAssignmentStateOnDelete.fulfilled,(state, {payload}) => {
          state = payload;
      } )
  }
})

export default selectedAssignmentSlice.reducer;
