import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import * as User from "./pages/user";
import * as Course from "./pages/course";
import * as CourseStudent from "./pages/course-student";
import * as Assignment from "./pages/assignment";
import HomePage from "./pages/Homepage";
import PageNotFound from "./pages/PageNotFound";
import { HarmoniaCookies } from "./utilities";
import { DashboardContainer } from "./components/template";
import { SubmissionViewPage } from "./pages/submission";
import Dev from "./pages/Dev";
import { SessionManagement } from "./utilities";
import { useParams } from 'react-router-dom';

// When hitting a path without signing in, we redirect it to sign in page with ?redirect= query.
// we do not redirect sign in to sign out, otherwise user will be immediately redirected to
// sign out page after signing in.
function redirectToSignIn() {
  if (
    window.location.pathname === "/" ||
    window.location.pathname === "/sign-out"
  ) {
    window.location.href = "/sign-in";
  } else {
    window.location.href = `/sign-in?redirect=${window.location.pathname}`;
  }
}



/** Component that handles all the routing logics. */
export default function Routes() {
  const loginCookies = HarmoniaCookies["x-access-token"].get(); // Get access token
  return (
    <Switch>
      {loginCookies &&  (
        <>
        <SessionManagement/>
        <DashboardContainer>
          <Switch>
              {/* Course related pages */}
              <Route exact path="/">
                <HomePage courseArchived={false} />
              </Route>
              <Route path="/archived-courses">
                <HomePage courseArchived={true} />
              </Route>

              {/* User related pages */}
              <Route exact path="/my-profile">
                <User.MyProfilePage />
              </Route>
              <Route path="/my-profile/edit">
                <User.MyProfileEditPage />
              </Route>
              <Route path="/request-teacher-account">
                <User.RequestTeacherAccountPage />
              </Route>
              <Route path="/create-password">
                <User.PasswordPage pageType="create" />
              </Route>
              <Route path="/change-password">
                <User.PasswordPage pageType="change" />
              </Route>
              <Route path="/sign-out">
                <User.SignOutPage />
              </Route>
              <Route exact path="/media-library">
                <User.MediaLibraryPage />
              </Route>
              <Route path="/media-library/:id">
                <User.MediaCollectionPage />
              </Route>
              <Route path="/content-library">
                <User.ContentLibraryPage />
              </Route>

              {/* Course related pages */}
              <Route exact path="/course/:courseId">
                <Course.AssignmentsPage />
              </Route>
              <Route path="/course/:courseId/grade-book">
                <Course.GradeBookPage />
              </Route>
              <Route path="/course/:courseId/info">
                <Course.CourseInformationPage />
              </Route>
              <Route path="/course/:courseId/people">
                <Course.PeoplePage />
              </Route>
              <Route path="/course/:courseId/discussion">
                <Course.DiscussionPage />
              </Route>
              <Route path="/course/:courseId/settings">
                <Course.SettingsPage />
              </Route>

              {/* Course related pages (student) */}
              <Route exact path="/student/course/:courseId">
                <CourseStudent.StudentAssignmentsPage />
              </Route>
              <Route path="/student/course/:courseId/info">
                <CourseStudent.StudentCourseInformationPage />
              </Route>

              {/* Assignment related pages */}
              <Route path="/course/:courseId/assignment/:assignmentId">
                <Assignment.AssignmentDetailPage />
              </Route>
              {/* Submission related pages */}
              <Route path="/course/:courseId/submission/:submissionId">
                <SubmissionViewPage />
              </Route>

              <Route path="/join/:token">
                <User.AcceptInvitationPage />
              </Route>

              {/* Developer Reserved Pages */}
              <Route path="/dev">
                <Dev />
              </Route>

              {/* 404 page */}
              <Route path='*'>
                <PageNotFound />
              </Route>
          </Switch>
        </DashboardContainer>
        </>
      )}
      <Route path="/sign-in">
        <User.SignInPage />
      </Route>
      <Route exact path="/sign-up">
        <User.SignUpPage />
      </Route>
      <Route path="/sign-up/confirmation">
        <User.ConfirmSignUpPage />
      </Route>
      <Route path="/reset-password">
        <User.ResetPasswordPage />
      </Route>
      <Route>{redirectToSignIn}</Route>
    </Switch>
  );
}
