import React, {useState, useEffect} from "react";
import {
  uploadDocument, useHarmoniaDispatch,  createOrUpdateAssignmentDueDateExtension,  deleteAssignment,
  updateAssignment, clearAssignmentStateOnDelete
} from '../../redux';
import { useHistory } from 'react-router-dom';
import { Collapse, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import AssignmentDetailEditDate from './AssignmentDetailEditDate';
import {
  HarmoniaButton,
  HarmoniaPaper,
  HarmoniaInput,
  Text,
  useToggle
} from '@harmonia-front-end/shared';
import {Assignment, AssignmentDateType, CourseStudent, DueDateExtension, AssignmentEditableFields, DueDateExtensionAction } from '../../types';
import DueDateExtensionItem from "./DueDateExtensionItem";
import { faAngleDown, faAngleRight, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import AssignmentInfoStyle from './AssignmentInfo.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const {  assignmentContainer, title, titleInput, assignmentDelete, top, bottom, dateText, students, studentsTop, arrow, studentsBottom, addDocumentContainer, extensions} = AssignmentInfoStyle;

type AssignmentInfoProps = {
  assignment: Assignment | null | undefined,
  courseStudents: CourseStudent[] | null | undefined,
  dueDateExtensions: DueDateExtension[] | null | undefined,
  courseStartDate: number,
  courseEndDate: number,
  openAddDocModal: () => void,
  courseID: number
}

const AssignmentInfo = (props: AssignmentInfoProps) => {
  // grabbing props
  const {assignment, courseStudents, dueDateExtensions, courseStartDate,courseEndDate, openAddDocModal, courseID,} = props;
  // Returning early to force re-render.
  if(!assignment || (courseStudents === null || courseStudents === undefined) || !dueDateExtensions) return null;
  const dispatch = useHarmoniaDispatch();


  /** DUE DATE EXTENSIONS **/

  // local state for setting/toggling due-date-extension collapse
  const [dueDatesOpen, setDueDatesOpen] = useState(false);
  const collapseIcon = dueDatesOpen ? faAngleDown : faAngleRight;
  const toggleDueDateExtensions = () => setDueDatesOpen((prevState) => !prevState);

  // local state for selecting a student to add due-date-extension
  const [selectedStudent, setSelectedStudent] = useState(0);

  // only students who don't have due-date-extension yet should be in dropdown to add one.
  const studentsWithoutExtension = courseStudents?.filter((student => {
    return !dueDateExtensions?.some(ext => ext.student === student.user_id);
  }));
  // set selectedStudent state with first in array once students are loaded or if an extension is added or removed.
  useEffect(() => {
    if(studentsWithoutExtension && studentsWithoutExtension.length > 0) {
      const firstStudent = studentsWithoutExtension[0];
      setSelectedStudent(firstStudent.user_id);
    }
  }, [studentsWithoutExtension.length]);

  // handler for selecting a student
  const handleStudentChange:React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    const studentID = parseInt(e.currentTarget.value, 10);
  setSelectedStudent(studentID);
  };

  // function that constructs due-date-extension payload and dispatches action to create.
  const createDueDateExtension = () => () => {
  const dueDatePayload = {assignmentID: assignment.id, studentID: selectedStudent, timestamp: assignment.due_at, action: DueDateExtensionAction.CREATE};
  dispatch(createOrUpdateAssignmentDueDateExtension(dueDatePayload));
  };

  /** UPLOADING DOCUMENT **/

  //local state for depicting the upload of a new document
  const [uploadingDoc, setUploadingDoc] = useState(false);

  const inputElementRef = React.useRef<HTMLInputElement>(null);

  // Handler for selecting file
  const openFileChooser = () => {
    if(!uploadingDoc){
      if (inputElementRef.current){
        inputElementRef.current.click();
      }
    }
  }
  // Uploading a document. kicks off loading state which resolves after successful API call.
  const uploadDocumentFile: React.ChangeEventHandler<HTMLFormElement> = (ev) => {
    ev.preventDefault();
    const formData = new FormData(ev.currentTarget);
    setUploadingDoc(true);
    dispatch(uploadDocument({assignmentID: assignment.id, formData: formData })).finally(() =>setUploadingDoc(false));
    ev.currentTarget.reset();
  };


  /* EDITING ASSIGNMENT TITLE */

  // local state for assignment title used in input field.
  const [assignmentTitle, setAssignmentTitle] = useState(assignment.title);

  // Handlers for changing title
  const handleTextInput: React.ChangeEventHandler<HTMLInputElement>= (ev) => {
    setAssignmentTitle(ev.currentTarget.value);
  }
  // function that trims new title in input field, creates payload, and dispatches action to edit title of assignment.
  const changeAssignmentTitleOnBlur: React.FocusEventHandler<HTMLInputElement> = (ev) => {
    ev.preventDefault();
    const trimmedNewTitle = ev.currentTarget.value.trim();
    if(trimmedNewTitle && trimmedNewTitle !== assignment.title){
      const payload = {...assignment, title: trimmedNewTitle};
      dispatch(updateAssignment({assignment: payload, field: AssignmentEditableFields.TITLE}));
    }
  }
  // when hitting enter, this function will blur the input field and thus trigger the function above.
  const changeAssignmentTitleOnReturn: React.KeyboardEventHandler<HTMLInputElement> = (ev) => {
    if(ev.key === 'Enter') ev.currentTarget.blur();
  }

  /* DELETING AN ASSIGNMENT */

  // Toggling modal to confirm deleting assignment
  const [deleteDialogOpen, toggleDeleteDialog] = useToggle();

  // We want the page to redirect back to Assignments Page upon deleting an Assignment. We prefer to use history.push here instead of 'window.location.href =' so the page will merely redirect rather than hard refresh. In order to accomplish this, we have to completely clear the assignments state on Redux first so it is exactly the same (empty) every time the AssignmentsPage loads (otherwise it will try to find dueDateExtensions/documents of something that is undefined and throw an error).
  const history = useHistory();
  const confirmDeleteAssignment = () => {
    toggleDeleteDialog();
    dispatch(deleteAssignment(assignment)).finally(() => {
      dispatch(clearAssignmentStateOnDelete({})).finally(() => {
        history.push(`/course/${courseID}`);
      })
    })
  };

  return (
    <>
    <div className={assignmentContainer}>
      {/* TOP - ASSIGNMENT TITLE & DELETE */}
      <div className={top}>
          <div className={title}>
            <span>Title:</span>
            <span>
            <HarmoniaInput
              onBlur={changeAssignmentTitleOnBlur}
              onKeyDown={changeAssignmentTitleOnReturn}
              onChange={handleTextInput}
              type="text" className={titleInput} value={assignmentTitle}/>
              </span>
            </div>
          <div style={{fontSize: '16px'}} className={assignmentDelete}>
            <FontAwesomeIcon icon={faTrashAlt} onClick={toggleDeleteDialog}/>
          </div>
      </div>
      {/* BOTTOM - ASSIGNMENT DATES/DUE DATES/ADDING DOCS */}
      <HarmoniaPaper className={bottom}>
        {/* EDITING ASSIGNMENT DATES */}
        <AssignmentDetailEditDate dateType={AssignmentDateType.ASSIGNED}courseStartDate={courseStartDate} courseEndDate={courseEndDate}timestamp={assignment.released_at} assignment={assignment}/>
        <AssignmentDetailEditDate dateType={AssignmentDateType.DUE}courseStartDate={courseStartDate} courseEndDate={courseEndDate}timestamp={assignment.due_at} assignment={assignment}/>
        {/* DUE DATE EXTENSIONS*/}
        <div className={students}>
          <div className={studentsTop}>
            <div className={dateText}>Due Date Extensions</div>
            <FontAwesomeIcon className={arrow} icon={collapseIcon} onClick={toggleDueDateExtensions}/>
          </div>
          <Collapse className={studentsBottom} isOpen={dueDatesOpen}>
            {/* only show select and options if there are students in a course */}
            {courseStudents && (courseStudents.length === 0 ? <div>There are currently no students in this course</div> :
          <select value={selectedStudent} name="student" onChange={handleStudentChange}>
            {/* only students who don't already have due-date-extensions should be in the dropdown to add an extension */}
            {courseStudents && studentsWithoutExtension.map((student, index) => {
              return <option key={index} value={student.user_id}>{`${student.last_name}, ${student.first_name}`}</option>
            })}
          </select>
  )}
          {selectedStudent != 0 && <HarmoniaButton variant="primary" dest="app" shape="circle" icon="add-to" onClick={createDueDateExtension()}></HarmoniaButton>}
          <div className={extensions}>
            {dueDateExtensions && dueDateExtensions.map((ext, index) => {
                return <DueDateExtensionItem key={index} extension={ext} assignmentDueDate={assignment.due_at} courseEndDate={courseEndDate} assignmentID={assignment.id}/>
            })}
          </div>
          </Collapse>
        </div>
        {/* UPLOAD OR ADD DOCUMENTS */}
        <div className={addDocumentContainer}>
          <form onChange={uploadDocumentFile} style={{display: 'none'}}>
            <input name="harfile" ref={inputElementRef} type="file" accept=".har"/>
          </form>
          <HarmoniaButton onClick={openFileChooser} variant={uploadingDoc ? "loading" : "primary"} dest="app" icon="upload" iconPos="left">{uploadingDoc ? 'Uploading' : 'Upload Documents'}</HarmoniaButton>
          <HarmoniaButton variant="primary" dest="app" icon="add-to" iconPos="left"
                onClick={openAddDocModal}
                >Add From Content Library</HarmoniaButton>
        </div>
      </HarmoniaPaper>
    </div>
    {/* MODAL FOR DELETING ASSIGNMENT */}
    <Modal isOpen={deleteDialogOpen}>
      <ModalHeader>Delete Assignment Set</ModalHeader>
      <ModalBody>
          <Text color="dark">
            {`Are you sure you want to delete ${assignment.title} and all of its assignments?`}
          </Text>
          <Text color="dark">
           This action cannot be undone.
          </Text>
      </ModalBody>
      <ModalFooter>
        <HarmoniaButton variant="secondary" dest="app" onClick={toggleDeleteDialog}>Cancel</HarmoniaButton>
        <HarmoniaButton variant="primary" dest="app" onClick={confirmDeleteAssignment}>Delete</HarmoniaButton>
      </ModalFooter>
    </Modal>
    </>
  )
}
export default AssignmentInfo;

