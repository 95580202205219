import React from "react";
import ReactDOM from "react-dom";
import Cookies from "js-cookie";
import HarmoniaSquare from "./HarmoniaSquare";
import HarmoniaHeaderStyle from "./HarmoniaHeader.module.scss";

const {
  header,
  strip1,
  strip2,
  nav,
  logo,
  profile,
  profileButton,
  profileLink,
  navItem,
  navItemDropdown,
  dropdown,
  dropdownStrip,
  dropdownItem,
} = HarmoniaHeaderStyle;

/** Type annotation for HeaderTabs objects. */
interface HeaderTabs {
  tabName: string;
  url: string | undefined;
  options: { label: string; url: string }[];
}

const baseURLs = (() => {
  if (
    process.env.HARMONIA_ENV === "production" ||
    process.env.REACT_APP_HARMONIA_ENV === "production"
  ) {
    return {
      blog: "https://blog.harmonia.illiacsoftware.com",
      videos: "https://videos.harmonia.illiacsoftware.com",
      support: "https://support.harmonia.illiacsoftware.com",
      home: "https://harmonia.cloud",
      dashboard: "https://dashboard.harmonia.illiacsoftware.com",
    };
  }
  if (
    process.env.HARMONIA_ENV === "staging" ||
    process.env.REACT_APP_HARMONIA_ENV === "staging"
  ) {
    return {
      blog: "https://blog.staging.harmonia.illiacsoftware.com",
      videos: "https://videos.staging.harmonia.illiacsoftware.com",
      support: "https://support.staging.harmonia.illiacsoftware.com",
      home: "https://staging.harmonia.illiacsoftware.com",
      dashboard: "https://dashboard.staging.harmonia.illiacsoftware.com",
    };
  }
  return {
    blog: "http://localhost:3000",
    videos: "http://localhost:3300",
    support: "http://localhost:3100",
    home: "http://localhost:3200",
    dashboard: "http://localhost:10000",
  };
})();

const headerTabs: HeaderTabs[] = [
  {
    tabName: "New Students",
    url: `${baseURLs.home}/students`,
    options: [],
  },
  {
    tabName: "New Teachers",
    url: `${baseURLs.home}/teachers`,
    options: [],
  },
  {
    tabName: "Our Team",
    url: `${baseURLs.home}/our-team`,
    options: [],
  },
  {
    tabName: "Pricing",
    url: `${baseURLs.home}/pricing`,
    options: [],
  },
  {
    tabName: "Downloads",
    url: `${baseURLs.home}/downloads`,
    options: [],
  },
  {
    tabName: "Learning Resources",
    url: undefined,
    options: [
      { label: "Blog", url: baseURLs.blog },
      { label: "Videos", url: baseURLs.videos },
      { label: "Support", url: baseURLs.support },
    ],
  },
];

/** NOTE on 11/28/2022: HomePage, video and blog sites are currently available to users (ad was sent out for new homepage). However, dashboard is not properly launched yet, so we need to make sure header links go to old pages for now.  */
const oldSiteHeaderLinks = {
  signUp: "https://harmonia.illiacsoftware.com/user/register",
  forStudents: "https://harmonia.illiacsoftware.com/students",
  forTeachers: "https://harmonia.illiacsoftware.com/teachers",
  products: "https://harmonia.illiacsoftware.com/products/harmonia-app",
  termsOfService:
    "https://harmonia.illiacsoftware.com/terms-of-service-agreement",
  privacyPolicy: "https://harmonia.illiacsoftware.com/privacy-policy",
  FERPA: "https://harmonia.illiacsoftware.com/ferpa",
  blog: "https://blog.harmonia.illiacsoftware.com",
  videos: "https://videos.harmonia.illiacsoftware.com",
  pricing: "https://harmonia.illiacsoftware.com/pricing",
  support: "https://harmonia.illiacsoftware.com/support",
  aboutUs: "https://harmonia.illiacsoftware.com/company",
};

const tempHeaderTabs: HeaderTabs[] = [
  {
    tabName: "For Students",
    url: oldSiteHeaderLinks.forStudents,
    options: [],
  },
  {
    tabName: "For Teachers",
    url: oldSiteHeaderLinks.forTeachers,
    options: [],
  },
  {
    tabName: "Our Team",
    url: oldSiteHeaderLinks.aboutUs,
    options: [],
  },
  {
    tabName: "Pricing",
    url: oldSiteHeaderLinks.pricing,
    options: [],
  },
  {
    tabName: "Downloads",
    url: oldSiteHeaderLinks.products,
    options: [],
  },
  {
    tabName: "Learning Resources",
    url: undefined,
    options: [
      { label: "Blog", url: oldSiteHeaderLinks.blog },
      { label: "Videos", url: oldSiteHeaderLinks.videos },
      {
        label: "Support",
        url: oldSiteHeaderLinks.support,
      },
    ],
  },
];

function HeaderLink({ tabName, url, options }: HeaderTabs) {
  const isDropdown = options.length !== 0;
  if (isDropdown) {
    return (
      <div className={`${navItem} ${navItemDropdown}`}>
        {tabName}
        <div className={dropdown}>
          <HarmoniaSquare className={dropdownStrip} variety="yellow" />
          {options.map(({ label, url: optionUrl }) => (
            <a className={dropdownItem} href={optionUrl} key={label}>
              {label}
            </a>
          ))}
        </div>
      </div>
    );
  }
  return (
    <a className={navItem} href={url}>
      {tabName}
    </a>
  );
}

/** Shared Harmonia Cloud header component for all sites. */
type HeaderProps = {
  isDashboard?: boolean;
};
function HarmoniaHeader(props: HeaderProps) {
  // TODO: pull this cookie detection to React hooks
  // TODO: change URL based on web infrastructure settings.
  const notSignedIn = Cookies.get("x-access-token") === undefined;
  const profileLinkURL = `${baseURLs.dashboard}/${
    notSignedIn ? "sign-in" : "sign-out"
  }`;
  const profileLinkText = notSignedIn ? "Sign In" : "Sign Out";
  const profileButtonLinkURL = `${baseURLs.dashboard}/${
    !notSignedIn ? "my-profile" : "sign-up"
  }`;
  const profileButtonLinkText = !notSignedIn ? "My Profile" : "Sign Up Free!";

  const { isDashboard } = props;

  return (
    <header className={header}>
      <HarmoniaSquare variety="yellow" className={strip1} />

      <a href={baseURLs.home} className={logo}>
        <img
          src="https://resources.harmonia.illiacsoftware.com/images/v3/white-harmonia-on-transparent.png"
          alt="Harmonia Logo"
        />
      </a>

      {isDashboard ? (
        <div className={profile}>
          <a
            href={profileLinkURL}
            className={profileLink}
            rel="noopener noreferrer"
          >
            {profileLinkText}
          </a>
          <button type="button" className={profileButton}>
            <a href={profileButtonLinkURL} rel="noopener noreferrer">
              {profileButtonLinkText}
            </a>
          </button>
        </div>
      ) : (
        <div className={profile}>
          <button type="button" className={profileButton}>
            <a href={baseURLs.dashboard} rel="noopener noreferrer">
              Sign In/Sign Up
            </a>
          </button>
        </div>
      )}

      <nav className={nav}>
        {headerTabs.map(({ tabName, url, options }) => (
          <HeaderLink
            tabName={tabName}
            url={url}
            options={options}
            key={tabName}
          />
        ))}
      </nav>
      <HarmoniaSquare variety="sky" className={strip2} />
    </header>
  );
}

export default HarmoniaHeader;
