import React, { useEffect} from 'react';
import { HarmoniaButton, useToggle } from "@harmonia-front-end/shared";
import { Collapse } from 'reactstrap';
import GradeBookPageStyle from './GradeBookPage.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faCaretDown,faCaretRight, faTrash} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { Submission, CourseStudent} from '../../../types';
const {submissionEntry, row, documentTableRow, remove, subCollapse, collapsibleCell, mobileHide} = GradeBookPageStyle;

type DocumentGradeBookTableEntryProps = {
  student: CourseStudent
  submissions: Submission[],
  expandAll: boolean,
  showSubmissionRemoveModal: (documentTitle: string, documentID: number) => void,
  showSubmissionRemoveByIDModal: (studentName: string, studentID: number, submissionID: number, indexToRemove: number) => void,
  courseID: number
}
const DocumentGradeBookTableEntry = (props: DocumentGradeBookTableEntryProps) => {
  const { student, submissions, expandAll, showSubmissionRemoveModal, showSubmissionRemoveByIDModal, courseID} = props;
  const [isExpanded, toggleExpanded] = useToggle();
  const icon = isExpanded ? faCaretDown : faCaretRight;
  const studentNameOnTable = `${student.last_name}, ${student.first_name}`;
  const studentNameInModal = `${student.first_name} ${student.last_name}`;

  // Listening for any change in the parent expand state on the DocumentGradeBookTable. The local expand (for each individual entry) should toggle to match the parent whenever it is changed on the DocumentGradeBookTable itself.
  useEffect(() => {
    if(expandAll !== isExpanded) {
      toggleExpanded();
    }
  }, [expandAll])
  // will render proper empty row if there is no submission.
  const NoSubmissionEntry = () => {
    return (
      <>
        <section className={`${row} ${documentTableRow}`}>
          <section>
            <article>{studentNameOnTable}</article>
            <article>0</article>
          </section>
          <section className={mobileHide}>
            <article></article>
            <article></article>
            <article></article>
            <article></article>
          </section>
        </section>
      </>
    )
  };
// will render single submission (or first row if multiple).
  const TopSubmissionRow = (props: {sub: Submission, isOnlyRow: boolean}) => {
    const {sub, isOnlyRow} = props;
    return (
      <section className={`${row} ${documentTableRow}`}>
        <section>
          <article>{studentNameOnTable}</article>
          <article>
            {submissions.length} &nbsp;
            {submissions.length > 1 &&
              <FontAwesomeIcon cursor='pointer' icon={icon} onClick={toggleExpanded} />
            }
          </article>
        </section>
        <section>
          <article>{sub.formattedTime}</article>
          <article>{sub.points}</article>
          <article>{`${sub.percent}% | ${sub.grade}`}</article>
          <article>
            <Link to={`/course/${courseID}/submission/${sub.id}`}>View</Link>&nbsp;
            <FontAwesomeIcon cursor='pointer' icon={faTrash} onClick={isOnlyRow ? () => showSubmissionRemoveModal(studentNameInModal, student.user_id) : () => showSubmissionRemoveByIDModal(studentNameInModal, student.user_id, sub.id, 1)} />
          </article>
        </section>
      </section>
    )
  };
// will render remaining submissions (if multiple).
  const CollapsibleSubmissionRow = (props: {sub: Submission, index: number}) => {
    const {sub, index} = props;
    return (
      <section className={`${row} ${documentTableRow}`}>
        <section className={mobileHide}>
          <article></article>
          <article></article>
        </section>
        <section>
          <article className={collapsibleCell}>{sub.formattedTime}</article>
          <article className={collapsibleCell}>{sub.points}</article>
          <article className={collapsibleCell}>{`${sub.percent}% | ${sub.grade}`}</article>
          <article className={collapsibleCell}>
            <FontAwesomeIcon cursor='pointer' icon={faTrash} onClick={() => showSubmissionRemoveByIDModal(studentNameInModal, student.user_id, sub.id, index + 2)} />
          </article>
        </section>
      </section>
    )
  }
  return (
    <div className={submissionEntry}>
      {/* 0 submissions */}
      {!submissions.length && <NoSubmissionEntry/>}
      {/* 1 submission */}
      {submissions.length === 1 && <TopSubmissionRow sub={submissions[0]} isOnlyRow={true}/>}
      {/* 1+ submissions */}
      {submissions.length > 1 &&
          <>
          <TopSubmissionRow sub={submissions[0]} isOnlyRow={!isExpanded}/>
          <Collapse isOpen={isExpanded} className={subCollapse}>
          {submissions.slice(1).map((sub, index) => {
            return <CollapsibleSubmissionRow sub={sub} index={index}/>
          })}
          </Collapse>
          </>
      }
    </div>
  )
}
export default DocumentGradeBookTableEntry;
