import { HarmoniaPaper, HarmoniaButton, useToggle, Text } from "@harmonia-front-end/shared";
import styles from './PageNotFound.module.scss';

const { notFound, backToHome } = styles;

export default function PageNotFound() {
  return (
    <HarmoniaPaper>
      <div className={notFound} data-cy='notFound'>Sorry! The page you tried to load could not be found.</div>
      {/* <div className={backToHome}>
        <HarmoniaButton variant="primary" dest="app" icon="back" iconPos="left" data-cy="backToHome" >Back To Courses</HarmoniaButton>
      </div> */}
    </HarmoniaPaper>
  )
}
