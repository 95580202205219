import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { HarmoniaHeader } from '@harmonia-front-end/shared';
import { ConnectedRouter } from 'connected-react-router';
import HarmoniaCookies from './utilities/HarmoniaCookies';
import Routes from './Routes';
import reduxStore, { history } from './redux';
import DashboardNotification from './components/notification/DashboardNotification';

import 'bootstrap/dist/css/bootstrap.min.css';
import './dashboard.scss';

// not ideal to load everything now, but it works for now.
// we will worry about it when the files become too large
// and we support too many languages.
import zh_hant from './languages/zh_hant.json';
import zh_hans from './languages/zh_hans.json';
import en from './languages/en.json';
import ko from './languages/ko.json';
import LanguageSelect from './components/pre-auth/language-select';

// TODO: strongly recommend to extract context to a standalone module to prevent cycling dependency.

// create a context to manage locale
const SwitchLocaleContext = React.createContext<any>('en');

function Dashboard() {
  const [locale, setLocale] = React.useState(HarmoniaCookies.locale.get() || 'en');

  const allMessages = {
    en,
    'zh-hans': zh_hans,
    'zh-hant': zh_hant,
    ko,
  };

  // This function is set as a context for language select component to access.
  const switchLocale = (event: { target: { value: string; }; }) => {
    setLocale(event.target.value);
    HarmoniaCookies.locale.set(event.target.value);
  };

  return (
    <SwitchLocaleContext.Provider value={switchLocale}>
      <IntlProvider locale={locale} defaultLocale="en" messages={allMessages[locale]}>
        <ReduxProvider store={reduxStore}>
          <ConnectedRouter history={history}>
            <HarmoniaHeader isDashboard={true} />
            <Routes />
            {/* <footer style={{ margin: 'auto' }}>
              <LanguageSelect />
            </footer> */}
            <DashboardNotification />
          </ConnectedRouter>
        </ReduxProvider>
      </IntlProvider>
    </SwitchLocaleContext.Provider>
  );
}

export { SwitchLocaleContext };
export default Dashboard;
