import React from 'react';
import MainContainer from './MainContainer';

type PrimaryTemplateProps = React.PropsWithChildren<{}>;

/**
 * Primary page template. How a primary template looks like
 * is being defined in the Harmonia Cloud prototype.
 */
function PrimaryTemplate(props: PrimaryTemplateProps) {
  const { children } = props;
  return (
    <MainContainer canvasType="primary">
      {children}
    </MainContainer>
  );
}

export default PrimaryTemplate;
