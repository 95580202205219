import React from 'react';
import { CourseEnrollmentRequest} from '../../../types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrashAlt, faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import { showDateAsFormattedString, stringCaseFormat } from '../../../utilities';
import style from './CourseManagement.module.scss';

type EnrollmentRequestProps = {
    enrollmentRequest: CourseEnrollmentRequest,
    showDeleteRequestModal: (request: CourseEnrollmentRequest) => void
}

const {declineRemove} = style;

const EnrollmentRequestEntry = ({enrollmentRequest, showDeleteRequestModal}: EnrollmentRequestProps) => {

    // formatting start and end dates of course
    const courseStartDate = showDateAsFormattedString(enrollmentRequest.course.start_date, 'YYYY-MM-DD');
    const courseEndDate = showDateAsFormattedString(enrollmentRequest.course.end_date, 'YYYY-MM-DD');

    const enrollStatus = enrollmentRequest.status === 'REJECTED' ? 'DECLINED' : enrollmentRequest.status

    return (
      <>
      <tr data-cy="enrollment-request">
        <td>{enrollmentRequest.course.title}</td>
        {/* NOTE: fix this so school info is formatted properly on enrollment request object */}
        <td>{enrollmentRequest.course.school.school}</td>
        <td>{`${courseStartDate} - ${courseEndDate}`}</td>
        {/* "REJECTED" is a possible value for status but is a harsh word to show users; "DECLINED" is softer */}
        <td>{stringCaseFormat(enrollStatus)}</td>
        <td className={declineRemove} onClick={() => {showDeleteRequestModal(enrollmentRequest)}}>
        <FontAwesomeIcon style={{width:"auto", marginRight: 4}} icon={faTimesCircle}/>
          Cancel
        </td>
      </tr>
        </>
    );
}
export default EnrollmentRequestEntry;
