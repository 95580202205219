/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { HarmoniaButton, HarmoniaInput, HarmoniaPaper } from '@harmonia-front-end/shared/component';
import { Text } from '@harmonia-front-end/shared';
import { pushErrorNotification, pushNotification, useHarmoniaDispatch } from '../../redux';
import { DashboardContent, DashboardTitleBar } from '../../components/template';
import { useUser, useRedirect } from '../../hooks';
import { API, errorMessage } from '../../utilities';
import LoadingPage from '../../components/LoadingPage';
import styles from './UserInfo.module.scss';

const { paper } = styles;

export default function RequestTeacherAccountPage() {
  const dispatch = useHarmoniaDispatch();
  const [user, loading] = useUser();

  const onSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    const data = new FormData(ev.currentTarget);
    console.log(JSON.stringify(data));
    const body = { school_name: data.get('school_name'), phone: data.get('phone'), web_site: data.get('web_site') };
    console.log(JSON.stringify(body));
    API.post('/api/teacher-account-requests', body)
      .then(() => {
        window.location.href = '/my-profile';
      })
      .catch((e) => { dispatch(pushErrorNotification(errorMessage(e.data))); });
  };

  useRedirect(user?.user_type === 2, '/my-profile', {
    severity: 'error',
    message: 'You\'re already an instructor.',
  });

  if (loading) return <DashboardContent><LoadingPage /></DashboardContent>;
  if (!user) return null;

  return (
    <>
      <DashboardTitleBar h1="Request Teacher's Account" />
      <DashboardContent>
        <HarmoniaPaper rounded className={paper}>
          <form onSubmit={onSubmit}>
            <Text color="dark" variant="body">
              Please provide the name of your institution, phone number, and web site URL for verification purposes and we will get back to you shortly.
            </Text>
            <div style={{ display: 'block', height: '12px' }}/>
            <Text color="dark" variant="body">
              School Name:
            </Text>
            <HarmoniaInput
              name="school_name"
              type="text"
              style={{ marginLeft: 0, width: '100%' }}
            />
            <Text color="dark" variant="body">
              School Contact Phone Number:
            </Text>
            <HarmoniaInput
              name="phone"
              type="text"
              style={{ marginLeft: 0, width: '100%' }}
            />
            <Text color="dark" variant="body">
              School Web Site:
            </Text>
            <HarmoniaInput
              name="web_site"
              type="text"
              style={{ marginLeft: 0, width: '100%' }}
            />
            <div style={{ display: 'block', height: '12px' }}/>
            <HarmoniaButton variant="primary" dest="app" type="submit" icon="next" iconPos="left">
              Submit
            </HarmoniaButton>
          </form>
        </HarmoniaPaper>
      </DashboardContent>
    </>
  );
}
