import React from 'react';
import TextStyle from './Text.module.scss';

const {
  text, gutterBottom: gutterBottomClass, inline: inlineClass,
  dark, light, grey, lightBlue, darkGrey,black, red, blue, altGray, altBlack, harmoniaGray,
  left, center, right, justify, noWrap: noWrapClass,
  headline, h1, subHeading, h2, body, sidebarListItem, sidebarListHeading, pageTitle,
  dashboardHeading, dashboardContentHeading, dashboardContentTitle, dashboardBasicInformation,
  dashboardList, dashboardWidgetItem, dashboardWidgetHeading, homePageHeading, homePageSubHeading, 
  homePageContent, homePageMainQuote, homePageMainQuoteName, homePageSmallQuote, homePageSmallQuoteName, homePageFeatureDescription, 
  homePageLink, homePageCopyright, normal, italic, oblique,
  moreHeading, moreSubHeading, moreEmphasis, moreParagraph, moreSectionHeading,
  forHeading, forSubHeading, forSectionHeading, forLeft, forRight
} = TextStyle;

type TextProps = React.PropsWithChildren<{
  /**
   * Color of the text. Default is unset.
   * To use the last four variants: 'warning', 'error', 'info', and 'success',
   * Bootstrap stylesheet is required.
   */
  color?: 'light' | 'dark' | 'grey' | 'dark-grey' | 'lightBlue' | 'black' | 'error' | 'success' | 'warning' | 'info' | 'red' | 'blue' | 'altGray' | 'altBlack' | 'harmoniaGray',

  /** Font settings for the text based on its intended usage. Default is unset. */
  variant?: 'headline' | 'h1' | 'subHeading' | 'h2' | 'body' | 'sidebarListItem' | 'sidebarListHeading' |
  'pageTitle' | 'dashboardHeading' | 'dashboardContentHeading' | 'dashboardContentTitle' |
  'dashboardBasicInformation' | 'dashboardList' | 'dashboardWidgetItem' | 'dashboardWidgetHeading' | 'homePageHeading' | 'homePageSubHeading' | 
  'homePageContent' | 'homePageMainQuote' | 'homePageMainQuoteName' | 'homePageSmallQuote' | 'homePageSmallQuoteName' | 'homePageFeatureDescription' | 
  'homePageLink' | 'homePageCopyright' |
  'moreHeading'| 'moreSubHeading' | 'moreEmphasis' | 'moreParagraph' | 'moreSectionHeading' |
  'forHeading' | 'forSubHeading' | 'forSectionHeading' | 'forLeft' | 'forRight'

  /** Text align. Default is unset. */
  align?: 'left' | 'center' | 'right' | 'justify',

  /** Font-style. Default is normal. */

  fontStyle?: 'normal' | 'italic' | 'oblique',

  /** Should the text wrap up? Default is false. */
  noWrap?: boolean,

  /** Apply bottom margin. Default is false. */
  gutterBottom?: boolean,

  /** Additional HTML classes to apply. */
  className?: string,

  /** Additional inline CSS properties. */
  style?: React.CSSProperties;

  /** Display as an inline element. */
  inline?: boolean,
}>;

function Text(props: TextProps) {
  const {
    children, color, variant, align, fontStyle, noWrap, className, gutterBottom, style, inline,
  } = props;

  const classList = className ? className.split(' ') : [];
  classList.push(text);

  if (inline) classList.push(inlineClass);

  if (noWrap) classList.push(noWrapClass);

  if (gutterBottom) classList.push(gutterBottomClass);

  if (color === 'light') classList.push(light);
  else if (color === 'grey') classList.push(grey);
  else if (color === 'lightBlue') classList.push(lightBlue);
  else if (color === 'dark-grey') classList.push(darkGrey);
  else if (color === 'black') classList.push(black);
  else if (color === 'dark') classList.push(dark);
  else if (color === 'error') classList.push('text-danger');
  else if (color === 'success') classList.push('text-success');
  else if (color === 'info') classList.push('text-info');
  else if (color === 'warning') classList.push('text-warning');
  else if (color === 'red') classList.push(red);
  else if (color === 'blue') classList.push(blue);
  else if (color === 'altGray') classList.push(altGray);
  else if (color === 'altBlack') classList.push(altBlack);
  else if (color === 'harmoniaGray') classList.push(harmoniaGray);


  if (align === 'left') classList.push(left);
  else if (align === 'center') classList.push(center);
  else if (align === 'right') classList.push(right);
  else if (align === 'justify') classList.push(justify);

  if (variant === 'headline') classList.push(headline); // h1
  if (variant === 'h1') classList.push(h1); // h1
  if (variant === 'subHeading') classList.push(subHeading); // h2
  if (variant === 'h2') classList.push(h2); // h2
  if (variant === 'body') classList.push(body);
  if (variant === 'sidebarListItem') classList.push(sidebarListItem);
  if (variant === 'sidebarListHeading') classList.push(sidebarListHeading); // h4
  if (variant === 'pageTitle') classList.push(pageTitle); // h1
  if (variant === 'dashboardHeading') classList.push(dashboardHeading); // h3
  if (variant === 'dashboardContentHeading') classList.push(dashboardContentHeading); // h6
  if (variant === 'dashboardContentTitle') classList.push(dashboardContentTitle); // h5
  if (variant === 'dashboardBasicInformation') classList.push(dashboardBasicInformation);
  if (variant === 'dashboardList') classList.push(dashboardList);
  if (variant === 'dashboardWidgetItem') classList.push(dashboardWidgetItem);
  if (variant === 'dashboardWidgetHeading') classList.push(dashboardWidgetHeading); // h6
  if (variant === 'homePageHeading') classList.push(homePageHeading);
  if (variant === 'homePageSubHeading') classList.push(homePageSubHeading);
  if (variant === 'homePageContent') classList.push(homePageContent);
  if (variant === 'homePageMainQuote') classList.push(homePageMainQuote);
  if (variant === 'homePageMainQuoteName') classList.push(homePageMainQuoteName);
  if (variant === 'homePageSmallQuote') classList.push(homePageSmallQuote);
  if (variant === 'homePageSmallQuoteName') classList.push(homePageSmallQuoteName);
  if (variant === 'homePageFeatureDescription') classList.push(homePageFeatureDescription);
  if (variant === 'homePageLink') classList.push(homePageLink);
  if (variant === 'homePageCopyright') classList.push(homePageCopyright);

  if (variant === 'moreHeading') classList.push(moreHeading)
  if (variant === 'moreSubHeading')classList.push(moreSubHeading);
  if (variant === 'moreEmphasis') classList.push(moreEmphasis)
  if (variant === 'moreParagraph') classList.push(moreParagraph);
  if (variant === 'moreSectionHeading') classList.push(moreSectionHeading);

  if (variant === 'forHeading') classList.push(forHeading);
  if (variant === 'forSubHeading') classList.push(forSubHeading);
  if (variant === 'forSectionHeading') classList.push(forSectionHeading);
  if (variant === 'forLeft') classList.push(forLeft);
  if (variant === 'forRight') classList.push(forRight);


  if (fontStyle === 'normal') classList.push(normal);
  if (fontStyle === 'italic') classList.push(italic);
  if (fontStyle === 'oblique') classList.push(oblique);

  const finalClassName = classList.join(' ');

  if (variant === 'headline' || variant === 'h1' || variant === 'pageTitle' || variant === 'moreHeading') {
    return <h1 className={finalClassName} style={style}>{children}</h1>;
  }
  if (variant === 'subHeading' || variant === 'h2' || variant === 'homePageSubHeading' || variant === 'moreSubHeading' || variant === 'moreSectionHeading') {
    return <h2 className={finalClassName} style={style}>{children}</h2>;
  }
  if (variant === 'dashboardHeading') {
    return <h3 className={finalClassName} style={style}>{children}</h3>;
  }
  if (variant === 'sidebarListHeading') {
    return <h4 className={finalClassName} style={style}>{children}</h4>;
  }
  if (variant === 'dashboardContentTitle') {
    return <h5 className={finalClassName} style={style}>{children}</h5>;
  }
  if (variant === 'dashboardContentHeading' || variant === 'dashboardWidgetHeading') {
    return <h6 className={finalClassName} style={style}>{children}</h6>;
  }

  return <p className={finalClassName} style={style}>{children}</p>;
}

export default Text;
