import React from 'react';
import MainContainer from './MainContainer';
import { HarmoniaSquare } from '../component';
import MiniTemplateStyle from './MiniTemplate.module.scss';

const {
  artwork, strip1, strip2, square1,
} = MiniTemplateStyle;

type MiniTemplateProps = React.PropsWithChildren<{
  /** The header artwork image HTML element source. */
  imgSrc?: string,

  /** The header artwork image HTML element source set. */
  imgSrcSet?: string,

  /** The header artwork image HTML element alternate description. */
  imgAlt?: string,
}>;

// TODO: figure out a better way to position HarmoniaSquare.
// TODO: header artwork aspect ration?

/**
 * Mini page template. How a mini template looks like
 * is being defined in the Harmonia Cloud prototype.
 *
 * A page complies with the mini template has a header artwork to be displayed.
 */
function MiniTemplate(props: MiniTemplateProps) {
  const {
    children, imgAlt, imgSrc, imgSrcSet,
  } = props;
  const hasImage = imgAlt && (imgSrc || imgSrcSet);
  return (
    <MainContainer canvasType="mini">
      {hasImage && (
      <div className={artwork}>
        <HarmoniaSquare className={strip1} variety="yellow" />
        <HarmoniaSquare className={strip2} variety="yellow" />
        <HarmoniaSquare
          className={square1}
          variety="sky"
          trapezoid
          trapezoidPos="top"
          trapezoidRatio={50}
          trapezoidSlice="start"
        />
        <img alt={imgAlt} src={imgSrc} srcSet={imgSrcSet} />
      </div>
      )}
      {children}
    </MainContainer>
  );
}

export default MiniTemplate;
