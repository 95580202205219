// notification.ts
/** Redux store that stores and disperses notifications. */
import { ReactNode } from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import { HarmoniaCookies } from '../utilities';

/** Notification properties that should be passed along with Redux actions. */
export type Notification = {
  /**
   * Notification type.
   * TODO: Severities are shared among multiple components, not just limited to notifications.
   * Perhaps to escalate this to the shared module?
   */
  severity?: 'success' | 'warning' | 'error' | 'info',

  /** The content of the notification. It can be a React Node for internationalization. */
  message: ReactNode,

  /** Optional time in milliseconds to auto-clear the notification. 5 seconds by default. */
  timeout?: number,
};

/**
 * Patch a time value to notifications to distinguish them.
 * Must not be patched manually. Leave actions like `pushNotification` to do this.
 */
export type NotificationWithTime = Notification & { time: number };

const initialState: NotificationWithTime[] = [];

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    /**
     * Push a notification into the state.
     * It receives an `Notification` object, patches it with a timestamp,
     * and pushes it to the state.
     */
    push: (state, action: PayloadAction<Notification>) => {
      state.push({ ...(action.payload), time: Date.now() });
    },

    /**
     * Push an error notification into the state.
     * A shortcut to push error notification, it only receives the content.
     * 'severity` would be set to 'error' and the timeout value would be in 5 seconds.
     */
    pushError: (state, action: PayloadAction<ReactNode>) => {
      // if (HarmoniaCookies['x-access-token'].get())
      state.push({ severity: 'error', message: action.payload, time: Date.now() });
    },

    /** Remove an notification based on its timestamp. */
    clear: (
      state, action: PayloadAction<number>,
    ) => state.filter((val) => val.time !== action.payload),

    /** Clear every notification in the notification poll. */
    clearAll: () => initialState,
  },
});

export const {
  push: pushNotification,
  pushError: pushErrorNotification,
  clear: clearNotification,
  clearAll: clearAllNotifications,
} = notificationSlice.actions;

export default notificationSlice.reducer;
