import { useEffect, useState } from 'react';
import style from './modal.module.scss';
const {blur, unblur, background, show, hide, hidden} = style;
/** Helper React hook to enable opening and closing of any modal component.
 * Returns an array of 3 items:
 * 1. a boolean variable to be used as state for showing modal
 * 2. a function to open the modal: blurs body elements behind modal, fades in modal backdrop and slides in modal content (all with animation).
 * 3. a function to close the modal: unblurs body elements, fades out modal backdrop and slides out.
 *
 * Modal components should be rendered on "modal-root" div (via ReactDOM.createPortal) so they are independent of component hierarchy on body root element to enable blur and fade effects.
 */

const useModal = () => {
  /** local state */
  const [modalShow, setModal] = useState(false);

  /** Grabbing needed root elements from DOM. */
  const backgroundRoot = document.getElementById('background-root') as HTMLDivElement;
  const bodyRoot = document.getElementById('root') as HTMLDivElement;
  const modalRoot = document.getElementById('modal-root') as HTMLDivElement;

  /** opening modal */
  const openModal = () => {
    setModal(true);
    // resetting classes if modal is re-opened on page after closing without page refresh.
    backgroundRoot.classList.remove(hide, hidden);
    // blurs body content behind modal
    bodyRoot.classList.remove(unblur);
    bodyRoot.classList.add(blur);
    // prevents scrolling on page while modal is open
    document.body.classList.add('modal-open');
    // adding backdrop and fading it in
    backgroundRoot.classList.add(background, show);
  };

  /** closing modal */
  const closeModal = () => {
    setModal(false);
    // the hidden class simply sets the z-index of background root to be behind everything, so user can interact again with elements on page. We need it to finish the fade-out animation properly while it is still in front.
    setTimeout(() => {
      backgroundRoot.classList.add(hidden);
    }, 500);
    // resetting classes.
    bodyRoot.classList.remove(blur, show);
    // unblurring body content behind modal.
    bodyRoot.classList.add(unblur);
    // reenable scroll on entire body.
    document.body.classList.remove('modal-open');
    // fading out backdrop.
    backgroundRoot.classList.add(hide);
  };

  return [modalShow, openModal, closeModal] as [boolean, () => void, () => void]
}

export default useModal;
