import React from 'react';
import DocImagePreviewModalStyle  from './DocImagePreviewModal.module.scss';
import ReactDOM from 'react-dom';
import { faChevronLeft,faChevronRight, faTimes} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Assignment, Document} from '../../types';
import LoadingPage from '../LoadingPage';
import {
  HarmoniaChevron,
  Text,
} from '@harmonia-front-end/shared';

const  {modalContainer, modalContent, title, docTitle, bottom, docPreviewImage, leftArrow, rightArrow, close, imageWrapper} = DocImagePreviewModalStyle;

type GradeReportModalProps = {
  closeGradeReportModal: () => void,
  show: boolean,
  // getGradeReport: (index: number) => void,
  url: string | undefined,
  reportIndex: number | undefined
}

// This component is used on both the AssignmentsPage and the AssignmentDetailPage. The assignment-documents on the AssignmentsPage consists of an array of multiple assignment objects, each with an array of document objects. For the AssignmentDetailPage, the array has just one assignment object. For moving between the different assignment objects on the AssignmentDetailPage, there is a page prop passed to this component which we use to run conditional logic in the preview functions.

const ViewGradeReportModal = (props: GradeReportModalProps) => {

  const {closeGradeReportModal, show, /* getGradeReport, */ url, reportIndex} = props;

  // console.log("show grade report?:");
  // console.log(show);
  // console.log(url);
  // console.log("reportIndex = " + reportIndex);

  // don't render if modal is not set to show, or there are no assignmentDocuments or selectedDoc
  if(!show || !reportIndex/*  || !url */) return null;

// modal component(s) rendered on separate root div than body so background effects (blur and fade) can be added.
const modalRoot = document.getElementById('modal-root') as HTMLDivElement;

    // console.log("root = " + modalRoot);

// We pass this function as an onClick to both modalContainer (backdrop) and modalContent (pop-up) divs so we the modal when the backdrop is clicked.
const closeOnBackDrop = (e: React.MouseEvent<HTMLElement>) => {
  e.stopPropagation();
  const className = e.currentTarget.className;
  if(className === modalContainer) {
    closeGradeReportModal();
  }
};


return ReactDOM.createPortal (
  <div className={modalContainer} onClick={closeOnBackDrop}>
    <div className={modalContent} onClick={closeOnBackDrop}data-cy="document-image-preview-modal">
      <div className={close}>
        <div onClick={() => closeGradeReportModal()}>
        <Text>X</Text>
        </div>
      </div>
      {/* TITLE */}
      <div className={title} data-cy="image-preview-title">
        <Text variant="dashboardHeading">
          Grade Report for Submission #{reportIndex}
        </Text>
      </div>
      <div style={{width: '100%', height: '100%', overflow: 'hidden'}} className={bottom}>
        {/* <div onClick={() => getGradeReport(reportIndex - 1)} data-cy="previous-image">
          <HarmoniaChevron direction="left" className={leftArrow} />
        </div> /*}
        {/* IMAGE */}
        <div style={{width: '100%', height: '100%', overflow: 'hidden'}} className={imageWrapper}>
          {!url && <LoadingPage/>}
          {url && <iframe style={{backgroundColor: 'white', width: '100%', height: '100%'}} src={url} title="Grade Report" className={docPreviewImage} data-cy="document-preview-image"/>}
        </div>
        {/* <div onClick={() => getGradeReport(reportIndex + 1)} data-cy="next-image">
          <HarmoniaChevron direction="right" className={rightArrow}  />
        </div> */}
      </div>
    </div>
  </div>,
  modalRoot
)
}

export default ViewGradeReportModal;

