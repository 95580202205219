import React from 'react';
import DashboardContentStyle from './DashboardContent.module.scss';

const { dashboardContent, content } = DashboardContentStyle;

type DashboardContentProps = React.PropsWithChildren<{
  /** HTML classes to be applied to the inner HTML div element where content is rendered. */
  contentClassName?: string;
}>;

function DashboardContent(props: DashboardContentProps) {
  const { children, contentClassName } = props;

  const contentClassList = contentClassName ? contentClassName.split(' ') : [];
  contentClassList.push(content);

  return (
    <div className={dashboardContent}>
      <div className={contentClassList.join(' ')}>
        {children}
      </div>
    </div>
  );
}

export default DashboardContent;
