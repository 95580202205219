// import libraries
import React, { useState, useEffect } from "react";
import { HarmoniaButton } from "@harmonia-front-end/shared/component";
import { useParams } from "react-router-dom";
import {
  useHarmoniaSelector,
  useHarmoniaDispatch,
  getCourseDetail, activateCourse
} from "../../redux";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { DashboardTitleBar, DashboardContent } from "../../components/template";
import { HarmoniaPaper, useToggle, Text } from "@harmonia-front-end/shared";
import { Link } from "react-router-dom";
import { CourseDetailedInfo } from "../../types";
import { showDateAsFormattedString } from "../../utilities";
import { ActivateCourse } from "../../components/course";
import LoadingPage from "../../components/LoadingPage";
import CoursePagesStyle from './CoursePages.module.scss';
import { useUser, useRedirect } from '../../hooks';

const {courseInfoContainer, courseInfo, invitationCodeLink, infoBottom, endedCourseEdit, active, planning, ended} = CoursePagesStyle;

export default function CourseInformationPage() {
  const dispatch = useHarmoniaDispatch();

  // Grab courseId (string) from params and convert it to a number (courseID).
  const { courseId } = useParams<{courseId: string}>();
  const courseID = parseInt(courseId, 10);

  // Local state for adding loading page when grabbing course info.
  const [courseDetailsLoading, setCourseDetailsLoading] = useState(true);

  // Determine whether or not a user is an instructor once the courses load
  const courses = useHarmoniaSelector(state => state.courses)
  let isTA = false;
  let foundCourse = courses?.course_instructors?.find(course => course.id === courseID);
  if (!foundCourse) {
      isTA = true;
      foundCourse = courses?.course_tas?.find(course => course.id === courseID);
  }
  let needToRedirect = foundCourse ? false : !Object.keys(courses).length ? false : true;

  // Making API call to grab course detail and set/unset loading.
  useEffect(() => {
    // if (foundCourse) {
      dispatch(getCourseDetail(courseID)).finally(() => {
        setCourseDetailsLoading(false);
      });
    // }
  }, [dispatch]);

  useRedirect(needToRedirect, '/', {
    severity: "error",
    message: "You are not an instructor of this course."
  });

  // Grabbing course information from Redux.
  const selectedCourse  = useHarmoniaSelector((state) => state.selectedCourse);
  const courseInformation = selectedCourse.courseInformation as CourseDetailedInfo || {};
  // Destructuring relevant course info.
  const {academic_term, cadential_64, course_product, course_product_price, end_date, enrollment_end_date, instructors, invitation_code, neapolitan_6, payment_type, school, start_date, status, title, year, id, lms_type } = courseInformation;
  // Determing what text to display regarding the fee paid for the course; the keys in this object refer to the payment_type on the course details object.
  const course_fee_paid_by: {[key: number]: string} = {
    0: 'Fee Paid:',
    1: 'Fee Paid:',
    2: 'Fee Paid By Students:',
    3: 'Fee Paid By School:'
  }
// Functions to 'fee paid/fee paid by': ${price}. Unavoidable Typescript 'error' due to using variable for object key; just ignore!
  const renderCoursePaidBy = (paymentType: number) => {
    return course_fee_paid_by[paymentType];
  }
  const renderCourseFee = (paymentType: number) => {
    // unpaid course
    if(paymentType === 0){
      return 'This is an unpaid course';
    }
    // free course
    if(paymentType === 1){
      return 'This course is free to join';
    }
    // fastspring or site license
    else {
      return `$${course_product_price} USD per student (${course_product})`;
    }
  }

  // local state for confirm activate course modal and relevant handlers for making API call to activate course

  const [activateModalOpen, toggleActiveModalOpen] = useToggle();

  const confirmActivateCourse = (courseID: number) => () => {
    dispatch(activateCourse(courseID)).finally(() => {
      toggleActiveModalOpen();
    })
  };


  // color coding course status
  const statusClass = status === 'ACTIVE' ? active : (status === 'PLANNING' ? planning : ended);


  return (
    <>
    {courseDetailsLoading && <DashboardContent><LoadingPage/></DashboardContent>}
    {!courseDetailsLoading && <DashboardTitleBar h1="Course Information" h2={courseInformation.title} h2_no2={`${courseInformation.academic_term} ${courseInformation.year}`}>
      </DashboardTitleBar>}
      <DashboardContent>
      <HarmoniaPaper className={courseInfoContainer}>
        {!courseDetailsLoading &&
        <>
    <div className={courseInfo} data-cy='courseInfo'>
      <div>
        <span>Course Title: </span>
        <span>{title}</span>
      </div>
      <div>
        <span>Instructors: </span>
        <span>{instructors?.map(instructor => `${instructor.last_name}, ${instructor.first_name}`).join('; ')}</span>
      </div>
      <div>
        <span>Institute: </span>
        <span>{school}</span>
      </div>
      <div>
        <span>Academic Term: </span>
        <span>{`${academic_term} ${year? year : ''}`}</span>
      </div>
      <div>
        <span>Course Period: </span>
        <span>{`${showDateAsFormattedString(start_date, 'Pp')}`}&ensp;&mdash;&ensp;{`${showDateAsFormattedString(end_date, 'Pp')}`}</span>
      </div>
      <div>
        <span>Enrollment End Date: </span>
        <span>{showDateAsFormattedString(enrollment_end_date, 'Pp')}</span>
      </div>
      <div>
        <span>Invitation Code: </span>
        <span>{invitation_code}</span>
        {/* PLACEHOLDER LINK FOR NOW */}
        <a href='https://harmonia.illiacsoftware.com/support?article=119' target="_blank">
        <span className={invitationCodeLink}>[What is this?]</span>
        </a>
      </div>
      {/* if course_product/course_product_id is null, don't show */}
      {course_product && course_product_price &&
      <div>
        <span>{renderCoursePaidBy(payment_type)}</span>
        <span>{renderCourseFee(payment_type)}</span>
      </div>
}
      <div>
        <span>Cadential 64 in this course is labeled as: </span>
        <span>{cadential_64 === 'V' ? 'V64' : 'I64'}</span>
      </div>
      <div>
        <span>Neapolitan 6 in this course is labeled as: </span>
        <span>{neapolitan_6 === 'N' ? 'N6' : 'bII6'}</span>
      </div>
      {process.env.REACT_APP_HARMONIA_ENV !== 'production' &&
          <div>
            <span>LMS Integration: </span>
            <span>{lms_type === 1 ? "Google Classroom" : lms_type === 2 ? "LTI Plugin" : "None" }</span>
          </div>
      }
      <div>
        <span>Status: </span>
        <span className={statusClass}>{status}</span>
      </div>
      {/* Only courses with status of 'PLANNING' or 'ACTIVE' are editable. */}
      <div className={infoBottom}>
        {
          status &&
        (status === 'ACTIVE' || status === 'PLANNING') && !isTA &&
          <Link to={`/course/${courseInformation.id}/settings`}>
            <HarmoniaButton variant="primary" dest="app" icon="edit" iconPos="left">
              Edit Course Settings
            </HarmoniaButton>
          </Link>

        }
        {
          status === 'PLANNING' && !isTA && 
          <ActivateCourse show={activateModalOpen} toggleModal={toggleActiveModalOpen} courseID={id} courseTitle={title}/>
        }
      </div>
    </div>
    </>
}
    </HarmoniaPaper>
    </DashboardContent>
    </>
  );
}
