import React from 'react';
import {
  HarmoniaButton, HarmoniaInput, HarmoniaPaper, stringIterSearch, Text, useToggle,
} from '@harmonia-front-end/shared';
import {
  Dropdown, DropdownItem, DropdownMenu, DropdownToggle,
} from 'reactstrap';
import { DashboardContent, DashboardTitleBar } from '../../components/template';
import { useContentLibrary, useRedirect, useUser } from '../../hooks';
import LoadingPage from '../../components/LoadingPage';
import style from './ContentLibrary.module.scss';
import { getContentLibraryDocumentPreviewURL, useHarmoniaDispatch } from '../../redux';
import { FormattedMessage } from 'react-intl';


const {
  menu, menuInput, menuInputWrap, paper, img,
} = style;

function ContentLibraryPage() {
  const [user, userLoading] = useUser();
  const [contentLibrary, contentLibraryLoading] = useContentLibrary();
  const [dropdownOpen, toggleDropdown] = useToggle();
  const [inputValue, setInputValue] = React.useState('');
  const [docID, setDocID] = React.useState(0);
  const [urlLoading, setURLLoading] = React.useState(false);
  const dispatch = useHarmoniaDispatch();

  const onInputChange: React.ChangeEventHandler<HTMLInputElement> = (ev) => {
    setInputValue(ev.target.value.toLocaleLowerCase());
  };
  const onItemClick = (id: number) => () => {
    setDocID(id);
    setURLLoading(true);
    dispatch(getContentLibraryDocumentPreviewURL(id))
      .finally(() => setURLLoading(false));
  };
  const selectedDoc = React.useMemo(() => contentLibrary?.find(
    (lib) => lib.documents.some((doc) => doc.id === docID),
  )?.documents.find((doc) => doc.id === docID), [docID, contentLibrary]);

  useRedirect(!userLoading && user?.user_type !== 2, '/my-profile', {
    severity: 'error',
    message: 'Only instructors can preview content library.',
  });

  if (userLoading || contentLibraryLoading) return <DashboardContent><LoadingPage /></DashboardContent>;
  if (!user || !contentLibrary) return null;

  return (
    <>
      <DashboardTitleBar h1="Content Library">
        <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
          <DropdownToggle caret size="sm">
            <FormattedMessage id="edit_file.select_document" defaultMessage="Select document"/>
          </DropdownToggle>
          <DropdownMenu className={menu} right>
            <div className={menuInputWrap}>
              <HarmoniaInput
                autoFocus
                placeholder="Search documents"
                onChange={onInputChange}
                className={menuInput}
              />
            </div>
            {contentLibrary.map((lib, index) => {
              const docToShow = lib.documents.filter(
                (doc) => stringIterSearch(doc.title.toLocaleLowerCase(), inputValue),
              );
              if (docToShow.length === 0) return null;
              return (
                <React.Fragment key={lib.set_key}>
                  {index !== 0 && <DropdownItem divider />}
                  <DropdownItem header>{lib.title}</DropdownItem>
                  {docToShow.map((doc) => (
                    <DropdownItem key={doc.doc_key} onClick={onItemClick(doc.id)}>
                      {doc.title}
                    </DropdownItem>
                  ))}
                </React.Fragment>
              );
            })}
          </DropdownMenu>
        </Dropdown>
      </DashboardTitleBar>
      <DashboardContent>
        <HarmoniaPaper rounded className={paper}>
          <Text variant="h2" color="dark-grey" gutterBottom>
            {selectedDoc?.title || 'Select a document to begin.'}
          </Text>
          {urlLoading && <LoadingPage />}
          {!urlLoading && selectedDoc && (!selectedDoc.previewURL ? (
            <>
              <Text variant="body" color="dark" inline>
                <FormattedMessage id="edit_file.preview_fail" defaultText="Failed to load the preview. Please try again."/>
              </Text>
              <HarmoniaButton variant="primary" dest="app" onClick={onItemClick(docID)}>
                <FormattedMessage id="edit_file.reload" defaultText="Reload"/>
              </HarmoniaButton>
            </>
          ) : (
            <img src={selectedDoc.previewURL} alt={selectedDoc.title} className={img} />
          ))}
        </HarmoniaPaper>
      </DashboardContent>
    </>
  );
}

export default ContentLibraryPage;
