import React, { useState } from "react";
import ReactDOM from "react-dom";
import {
  faExclamationTriangle,
  faExclamationCircle,
  faInfoCircle,
  faCheckCircle,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HarmoniaButton } from "@harmonia-front-end/shared";
import {
  useHarmoniaDispatch,
  useHarmoniaSelector,
  clearNotification,
  NotificationWithTime,
} from "../../redux";
import DashboardNotificationStyle from "./DashboardNotification.module.scss";
import { is } from "immer/dist/internal";

const { container, item, bar, icon, content, header, error, success, close, slideIn, slideOut } =
  DashboardNotificationStyle;

function DashboardNotificationItem(props: NotificationWithTime) {
  const { severity, time, message, timeout } = props;
  const dispatch = useHarmoniaDispatch();

  const clearSelf = React.useCallback(() => {
    dispatch(clearNotification(time));
  }, [dispatch, time]);

  const [isClosing, setClosing] = useState(false);

  const DEFAULT_TIMEOUT = 5000;

  React.useEffect(() => {

      // Handle default timeout
      const shouldAutoClear = !timeout || timeout >= 0;
      const trueTimeout = timeout || DEFAULT_TIMEOUT;
      let timeoutID: number;
      if (shouldAutoClear) {
        window.setTimeout(() => {
          setClosing(true);
          // console.log("notification timed out.")
        },trueTimeout);
      }
  })

  const ANIMATION_DELAY = 200;

  React.useEffect(() => {
    // Handle setClosing
    if (isClosing) {
      // console.log("isClosing.");
      window.setTimeout(() => {
        clearSelf();
      }, ANIMATION_DELAY)
    }
  },[isClosing])

  const classList = [item];
  if (severity === "error") classList.push(error);
  else if (severity === "info") classList.push("alert-info");
  else if (severity === "success") classList.push(success);
  else if (severity === "warning") classList.push("alert-warning");
  else classList.push("alert-secondary");

  const faIcon = (() => {
    if (severity === "error") return faExclamationCircle;
    if (severity === "info") return faInfoCircle;
    if (severity === "success") return faCheckCircle;
    if (severity === "warning") return faExclamationTriangle;
    return undefined;
  })();

  return (
    <div
      key={time.valueOf()}
      className={`${classList.join(" ")} ${isClosing ? slideOut : slideIn}`}
    >
      {faIcon && <FontAwesomeIcon className={icon} icon={faIcon} />}
      <div className={content}>
        <div className={header}>
          {severity === "success" ? "Success" : "Error"}
        </div>
        {message}
      </div>
      {/* <HarmoniaButton variant="icon" dest="app" icon="close" onClick={clearSelf} /> */}
      <button className={close} onClick={() => setClosing(true)}>
        <FontAwesomeIcon icon={faTimes} />
      </button>
    </div>
  );
}

/**
 * React component to show Harmonia Cloud dashboard notifications.
 *
 * Harmonia Cloud dashboard's notification system is a part of the Redux logic,
 * so this component should be placed inside the Redux store provider.
 * Other than that, the place should not matter
 * as rendered element is ported to the root of the HTML document body
 * using `ReactDOM.createPortal`.
 */
function DashboardNotification() {
  const notifications = useHarmoniaSelector((state) => state.notification);

  return ReactDOM.createPortal(
    <div className={container}>
      {notifications.map(({ severity, time, message, timeout }) => (
        <DashboardNotificationItem
          key={time.valueOf()}
          severity={severity}
          message={message}
          time={time}
          timeout={timeout}
        />
      ))}
    </div>,
    document.body
  );
}

export default DashboardNotification;
