// cookies.ts
/** A cookies operation definition. */

// TODO: raise this to shared library.

import Cookies from 'js-cookie';

/** A class that wraps up common Cookies operations with specific name. */
class HarmoniaCookiesEntry {
  /**
   * Constructs a set of Cookies operation for an cookies entry's name.
   * @constructor
   * @param name The name of the cookies entry.
   */
  constructor(private name: string) {}

  /** Get the Cookies value by the name. */
  get() {
    return Cookies.get(this.name);
  }

  /**
   * Set the Cookies value by the name.
   * @param val The value to be set.
   * @param opt Options from 'js-cookie' library.
   */
  set(val: string | object, opt?: Cookies.CookieAttributes) {
    // const domain = (() => {
    //   if (process.env.REACT_APP_HARMONIA_ENV === 'production') return 'harmonia.illiacsoftware.com';
    //   if (process.env.REACT_APP_HARMONIA_ENV === 'staging') return 'staging.harmonia.illiacsoftware.com';
    //   return 'localhost';
    // })();

    Cookies.set(this.name, val, opt);

  }

  /** Remove the Cookies entry by the name. */
  remove(opt?: Cookies.CookieAttributes) {
    Cookies.remove(this.name, opt);
  }
}

/** An Object specifying some Cookies entry operations with its name and operations.
 * Cookies are controlled entirely by back-end API middleware. All the front-end does is send in token during API request and set token after API response. To ensure everything requiring log-in/log-out is regulated entirely by back-end, we need to use 3 cookies:
 *
 * 1. "x-access-token": used for verifying existence of access token for rendering pages requiring log-in as well as making API calls. This key matches what is sent in API response header and its value is the token itself.
 * 2. "token-expire-time": used for triggering session auto log-out warning modal and for displaying session time remaining in countdown. We create this cookie because we are unable to access the expiration date of "x-access-token". It is set at the same time and with the same expiration as "x-access-token", and its value should be the expiration date of the cookie.
 * 3. "session-timed-out": used to display a message on sign-in page if user was auto logged-out. It is set to expire in 5 seconds (as it needs to persist past the page refresh but otherwise clear quickly) and its set value should be simply the string "session-timed-out".
 */
const HarmoniaCookies = {
  /** Entry name that holds the user login info. */
  'x-access-token': new HarmoniaCookiesEntry('x-access-token'),
  'token-expire-time': new HarmoniaCookiesEntry('token-expire-time'),
  'session-timed-out': new HarmoniaCookiesEntry('session-timed-out'),

  locale: new HarmoniaCookiesEntry('locale'),
};

export default HarmoniaCookies;
