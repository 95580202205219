/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ContentLibrary } from '../types';
import { API } from '../utilities';
import { pushErrorNotification } from './notification';

type ContentLibraryInitialState = {
  contentLibrary?: ContentLibrary | null;
};

const initialState: ContentLibraryInitialState = {};

export const getEntireContentLibrary = createAsyncThunk<
ContentLibrary, void, { rejectValue: void }
>(
  'contentLibrary/getEntireContentLibrary',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await API.get('/api/content-library');
      return response.data.data as ContentLibrary;
    } catch (e) {
      dispatch(pushErrorNotification(e.data));
      return rejectWithValue();
    }
  },
);

export const getContentLibraryDocumentPreviewURL = createAsyncThunk<
string, number, { rejectValue: void }
>(
  'contentLibrary/getContentLibraryDocumentPreviewURL',
  async (docID, { dispatch, rejectWithValue }) => {
    try {
      console.log('before response getContentLibraryPreview');
      const response = await API.get(`/api/content-library/${docID}/preview-url`);
      console.log('response is: ', response);
      return response.data.data.url as string;
    } catch (e) {
      dispatch(pushErrorNotification(e.data));
      return rejectWithValue();
    }
  },
);

const contentLibrarySlice = createSlice({
  name: 'contentLibrary',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getEntireContentLibrary.fulfilled,
        (state, { payload }) => { state.contentLibrary = payload; },
      )
      .addCase(
        getEntireContentLibrary.rejected,
        (state) => { state.contentLibrary = null; },
      )
      .addCase(
        getContentLibraryDocumentPreviewURL.fulfilled,
        (state, { payload, meta }) => {
          state.contentLibrary?.forEach(({ documents }) => {
            documents.forEach((document) => {
              if (document.id === meta.arg) document.previewURL = payload;
            });
          });
        },
      )
      .addCase(
        getContentLibraryDocumentPreviewURL.rejected,
        (state, { meta }) => {
          state.contentLibrary?.forEach(({ documents }) => {
            documents.forEach((document) => {
              if (document.id === meta.arg) document.previewURL = '';
            });
          });
        },
      );
  },
});

export default contentLibrarySlice.reducer;
