import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown, faCaretUp, faCopy} from "@fortawesome/free-solid-svg-icons";
import {FormattedMessage} from "react-intl";
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {Link} from "react-router-dom";
import {useHarmoniaDispatch, archiveUnarchiveCourse, duplicateCourse, activateCourse, getUserInfo} from "../../../redux";
import {Draggable} from "react-beautiful-dnd";
import {HarmoniaButton, HarmoniaPaper, Text, useToggle} from "@harmonia-front-end/shared";
import {ActivateCourse} from "../../course";
import SingleCourseDropdown from "./SingleCourseDropdown";
import {CourseDetailedInfo} from "../../../types";
import {showDateAsFormattedString, stringCaseFormat} from "../../../utilities";
import styles from "./SingleCourse.module.scss";

const {singleCourse, courseTop, drag, courseTitle, statusActive, statusPlanning, statusEnding, duplicate, infoLeft, infoRight, infoItem, courseBottom, bottomInfo, infoActivate} = styles;


type SingleCourseProps = {
    course: CourseDetailedInfo,
    userCourseRole: 'instructor' | 'ta' | 'student',
    index: number,
    showDeleteCourseModal: (course: CourseDetailedInfo) => void,
    setWaiting: (waiting: boolean) => void
}

const SingleCourse = ({course, userCourseRole, index, showDeleteCourseModal, setWaiting}: SingleCourseProps) => {

    const dispatch = useHarmoniaDispatch();

    const [duplicateModalOpen, toggleDuplicateModalOpen] = useToggle();
    const [activateModalOpen, toggleActivateModalOpen] = useToggle()

    // deconstruct course info
    const {title, school, academic_term, year, status, start_date, end_date, id} = course;

    // convert time stamps for start and end date to readable time.
    const startDate = showDateAsFormattedString(start_date, 'YYYY-MM-DD');
    const endDate = showDateAsFormattedString(end_date, 'YYYY-MM-DD');

    // handler for archiving/unarchiving a course
    const handleArchiveUnarchive = ({course, archive, userCourseRole}: {course: CourseDetailedInfo, archive: boolean, userCourseRole: 'instructor' | 'ta' | 'student'}) => () => {
        dispatch(archiveUnarchiveCourse({course, archive, userCourseRole}));
    };

    const handleDuplicateCourse = (courseID: number) => () => {
        toggleDuplicateModalOpen();
        dispatch(duplicateCourse(courseID));
        setWaiting(true);
    };

    // course link should take students of course to StudentAssignmentsPage and instructor(s) of course to AssignmentsPage
    const courseLink = userCourseRole === 'student' ? `/student/course/${course.id}` : `/course/${course.id}`;

    // classes for course status
    let courseStatus = '';
    if (status === "ACTIVE") {
        courseStatus = statusActive;
    } else if (status === "PLANNING") {
        courseStatus = statusPlanning;
    } else {
        courseStatus = statusEnding;
    };

    return (
        <>
            <Draggable draggableId={`${course.id}`} index={index}>
                {(draggableProvided) => (
                    <HarmoniaPaper
                        id={`single-course + ${course.id}`}
                        className={singleCourse}
                        {...draggableProvided.draggableProps}
                        ref={draggableProvided.innerRef}
                    >
                        <div className={courseTop} {...draggableProvided.dragHandleProps}>
                            <div>
                                <div className={drag}  >
                                    <FontAwesomeIcon icon={faCaretUp} />
                                    <FontAwesomeIcon icon={faCaretDown} />
                                </div>
                                <div className={courseTitle}>
                                    <Link to={courseLink} data-cy="course-title">{title}</Link>
                                </div>
                            </div>
                            <div data-cy="course-dropdown">
                                <SingleCourseDropdown course={course} userCourseRole={userCourseRole} showDeleteCourseModal={showDeleteCourseModal}
                                    handleArchiveUnarchive={handleArchiveUnarchive}
                                    toggleDuplicateModalOpen={toggleDuplicateModalOpen}
                                />
                            </div>
                        </div>
                        <div className={courseBottom}>
                            <div className={bottomInfo}>
                                <div className={infoLeft}>
                                    <div className={infoItem}>
                                        <span>Course Period:</span>
                                        <span>{`${startDate} - ${endDate}`}</span>
                                    </div>
                                    <div>
                                        {/* show term and year if both exist; if only one exists show that one; or if neither show message that term has not been set yet */}
                                        {
                                            academic_term && year &&
                                            <div className={infoItem}>
                                                <span>Term: </span>
                                                <span>{academic_term} {year}</span>
                                            </div>
                                        }
                                        {
                                            academic_term && !year &&
                                            <div className={infoItem}>
                                                <span>Term: </span>
                                                <span>{academic_term}</span>
                                            </div>
                                        }
                                        {
                                            !academic_term && year &&
                                            <div className={infoItem}>
                                                <span>Year: </span>
                                                <span>{year}</span>
                                            </div>
                                        }
                                    </div>
                                    <div className={infoItem}>
                                        <span>School: </span>
                                        <span>{school}</span>
                                    </div>
                                    <div className={infoItem}>
                                        <span>Status: </span>
                                        <span className={courseStatus}>{stringCaseFormat(status)}</span>
                                        {
                                            status === 'PLANNING' &&
                                            <div className={infoActivate}>
                                                <ActivateCourse show={activateModalOpen} toggleModal={toggleActivateModalOpen} courseID={id} courseTitle={title} getUserInfo={getUserInfo} />
                                            </div>
                                        }
                                    </div>
                                </div>
                                {/* <div className={infoRight}>
                    <div className={infoItem}>
                      <span>Status: </span>
                      <span className={courseStatus}>{stringCaseFormat(status)}</span>
                    </div>
                    <div className={duplicate} onClick={()=>{toggleDuplicateModalOpen()}}>
                      <FontAwesomeIcon icon={faCopy}/>
                      <Text>Duplicate</Text>
                    </div>
                  </div> */}
                            </div>
                        </div>
                    </HarmoniaPaper>
                )}
            </Draggable>
            {/* pop-up modal for duplicating course */}
            {duplicateModalOpen &&
                <Modal isOpen={duplicateModalOpen}>
                    <ModalHeader>Duplicate Course</ModalHeader>
                    <ModalBody>
                        <Text color="dark">{`This will create a new course with all the existing content of this course. The new course will be set to planning stage`}.</Text>
                        <Text color="dark">Do you wish to proceed?</Text>
                    </ModalBody>
                    <ModalFooter>
                        <HarmoniaButton variant="secondary" dest="app" onClick={toggleDuplicateModalOpen}>
                            Cancel
                        </HarmoniaButton>
                        <HarmoniaButton variant="primary" dest="app" onClick={handleDuplicateCourse(course.id)} >Confirm</HarmoniaButton>
                    </ModalFooter>
                </Modal>
            }
        </>
    );
}
export default SingleCourse;



