import {useState, useEffect} from "react";
import {useParams, Link} from "react-router-dom";
import {
    useHarmoniaSelector,
    getCourseDetail,
    updateCourseSettings,
    useHarmoniaDispatch,
    pushNotification,
    activateCourse,
} from "../../redux";
import {
    useHarmoniaSchools,
    useHarmoniaTerms,
    useRedirect,
    useUser,
} from "../../hooks";
import {
    HarmoniaButton,
    HarmoniaInput,
    HarmoniaPaper,
    HarmoniaSelect,
    Text,
    useToggle,
} from "@harmonia-front-end/shared";
import {Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import {DashboardContent, DashboardTitleBar} from "../../components/template";
import {withRouter} from "react-router";
import LoadingPage from "../../components/LoadingPage";
import {
    CourseDetailedInfo,
    UpdateActiveCoursePayload,
    UpdatePlanningCoursePayload,
    School,
    CourseInstructorTA,
    CourseEditableFields,
} from "../../types";
import {showDateAsFormattedString} from "../../utilities";
import {CourseDatePicker} from "../../components/course";
import CoursePagesStyle from "./CoursePages.module.scss";

const {
    courseInfoContainer,
    courseInfoItem,
    courseFieldName,
    radioField,
    titleEdit,
    radio,
    courseDateWrapper,
    courseInfo,
    courseDateItem,
    startingDate,
    endingDate,
    infoBottom,
    select,
    focus,
} = CoursePagesStyle;

const SettingsPage = () => {
    const dispatch = useHarmoniaDispatch();

    // courseId is a string, taken from params. we convert it to a number (courseID) and use that to grab the course info or use in the URL for link and redirect back to course info page.
    const {courseId} = useParams<{courseId: string}>();
    const courseID = parseInt(courseId, 10);

    // local state for tracking loading of course details
    const [courseDetailsLoading, setCourseDetailsLoading] = useState(true);

    // local state for showing school payment warning
    const [showWarning, setShowWarning] = useState(false);

    // Determine whether or not a user is an instructor once the courses load
    const courses = useHarmoniaSelector((state) => state.courses);
    let foundCourse = courses?.course_instructors?.find(
        (course) => course.id === courseID
    );
    let needToRedirect = foundCourse
        ? false
        : !Object.keys(courses).length
            ? false
            : true;

    useEffect(() => {
        if (foundCourse) {
            dispatch(getCourseDetail(courseID)).finally(() =>
                setCourseDetailsLoading(false)
            );
        }
    }, [dispatch, foundCourse]);

    useRedirect(needToRedirect, "/", {
        severity: "error",
        message: "You are not an instructor of this course.",
    });

    // Grabbing selectedCourse from redux store, then storing all course info as separate variable 'course'.
    const selectedCourse = useHarmoniaSelector((state) => state.selectedCourse);

    const course = (selectedCourse.courseInformation as CourseDetailedInfo) || {};

    /* useHarmoniaSchools and useHarmoniaTerms are hooks that allow us to
    obtain lists of all possible schools and terms. They return the lists,
    as well as loading status (boolean). */
    const [schools, schoolsLoading] = useHarmoniaSchools();
    const [terms, termsLoading] = useHarmoniaTerms();

    // Set all course information as local state on this component. We do this so we have can local state for the course title and select tags (school, term) to use in our change handlers. We have to define the object with default values for everything (rather than trying to pass in an empty object and assert it as the CourseDetailedInfo type) so we get rid of the "controlled/uncontrolled component" warning.
    const [courseInformation, setCourseInformation] = useState({
        academic_term: "",
        academic_term_id: -1,
        cadential_64: "V",
        course_product: "",
        course_product_id: -1,
        course_product_price: -1,
        end_date: 0,
        enrollment_end_date: 0,
        fastspring_product_path: "",
        id: -1,
        instructors: [] as CourseInstructorTA[],
        invitation_code: "",
        neapolitan_6: "II",
        payment_type: -1,
        payment_id: -1,
        school: "",
        school_id: -1,
        start_date: 0,
        status: "ACTIVE",
        ta: {} as CourseInstructorTA,
        title: "",
        user_course_role: "",
        uuid: "",
        year: -1,
        weight: -1,
        archived: false,
    } as CourseDetailedInfo);
    // The course info is only grabbed from API after the first useEffect (see above) and not on initial render. This means that when we try to set the local courseInfo state (using the course) for the first time, it is undefined. So, we have to force a re-render via this useEffect to properly set our local courseInfo state.

    useEffect(() => {
        if (Object.keys(course).length !== 0) {
            setCourseInformation(course);
        }
    }, [course]);

    /* Activating a Course */

    // local state for confirm activate course modal and relevant handlers for making API call to activate course.

    const [activateModalOpen, toggleActiveModalOpen] = useToggle();

    const confirmActivateCourse = (courseID: number) => () => {
        dispatch(activateCourse(courseID)).finally(() => {
            toggleActiveModalOpen();
        });
    };
    // Grab hash from url and push notification

    useEffect(() => {
        // Check if url is coming from course creation
        const hash = window.location.hash;
        if (hash == "#course_created") {
            dispatch(
                pushNotification({
                    severity: "success",
                    message: `New course created!`,
                })
            );
        }
    }, []);

    // Destructuring courseInfo object; all these fields serve as the local state for change/event handlers.
    let {
        id,
        title,
        academic_term,
        academic_term_id,
        year,
        school,
        school_id,
        payment_type,
        start_date,
        end_date,
        enrollment_end_date,
        cadential_64,
        neapolitan_6,
        lms_type
    } = courseInformation as CourseDetailedInfo;

    if (!school_id)
        school_id = 0;

    // Construct planning and active payloads. API expects a different payload type depending on if course status is 'PLANNING' or 'ENDED'.  Whenever a change is made, we send in the proper payload according to the course status with the appropriate changed field on the object.
    // NOTE: the school and academic_term fields on the payload object refer to the IDs of the school/academic_term INSTEAD of their name (as it is on the course details object).
    let planningPayload: UpdatePlanningCoursePayload = {
        academic_term: course.academic_term_id,
        cadential_64: course.cadential_64,
        end_date: course.end_date,
        enrollment_end_date: course.enrollment_end_date,
        id: course.id,
        neapolitan_6: course.neapolitan_6,
        payment_type: course.payment_type,
        school: school_id,
        start_date: course.start_date,
        title: course.title,
        lms_type: course.lms_type || 0
    };

    let activePayload: UpdateActiveCoursePayload = {
        cadential_64: course.cadential_64,
        enrollment_end_date: course.enrollment_end_date,
        id: course.id,
        neapolitan_6: course.neapolitan_6,
        title: course.title,
        lms_type: course.lms_type || 0
    };

    /** EDITING COURSE TITLE */

    // handler for changing title
    const handleTextInput: React.ChangeEventHandler<HTMLInputElement> = (ev) => {
        setCourseInformation({
            ...courseInformation,
            title: ev.currentTarget.value,
        });
    };

    // Function that trims new title (in case there are spaces inputted), creates payload with new title and dispatches action to edit course title.
    const changeCourseTitleOnBlur: React.FocusEventHandler<HTMLInputElement> = (
        ev
    ) => {
        ev.preventDefault();
        const trimmedNewTitle = ev.currentTarget.value.trim();
        if (trimmedNewTitle && trimmedNewTitle !== course.title) {
            let updatedCourse;
            if (course.status === "PLANNING") {
                updatedCourse = {...planningPayload, title: trimmedNewTitle};
            } else {
                updatedCourse = {...activePayload, title: trimmedNewTitle};
            }
            console.log(JSON.stringify(updatedCourse));
            dispatch(
                updateCourseSettings({
                    course: updatedCourse,
                    field: CourseEditableFields.TITLE,
                })
            );
        }
    };
    // After typing in new title, the course title change is trigged by either clicking away from the input field (blurring) or pressing enter. Upon pressing enter, this function will blur the input element and thus trigger the function above.
    const changeCourseTitleOnReturn: React.KeyboardEventHandler<
        HTMLInputElement
    > = (ev) => {
        if (ev.key === "Enter") ev.currentTarget.blur();
    };

    /** EDITING COURSE TERM/SCHOOL */

    // The handleSelectChange and handleRadioChange are split into separate functions so as to provide proper typings for the handler and its event parameter (as the type of element they are used on is different) and also to make the functions simpler and more direct.
    const handleSelectChange: React.ChangeEventHandler<HTMLSelectElement> = (
        ev
    ) => {
        ev.preventDefault();
        if (ev.currentTarget.name === "academic_term") {
            const newTerm = parseInt(ev.currentTarget.value, 10);
            const updatedCourse = {...planningPayload, academic_term: newTerm};
            dispatch(
                updateCourseSettings({
                    course: updatedCourse,
                    field: CourseEditableFields.TERM,
                })
            );
        }
        if (ev.currentTarget.name === "school") {
            const newSchool = parseInt(ev.currentTarget.value, 10);
            const updatedCourse = {...planningPayload, school: newSchool};
            dispatch(
                updateCourseSettings({
                    course: updatedCourse,
                    field: CourseEditableFields.SCHOOL,
                })
            );
        }
    };
    /** EDITING COURSE NEAPOLITAN 6/CAD 64 */
    const handleRadioChange: React.ChangeEventHandler<HTMLInputElement> = (
        ev
    ) => {
        ev.preventDefault();
        if (ev.currentTarget.name === "neapolitan_6") {
            let updatedCourse;
            if (course.status === "PLANNING") {
                updatedCourse = {
                    ...planningPayload,
                    neapolitan_6: ev.currentTarget.value,
                };
            } else {
                updatedCourse = {
                    ...activePayload,
                    neapolitan_6: ev.currentTarget.value,
                };
            }
            dispatch(
                updateCourseSettings({
                    course: updatedCourse,
                    field: CourseEditableFields.NEO_6,
                })
            );
        }

        if (ev.currentTarget.name === "cadential_64") {
            let updatedCourse;
            if (course.status === "PLANNING") {
                updatedCourse = {
                    ...planningPayload,
                    cadential_64: ev.currentTarget.value,
                };
            } else {
                updatedCourse = {
                    ...activePayload,
                    cadential_64: ev.currentTarget.value,
                };
            }
            dispatch(
                updateCourseSettings({
                    course: updatedCourse,
                    field: CourseEditableFields.CAD_64,
                })
            );
        }

        if (
            ev.currentTarget.name === "payment_type" &&
            course.status === "PLANNING"
        ) {
            // Convert payment type to number (to prevent typing errors).
            const newPaymentType = parseInt(ev.currentTarget.value, 10);

            // local state for confirm fee paid by school payment modal.
            if (newPaymentType === 3) {
                setShowWarning(true);
            } else {
                const updatedCourse = {
                    ...planningPayload,
                    payment_type: newPaymentType,
                };
                dispatch(
                    updateCourseSettings({
                        course: updatedCourse,
                        field: CourseEditableFields.PAYMENT_TYPE,
                    })
                );
            }
        }
    };

    const handleLMSTypeChange: React.ChangeEventHandler<HTMLInputElement> = (ev) => {
        ev.preventDefault();
        let updatedCourse;
        if (course.status === "PLANNING") {
            updatedCourse = {
                ...planningPayload,
                lms_type: parseInt(ev.currentTarget.value),
            };
        } else {
            updatedCourse = {
                ...activePayload,
                lms_type: parseInt(ev.currentTarget.value),
            };
        }
        dispatch(
            updateCourseSettings({
                course: updatedCourse,
                field: CourseEditableFields.LMS_TYPE,
            })
        );
    };

    // Redirect the user to the course info page if they try to access the settings page of an ended course.
    useRedirect(course.status === "ENDED", `/course/${courseID}/info`, {
        severity: "error",
        message: "Course has ended.",
    });

    if (schoolsLoading || termsLoading) return <DashboardContent><LoadingPage /></DashboardContent>;
    // Sort schools in alphabetical order once they are loaded. TO-DO: find better way to sort schools (ex: by state).
    let sortedSchools: School[] = [];
    if (schools) {
        // We have to make shallow copy of schools array first because it is read-only.
        sortedSchools = [...schools];
        sortedSchools.sort((a, b) => {
            const schoolA = a.school.toLowerCase();
            const schoolB = b.school.toLowerCase();
            return schoolA < schoolB ? -1 : schoolA > schoolB ? 1 : 0;
        });
    }

    const options = [];
    for (let i = 1; i < 6; i++) {
        options.push(i);
    }

    return (
        <>
            <Modal isOpen={showWarning}>
                <ModalHeader>Billing and Payment Agreenment</ModalHeader>
                <ModalBody>
                    The course instructor agrees to be the contact person and guarantor of
                    payment when selecting the Fee Paid By School option. Invoice will be
                    sent to the instructor 3 weeks to one month after the course starting
                    date.
                </ModalBody>
                <ModalFooter>
                    <HarmoniaButton
                        variant="secondary"
                        dest="app"
                        onClick={() => {
                            setShowWarning(false);
                        }}
                    >
                        Cancel
                    </HarmoniaButton>
                    <HarmoniaButton
                        variant="primary"
                        dest="app"
                        onClick={() => {
                            const payload = {...planningPayload, payment_type: 3};
                            dispatch(
                                updateCourseSettings({
                                    course: payload,
                                    field: CourseEditableFields.PAYMENT_TYPE,
                                })
                            ).finally(() => setShowWarning(false));
                        }}
                    >
                        I Agree
                    </HarmoniaButton>
                </ModalFooter>
            </Modal>
            {courseDetailsLoading && <DashboardContent><LoadingPage /></DashboardContent>}
            {!courseDetailsLoading && (
                <DashboardTitleBar
                    h1="Edit Course Settings"
                    h2={course.title}
                    h2_no2={`${course.academic_term} ${course.year}`}
                />
            )}
            <DashboardContent>
                <HarmoniaPaper className={courseInfoContainer}>
                    <div className={courseInfo}>
                        <div>
                            <span>Course Title:</span>
                            <HarmoniaInput
                                name="title"
                                type="text"
                                value={title}
                                onChange={handleTextInput}
                                onBlur={changeCourseTitleOnBlur}
                                onKeyDown={changeCourseTitleOnReturn}
                                className={titleEdit}
                            />
                        </div>
                        {/* Academic term, institute, payment type and start/end dates are only editable if course
            status is "PLANNING" */}
                        {course.status === "PLANNING" && (
                            <>
                                <div>
                                    <label htmlFor="academic_term">Academic Term:</label>
                                    <select
                                        name="academic_term"
                                        onChange={handleSelectChange}
                                        value={academic_term_id}
                                    >
                                        {terms.map((term) => (
                                            <option key={term.id} value={term.id}>
                                                {term.academic_term}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                {/* Hide year field from the form for now */}
                                {/* <label htmlFor="year">Year</label>
            <select name="year" onChange={handleChange}>
              {yearOptions.map((singleYear) =>
                singleYear === year ? (
                  <option key={singleYear} value={singleYear} selected>
                    {singleYear}
                  </option>
                ) : (
                  <option key={singleYear} value={singleYear}>
                    {singleYear}
                  </option>
                )
              )}
            </select>
            <br /> */}
                                <div>
                                    <label htmlFor="school">Institute:</label>
                                    <select
                                        name="school"
                                        value={school_id}
                                        onChange={handleSelectChange}
                                    >
                                        {sortedSchools.map((singleSchool) => (
                                            <option key={singleSchool.id} value={singleSchool.id}>
                                                {singleSchool.school}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div>
                                    <label htmlFor="payment_type" className={radioField}>
                                        Fee Paid By:{" "}
                                    </label>
                                    <label htmlFor="student_purchases">Students</label>
                                    <input
                                        name="payment_type"
                                        id="license_type2"
                                        type="radio"
                                        value="2"
                                        checked={payment_type === 2}
                                        onChange={handleRadioChange}
                                        className={radio}
                                    />
                                    <label htmlFor="school_purchases">School</label>
                                    <input
                                        name="payment_type"
                                        id="license_type3"
                                        type="radio"
                                        value="3"
                                        checked={payment_type === 3}
                                        onChange={handleRadioChange}
                                        className={radio}
                                    />
                                </div>

                                <div className={`${courseDateItem} ${courseInfoItem}`}>
                                    <label
                                        htmlFor="enrollment_end_date"
                                        className={courseFieldName}
                                    >
                                        Enrollment End Date:
                                    </label>
                                    <CourseDatePicker
                                        dateType="enrollment_end_date"
                                        coursePayload={planningPayload}
                                        course={course}
                                    />
                                </div>
                                <div>
                                    <span style={{fontWeight: 'normal'}}>
                                        The enrollment end date is limited to two weeks past the course start time or 1/3 the length of the course.
                                    </span>
                                </div>
                                <div className={courseDateWrapper}>
                                    <div className={`${courseDateItem} ${startingDate}`}>
                                        <label htmlFor="start_date" className={courseFieldName}>
                                            Course Period:
                                        </label>
                                        <CourseDatePicker
                                            dateType="start_date"
                                            coursePayload={planningPayload}
                                            course={course}
                                        />
                                    </div>
                                    <div>to</div>
                                    <div className={`${courseDateItem} ${endingDate}`}>
                                        <CourseDatePicker
                                            dateType="end_date"
                                            coursePayload={planningPayload}
                                            course={course}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                        {/* Only the title, enrollment end date, nea6 and cad64 are editable if a course is active. other fields (term, institute, payment type and start/end dates) are displayed akin to the CourseInformationPage and thus uneditable */}
                        {course.status === "ACTIVE" && (
                            <>
                                <div className={courseInfoItem}>
                                    <span className={courseFieldName}>Academic Term: </span>
                                    <span>{academic_term}</span>
                                </div>
                                <div className={courseInfoItem}>
                                    <span className={courseFieldName}>Institute: </span>
                                    <span>{school}</span>
                                </div>
                                <div className={courseInfoItem}>
                                    <span className={courseFieldName}>Fee Paid By: </span>
                                    <span>{payment_type === 2 ? "students" : "school"}</span>
                                </div>
                                <div className={`${courseDateItem} ${courseInfoItem}`}>
                                    <label
                                        htmlFor="enrollment_end_date"
                                        className={courseFieldName}
                                    >
                                        Enrollment End Date:
                                    </label>
                                    <CourseDatePicker
                                        dateType="enrollment_end_date"
                                        coursePayload={activePayload}
                                        course={course}
                                    />
                                </div>
                                <div>
                                    <span style={{fontWeight: 'normal'}}>
                                        The enrollment end date is limited to two weeks past the course start time or 1/3 the length of the course.
                                    </span>
                                </div>
                                <div className={courseInfoItem}>
                                    <span className={courseFieldName}>Course Period: </span>
                                    <span>{`${showDateAsFormattedString(
                                        start_date,
                                        "MM/DD/YYYY"
                                    )} - ${showDateAsFormattedString(
                                        end_date,
                                        "MM/DD/YYYY"
                                    )}`}</span>
                                </div>
                            </>
                        )}

                        <div style={{height: '20px'}} className={courseInfoItem}>
                            <label htmlFor="cadential_64" className={radioField}>
                                Cadential 64 in this course is labeled as:
                            </label>
                            <label htmlFor="cadential_64_I">I64</label>
                            <input
                                name="cadential_64"
                                id="cadential_64_I"
                                type="radio"
                                value="I"
                                checked={cadential_64 === "I"}
                                onChange={handleRadioChange}
                                className={radio}
                            />
                            <label htmlFor="cadential_64_V">V64</label>
                            <input
                                name="cadential_64"
                                id="cadential_64_V"
                                type="radio"
                                value="V"
                                checked={cadential_64 === "V"}
                                onChange={handleRadioChange}
                                className={radio}
                            />
                        </div>

                        <div style={{height: '20px'}} className={courseInfoItem}>
                            <label htmlFor="neapolitan_6" className={radioField}>
                                Neapolitan 6 in this course is labeled as:
                            </label>
                            <label htmlFor="neapolitan_6_N">N6</label>
                            <input
                                name="neapolitan_6"
                                id="neapolitan_6_N"
                                type="radio"
                                value="N"
                                checked={neapolitan_6 === "N"}
                                onChange={handleRadioChange}
                                className={radio}
                            />
                            <label htmlFor="neapolitan_6_N">bII6</label>
                            <input
                                name="neapolitan_6"
                                id="neapolitan_6_II"
                                type="radio"
                                value="II"
                                checked={neapolitan_6 === "II"}
                                onChange={handleRadioChange}
                                className={radio}
                            />
                        </div>

                        {process.env.REACT_APP_HARMONIA_ENV !== 'production' &&
                            <div className={courseInfoItem}>
                                <label htmlFor="lms_type" className={radioField}>
                                    LMS Integration:
                                </label>
                                <label htmlFor="lms_type_none">None</label>
                                <input
                                    name="lms_type"
                                    id="lms_type_none"
                                    type="radio"
                                    value="0"
                                    checked={!lms_type}
                                    onChange={handleLMSTypeChange}
                                    className={radio}
                                />
                                <label htmlFor="lms_type_gc">Google Classroom</label>
                                <input
                                    name="lms_type"
                                    id="lms_type_gc"
                                    type="radio"
                                    value="1"
                                    checked={lms_type === 1}
                                    onChange={handleLMSTypeChange}
                                    className={radio}
                                />
                                <label htmlFor="lms_type_lti">LTI Plugin</label>
                                <input
                                    name="lms_type"
                                    id="lms_type_lti"
                                    type="radio"
                                    value="2"
                                    checked={lms_type === 2}
                                    onChange={handleLMSTypeChange}
                                    className={radio}
                                />
                            </div>
                        }


                        {/* clicking on back button takes user back to course info page */}
                        <div className={infoBottom}>
                            <Link to={`/course/${courseID}/info`}>
                                <HarmoniaButton
                                    variant="primary"
                                    dest="app"
                                    icon="back"
                                    iconPos="left"
                                >
                                    Back
                                </HarmoniaButton>
                            </Link>
                        </div>
                    </div>
                </HarmoniaPaper>
            </DashboardContent>
        </>
    );
};

export default withRouter(SettingsPage);
