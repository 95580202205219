import React from 'react';
import { CourseInvitation} from '../../../types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle, faTimesCircle, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import { showDateAsFormattedString, stringCaseFormat } from '../../../utilities';
import style from './CourseManagement.module.scss';

const {declineRemove, accept} = style;

type CourseInvitationEntryProps = {
  invitation: CourseInvitation,
  showAcceptInvitationModal: (invitation: CourseInvitation) => void
  showDeleteInvitationModal: (invitation: CourseInvitation) => void,
}

const CourseInvitationEntry = ({invitation, showAcceptInvitationModal, showDeleteInvitationModal} : CourseInvitationEntryProps) => {

  // destructuring course info
  const {title, school, start_date, end_date} = invitation.course_info;
  // formatting start and end dates of course
  const courseStartDate = showDateAsFormattedString(start_date, 'YYYY-MM-DD');
  const courseEndDate = showDateAsFormattedString(end_date, 'YYYY-MM-DD');
  return (
    <>
    <tr data-cy="course-invitation">
      <td>{title}</td>
      {/* NOTE: fix this so school info is formatted properly on invitation object */}
      <td>{school.school}</td>
      <td>{`${courseStartDate} - ${courseEndDate}`}</td>
      <td>{stringCaseFormat(invitation.join_as)}</td>
      <td>
        {/* <div className={accept} onClick={() => showAcceptInvitationModal(invitation)}>Accept</div>
        <div className={declineRemove} onClick={() => showDeleteInvitationModal(invitation)}>Decline</div> */}
        <span data-cy="accept-invitation" className={accept} onClick={() => showAcceptInvitationModal(invitation)}>
          <FontAwesomeIcon style={{width:"auto", marginRight: 4}} icon={faCheckCircle} />
          Accept
        </span>
        <br />
        <span data-cy="decline-invitation" className={declineRemove} onClick={() => showDeleteInvitationModal(invitation)}>
          <FontAwesomeIcon style={{width:"auto", marginRight: 4}} icon={faTimesCircle} />
          Decline
        </span>

      </td>
    </tr>
    </>
  )
}
export default CourseInvitationEntry;
