import React, {useState, Fragment} from 'react';
import {
  HarmoniaButton,
} from '@harmonia-front-end/shared';
import { useDispatch, useSelector} from 'react-redux';
import { Collapse} from 'reactstrap';
import AddDocFromContentLibModalStyle  from './AddDocFromContentLibModal.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faEye } from '@fortawesome/free-solid-svg-icons';
const { singleDocCollection, docCollectionTitle, rightAdd, docCollectionCollapse, addAll } = AddDocFromContentLibModalStyle;

const ModalDocCollection = (props) => {
  // each doc collection has local state to show its contents via a collapse
  const [isDocCollectionOpen, setDocCollectionOpen] = useState(false);
  const toggleDocCollection = () => setDocCollectionOpen((prevState) => !prevState);
  const collapseIcon = isDocCollectionOpen ? faAngleUp : faAngleDown;

  const {docCollection, selectDocumentToAdd, selectDocumentSetToAdd, getDocumentImagePreview} = props;
  return(
  <div className={singleDocCollection}>
    <div className={docCollectionTitle}>
      <div>
        <span >{docCollection.title}</span>
        <FontAwesomeIcon icon={collapseIcon} onClick={toggleDocCollection}/>
      </div>
      {isDocCollectionOpen && <div className={rightAdd}>
      <HarmoniaButton variant="primary" dest="app" icon="add-to" iconPos="right" className={addAll} onClick={() => {selectDocumentSetToAdd(docCollection.documents)}}>Add All</HarmoniaButton>
        </div>}
    </div>
    <Collapse className={docCollectionCollapse} isOpen={isDocCollectionOpen}>
      {docCollection.documents.map((document, index) => {
        return <div key={index}>
          <div >
            <span>{document.title}</span>
            <span>  <FontAwesomeIcon icon={faEye} onClick={getDocumentImagePreview(document.id)}/></span>

          </div>
          <div>
          <HarmoniaButton variant="primary" dest="app" shape="circle" icon="add-to" onClick={() => {
selectDocumentToAdd(document)
}}></HarmoniaButton>
          </div>

        </div>
      })}
    </Collapse>
  </div>
  )
}
export default ModalDocCollection;
