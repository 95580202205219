import React from 'react';
import HarmoniaSquareStyles from './HarmoniaSquare.module.scss';
import { arrayChoice, randInt } from '../utilities';

const { sky, yellow, lightYellow } = HarmoniaSquareStyles;

type HarmoniaSquareVariant = 'sky' | 'yellow' | 'light-yellow';
const harmoniaSquareVariant: HarmoniaSquareVariant[] = ['sky', 'yellow', 'light-yellow'];

type HarmoniaSquareTrapezoidPos = 'top' | 'right' | 'bottom' | 'left';
const harmoniaSquareTrapezoidPos: HarmoniaSquareTrapezoidPos[] = ['top', 'right', 'bottom', 'left'];

type HarmoniaSquareTrapezoidSlice = 'start' | 'end';
const harmoniaSquareTrapezoidSlice: HarmoniaSquareTrapezoidSlice[] = ['start', 'end'];

type HarmoniaSquareProps = {
  /** Color of the square. */
  variety?: HarmoniaSquareVariant | 'random';

  /** Whether the square is a trapezoid. */
  trapezoid?: boolean;

  /** Where the slicing edge should start. */
  trapezoidPos?: HarmoniaSquareTrapezoidPos | 'random';

  /**
   * Where should the slicing-edge lies.
   * If the side is horizontal, 'start' means the left is sliced.
   * If the side is vertical, 'start' means the top is sliced
   */
  trapezoidSlice?: HarmoniaSquareTrapezoidSlice | 'random';

  /** The number to be passed into CSS clip-path property. */
  trapezoidRatio?: number | 'random';
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

/**
 * Ornamental square component for Harmonia Cloud.
 *
 * All other intrinsic HTML div element attributes can be passed in as well.
 */
function HarmoniaSquare(props: HarmoniaSquareProps) {
  let {
    variety, trapezoidPos, trapezoidSlice, trapezoidRatio, style,
    // eslint-disable-next-line prefer-const
    trapezoid, children, className, ...rest
  } = props;
  if (variety === 'random') variety = arrayChoice(harmoniaSquareVariant);
  if (trapezoidPos === 'random') trapezoidPos = arrayChoice(harmoniaSquareTrapezoidPos);
  if (trapezoidSlice === 'random') trapezoidSlice = arrayChoice(harmoniaSquareTrapezoidSlice);
  if (trapezoidRatio === 'random') trapezoidRatio = randInt(0, 100);
  if (typeof trapezoidRatio === 'number' && trapezoidRatio < 0) trapezoidRatio = 0;
  if (typeof trapezoidRatio === 'number' && trapezoidRatio > 100) trapezoidRatio = 100;

  const classList = className ? className.split(' ') : [];
  if (variety === 'sky') classList.push(sky);
  if (variety === 'yellow') classList.push(yellow);
  if (variety === 'light-yellow') classList.push(lightYellow);

  if (trapezoid && !(style?.clipPath)) {
    if (!style) style = {};
    const path = ['100% 0', '100% 100%', '0 100%', '0 0'];
    if (trapezoidPos === 'top') { // 3 -> 0
      if (trapezoidSlice === 'start') path[3] = `${trapezoidRatio}% 0`;
      else path[0] = `${trapezoidRatio}% 0`;
    } else if (trapezoidPos === 'bottom') { // 2 -> 1
      if (trapezoidSlice === 'start') path[2] = `${trapezoidRatio}% 100%`;
      else path[1] = `${trapezoidRatio}% 100%`;
    } else if (trapezoidPos === 'left') { // 3 -> 2
      if (trapezoidSlice === 'start') path[3] = `0 ${trapezoidRatio}%`;
      else path[2] = `0 ${trapezoidRatio}%`;
    } else if (trapezoidPos === 'right') { // 0 -> 1
      if (trapezoidSlice === 'start') path[0] = `100% ${trapezoidRatio}%`;
      path[1] = `100% ${trapezoidRatio}%`;
    }
    style.clipPath = `polygon(${path.join(', ')})`;
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div style={style} className={classList.join(' ')} {...rest}>
      {children}
    </div>
  );
}

HarmoniaSquare.defaultProps = {
  variety: 'random',
  trapezoid: false,
  trapezoidPos: 'random',
  trapezoidSlice: 'random',
  trapezoidRatio: 'random',
};

export default HarmoniaSquare;
