import React, { useState, useEffect } from "react";
import { useHarmoniaSelector, useHarmoniaDispatch, getSubmissionsByAssignmentID, getCourseAssignmentDocuments, getSubmissionsByCourseIDWOUser} from '../../../redux';
import {Assignment, CourseStudent, Submission, Document } from '../../../types';
import LoadingPage from '../../LoadingPage';
import {AssignmentGradeBookTable, DocumentGradeBookTable} from '..';
import {renderAssignmentDocNum, showDateAsFormattedString, makeCSVString} from '../../../utilities';
import {saveAs} from 'file-saver';
import { HarmoniaButton } from "@harmonia-front-end/shared/component";

type SubmissionByAssignmentProps = {
  courseID: number,
  assignments: Assignment[],
  students: CourseStudent[]
};

// Typings for which submission value to display on assignment overview
export enum ViewSubmissionByType {
  SCORE, PERCENT, GRADE
};

const SubmissionByAssignment = (props: SubmissionByAssignmentProps) => {
  const dispatch = useHarmoniaDispatch();
  const {assignments, students, courseID} = props;
  // Local state variables for loading and selecting an assignment and selecting a document from that assignment.
  const[submissionsLoading, setSubmissionsLoading] = useState(false);
  const [selectedAssignments, setSelectedAssignments] = useState(Array<Assignment>()) as [Assignment[], React.Dispatch<React.SetStateAction<{}>>];
  const [selectedDocument, setSelectedDocument] = useState({}) as [Document, React.Dispatch<React.SetStateAction<{}>>];;
  const [viewSubmissionBy, setViewSubmissionBy] = useState(ViewSubmissionByType.SCORE);;

  // Each document should display the assignment and document number (1.1, 4.6) that represents the respective position of each.
  // const assignmentIndex = assignments?.findIndex(assignment => assignment.id === selectedAssignment.id) as number;
  // Once we have the assignments for the course from API, we need to grab and append the documents for each. Later, we will amend API to grab assignments and documents in a single call.
  useEffect(() => {
    if (assignments) {
      assignments.forEach(assignment => {
        dispatch(getCourseAssignmentDocuments(assignment.id))
      })
    }
  }, [dispatch])

  // Handler for selecting an assignment. We grab the assignment's ID from the select element and use it to find the correct assignment. Then, we set the selectedAssignment state with it and make an API call to grab its submissions.
  const handleAssignmentChange:React.ChangeEventHandler<HTMLSelectElement> = (ev) => {
    if (ev.currentTarget.value === 'O')
        return;
    if (ev.currentTarget.value === 'A') {
        setSelectedAssignments(assignments);
        setSubmissionsLoading(true);
        dispatch(getSubmissionsByCourseIDWOUser(courseID)).finally(() => setSubmissionsLoading(false));
        return;
    }
    const assignmentID = parseInt(ev.currentTarget.value, 10);
    // const assignment = assignments.filter(assignment => assignment.id === assignmentID)[0];
    setSelectedAssignments(assignments.filter(assignment => assignment.id === assignmentID));
    setSubmissionsLoading(true);
    dispatch(getSubmissionsByAssignmentID(assignmentID)).finally(() => setSubmissionsLoading(false));
  };

  // Handler for selecting a document. No API calls are made, just setting the selectedDocument state.
  const handleDocumentChange:React.ChangeEventHandler<HTMLSelectElement> = (ev) => {
      // Selecting "Overview" should unselect a document and thus reset to showing submissions for all documents in assignment again.
      if(ev.currentTarget.value === '0'){
        setSelectedDocument({});
      } else {
      const docID = parseInt(ev.currentTarget.value, 10);
      // const document = selectedAssignment.documents?.filter(assignment => assignment.id === docID)[0] as Document;
      for (const a of selectedAssignments) {
          if (a.documents) {
              for (const d of a.documents) {
                  if (d.id === docID) {
                      setSelectedDocument(d);
                      break;
                  }
              }
          }
      }
    }
  };

  // Handler for changing view options for "Overview".
  const changeViewSubmissionBy= (ev: any) => {
    const viewSubmissionSetting = ev.currentTarget.value;
    if(viewSubmissionSetting == ViewSubmissionByType.SCORE){
      setViewSubmissionBy(ViewSubmissionByType.SCORE);
    }
    if(viewSubmissionSetting == ViewSubmissionByType.PERCENT){
      setViewSubmissionBy(ViewSubmissionByType.PERCENT);
    }
    if(viewSubmissionSetting == ViewSubmissionByType.GRADE){
      setViewSubmissionBy(ViewSubmissionByType.GRADE);
    }
  };

  // Function that will find submissions (if any) for selected document by every student and return an array of 0, 1 or 1+ submissions, each with a new field (formattedTime) appended to properly display submission time.
  const getDocumentSubmissions = (studentID: number, docID:number, submissions: Submission[]) => {
    // Finding a documents's submissions by that student.
    const documentSubmissions = submissions?.filter(sub => {
      return (sub.document === docID) && (sub.student === studentID);
    });
    // Return an empty array if there are no submissions; we check the length of array when rendering.
    if(documentSubmissions?.length === 0){
        return [];
    } else if(documentSubmissions.length === 1) {
        const formattedTime = showDateAsFormattedString(documentSubmissions[0].submit_time, 'YYYY-MM-DD hh:mm');
        const singleSubWithFormattedTime = {...documentSubmissions[0], formattedTime};
        return [singleSubWithFormattedTime];
    } else{
      // Return the submissions as an array, sorted by descending points and with submission time in readable format.
      const sortedSubmissions = documentSubmissions.sort((a,b) => {
        return b.points - a.points
      }).map(submission => {
        const formattedTime = showDateAsFormattedString(submission.submit_time, 'YYYY-MM-DD hh:mm');
        return {...submission, formattedTime}
      });
      return sortedSubmissions;
    }
  }

// Grabbing the submissions for the selected assignment from Redux.
const {submissionsByAssignment} = useHarmoniaSelector(state => state.selectedCourse);

    // following same logic in AssignmentGradeBookTable.tsx
    const exportCSV = (courseStudents:any, selectedAssignments:any, submissionsByAssignment:any, getDocumentSubmissions:any, viewSubmissionBy:any) => {
        let csv =
            ['Name,' + selectedAssignments.map((a: Assignment) => {
                return a.documents?.map((doc: Document, docIndex: any) => {
                    const ai = assignments?.findIndex(assignment => assignment.id === a.id)
                    return makeCSVString(renderAssignmentDocNum(ai, docIndex));
                }).join(',');
            }).join(',')].concat(
                courseStudents?.map((student: any) => {
                    return [makeCSVString(`${student.last_name}, ${student.first_name}`)].concat(
                        selectedAssignments.map((a: Assignment) => {
                            return a.documents?.map((doc: Document) => {
                                const submissions = getDocumentSubmissions(student.user_id, doc.id, submissionsByAssignment);
                                let points;
                                let percent;
                                let grade;
                                if (submissions.length) {
                                    const topSub = submissions[0];
                                    points = topSub.points;
                                    percent = topSub.percent;
                                    grade = topSub.grade;
                                    // otherwise show empty values
                                } else {
                                    points = '';
                                    percent = '';
                                    grade = '';
                                }
                                switch (viewSubmissionBy) {
                                    case ViewSubmissionByType.SCORE:
                                        return makeCSVString(points);
                                    case ViewSubmissionByType.PERCENT:
                                        return makeCSVString(percent);
                                    case ViewSubmissionByType.GRADE:
                                        return makeCSVString(grade);
                                    default:
                                        return '';
                                }
                            }).join(',');
                        })
                    ).join(',');
            })).join('\n');
        saveAs(new Blob([csv], {type: 'text/csv;charset=utf-8'}), "gradebook_data.csv");
    };

    const exportDocCSV = (selectedDocument:Document, students:CourseStudent[], submissionsByAssignment:Submission[], getDocumentSubmissions:any, collapsed:boolean) => {
        let csv =
            ['Name,Submissions,Submission Time,Score,Percent,Grade'].concat(
                students?.map((student: CourseStudent) => {
                    const submissions = getDocumentSubmissions(student.user_id, selectedDocument.id, submissionsByAssignment);
                    if (submissions.length > 0) {
                        let s = collapsed ? submissions.slice(0, 1) : submissions;
                        return s.map((sub: Submission, i:number) => {
                                return [i ? '' : makeCSVString(`${student.last_name}, ${student.first_name}`),
                                        i ? '' : makeCSVString(submissions.length),
                                        makeCSVString(sub.formattedTime),
                                        makeCSVString(sub.points),
                                        makeCSVString(sub.percent),
                                        makeCSVString(sub.grade)
                                        ].join(',');
                            }).join('\n');
                    } else 
                        return [makeCSVString(`${student.last_name}, ${student.first_name}`), '', '', '', '', ''].join(',');
            })).join('\n');
        saveAs(new Blob([csv], {type: 'text/csv;charset=utf-8'}), "gradebook_data.csv");
    };

return(
  <div>
    <select style={{minWidth: '250px'}} name="assignment" onChange={handleAssignmentChange}>
      {selectedAssignments.length === 0 && <option value="O">--</option>}
      <option value="A">All Assignment Sets</option>
      {assignments && assignments.map((assignment, index) => {
        const {id, title} = assignment;
        return <option value={id} key={index}>
          {title}
        </option>
      })}
    </select>
    {/* Show loading spinner while grabbing submissions */}
    {submissionsLoading && <LoadingPage/>}
    {/* Only render the view options if an assignment was selected, everything has loaded and dropdown says "Overview" instead of having selected a document */}
    {selectedAssignments.length !== 0 && !submissionsLoading && Object.keys(selectedDocument).length === 0 &&
        <>
        <div style={{marginTop: '8px'}} className="viewOptions">
          <label htmlFor="byScore">By Score (Points)</label>
          <input
            name="viewSubmissionBy"
            type="radio"
            value={ViewSubmissionByType.SCORE}
            checked={viewSubmissionBy == ViewSubmissionByType.SCORE}
            onChange={changeViewSubmissionBy}
          />
          <label htmlFor="byPercent">By Percent (%)</label>
          <input
            name="viewSubmissionBy"
            type="radio"
            value={ViewSubmissionByType.PERCENT}
            checked={viewSubmissionBy == ViewSubmissionByType.PERCENT}
            onChange={changeViewSubmissionBy}
          />
          <label htmlFor="byPercent">By Letter Grade</label>
          <input
            name="viewSubmissionBy"
            type="radio"
            value={ViewSubmissionByType.GRADE}
            checked={viewSubmissionBy == ViewSubmissionByType.GRADE}
            onChange={changeViewSubmissionBy}
          />
        </div>
        </>
    }
    {selectedAssignments.length !== 0 && !submissionsLoading && Object.keys(selectedDocument).length !== 0 &&
        <div style={{marginTop: '8px'}}/>
    }
    {/* Only show the dropdown with "Overview" and the list of documents if we selected an assignment first. */}
    {selectedAssignments.length !== 0 && !submissionsLoading &&
        <div>
          <select name="document" onChange={handleDocumentChange}>
            <option value="0">Overview</option>
            {
              selectedAssignments.map((a:Assignment) => {
                  const ai = assignments?.findIndex(assignment => assignment.id === a.id);
                  return a.documents?.map((doc, docIndex) => {
                      const docTitleWithNum = `${renderAssignmentDocNum(ai, docIndex)} ${doc.title}`
                      return (
                          <option value={doc.id} key={docIndex}>
                              {docTitleWithNum}
                          </option>
                      )
                  });
              }).flat()
            }
          </select>
        </div>
    }
    {/* Render the AssignmentGradeBookTable if we selected an assignment and grabbed its submissions but did not select a document. */}
    {selectedAssignments.length !== 0 && !submissionsLoading && !Object.keys(selectedDocument).length &&
      <>
        <br/>
        <HarmoniaButton
          variant="primary"
          dest="app"
          icon="download"
          iconPos="left"
          onClick={() => exportCSV(students, selectedAssignments, submissionsByAssignment, getDocumentSubmissions, viewSubmissionBy)}>
          Download CSV File
        </HarmoniaButton>
        <AssignmentGradeBookTable courseStudents={students} selectedAssignments={selectedAssignments} submissionsByAssignment={submissionsByAssignment} getDocumentSubmissions={getDocumentSubmissions} viewSubmissionBy={viewSubmissionBy} assignments={assignments}/>
      </>
    }
    {/* Render the DocumentGradeBookTable if we selected a document. */}
    {Object.keys(selectedDocument).length > 0 && 
        <>
        <DocumentGradeBookTable selectedDocument={selectedDocument} students={students} submissionsByAssignment={submissionsByAssignment as Submission[]} getDocumentSubmissions={getDocumentSubmissions} courseID={courseID}
         exportDocCSV={exportDocCSV}/>
        </>
    }
  </div>
)
}
export default SubmissionByAssignment;
