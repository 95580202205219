import React, {useState, Fragment, useEffect} from 'react';
import {Draggable} from 'react-beautiful-dnd';
import {useHarmoniaDispatch, updateDocument, downloadDocumentWithLink, } from '../../redux';
import {Collapse} from 'reactstrap';
import DocumentListingItemStyle from './DocumentListingItem.module.scss';
import DocumentItemTimedTime from './DocumentItemTimedTime';
import DocumentItemShowGradeOnDate from './DocumentItemShowGradeOnDate';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrashAlt, faCaretUp, faCaretDown, faChevronRight, faChevronDown} from '@fortawesome/free-solid-svg-icons';
import {HarmoniaButton, HarmoniaPaper} from '@harmonia-front-end/shared';
import {Document, Assignment, DocumentEditableFields} from '../../types';
import googleClass from './googleclass.png';

// CSS classnames
const {
    documentCollapseWrapper,
    documentCollapseItem,
    documentWrapper,
    documentTop,
    docTopLeft,
    documentCollapseArrow,
    docTitle,
    documentTitleEdit,
    documentListingItem,
    docTrashRight,
    checkboxInput,
    gradable,
    notGradable,
    delayGrade,
    bottom,
    draggableArrows,
    downloadPreviewDoc,
    set
} = DocumentListingItemStyle;

type DocumentListingItemProps = {
    singleDoc: Document,
    index: number,
    courseEndDate: number | undefined,
    assignment: Assignment,
    openDocPreviewModal: () => void,
    getDocumentImagePreview: (index: number) => void,
    modalShow: boolean,
    assignmentIndex: number,
    showDeleteDocumentModal: (document: Document) => void,
    showGoogleModal: (doc: Document) => void,
    forceOpen: boolean,
    setOpenDocState: (index: number, isOpen: boolean) => void,
    googleButton: boolean,
    disableGoogle: boolean
}

const DocumentListingItem = (
    {singleDoc, index, courseEndDate, assignment, openDocPreviewModal, modalShow, getDocumentImagePreview, assignmentIndex, showDeleteDocumentModal, showGoogleModal, forceOpen, setOpenDocState, googleButton, disableGoogle}: DocumentListingItemProps
) => {

    const dispatch = useHarmoniaDispatch();

    /** LOCAL STATE VARIABLES */


    // edting document title
    const [documentTitle, setDocumentTitle] = useState(singleDoc.title);

    // toggling collapse for editing document and down/up arrows
    const [isOpen, setIsOpen] = useState(forceOpen /* false */);
    const toggle = () => {
        setOpenDocState(index, !isOpen);
        setIsOpen((prevState) => !prevState);
    }
    const collapseIcon = isOpen ? faChevronDown : faChevronRight;

    /** EDITING A DOCUMENT */


    // handler for text input in document title field
    const handleTextInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setDocumentTitle(e.currentTarget.value);
    };


    // handlers for submitting document title change by either blurring or hitting enter
    const changeDocumentTitleOnBlur: React.FocusEventHandler<HTMLInputElement> = (ev) => {
        ev.preventDefault();
        const trimmedNewTitle = ev.currentTarget.value.trim();
        if (trimmedNewTitle && trimmedNewTitle !== singleDoc.title) {
            const payload = {...singleDoc, title: trimmedNewTitle};
            dispatch(updateDocument({document: payload, field: DocumentEditableFields.TITLE}));
        }
    };
    const changeDocumentTitleOnReturn: React.KeyboardEventHandler<HTMLInputElement> = (ev) => {
        if (ev.key === 'Enter') ev.currentTarget.blur();
    };

    // to do: handler(s) for drag/drop files
    const dragHandle: React.DragEventHandler<HTMLDivElement> = (ev) => {

    }

    // handlers for checkbox inputs (for fields like practice, multiple submission, etc; extra logic for handling delay_showing_grade or timed )
    const handleCheckboxInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {

        // console.info(e.target.name)
        // This is a really bad way to detect what's changed, should find cleaner soluion in the future

        let editField = DocumentEditableFields.DELAY_DATE;
        switch (e.target.name) {
            case "practice":
                editField = DocumentEditableFields.PRACTICE;
                break;
            case "incremental_grading":
                editField = DocumentEditableFields.INCREMENT;
                break;
            case "multiple_submissions":
                editField = DocumentEditableFields.MULTI_SUB;
                break;
            case "delay_showing_grade":
                editField = DocumentEditableFields.DELAY_SHOW_GRADE;
                break;
            case "timed":
                editField = DocumentEditableFields.TIMED_BOOL;
                break;
        }
        // TO DO: find a way to use [e.target.name] logic but have it not cause a typing error.


        const payload = {...singleDoc, [e.target.name]: e.target.checked};

        if (
            payload.delay_showing_grade === true &&
            (singleDoc.show_grade_on_date === null ||
                singleDoc.show_grade_on_date === 0)
        ) {
            // if not set, date for delaying grade should default to assignment due date
            payload.show_grade_on_date = assignment.due_at;
        }
        // if timed is set, default is 30 minutes
        if (payload.timed === true && singleDoc.timed_time_seconds === null) {
            payload.timed_time_seconds = 1800;
        }
        dispatch(updateDocument({document: payload, field: editField}));
    };


    /** IMAGE PREVIEW */

    const showDocumentImage = () => {
        if (!modalShow) {
            openDocPreviewModal();
        }
        getDocumentImagePreview(index);
    };

    /** DOWNLOADING A DOCUMENT */

    const downloadDocument = () => {
        // Note: see selectedAssignment.ts file in Redux directory for explanation of download logic.
        dispatch(downloadDocumentWithLink(singleDoc));
    };

    // using this to dynamically change width of input field.
    // TO DO: optimize which number to multiply length by
    const titleInputWidth = `${documentTitle.length * 1}%`;

    return (
        <>
            <Draggable draggableId={`draggable${singleDoc.id}`} index={index}>
                {(provided) => (
                    <HarmoniaPaper
                        className={`${documentListingItem}`}
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                        data-cy="document-listing-item">
                        <div className={documentWrapper}>
                            {/* DOCUMENT TOP */}
                            <div className={documentTop}>
                                <div className={docTopLeft} >
                                    {/* ARROWS */}
                                    <div className={draggableArrows}  {...provided.dragHandleProps} data-cy="document-draggable-arrows" >
                                        <FontAwesomeIcon icon={faCaretUp} />
                                        <div className={set} data-cy="document-num">{`${assignmentIndex + 1}.${index + 1}`}</div>
                                        <FontAwesomeIcon icon={faCaretDown} />
                                    </div>
                                    {/* COLLAPSE CHEVRON */}
                                    <span>
                                        <FontAwesomeIcon
                                            icon={collapseIcon}
                                            onClick={toggle}
                                            className={documentCollapseArrow}
                                            data-cy="document-collapse-arrow"
                                        />
                                        {/* TITLE */}
                                    </span>
                                    {/* doc title should be normal text when document is collapsed, and an input field when doc is expanded */}
                                    <span className={docTitle}>
                                        {isOpen ?
                                            <input
                                                style={{'width': titleInputWidth}}
                                                type="text"
                                                onBlur={changeDocumentTitleOnBlur}
                                                onKeyDown={changeDocumentTitleOnReturn}
                                                className={documentTitleEdit}
                                                value={documentTitle}
                                                onChange={handleTextInput}
                                                name="document-title"
                                            />

                                            : <span data-cy="document-title">{singleDoc.title}</span>
                                        }
                                    </span>
                                </div>
                                {/* DELETE */}
                                <div className={docTrashRight} data-cy="document-delete">
                                    <FontAwesomeIcon
                                        onClick={() => {showDeleteDocumentModal(singleDoc)}}
                                        icon={faTrashAlt}
                                    />
                                </div>
                            </div>
                            {/* editing attributes like practice, incremental grading, delayShowingGrade, timed, etc is only possible if a document is gradable */}

                            {/* NOTE: adding static padding-top (10px) on documentCollapseWrapper changes the height of the documentContainer when not collapsed (so gradable docs are slightly taller than non-gradable). In order to add padding to the collapse, while keeping every document the same height when not collapsed, we set it dynamically here.   */}

                            <div className={documentCollapseWrapper} style={{paddingTop: isOpen ? '10px' : '0'}}>
                                <Collapse isOpen={isOpen} data-cy="document-collapse">
                                    {/* only gradable documents have the following fields to show and edit: practice, multiple_submission, delay_showing_grade and timed */}
                                    {singleDoc.gradable &&
                                        <>
                                            <div className={documentCollapseItem}>
                                                <input
                                                    className={checkboxInput}
                                                    type="checkbox"
                                                    checked={singleDoc.practice}
                                                    onChange={handleCheckboxInput}
                                                    name="practice"
                                                />

                                                <label>Practice Mode</label>
                                            </div>
                                            {/* only documents in practice mode can have incremental grading */}
                                            {
                                                singleDoc.practice &&
                                                <div className={documentCollapseItem}>
                                                    <input
                                                        className={checkboxInput}
                                                        type="checkbox"
                                                        checked={singleDoc.incremental_grading}
                                                        onChange={handleCheckboxInput}
                                                        name="incremental_grading"
                                                    />

                                                    <label>Incremental Grading</label>
                                                </div>
                                            }
                                            {/* show multiple submissions and delay showing grade if NOT practice mode */}
                                            {
                                                !singleDoc.practice &&
                                                <div className={documentCollapseItem}>
                                                    <input
                                                        className={checkboxInput}
                                                        type="checkbox"
                                                        checked={singleDoc.multiple_submissions}
                                                        onChange={handleCheckboxInput}
                                                        name="multiple_submissions"
                                                    />
                                                    <label>Allow Multiple Submission</label>
                                                </div>
                                            }
                                            {!singleDoc.practice && (
                                                <Fragment>
                                                    <div className={`${documentCollapseItem} ${delayGrade}`}>
                                                        <div>
                                                            <input
                                                                className={checkboxInput}
                                                                type="checkbox"
                                                                checked={singleDoc.delay_showing_grade}
                                                                onChange={handleCheckboxInput}
                                                                name="delay_showing_grade"
                                                            />

                                                            <label>Delay showing grade to students</label>
                                                        </div>
                                                        {/* Send payload as prop so we can call updateDocumentThunk inside this component */}
                                                        {singleDoc.delay_showing_grade &&

                                                            <DocumentItemShowGradeOnDate
                                                                document={singleDoc}
                                                                assignmentDueDate={assignment.due_at} courseEndDate={courseEndDate as number} />

                                                        }
                                                    </div>


                                                    <div className={documentCollapseItem}>
                                                        <input
                                                            className={checkboxInput}
                                                            type="checkbox"
                                                            checked={singleDoc.timed}
                                                            onChange={handleCheckboxInput}
                                                            name="timed"
                                                        />
                                                        <label>Timed Document</label>
                                                        {/* Send payload as prop so we can dispatch updateDocument action inside this component */}
                                                        <DocumentItemTimedTime document={singleDoc} />
                                                    </div>
                                                </Fragment>
                                            )}
                                        </>}
                                    <div className={bottom}>
                                        <div className={downloadPreviewDoc}>
                                            <HarmoniaButton variant="primary" dest="app" icon="download" iconPos="left"
                                                onClick={downloadDocument}
                                                data-cy="document-download-button"
                                            >
                                                Download Lesson
                                            </HarmoniaButton>
                                            <HarmoniaButton variant="primary" dest="app" icon="preview" iconPos="left"
                                                onClick={showDocumentImage}
                                                data-cy="document-preview-button"
                                            >Preview Lesson</HarmoniaButton>
                                            {/* Google Classroom link */}
                                            {googleButton && process.env.REACT_APP_HARMONIA_ENV !== 'production' &&
                                                <button disabled={disableGoogle} style={{border: 'none', padding: '0px', fontSize: '12px', backgroundColor: 'rgba(0,0,0,0)'}} onClick={() => showGoogleModal(singleDoc)}>
                                                    <img src={googleClass} />
                                                    &ensp;Add to Google Classroom
                                                </button>
                                            }
                                        </div>
                                        {!singleDoc.gradable &&
                                            <div data-cy="document-gradable-text">Not Gradable</div>
                                        }
                                    </div>
                                </Collapse>
                            </div>

                        </div>
                    </HarmoniaPaper>
                )}
            </Draggable>
        </>
    );
};
export default DocumentListingItem;
