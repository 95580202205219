import React from 'react';
import { FormattedMessage } from 'react-intl';
import { HarmoniaButton, HarmoniaInput, Text } from '@harmonia-front-end/shared';
import { pushErrorNotification, useHarmoniaDispatch } from '../../redux';
import { validatePassword, API, errorMessage } from '../../utilities';
import { useQueryParams } from '../../hooks';
import UserFormStyle from './UserAuth.module.scss';
import { DashboardContainer, DashboardContent, DashboardTitleBar } from '../../components/template';
import LoadingPage from '../../components/LoadingPage';

const {
  form, formInputs, formButton, input,
} = UserFormStyle;

type PasswordPageProps = {
  /** Whether the password page is for password change or password create. */
  pageType: 'change' | 'create'
};

/**
 * User password modification page for the Harmonia Dashboard,
 * routed to the page '/create-password' if to create a new password
 * or to the page '/change-password' if to change an existed password.
 *
 * The function of the page is being defined by the `pageFunction` props,
 * which is a must provide props for this page.
 */
export default function PasswordPage({ pageType }: PasswordPageProps) {
  const { token } = useQueryParams();
  const dispatch = useHarmoniaDispatch();

  const isChange = pageType === 'change';
  const isCreateAndValid = token && !isChange;

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    formData.set('token', token || '');
    API.post('/api/users/password', formData)
      .then(() => { window.location.href = '/my-profile'; })
      .catch((err) => dispatch(pushErrorNotification(errorMessage(err.data))));
  };

  const [{
    upper, lower, digit, length,
  }, setPasswordState] = React.useState({
    upper: false,
    lower: false,
    digit: false,
    length: false,
  });
  const handlePasswordInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordState({
      ...validatePassword(e.target.value),
      length: e.target.value.length >= 8,
    });
  };

  if (!(isChange || isCreateAndValid)) return <DashboardContent><LoadingPage /></DashboardContent>;

  return (
    <>
      <DashboardTitleBar h1={isChange ? 'Change Password' : 'Create Password'} />
      <DashboardContent>
        <form className={form} onSubmit={handleSubmit}>
          <div className={formInputs}>
            {isChange && <HarmoniaInput style={{width: '350px', marginLeft: 0}} className={input} type="password" placeholder="Old Password" name="current_password" />}
            <HarmoniaInput style={{width: '350px', marginLeft: 0}} className={input} type="password" placeholder="Create Password" name="password" onChange={handlePasswordInput} />
            <HarmoniaInput style={{width: '350px', marginLeft: 0}} className={input} type="password" placeholder="Confirm Password" name="password_confirm" />
            <div  style={{height: '8px'}}/>
            <div>
              Password must have:
              <Text variant="body" color={upper ? 'success' : 'error'}>
                At least one uppercase letter.
              </Text>
              <Text variant="body" color={lower ? 'success' : 'error'}>
                At least one lowercase letter.
              </Text>
              <Text variant="body" color={digit ? 'success' : 'error'}>
                At least one digit.
              </Text>
              <Text variant="body" color={length ? 'success' : 'error'}>
                At least 8 characters.
              </Text>
            </div>
          </div>
          <HarmoniaButton className={formButton} type="submit" variant="primary" dest="web" icon="next">
            <FormattedMessage id="general.submit" defaultMessage="Submit" />
          </HarmoniaButton>
        </form>
      </DashboardContent>
    </>
  );
}
