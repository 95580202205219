/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API, errorMessage } from '../utilities';
import { pushErrorNotification, pushNotification } from './notification';
import { MediaCollection, MediaCollectionWithFiles, MediaFile } from '../types';

type MediaInitialState = {
  /**
   * The `mediaCollections` variable stores a Harmonia user's media collections and files.
   * This optional value can be a concrete media collections with files object, null, or undefined.
   *
   * If the value is undefined, it means that no media collections and files
   * have been pulled from the API.
   * If the value is null, it means that API has been called, but failed.
   * Otherwise, the `user` attribute would be an concrete User object.
   *
   * Such value settings on `null` and `undefined` is prepared
   * in light of the `useUserMediaCollectionsAndFiles` React hooks in the `hooks` folder.
   * Check the `useUserMediaCollectionsAndFiles` React hook for details.
   */
  mediaCollections?: MediaCollectionWithFiles[] | null;
};

const initialState: MediaInitialState = {};

/** Helper function to sort media collections or files based on their weights. */
const weightSort = <T extends { weight: number }>(a: T, b: T) => a.weight - b.weight;

/**
 * Helper function to put or update a media file to the media state.
 * It would search through the media collection and put/update the media file
 * to the corresponded media collection.
 *
 * If a media file with the same ID is not in the state, the file would be pushed.
 * Otherwise, the content would be updated.
 *
 * @param file the media file to push
 * @param collections a complete media state `mediaCollections` attributes. It has to be valid.
 */
function pushOrUpdateMediaFileToCollection(
  file: MediaFile, collections: MediaCollectionWithFiles[],
): void {
  for (let i = 0; i < collections.length; i += 1) {
    /* TODO: API may use both string and int for media collection ID.
        Use == for string and number comparison */ // eslint-disable-next-line eqeqeq
    if (collections[i].id == file.media_collection) {
      if (!collections[i].mediaFiles) collections[i].mediaFiles = [];
      const searchIndex = collections[i].mediaFiles?.findIndex(({ id }) => file.id === id);
      if (searchIndex === undefined || searchIndex === -1) collections[i].mediaFiles?.push(file);
      else { // @ts-ignore
        collections[i].mediaFiles[searchIndex] = file;
      }
      collections[i].mediaFiles = collections[i].mediaFiles?.sort(weightSort);
      break;
    }
  }
}

/**
 * Redux async thunk to acquire all media collections with their related media files from the API.
 *
 * It will call the API and, if succeeded, a `MediaCollectionWithFiles[]` variable
 * would be returned. The media state's `mediaCollections` variable would be completely overwritten.
 * Otherwise, the async thunk would reject with nothing returned,
 * and the API error response would be dispatched as an error notification.
 * The media reducer would set the `mediaCollections` variable in the store to be null,
 * indicating an erroneous API call.
 *
 * This thunk API is called by the `useUserMediaCollectionsAndFiles` hooks,
 * to pull all media collections and files for the app initialization.
 *
 * This thunk does not have any parameters.
 */
export const getAllMediaCollectionsAndFiles = createAsyncThunk<
MediaCollectionWithFiles[], void, { rejectValue: void }
>(
  'media/getMediaCollections',
  async (_, { dispatch, rejectWithValue }) => {
    let mediaCollections: MediaCollectionWithFiles[] = [];
    try {
      const response = await API.get('/api/media-collections');
      mediaCollections = response.data.data as MediaCollection[];
    } catch (e) {
      dispatch(pushErrorNotification(errorMessage(e.data)));
      return rejectWithValue();
    }

    for (let i = 0; i < mediaCollections.length; i += 1) {
      const { id } = mediaCollections[i];
      try {
        // eslint-disable-next-line no-await-in-loop
        const response = await API.get(`/api/media-collections/${id}/media-files`);
        mediaCollections[i].mediaFiles = response.data.data as MediaFile[];
      } catch (e) {
        dispatch(pushErrorNotification(errorMessage(e.data)));
        return rejectWithValue();
      }
    }

    return mediaCollections;
  },
);

/**
 * Redux async thunk to create a new media collection.
 *
 * It would return a new `MediaCollection` object if the API call is succeeded.
 * The media reducer would push the mew media collection metadata to the state.
 * Otherwise, it would reject with no value returned
 * and push an error notification based on the API error response.
 *
 * This thunk does not have any parameters.
 */
export const createMediaCollection = createAsyncThunk<MediaCollection, void, { rejectValue: void }>(
  'media/createMediaCollection',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await API.post('/api/media-collections');
      dispatch(pushNotification({
        severity: 'success',
        message: 'New media collection created.',
      }));
      return response.data.data as MediaCollection;
    } catch (e) {
      dispatch(pushErrorNotification(errorMessage(e.data)));
      return rejectWithValue();
    }
  },
);

/**
 * Redux async thunk to update a media collection's title based on its ID.
 *
 * Both successful or erroneous API call would return nothing.
 * If rejected, nothing would happen to the store.
 * An error notification would be pushed based on the API error response.
 * If fulfilled, the media reducer would acquire from the arguments
 * and update the corresponded media collection with the new title.
 *
 * An object is required by the Redux async thunk. The `mediaCollectionID` attribute
 * is the to-be-updated media collection's ID, and the `newTitle` attribute
 * is the to-be-updated title.
 */
export const updateMediaCollectionTitleByID = createAsyncThunk<
void, { mediaCollectionID: number, newTitle: string }
>(
  'media/updateMediaCollectionTitleByID',
  async ({ mediaCollectionID, newTitle }, { dispatch }) => {
    try {
      await API.put(`/api/media-collections/${mediaCollectionID}`, {
        collection_title: newTitle,
      });
      // dispatch(pushNotification({
      //   severity: 'info',
      //   message: 'Media collection title updated.',
      // }));
    } catch (e) {
      dispatch(pushErrorNotification(errorMessage(e.data)));
    }
  },
);

/**
 * Redux async thunk to delete a media collection based on its ID.
 *
 * Both successful or erroneous API call would return nothing.
 * If rejected, nothing would happen to the store.
 * An error notification would be pushed based on the API error response.
 * If fulfilled, the media reducer would acquire from the thunk arguments
 * and delete the corresponded media collection.
 *
 * A media collection ID is required by the Redux async thunk.
 * The media collection ID is a number.
 */
export const deleteMediaCollectionByID = createAsyncThunk<void, number>(
  'media/deleteMediaCollection',
  async (mediaCollectionID, { dispatch }) => {
    try {
      await API.delete(`/api/media-collections/${mediaCollectionID}`);
      dispatch(pushNotification({
        severity: 'success',
        message: `Media collection deleted.`,
      }));
    } catch (e) {
      dispatch(pushErrorNotification(errorMessage(e.data)));
    }
  },
);

/**
 * Redux async thunk to reorder the set of media collections.
 *
 * If fulfilled, an object with each media collection's ID and the new weight
 * would be returned from the thunk. The media reducer would collect those IDs and weights
 * to update and sort the media collections.
 *
 * If rejected, nothing would happen to the store.
 * An error notification would be pushed based on the API error response.
 *
 * An array of media collection ID with the new order is the required thunk arguments.
 */
export const reorderMediaCollections = createAsyncThunk<
Pick<MediaCollection, 'id' | 'weight'>[], number[], { rejectValue: void }
>(
  'media/reorderMediaCollections',
  async (media_collections, { dispatch, rejectWithValue }) => {
    try {
      const response = await API.post('/api/media-collections/weight', { media_collections });
      const mediaCollections = response.data.data as MediaCollection[];

      // dispatch(pushNotification({
      //   severity: "success",
      //   message: "New order has been saved."
      // }))


      return mediaCollections.map(({ id, weight }) => ({ id, weight }));
    } catch (e) {
      dispatch(pushErrorNotification(errorMessage(e.data)));
      return rejectWithValue();
    }
  },
);

/**
 * Redux async thunk to upload a media file to a media collection.
 *
 * To call the thunk, an object should be sent as the argument
 * with `mediaCollection` attribute to be the media collection ID
 * and `formData` to be a `FormData` object.
 *
 * The `formData` must have a `mediafile` key and a File to be its value.
 * The file must be smaller than 20MB, or the call would be rejected.
 *
 * If fulfilled, the API would respond with a media file metadata.
 * The media reducer would push and sort the media file metadata to its associated media collection.
 *
 * If rejected, nothing would happen to the store.
 * An error notification would be pushed based on the API error response.
 */
export const createMediaFile = createAsyncThunk<
MediaFile, { mediaCollection: number, formData: FormData }, { rejectValue: void }
>(
  'media/createMediaFile',
  async ({ formData, mediaCollection }, { dispatch, rejectWithValue }) => {
    if (!Object.is(Object.getPrototypeOf(formData.get('mediafile')), File.prototype)) return rejectWithValue();
    if ((formData.get('mediafile') as File).size / 1024 / 1024 > 20) {
      dispatch(pushErrorNotification('Only files with size smaller than 20 MB are supported.'));
      return rejectWithValue();
    }
    try {
      // dispatch(pushNotification({ severity: 'info', message: 'Media file uploading.', timeout: 10000 }));
      const response = await API.post(
        `/api/media-collections/${mediaCollection}/media-files`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } },
      );
      dispatch(pushNotification({ severity: 'success', message: 'New media file uploaded.' }));
      return response.data.data as MediaFile;
    } catch (e) {
      dispatch(pushErrorNotification(errorMessage(e.data)));
      return rejectWithValue();
    }
  },
);

/**
 * Redux async thunk to acquire the latest info about a media file with its ID.
 *
 * If fulfilled, a new media file metadata would be responded from the API,
 * and the media reducer would update corresponded media file metadata to the store.
 *
 * If rejected, nothing would happen to the store.
 * An error notification would be pushed based on the API error response.
 *
 * The media file ID is required to pass in the thunk as the argument.
 */
export const showMediaFileByID = createAsyncThunk<MediaFile, number, { rejectValue: void }>(
  'media/showMediaFileByID',
  async (mediaFileID, { dispatch, rejectWithValue }) => {
    try {
      const response = await API.get(`/api/media-files/${mediaFileID}`);
      const mediaFile = response.data.data as MediaFile;
      if(mediaFile.transcode_status === 'COMPLETED') {
        dispatch(pushNotification({
          severity: 'success',
          message: 'File transcoding complete.'
        }))
      }
      return mediaFile;
    } catch (e) {
      dispatch(pushErrorNotification(errorMessage(e.data)));
      return rejectWithValue();
    }
  },
);

/**
 * Redux async thunk to update a media file's title by its ID.
 *
 * If fulfilled, the API would respond with an updated media file metadata.
 * The media reducer would take the metadata and update the corresponded media file.
 *
 * If rejected, nothing would happen to the store.
 * An error notification would be pushed based on the API error response.
 *
 * The media file ID and the new title should be packed as an object and pass to the thunk.
 */
export const updateMediaFileTitleByID = createAsyncThunk
<
MediaFile, { mediaFileID: number, newTitle: string }, { rejectValue: void }

>
(
  'media/updateMediaFileByID',
  async ({ mediaFileID, newTitle }, { dispatch, rejectWithValue }) => {
    try {
      const response = await API.put(`/api/media-files/${mediaFileID}`, { title: newTitle });
      // dispatch(pushNotification({
      //   severity: 'info',
      //   message: `Media file title changed to ${newTitle} successfully.`,
      // }));
      return response.data.data as MediaFile;
    } catch (e) {
      dispatch(pushErrorNotification(errorMessage(e.data)));
      return rejectWithValue();
    }
  },
);

/**
 * Redux async thunk to delete a media file based on its ID.
 *
 * Both successful or erroneous API call would return nothing.
 * If rejected, nothing would happen to the store.
 * An error notification would be pushed based on the API error response.
 * If fulfilled, the media reducer would acquire from the thunk arguments
 * and delete the corresponded media file.
 *
 * A media file ID is required by the Redux async thunk. The media file ID is a number.
 */
export const deleteMediaFileByID = createAsyncThunk(
  'media/deleteMediaFileByID',
  async (mediaFileID: number, { dispatch }) => {
    try {
      await API.delete(`/api/media-files/${mediaFileID}`);
      dispatch(pushNotification({
        severity: 'success',
        message: `Media file deleted.`,
      }));
    } catch (e) {
      dispatch(pushErrorNotification(errorMessage(e.data)));
    }
  },
);

/**
 * Redux async thunk to reorder the set of media files.
 *
 * If fulfilled, an object with each media file's ID and the new weight
 * would be returned from the thunk. The media reducer would collect those IDs and weights
 * to update and sort related media files.
 *
 * If rejected, nothing would happen to the store.
 * An error notification would be pushed based on the API error response.
 *
 * An array of media file ID with the new order and the related media collection ID
 * should be packed as an object and passed to the thunk.
 */
export const reorderMediaFiles = createAsyncThunk<
Pick<MediaFile, 'id' | 'weight'>[], {
  mediaCollectionID: number,
  mediaFilesOrder: number[],
}, { rejectValue: void }
>(
  'media/reorderMediaFiles',
  async ({ mediaCollectionID, mediaFilesOrder }, { dispatch, rejectWithValue }) => {
    try {
      const response = await API.post(`/api/media-collections/${mediaCollectionID}/media-files/weight`, {
        media_files: mediaFilesOrder,
      });
      const mediaFiles = response.data.data as MediaCollection[];

      // dispatch(pushNotification({
      //   severity: "success",
      //   message: "New order has been saved."
      // }))
      return mediaFiles.map(({ id, weight }) => ({ id, weight }));
    } catch (e) {
      dispatch(pushErrorNotification(errorMessage(e.data)));
      return rejectWithValue();
    }
  },
);

const mediaSlice = createSlice({
  name: 'media',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getAllMediaCollectionsAndFiles.fulfilled,
        (state, { payload }) => {
          state.mediaCollections = payload.sort(weightSort);
        },
      )
      .addCase(
        getAllMediaCollectionsAndFiles.rejected,
        (state) => { state.mediaCollections = null; },
      )
      .addCase(
        createMediaCollection.fulfilled,
        (state, { payload }) => {
          if (!state.mediaCollections) state.mediaCollections = [];
          state.mediaCollections.push(payload);
          state.mediaCollections = state.mediaCollections.sort(weightSort);
        },
      )
      .addCase(
        updateMediaCollectionTitleByID.fulfilled,
        (state, { meta }) => {
          if (!state.mediaCollections) state.mediaCollections = [];
          const { mediaCollectionID: id, newTitle } = meta.arg;
          const i = state.mediaCollections.findIndex((col) => col.id === id);
          if (i !== -1) state.mediaCollections[i].collection_title = newTitle;
        },
      )
      .addCase(
        deleteMediaCollectionByID.fulfilled,
        (state, { meta }) => {
          state.mediaCollections = state.mediaCollections?.filter((c) => c.id !== meta.arg);
        },
      )
      .addCase(
        reorderMediaCollections.fulfilled,
        (state, { payload }) => {
          payload.forEach(({ id, weight }) => {
            const find = state.mediaCollections?.find(({ id: colID }) => colID === id);
            if (find) find.weight = weight;
          });
          state.mediaCollections = state.mediaCollections?.sort(weightSort);
        },
      )
      .addCase(
        createMediaFile.fulfilled,
        (state, { payload }) => {
          if (!state.mediaCollections) state.mediaCollections = [];
          pushOrUpdateMediaFileToCollection(payload, state.mediaCollections);
        },
      )
      .addCase(
        showMediaFileByID.fulfilled,
        (state, { payload }) => {
          if (!state.mediaCollections) state.mediaCollections = [];
          pushOrUpdateMediaFileToCollection(payload, state.mediaCollections);
        },
      )
      .addCase(
        updateMediaFileTitleByID.fulfilled,
        (state, { payload }) => {
          if (!state.mediaCollections) state.mediaCollections = [];
          pushOrUpdateMediaFileToCollection(payload, state.mediaCollections);
        },
      )
      .addCase(
        deleteMediaFileByID.fulfilled,
        (state, { meta }) => {
          state.mediaCollections?.forEach((col) => {
            if (col.mediaFiles?.some((f) => f.id === meta.arg)) {
              col.mediaFiles = col.mediaFiles?.filter((f) => f.id !== meta.arg);
            }
          });
        },
      )
      .addCase(
        reorderMediaFiles.fulfilled,
        (state, { payload, meta }) => {
          const { mediaCollectionID } = meta.arg;
          const mediaFiles = state.mediaCollections?.find(
            ({ id }) => id === mediaCollectionID,
          )?.mediaFiles;
          payload.forEach(({ id, weight }) => {
            const find = mediaFiles?.find(({ id: fileID }) => fileID === id);
            if (find) find.weight = weight;
          });
          mediaFiles?.sort(weightSort);
        },
      );
  },
});

export default mediaSlice.reducer;
