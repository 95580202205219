import React from 'react';
import DocImagePreviewModalStyle  from './DocImagePreviewModal.module.scss';
import ReactDOM from 'react-dom';
import { faChevronLeft,faChevronRight, faTimes} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Assignment, Document} from '../../types';
import LoadingPage from '../LoadingPage';
import {
  HarmoniaChevron,
  Text,
} from '@harmonia-front-end/shared';

const  {modalContainer, modalContent, title, docTitle, bottom, docPreviewImage, leftArrow, rightArrow, close, imageWrapper} = DocImagePreviewModalStyle;

type DocImagePreviewModalProps = {
  closeDocPreviewModal: () => void,
  selectedDoc: Document,
  show: boolean,
  getDocumentImagePreview: (index: number) => void,
  urlLoading: boolean,
  assignmentDocuments: Assignment[],
  page: string
}

// This component is used on both the AssignmentsPage and the AssignmentDetailPage. The assignment-documents on the AssignmentsPage consists of an array of multiple assignment objects, each with an array of document objects. For the AssignmentDetailPage, the array has just one assignment object. For moving between the different assignment objects on the AssignmentDetailPage, there is a page prop passed to this component which we use to run conditional logic in the preview functions.

const DocImagePreviewModal = (props: DocImagePreviewModalProps) => {

  const {closeDocPreviewModal, selectedDoc,show, getDocumentImagePreview, urlLoading, assignmentDocuments, page} = props;

  // don't render if modal is not set to show, or there are no assignmentDocuments or selectedDoc
  if(!show || assignmentDocuments.length === 0 || !selectedDoc) return null;

  // initializing the indices.
  let assignmentIndex:number;
  let documentIndex:number;
  // When this modal launches, we use the selectedDoc (a memoized document obj on both AssignmentDetailPage and AssignmentsPage,passed as a prop to this modal) to grab the corresponding indices (which assignment and which assignment-document) in the array. Every time we use one of the preview functions, we use the indices to reset the docID state on the parent component, which both changes the selectedDoc and triggers a re-render of the parent component (and thus this modal).
  assignmentDocuments?.forEach((assignment, i) => {
    assignment.documents?.some((doc, j) => {
      if(doc.id === selectedDoc.id){
        assignmentIndex = i;
        documentIndex = j;
        return true;
      }
    })
  });

  // we use these length variables to check whether we are at the end or beginning of a respective array.
  const assignmentLength = assignmentDocuments.length;
  const currentDocsLength = assignmentDocuments[assignmentIndex].documents.length;

  //  GETTING NEXT
  const getNextDocPreview = () => () => {
    if(documentIndex< currentDocsLength - 1){
      documentIndex++;
    }
    else{
      documentIndex = 0;
      // Because the Assignments page has multiple assignment objects, we have to navigate to the next assignment when we go past the end of the current docs array or go back to the very beginning if we've reached the end of everything.
      if(page === 'Assignments'){
          assignmentIndex < assignmentLength - 1 ? assignmentIndex++ : assignmentIndex = 0;

      }
    }
  // getDocumentImagePreview is a prop passed to this modal. the function differs slightly on both pages due to dealing with one array vs multiple. Maybe we can refactor the functions to both take in two parameters?
  if(page === 'AssignmentDetail') {
    getDocumentImagePreview(documentIndex);
  }
  if(page === 'Assignments'){
    getDocumentImagePreview(assignmentIndex, documentIndex)
  }
 };

 // GETTING PREVIOUS
  const getPreviousDocPreview = () => () => {
    if(documentIndex > 0){
    documentIndex--;
    }
    else {
      documentIndex = currentDocsLength -1;
      if(page === 'Assignments'){
        assignmentIndex > 0 ? assignmentIndex-- : assignmentIndex = assignmentLength -1;
        // because each assignment-document array can have different lengths, we have to reset the documentIndex to be at the end of the previous array of documents.
       documentIndex = assignmentDocuments[assignmentIndex].documents.length - 1;
      }
    }
    if(page === 'AssignmentDetail') {
      getDocumentImagePreview(documentIndex);
    }
    if(page === 'Assignments'){
      getDocumentImagePreview(assignmentIndex, documentIndex)
    }
 };

const assignmentTitle = assignmentDocuments[assignmentIndex].title;


// modal component(s) rendered on separate root div than body so background effects (blur and fade) can be added.
const modalRoot = document.getElementById('modal-root') as HTMLDivElement;

// We pass this function as an onClick to both modalContainer (backdrop) and modalContent (pop-up) divs so we the modal when the backdrop is clicked.

const closeOnBackDrop = (e: React.MouseEvent<HTMLElement>) => {
  e.stopPropagation();
  const className = e.currentTarget.className;
  if(className === modalContainer) {
    closeDocPreviewModal();
  }
};


return ReactDOM.createPortal (
  <div className={modalContainer} onClick={closeOnBackDrop}>
    <div className={modalContent} onClick={closeOnBackDrop}data-cy="document-image-preview-modal">
      <div className={close}>
        <div onClick={() => closeDocPreviewModal()}>
        <Text>X</Text>
        </div>
      </div>
      {/* TITLE */}
      <div className={title} data-cy="image-preview-title">
        {selectedDoc &&
        <>
        <Text variant="dashboardHeading">
          {assignmentTitle}
        </Text>
        <Text variant="dashboardHeading" className={docTitle}>
          {`${documentIndex + 1}. ${selectedDoc.title}`}
        </Text>
        </>
        }
      </div>
      <div className={bottom}>
        <div onClick={getPreviousDocPreview()} data-cy="previous-image">
          <HarmoniaChevron direction="left" className={leftArrow} />
        </div>
        {/* IMAGE */}
        <div className={imageWrapper}>
          {urlLoading && <LoadingPage/>}
          {(!urlLoading && selectedDoc) && <img src={selectedDoc.previewURL} alt={selectedDoc.title} className={docPreviewImage} data-cy="document-preview-image"/>}
        </div>
        <div onClick={getNextDocPreview()} data-cy="next-image">
          <HarmoniaChevron direction="right" className={rightArrow}  />
        </div>
      </div>
    </div>
  </div>,
  modalRoot
)
}
export default DocImagePreviewModal;

