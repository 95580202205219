import React from 'react';
import {Text} from '@harmonia-front-end/shared';
import {pushErrorNotification, useHarmoniaDispatch} from '../../redux';
import {API, errorMessage, HarmoniaCookies} from '../../utilities';

/**
 * User sign out page for the Harmonia Dashboard, routed to the page '/sign-out'.
 *
 * This page is intended for existed user to sign out.
 *
 * If the 'x-access-token' is not existed in the browser, the user cannot see this page.
 * This logic is being handled by the DashboardRoutes page, where the rule is
 * if there is no cookies, URL query to this page would be undefined.
 */
export default function SignOutPage() {
    const dispatch = useHarmoniaDispatch();

    React.useEffect(() => {
        API.get('/api/users/sign-out')
            .then(() => {
                HarmoniaCookies['x-access-token'].remove(); // Remove Cookies
                window.sessionStorage.removeItem('googleSession');
            })
            .then(() => {
                window.location.href = '/sign-in';
            })
            .catch((err) => dispatch(pushErrorNotification(errorMessage(err.data))));
    }, [dispatch]);

    // return <Text variant="h1" color="grey" align="center" gutterBottom></Text>;
    return <></>;
}
