import React, { Fragment, useState } from 'react';
import { Collapse } from 'reactstrap';
import { updateDocument } from '../../redux';
import { useDispatch } from 'react-redux';
import { Document, DocumentEditableFields } from '../../types';
import style from './DocumentListingItem.module.scss';

const {documentTime} = style;

const DocumentItemTimedTime = (props: {document: Document}) => {
  const dispatch = useDispatch();
  const { document } = props;
  const {timed_time_seconds} = document;
  if (timed_time_seconds === null)
    return null;
  // let hour: number;
  const [hour, setHour] = useState(Math.floor(timed_time_seconds / 3600));
  let minute: number;
  // Default to 30 minutes
  // Converting timed_time_seconds field into readable time to display in select elements
  // hour = Math.floor(timed_time_seconds / 3600);
  minute = (timed_time_seconds / 60) % 60;
  // API call is made whenever new hour or minute value is selected

  // TO DO: set typing for handleTimeChange function.
  const handleTimeChange:React.ChangeEventHandler<HTMLSelectElement>= (e) => {
    const name: string = e.currentTarget.name;
    const value: number = parseInt(e.currentTarget.value, 10);
    // console.log("A");
    // console.log(value);
    let h;
    if (name === 'hour') {
      setHour(value);
      h = value;
    } else
      h = hour;
    if (name === 'minute')
      minute = value;
    if (!h && !minute)
      minute = 1;
    if (h === 8)
      minute = 0;
    if (minute > 15 || h > 0)
      minute = Math.floor(minute / 5) * 5;
    // console.log("hour = " + h);
    // console.log("min = " + minute);
    const hourSeconds = h * 3600;
    const minuteSeconds = minute * 60;
    let newTimedTimeSeconds = hourSeconds + minuteSeconds;
    // if (newTimedTimeSeconds < 1)
    //     newTimedTimeSeconds = 1;
    // if (newTimedTimeSeconds > 14400)
    //     newTimedTimeSeconds = 14400;
    const payload = { ...document, timed_time_seconds: newTimedTimeSeconds };
    dispatch(updateDocument({ document: payload,field: DocumentEditableFields.TIMED_BOOL}));
  };
  // Hour and minute values for option elements
  const hoursArr = [0, 1, 2, 3, 4, 5, 6, 7, 8];
  const minutesArr = hour === 0 ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 20, 25, 30, 35, 40, 45, 50, 55] :
                     hour === 8 ? [0] :
                     [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];
  // Helper function to render arrays into said option elements
  const renderTimeUnitOptions = (arr: number[]) => {
    return (
      <Fragment>
        {arr.map((timeUnit, index) => {
          return <option key={index} value={timeUnit}>{timeUnit}</option>;
        })}
      </Fragment>
    );
  };
  const hourOptions = renderTimeUnitOptions(hoursArr);
  const minuteOptions = renderTimeUnitOptions(minutesArr);
  return (
    <div className={documentTime}>
      <div className="hour-container">
        <label>Hours: </label>
        <select name="hour" value={hour} onChange={handleTimeChange} data-cy="document-hour-select">
          {hourOptions}
        </select>
      </div>
      <div className="minute-container">
      <label>Minutes: </label>
        <select name="minute" value={minute} onChange={handleTimeChange} data-cy="document-minute-select">
          {minuteOptions}
        </select>
      </div>
    </div>
  );
};
export default DocumentItemTimedTime;
