// import libraries
import {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import {
  useHarmoniaSelector,
  useHarmoniaDispatch,
  getCourseDetail,
  getCourseInstructors,
  getCourseStudents,
  getCourseTas,
  getCourseEnrollmentRequestsByCourseID,
  getCourseInvitations
} from '../../redux';
import { DashboardContent, DashboardTitleBar } from '../../components/template';
import LoadingPage from '../../components/LoadingPage';
import { Text } from "@harmonia-front-end/shared";
import {CoursePeople, CourseEnrollmentRequests } from '../../components/course';
import { PersonnelType } from '../../components/course/CoursePeople';
import { CourseInvitation } from '../../types';
import { useRedirect } from '../../hooks';


const PeoplePage = () => {
  const dispatch = useHarmoniaDispatch();

  // grab courseId from params and convert it to a number (courseID).
  const { courseId } = useParams<{courseId: string}>();
  const courseID = parseInt(courseId, 10);

  // local state for tracking loading: course details, students, instructors, tas, enrollment requests and invitations. NOTE we may not use most of them but will make them in case we need them.
  const [courseDetailsLoading, setCourseDetailsLoading] = useState(true);
  const [courseStudentsLoading, setCourseStudentsLoading] = useState(true);
  const [courseTAsLoading, setCourseTAsLoading] = useState(true);
  const [courseInstructorsLoading, setCourseInstructorsLoading] = useState(true);
  const [enrollmentRequestsLoading, setEnrollmentRequestsLoading] = useState(true);
  const [invitationsLoading, setInvitationsLoading] = useState(true);



  // Determine whether or not a user is an instructor once the courses load
  const courses = useHarmoniaSelector(state => state.courses)
  let isTA = false;
  let foundCourse = courses?.course_instructors?.find(course => course.id === courseID);
  if (!foundCourse) {
      isTA = true;
      foundCourse = courses?.course_tas?.find(course => course.id === courseID);
  }
  let needToRedirect = foundCourse ? false : !Object.keys(courses).length ? false : true;

  useEffect(() => {
    if (foundCourse) {
      dispatch(getCourseDetail(courseID)).finally(() =>{
        setCourseDetailsLoading(false)
      });
    }
  }, [dispatch, foundCourse]);
  // making API calls to fetch course personnel, invitations and enrollment requests
  useEffect(() => {
    if (foundCourse) {
      dispatch(getCourseStudents(courseID)).finally(() => {
        setCourseStudentsLoading(false);
      });
    }
  }, [dispatch, foundCourse]);
  useEffect(() => {
    if (foundCourse) {
      dispatch(getCourseInstructors(courseID)).finally(() => {
        setCourseInstructorsLoading(false);
      });
    }
  }, [dispatch, foundCourse]);
  useEffect(() => {
    if (foundCourse) {
      dispatch(getCourseTas(courseID)).finally(() => {
        setCourseTAsLoading(false);
      })
    }
  }, [dispatch, foundCourse]);
  useEffect(() => {
    if (foundCourse) {
      dispatch(getCourseEnrollmentRequestsByCourseID(courseID)).finally(() => {
        setEnrollmentRequestsLoading(false);
      });
    }
  }, [dispatch, foundCourse]);
  useEffect(() => {
    if (foundCourse) {
      dispatch(getCourseInvitations(courseID)).finally(() => {
        setInvitationsLoading(false);
      })
    }
  }, [dispatch, foundCourse])

  useRedirect(needToRedirect, '/', {
    severity: "error",
    message: "You are not an instructor of this course."
  });


  // grab selectedCourse and user state from Redux store.
  const { selectedCourse, user } = useHarmoniaSelector((state) => state);


  // deconstruct all selectedCourse details.
  const {courseInformation, instructors, students, tas, enrollmentRequests, invitations} = selectedCourse;

  // console.log('state on People Page: ', useHarmoniaSelector(state => state));

  const renderCoursePeople = (personnel: PersonnelType) => {
    const personnelTypeToTitle = [
      {type: PersonnelType.INSTRUCTOR, title: 'Instructors'},
      {type: PersonnelType.TA, title: 'Teaching Assistants'},
      {type: PersonnelType.STUDENT, title: 'Students'}
    ];
    return (
      <>
      {/* <Text color="black" variant="dashboardContentHeading">
        {personnelTypeToTitle.filter(elem => elem.type === personnel).map(elem => elem.title)}
      </Text> */}
      <CoursePeople selectedCourse={selectedCourse} personnel={personnel} invitations={invitations as CourseInvitation[]} userID={user.user?.id as number} isTA={isTA}/>
      </>
    )

  }

  return (
    <>
    {courseDetailsLoading && <DashboardContent><LoadingPage/></DashboardContent>}
    {!courseDetailsLoading && <DashboardTitleBar h1="People" h2={courseInformation?.title} h2_no2={`${courseInformation?.academic_term} ${courseInformation?.year}`}/>}
    <DashboardContent>
    <div>

      <>
  { enrollmentRequests && enrollmentRequests?.length > 0 &&
  <>
  <CourseEnrollmentRequests enrollmentRequests={enrollmentRequests} courseID={courseID} isTA={isTA}/>
  </>
  }
   {instructors && renderCoursePeople(PersonnelType.INSTRUCTOR)}
   {tas && renderCoursePeople(PersonnelType.TA)}
   {students && renderCoursePeople(PersonnelType.STUDENT)}
      </>
        </div>
    </DashboardContent>

</>
  );
}
export default PeoplePage;
