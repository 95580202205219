// courses.ts
/** This file contains essential Redux actions and reducers to handle courses data in the store. */

import {
  createAction,
  createAsyncThunk,
  createReducer,
  current,
  createSlice,
} from "@reduxjs/toolkit";
import API from "../utilities/API";
import { pushErrorNotification, pushNotification } from "./notification";
import {
  Assignment,
  Document,
  CourseDetailedInfo,
  CourseStudent,
  CourseInstructorTA,
  UpdatePlanningCoursePayload,
  CourseInvitation,
  UpdateActiveCoursePayload,
  CourseEnrollmentRequest,
  Submission,
  User,
  CourseEditableFields,
} from "../types";
import { errorMessage, formatStudentName } from "../utilities";
import { getCourseEnrollmentRequestsByUser } from "./course-management";

// defining and creating initial state for SelectedCourse

export type SelectedCourseStateType = {
  assignments?: Assignment[] | null;
  courseInformation?: CourseDetailedInfo | null;
  students?: CourseStudent[] | null;
  tas?: CourseInstructorTA[] | null;
  instructors?: CourseInstructorTA[] | null;
  invitations?: CourseInvitation[] | null;
  enrollmentRequests?: CourseEnrollmentRequest[] | null;
  submissionsByAssignment?: Submission[] | null;
  submissionsByUser?: Submission[] | null;
};

const initialState: SelectedCourseStateType = {};

/******** ACTIONS/THUNKS *********/

// GENERAL COURSE STUFF

export const getCourseDetail = createAsyncThunk<
  CourseDetailedInfo,
  number,
  { rejectValue: void }
>(
  "courses/getCourseDetail_NEW",
  async (courseID, { dispatch, rejectWithValue }) => {
    try {
      const response = await API.get(`/api/courses/${courseID}`);
      return response.data.data as CourseDetailedInfo;
    } catch (e) {
      dispatch(pushErrorNotification(errorMessage(e.data)));
      return rejectWithValue();
    }
  }
);

export const updateCourseSettings = createAsyncThunk<
  CourseDetailedInfo,
  {
    course:
      | CourseDetailedInfo
      | UpdatePlanningCoursePayload
      | UpdateActiveCoursePayload;
    field: CourseEditableFields;
  },
  { rejectValue: void }
>(
  "courses/updateCourseSettings",
  async ({ course, field }, { dispatch, rejectWithValue }) => {
    try {
      const response = await API.put(`/api/courses/${course.id}`, course);
      const updatedCourse = response.data.data as CourseDetailedInfo;

      // let notifChange;

      // switch (field) {
      //   case CourseEditableFields.TITLE:
      //     notifChange = "title";
      //     break;
      //   case CourseEditableFields.TERM:
      //     notifChange = "term";
      //     break;
      //   case CourseEditableFields.SCHOOL:
      //     notifChange = "school";
      //     break;
      //   case CourseEditableFields.PAYMENT_TYPE:
      //     notifChange = "payment type";
      //     break;
      //   case CourseEditableFields.ENROLLMENT_END_DATE:
      //     notifChange = "enrollment end date";
      //     break;
      //   case CourseEditableFields.START_DATE:
      //     notifChange = "start date";
      //     break;
      //   case CourseEditableFields.END_DATE:
      //     notifChange = "end date";
      //     break;
      //   case CourseEditableFields.NEO_6:
      //     notifChange = "neopolitan 6 setting";
      //     break;
      //   case CourseEditableFields.CAD_64:
      //     notifChange = "cadential 64 setting";
      //     break;
      //   case CourseEditableFields.LMS_TYPE:
      //     notifChange = "LMS type";
      //     break;
      // }
      // dispatch(pushNotification({
      //   severity: 'success',
      //   message: `Course ${notifChange} updated.`
      // }))

      return updatedCourse;
    } catch (e) {
      dispatch(pushErrorNotification(errorMessage(e.data)));
      return rejectWithValue();
    }
  }
);

export const deleteCourse_NEW = createAsyncThunk(
  "courses/deleteCourse",
  async (courseID: number, { dispatch }) => {
    try {
      await API.delete(`/api/courses/${courseID}`);
    } catch (e) {
      dispatch(pushErrorNotification(errorMessage(e.data)));
    }
  }
);

export const activateCourse = createAsyncThunk<
  CourseDetailedInfo,
  Number,
  { rejectValue: void }
>("courses/activateCourse", async (courseID, { dispatch, rejectWithValue }) => {
  try {
    const response = await API.post(`/api/courses/${courseID}/activations`);
    const activatedCourse = response.data.data as CourseDetailedInfo;

    dispatch(
      pushNotification({
        severity: "success",
        message: "Course activated!",
      })
    );

    return activatedCourse;
  } catch (e) {
    dispatch(pushErrorNotification(errorMessage(e.data)));
    return rejectWithValue();
  }
});

// COURSE PERSONNEL

export const getCourseStudents = createAsyncThunk<
  CourseStudent[],
  number,
  { rejectValue: void }
>(
  "courses/getCourseStudents",
  async (courseID, { dispatch, rejectWithValue }) => {
    try {
      const response = await API.get(`/api/courses/${courseID}/students`);
      return response.data.data as CourseStudent[];
    } catch (e) {
      dispatch(pushErrorNotification(errorMessage(e.data)));
      return rejectWithValue();
    }
  }
);

export const getCourseTas = createAsyncThunk<
  CourseInstructorTA[],
  number,
  { rejectValue: void }
>("courses/getCourseTAs", async (courseID, { dispatch, rejectWithValue }) => {
  try {
    const response = await API.get(`/api/courses/${courseID}/tas`);
    return response.data.data as CourseInstructorTA[];
  } catch (e) {
    dispatch(pushErrorNotification(errorMessage(e.data)));
    return rejectWithValue();
  }
});
export const getCourseInstructors = createAsyncThunk<
  CourseInstructorTA[],
  number,
  { rejectValue: void }
>(
  "courses/getCourseInstructors",
  async (courseID, { dispatch, rejectWithValue }) => {
    try {
      const response = await API.get(`/api/courses/${courseID}/instructors`);
      return response.data.data as CourseInstructorTA[];
    } catch (e) {
      dispatch(pushErrorNotification(errorMessage(e.data)));
      return rejectWithValue();
    }
  }
);

export const deleteCourseStudent = createAsyncThunk<
  void,
  { course: CourseDetailedInfo; user: CourseStudent | CourseInstructorTA },
  { rejectValue: void }
>(
  "courses/deleteCourseStudent",
  async ({ course, user }, { dispatch, rejectWithValue }) => {
    try {
      const response = await API.delete(
        `/api/courses/${course.id}/students/${user.user_id}`
      );
      const userName = `${user.first_name} ${user.last_name}`;
      const courseTitle = course.title;
      dispatch(
        pushNotification({
          severity: "success",
          message: `${userName} has been removed as a student.`,
        })
      );
      return response.data.data;
    } catch (e) {
      dispatch(pushErrorNotification(errorMessage(e.data)));
      return rejectWithValue();
    }
  }
);

export const deleteCourseTA = createAsyncThunk<
  void,
  { course: CourseDetailedInfo; user: CourseStudent | CourseInstructorTA },
  { rejectValue: void }
>(
  "courses/deleteCourseTA",
  async ({ course, user }, { dispatch, rejectWithValue }) => {
    try {
      const response = await API.delete(
        `/api/courses/${course.id}/tas/${user.user_id}`
      );
      const userName = `${user.first_name} ${user.last_name}`;
      const courseTitle = course.title;
      dispatch(
        pushNotification({
          severity: "success",
          message: `${userName} has been removed as a TA.`,
        })
      );
      return response.data.data;
    } catch (e) {
      dispatch(pushErrorNotification(errorMessage(e.data)));
      return rejectWithValue();
    }
  }
);

export const deleteCourseInstructor = createAsyncThunk<
  void,
  { course: CourseDetailedInfo; user: CourseStudent | CourseInstructorTA },
  { rejectValue: void }
>(
  "courses/deleteCourseInstructor",
  async ({ course, user }, { dispatch, rejectWithValue }) => {
    try {
      const response = await API.delete(
        `/api/courses/${course.id}/instructors/${user.user_id}`
      );
      const userName = `${user.first_name} ${user.last_name}`;
      const courseTitle = course.title;
      dispatch(
        pushNotification({
          severity: "success",
          message: `${userName} has been removed as an instructor.`,
        })
      );
      return response.data.data;
    } catch (e) {
      dispatch(pushErrorNotification(errorMessage(e.data)));
      return rejectWithValue();
    }
  }
);

// ASSIGNMENTS

export const getCourseAssignments = createAsyncThunk<
  Assignment[],
  number,
  { rejectValue: void }
>(
  "courses/getCourseAssignments",
  async (courseID, { dispatch, rejectWithValue }) => {
    try {
      const response = await API.get(
        `/api/courses/${courseID}/assignments?view-grade=true`
      );
      return response.data.data as Assignment[];
    } catch (e) {
      dispatch(pushErrorNotification(errorMessage(e.data)));
      return rejectWithValue();
    }
  }
);

export const getCourseAssignmentDocuments = createAsyncThunk<
  { documents: Document[]; assignmentID: number },
  number,
  { rejectValue: void }
>(
  "courses/getCourseAssignmentDocuments",
  async (assignmentID, { dispatch, rejectWithValue }) => {
    try {
      const response = await API.get(
        `/api/assignments/${assignmentID}/documents`
      );
      const documents = response.data.data;
      return { documents, assignmentID };
    } catch (e) {
      dispatch(pushErrorNotification(errorMessage(e.data)));
      return rejectWithValue();
    }
  }
);

export const getAssignmentDocumentImagePreviewURL = createAsyncThunk<
  { assignmentIndex: number; documentID: number; previewURL: string },
  { assignmentIndex: number; documentID: number },
  { rejectValue: void }
>(
  "courses/getAssignmentDocumentImagePreviewURL",
  async ({ assignmentIndex, documentID }, { dispatch, rejectWithValue }) => {
    try {
      const response = await API.get(
        `/api/documents/${documentID}/preview-url`
      );
      const previewURL = response.data.data.url;
      return { assignmentIndex, documentID, previewURL };
    } catch (e) {
      dispatch(pushErrorNotification(errorMessage(e.data)));
      return rejectWithValue();
    }
  }
);

export const createAssignment = createAsyncThunk<
  Assignment,
  number,
  { rejectValue: void }
>(
  "courses/createAssignment",
  async (courseID, { dispatch, rejectWithValue }) => {
    try {
      const response = await API.post(`/api/courses/${courseID}/assignments`);
      const assignment = response.data.data as Assignment;
      // when a new assignment is created, the user is automatically redirected to the detail page of said new assignment. Because the URL requires the ID of the new assignment, we have to do the redirect here rather than inside the React component (we can't access the newest assignmentID on the front-end after a successful API call but before the page re-renders; for now this is the simplest way).
      if (response.status === 200) {
        window.location.href = `/course/${courseID}/assignment/${assignment.id}#assignment_created`;
      }
      return assignment;
    } catch (e) {
      dispatch(pushErrorNotification(errorMessage(e.data)));
      return rejectWithValue();
    }
  }
);

export const deleteAssignment = createAsyncThunk(
  "courses/deleteAssignment",
  async (assignment: Assignment, { dispatch }) => {
    try {
      await API.delete(`/api/assignments/${assignment.id}`);
      dispatch(
        pushNotification({
          severity: "success",
          message: `${assignment.title} deleted.`,
        })
      );
    } catch (e) {
      dispatch(pushErrorNotification(errorMessage(e.data)));
    }
  }
);

// API response here returns the assignments in new order BUT the without the documents appended to them (recall this is done separately, after grabbing the documents, via the getCourseAssignmentDocuments action). In this action/thunk, we still make the API call to save the new order in the database but upon a successful response we reorder the array of assignments we passed in (which already each have the documents appended) to match the new order, and send in those assignments when we update the Redux store.

export const updateCourseAssignmentOrder = createAsyncThunk<
  Assignment[],
  { courseID: number; assignments: Assignment[]; newOrder: number[] },
  { rejectValue: void }
>(
  "assignments/updateCourseAssignmentOrder",
  async (
    { courseID, assignments, newOrder },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await API.post(
        `/api/courses/${courseID}/assignments/weight`,
        { assignments: newOrder }
      );
      // create a shallow copy of the assignments we passed in so we can mutate it.
      const orderedAssignments = [...assignments];
      // create a 'dictionary' consisting of an object with key/value pairs of the assignmentID and its position in the array (this makes the sorting go faster).
      const assignmentPositions = {};
      for (const [index, id] of newOrder.entries()) {
        assignmentPositions[id] = index;
      }
      // use the ID/index 'dictionary' to sort the array of assignments by id so they match the new order, then pass them in when we dispatch the action to update the Redux store.
      orderedAssignments.sort(
        (a, b) => assignmentPositions[a.id] - assignmentPositions[b.id]
      );

      dispatch(
        pushNotification({
          severity: "success",
          message: "New assignment order saved.",
        })
      );

      return orderedAssignments;
    } catch (e) {
      dispatch(pushErrorNotification(errorMessage(e.data)));
      return rejectWithValue();
    }
  }
);

export const updateCourseAssignmentOrderLocally = createAsyncThunk<
  Assignment[],
  Assignment[],
  { rejectValue: void }
>(
  "/courses/updateCourseAssignmentOrderLocally",
  async (reorderedAssignments, { dispatch, rejectWithValue }) => {
    try {
      return reorderedAssignments;
    } catch (e) {
      dispatch(pushErrorNotification(errorMessage(e.data)));
      return rejectWithValue();
    }
  }
);

// INVITATIONS

export const getCourseInvitations = createAsyncThunk<
  CourseInvitation[],
  number,
  { rejectValue: void }
>(
  "courses/getCourseInvitations",
  async (courseID, { dispatch, rejectWithValue }) => {
    try {
      const response = await API.get(
        `/api/courses/${courseID}/course-invitations`
      );
      return response.data.data as CourseInvitation[];
    } catch (e) {
      dispatch(pushErrorNotification(errorMessage(e.data)));
      return rejectWithValue();
    }
  }
);

export const createCourseInvitation = createAsyncThunk<
  void,
  { role: string; emails: string[]; course: number },
  { rejectValue: void }
>(
  "/courses/createCourseInvitation",
  async ({ role, emails, course }, { dispatch, rejectWithValue }) => {
    try {
      // create new invitation...
      const response = await API.post(`/api/course-invitations`, {
        role,
        emails,
        course,
      });
      const plural = emails.length > 1 ? "s" : "";
      dispatch(
        pushNotification({
          severity: "success",
          message: `${role}${plural} invited to course!`,
        })
      );
      //... and then get course invitations immediately after to display new invitaiton.
      dispatch(getCourseInvitations(course));
    } catch (e) {
      dispatch(pushErrorNotification(errorMessage(e.data)));
      return rejectWithValue();
    }
  }
);

export const removeCourseInvitation = createAsyncThunk(
  "courses/removeCourseInvitation",
  async (invitation: CourseInvitation, { dispatch }) => {
    try {
      const response = await API.delete(
        `/api/course-invitations/${invitation.id}`
      );
      dispatch(
        pushNotification({
          severity: "success",
          message: `Invitation to ${invitation.invitee_email} removed.`,
        })
      );
    } catch (e) {
      dispatch(pushErrorNotification(e.data));
    }
  }
);

// ENROLLMENT REQUESTS

export const getCourseEnrollmentRequestsByCourseID = createAsyncThunk<
  CourseEnrollmentRequest[],
  number,
  { rejectValue: void }
>(
  "courses/getCourseEnrollmentRequests",
  async (courseID, { dispatch, rejectWithValue }) => {
    try {
      const response = await API.get(
        `/api/courses/${courseID}/course-enrollment-requests`
      );
      return response.data.data as CourseEnrollmentRequest[];
    } catch (e) {
      dispatch(pushErrorNotification(errorMessage(e.data)));
      return rejectWithValue();
    }
  }
);

export const acceptCourseEnrollmentRequests = createAsyncThunk<
  void,
  { requestIDs: number[]; courseID: number },
  { rejectValue: void }
>(
  "courses/acceptCourseEnrollmentRequests",
  async ({ requestIDs, courseID }, { dispatch, rejectWithValue }) => {
    try {
      // API call doesn't return any student obj(s), so rather than appending new student(s) after we make this request we simply grab all the students again (which now includes any newly added students ) using the courseID also passed into this function.
      const response = await API.post("/api/course-enrollment-admissions", {
        course_enrollment_request_ids: requestIDs,
      });
      dispatch(getCourseStudents(courseID));

      let messageConfirm = (
        requestIDs.length > 1 ? `${requestIDs.length} Students` : "Student"
      ).concat(" added to course!");
      dispatch(
        pushNotification({
          severity: "success",
          message: messageConfirm,
        })
      );
    } catch (e) {
      dispatch(pushErrorNotification(errorMessage(e.data)));
      return rejectWithValue();
    }
  }
);

export const rejectCourseEnrollmentRequests = createAsyncThunk(
  "courses/rejectCourseEnrollmentRequests",
  async (requests: CourseEnrollmentRequest[], { dispatch }) => {
    try {
      const requestIDs = requests.map((request) => request.id);
      const response = await API.post("/api/course-enrollment-rejections", {
        course_enrollment_request_ids: requestIDs,
      });
      let messageConfirm;
      if (requests.length > 1) {
        messageConfirm =
          requests.length + " enrollment requests were declined.";
      } else {
        messageConfirm = `${requests[0].student.first_name} ${requests[0].student.last_name}'s request was declined.`;
      }

      dispatch(
        pushNotification({
          severity: "success",
          message: messageConfirm,
        })
      );
    } catch (e) {
      dispatch(pushErrorNotification(errorMessage(e.data)));
    }
  }
);

// SUBMISSIONS

export const getSubmissionsByAssignmentID = createAsyncThunk<
  Submission[],
  number,
  { rejectValue: void }
>(
  "/courses/getSubmissionsByAssignmentID",
  async (assignmentID, { dispatch, rejectWithValue }) => {
    try {
      const response = await API.get(
        `/api/assignments/${assignmentID}/submissions`
      );
      return response.data.data as Submission[];
    } catch (e) {
      dispatch(pushErrorNotification(errorMessage(e.data)));
      return rejectWithValue();
    }
  }
);

export const getSubmissionsByCourseID = createAsyncThunk<
  Submission[],
  { userID: number; courseID: number },
  { rejectValue: void }
>(
  "/courses/getSubmissionsByCourseID",
  async ({ userID, courseID }, { dispatch, rejectWithValue }) => {
    try {
      const response = await API.get(
        `/api/user/${userID}/courses/${courseID}/submissions`
      );
      return response.data.data as Submission[];
    } catch (e) {
      dispatch(pushErrorNotification(errorMessage(e.data)));
      return rejectWithValue();
    }
  }
);

export const getSubmissionsByCourseIDWOUser = createAsyncThunk<
  Submission[],
  number,
  { rejectValue: void }
>(
  "/courses/getSubmissionsByCourseIDWOUser",
  async (courseID, { dispatch, rejectWithValue }) => {
    try {
      const response = await API.get(
        `/api/courses/${courseID}/submissions`
      );
      return response.data.data as Submission[];
    } catch (e) {
      dispatch(pushErrorNotification(errorMessage(e.data)));
      return rejectWithValue();
    }
  }
);

// these functions take in the same arguments and make the same API call. however, the state they update in the Redux store is different (either submissionsByUser or submissionsByAssignment);
export const deleteSubmissionOnStudentTable = createAsyncThunk<
  void,
  { user: { name: string; id: number }; document: number },
  { rejectValue: void }
>(
  "courses/deleteSubmissionOnStudentTable",
  async ({ user, document }, { dispatch, rejectWithValue }) => {
    try {
      // Not sure how to get "selectedUser prop in here"
      await API.delete(
        `/api/user/${user.id}/documents/${document}/submissions`
      );
      const formattedName = formatStudentName(user.name);
      dispatch(
        pushNotification({
          severity: "success",
          message: `${formattedName}'s submission was removed.`,
        })
      );
    } catch (e) {
      dispatch(pushErrorNotification(errorMessage(e.data)));
      return rejectWithValue();
    }
  }
);

export const deleteSubmissionOnAssignmentDocTable = createAsyncThunk<
  void,
  { user: { name: string; id: number }; document: number },
  { rejectValue: void }
>(
  "courses/deleteSubmissionOnAssignmentTable",
  async ({ user, document }, { dispatch, rejectWithValue }) => {
    try {
      await API.delete(
        `/api/user/${user.id}/documents/${document}/submissions`
      );
      // const formattedName = formatStudentName(user.name);
      dispatch(
        pushNotification({
          severity: "success",
          message: `${user.name}'s submission was removed.`,
        })
      );
    } catch (e) {
      dispatch(pushErrorNotification(errorMessage(e.data)));
      return rejectWithValue();
    }
  }
);

// Function to delete a single submission by ID
export const deleteSubmissionByIDOnStudentTable = createAsyncThunk<
  void,
  { submissionID: number; username: string },
  { rejectValue: void }
>(
  "courses/deleteSubmissionByIDOnStudentTable",
  async ({ submissionID, username }, { dispatch, rejectWithValue }) => {
    try {
      await API.delete(`/api/submissions/${submissionID}`);
      const formattedName = formatStudentName(username);
      dispatch(
        pushNotification({
          severity: "success",
          message: `${formattedName}'s submission was removed.`,
        })
      );
    } catch (e) {
      dispatch(pushErrorNotification(errorMessage(e.data)));
      return rejectWithValue();
    }
  }
);

export const deleteSubmissionByIDOnAssignmentDocTable = createAsyncThunk<
  void,
  { submissionID: number; username: string },
  { rejectValue: void }
>(
  "courses/deleteSubmissionByIDOnAssignmentTable",
  async ({ submissionID, username }, { dispatch, rejectWithValue }) => {
    try {
      await API.delete(`/api/submissions/${submissionID}`);
      // const formattedName = formatStudentName(username);
      dispatch(
        pushNotification({
          severity: "success",
          message: `${username}'s submission was removed.`,
        })
      );
    } catch (e) {
      dispatch(pushErrorNotification(errorMessage(e.data)));
      return rejectWithValue();
    }
  }
);


// Fastspring Payment
export const makeFastspringPurchase = createAsyncThunk<
  number,
  { orderID: string; reference: string; courseID: number },
  { rejectValue: void }
>(
  "/courses/makeFastspringPurchase",
  async ({ orderID, reference, courseID }, { dispatch, rejectWithValue }) => {
    try {
      const response = await API.post(`/api/fastspringpurchase`, {
        orderID,
        reference,
        courseID,
      });
      const paymentID = response.data.data as number;
      dispatch(
        pushNotification({
          severity: "success",
          message: "Course fee payment successful.",
        })
      );
      return paymentID;
    } catch (e) {
      dispatch(pushErrorNotification(errorMessage(e.data)));
      return rejectWithValue();
    }
  }
);

const selectedCourseSlice = createSlice({
  name: "selectedCourse",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCourseDetail.fulfilled, (state, { payload }) => {
        state.courseInformation = payload;
      })
      .addCase(activateCourse.fulfilled, (state, { payload }) => {
        state.courseInformation = payload;
      })
      .addCase(getCourseStudents.fulfilled, (state, { payload }) => {
        state.students = payload;
      })
      .addCase(getCourseTas.fulfilled, (state, { payload }) => {
        state.tas = payload;
      })
      .addCase(getCourseInstructors.fulfilled, (state, { payload }) => {
        state.instructors = payload;
      })
      .addCase(deleteCourseStudent.fulfilled, (state, { meta }) => {
        const studentsWithoutDeleted = state.students?.filter(
          (student) => student.user_id !== meta.arg.user.user_id
        );
        state.students = studentsWithoutDeleted;
      })
      .addCase(deleteCourseTA.fulfilled, (state, { meta }) => {
        const tasWithoutDeleted = state.tas?.filter(
          (ta) => ta.user_id !== meta.arg.user.user_id
        );
        state.tas = tasWithoutDeleted;
      })
      .addCase(deleteCourseInstructor.fulfilled, (state, { meta }) => {
        const instructorsWithoutDeleted = state.instructors?.filter(
          (instructor) => instructor.user_id !== meta.arg.user.user_id
        );
        state.instructors = instructorsWithoutDeleted;
      })
      .addCase(getCourseAssignments.fulfilled, (state, { payload }) => {
        state.assignments = payload;
      })
      .addCase(getCourseAssignmentDocuments.fulfilled, (state, { payload }) => {
        const newAssignments = state.assignments?.map((assignment) => {
          return assignment.id === payload.assignmentID
            ? { ...assignment, documents: payload.documents }
            : assignment;
        });
        state.assignments = newAssignments;
      })
      .addCase(
        getAssignmentDocumentImagePreviewURL.fulfilled,
        (state, { payload }) => {
          // payload: {assignmentIndex, documentID, previewURL}
          // deconstruct payload
          const { assignmentIndex, documentID, previewURL } = payload;
          if (!state.assignments) state.assignments = [];
          const assignments = state.assignments;
          // go through proper assignment documents and make a new array consisting of existing docs but append the docPreviewURL to the proper document in docs array.
          const newDocArray = assignments[assignmentIndex].documents?.map(
            (doc) => {
              return doc.id === documentID
                ? { ...doc, previewURL: previewURL }
                : doc;
            }
          );
          // reconstruct assignment obj with new documents array.
          const assignmentWithDocPreview = {
            ...assignments[assignmentIndex],
            documents: newDocArray,
          };
          // remove old assignment obj and add new one
          assignments.splice(assignmentIndex, 1, assignmentWithDocPreview);

          state.assignments = assignments;
        }
      )
      .addCase(createAssignment.fulfilled, (state, { payload }) => {
        if (!state.assignments) state.assignments = [];
        state.assignments = [...state.assignments, payload];
      })
      .addCase(deleteAssignment.fulfilled, (state, { meta }) => {
        const assignmentsWithoutDeleted = state.assignments?.filter(
          (assignment) => assignment.id !== meta.arg.id
        );
        state.assignments = assignmentsWithoutDeleted;
      })
      .addCase(updateCourseSettings.fulfilled, (state, { payload }) => {
        state.courseInformation = payload;
      })
      .addCase(
        updateCourseAssignmentOrderLocally.fulfilled,
        (state, { payload }) => {
          state.assignments = payload;
        }
      )
      .addCase(updateCourseAssignmentOrder.fulfilled, (state, { payload }) => {
        state.assignments = payload;
      })
      .addCase(getCourseInvitations.fulfilled, (state, { payload }) => {
        state.invitations = payload;
      })
      .addCase(removeCourseInvitation.fulfilled, (state, { meta }) => {
        const invitationsWithoutRemoved = state.invitations?.filter(
          (invitation) => invitation.id !== meta.arg.id
        );
        state.invitations = invitationsWithoutRemoved;
      })
      .addCase(
        getCourseEnrollmentRequestsByCourseID.fulfilled,
        (state, { payload }) => {
          state.enrollmentRequests = payload;
        }
      )
      .addCase(acceptCourseEnrollmentRequests.fulfilled, (state, { meta }) => {
        const requestsWithoutAccepted = state.enrollmentRequests?.filter(
          (request) => {
            return !meta.arg.requestIDs.includes(request.id);
          }
        );
        state.enrollmentRequests = requestsWithoutAccepted;
      })
      .addCase(rejectCourseEnrollmentRequests.fulfilled, (state, { meta }) => {
        const requestsWithoutRejected = state.enrollmentRequests?.filter(
          (request) => {
            return !meta.arg.includes(request);
          }
        );
        state.enrollmentRequests = requestsWithoutRejected;
      })
      .addCase(getSubmissionsByAssignmentID.fulfilled, (state, { payload }) => {
        state.submissionsByAssignment = payload;
      })
      .addCase(getSubmissionsByCourseIDWOUser.fulfilled, (state, { payload }) => {
        state.submissionsByAssignment = payload;
      })
      .addCase(getSubmissionsByCourseID.fulfilled, (state, { payload }) => {
        state.submissionsByUser = payload;
      })
      .addCase(deleteSubmissionOnStudentTable.fulfilled, (state, { meta }) => {
        // console.log("before: " + state.submissionsByUser?.length);
        // console.log("meta: " + JSON.stringify(meta, null, 2));
        const submissionsWithoutDeleted = state.submissionsByUser?.filter((submission) => {
            // console.log("submission: " + JSON.stringify(submission, null, 2));
            // console.log("submission.document: " + JSON.stringify(submission.document, null, 2));
            return submission.document !== meta.arg.document;
        });
        state.submissionsByUser = submissionsWithoutDeleted;
        // console.log("after: " + state.submissionsByUser?.length);
      })
      .addCase(deleteSubmissionByIDOnStudentTable.fulfilled, (state, { meta }) => {
        // console.log("before: " + state.submissionsByUser?.length);
        // console.log("meta: " + JSON.stringify(meta, null, 2));
        const submissionsWithoutDeleted = state.submissionsByUser?.filter((submission) => {
            // console.log("submission: " + JSON.stringify(submission, null, 2));
            // console.log("submission.document: " + JSON.stringify(submission.document, null, 2));
            return submission.id !== meta.arg.submissionID;
        });
        state.submissionsByUser = submissionsWithoutDeleted;
        // console.log("after: " + state.submissionsByUser?.length);
      })
      .addCase(deleteSubmissionOnAssignmentDocTable.fulfilled, (state, { meta }) => {
          // console.log("before: " + state.submissionsByAssignment?.length);
          const submissionsWithoutDeleted = state.submissionsByAssignment?.filter((submission) => {
              return submission.student !== meta.arg.user.id;
          });
          state.submissionsByAssignment = submissionsWithoutDeleted;
          // console.log("after: " + state.submissionsByAssignment?.length);
        }
      )
      .addCase(deleteSubmissionByIDOnAssignmentDocTable.fulfilled, (state, { meta }) => {
          // console.log("before: " + state.submissionsByAssignment?.length);
          const submissionsWithoutDeleted = state.submissionsByAssignment?.filter((submission) => {
              return submission.id !== meta.arg.submissionID;
          });
          state.submissionsByAssignment = submissionsWithoutDeleted;
          // console.log("after: " + state.submissionsByAssignment?.length);
        }
      )
      .addCase(makeFastspringPurchase.fulfilled, (state, { payload }) => {
        const updatedCourseInformation = {
          ...state.courseInformation,
          payment_id: payload,
        } as CourseDetailedInfo;
        state.courseInformation = updatedCourseInformation;
      });
  },
});
export default selectedCourseSlice.reducer;
