import React, { useEffect, useState } from 'react';
import { useMediaQuery, useToggle } from '@harmonia-front-end/shared';
import { useHarmoniaSelector } from '../../redux';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import DashboardContainerStyle from './DashboardContainer.module.scss';
import DashboardSidebarDropdown from './DashboardSidebarDropdown';
// import layout from '@harmonia-front-end/shared/styles/lib/_layout.scss';

const {
  dashboardContainer, sidebar, content, navBar, menuIcon, mainContent
} = DashboardContainerStyle;

type DashboardContainerProps = React.PropsWithChildren<{}>;

function DashboardContainer(props: DashboardContainerProps) {
  const { children } = props;
  const [isDown, toggleDown] = useToggle();
  // TODO: consider import this $md width directly from the scss file.
  const isLessThanMedium = useMediaQuery('(min-width: 768px)');
  const showContent = !isLessThanMedium ? isDown : true;

  // If the URL bar says 'course/${courseID}', the user has clicked on a course/is on a course page, and the course dropdown should be shown.
  const location = useLocation();
  
  // Slicing off first '/' and splitting pathname by '/'s into an array so we can access the word 'course' and the courseID.
  const pathname = location.pathname.slice(1).split('/');
  const onCoursePage = pathname[0] === 'course' || pathname[0] === 'student';
  const courseID = parseInt(pathname[0] ==='student' ? pathname[2] : pathname[1], 10);

  const courseTitle = useHarmoniaSelector(state => state.selectedCourse.courseInformation?.title);

  const [offset, setOffset] = useState(0);

  const courses = useHarmoniaSelector(state => state.courses)
  const isTA = !!courses?.course_tas?.find(course => course.id === courseID);

  useEffect(() => {
    const onScroll = () => {
      if (window.innerWidth > 767 && window.pageYOffset < 110) {
        setOffset(window.pageYOffset);
      } else {
        setOffset(110);
      }
    }
    window.removeEventListener("scroll", onScroll)
    window.addEventListener("scroll", onScroll, {passive: true})
    return () => window.removeEventListener("scroll", onScroll)
  }, [])

  return (
    <div className={dashboardContainer}>
        <div className={sidebar} style={{top: 110 - offset}}>
          <div className={navBar}>
            Dashboard
            <FontAwesomeIcon icon={faBars} className={menuIcon} size="2x" onClick={toggleDown} />
          </div>
          <div className={content} style={{ display: showContent ? 'initial' : 'none' }}>
            <DashboardSidebarDropdown type="dashboard" />
          </div>
          {onCoursePage &&
            <div className={content} style={{ display: showContent ? 'initial' : 'none' }}>
              <DashboardSidebarDropdown h1={courseTitle} type="course" courseID={courseID} isTA={isTA} />
            </div>
          }
        </div>
        <div className={mainContent}>
          {children}
        </div>
      </div>
  );
}

export default DashboardContainer;
