import React from 'react';
import DatePicker from 'react-datepicker';
import { DueDateExtension, DueDateExtensionAction } from '../../types';
import {
 useHarmoniaDispatch,  createOrUpdateAssignmentDueDateExtension, deleteAssignmentDueDateExtension
} from '../../redux';
import style from './AssignmentInfo.module.scss';
import {faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const { dueDateExtensionItem, dueDateExtInput } = style;

type DueDateExtensionItemProps = {
  extension: DueDateExtension,
  assignmentDueDate: number,
  courseEndDate: number,
  assignmentID: number,
}

const DueDateExtensionItem = (props: DueDateExtensionItemProps) => {
  const {extension, assignmentDueDate, courseEndDate, assignmentID} = props;

  const oneYear = 86400 * 365;

  const dispatch = useHarmoniaDispatch();
  const minDate = new Date(assignmentDueDate * 1000);
  const maxDate = new Date(courseEndDate * 1000);
  // for now, max date for due-date-extension is set for 6 months after course end.
  maxDate.setMonth(maxDate.getMonth() + 6);

  const selectedDate = new Date(extension.extension_due_at * 1000);
  // function that takes in a selected date and updates due-date on the extension.
  const handleDueDateChange = (date: Date) => {
    // convert date to timestamp and then dispatch proper payload to change due date.
    const timestamp = date.getTime() / 1000;
    const dueDatePayload = {assignmentID, studentID: extension.student, timestamp, action: DueDateExtensionAction.UPDATE};
    dispatch(createOrUpdateAssignmentDueDateExtension(dueDatePayload));
  };
  // constructing payload for deleting.
  const deletePayload = {assignmentID, studentID: extension.student}
  return (
    <div className={dueDateExtensionItem}>
      <div>{`${extension.last_name}, ${extension.first_name}`}</div>
      <div>
      <DatePicker
      selected={selectedDate}
      minDate={minDate}
      maxDate={maxDate}
      onChange={handleDueDateChange}
      showTimeSelect
      dateFormat="Pp"
      className={dueDateExtInput}
      filterTime={(t: any) => { return (minDate === undefined || t >= minDate) && (maxDate === undefined || t <= maxDate); }}
      />
      </div>
      <div>
        <FontAwesomeIcon icon={faTrashAlt} onClick={() => {dispatch(deleteAssignmentDueDateExtension(deletePayload))}}/>
      </div>
    </div>
  )

}
export default DueDateExtensionItem;
