/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { HarmoniaButton, HarmoniaInput, HarmoniaPaper } from '@harmonia-front-end/shared';
import { updateUserInfo, useHarmoniaDispatch } from '../../redux';
import { DashboardContent, DashboardTitleBar } from '../../components/template';
import LoadingPage from '../../components/LoadingPage';
import { useHarmoniaLanguages, useHarmoniaTimezones, useUser } from '../../hooks';
import styles from './UserInfo.module.scss';
import { User, UserType } from '../../types';
import { Modal } from 'reactstrap';
const { paper, form, labelInput, checkBox, buttonWrapper, noti, hide } = styles;

export default function MyProfileEditPage() {
  const dispatch = useHarmoniaDispatch();
  const [user, userLoading] = useUser();
  const [timezones, timezonesLoading] = useHarmoniaTimezones();
  const [languages, languagesLoading] = useHarmoniaLanguages();
  const [passwordModal, setPasswordModal] = useState(false);
  const [userInfo, setUserInfo] = useState({
    id: -1,
    user_type: UserType.STUDENT,
    first_name: '',
    last_name: '',
    email: '',
    will_receive_marketing_emails: true,
    will_receive_notification_emails: true,
    has_pending_teacher_account_request: true,
    timezone: -1,
    language: '',
    uuid: ''
  } as User);

  useEffect(() => {
    if (user?.id) {
      setUserInfo(user);
    }
    if (passwordModal) {
      setPasswordModal(false);
    }
  }, [user])

  const handleEmailSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const submission = {
      ...user,
      ...Object.fromEntries<any>(new FormData(e.currentTarget).entries())
    }
    delete submission.id;
    delete submission.uuid;
    delete submission.user_type;
    dispatch(updateUserInfo(submission));
  }

  const handleUserInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserInfo({...userInfo, [e.target.name]: e.target.value});
  }

  const handleInputEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      (e.target as HTMLInputElement).blur();
    }
  }

  const handleInputSubmit = (e: React.ChangeEvent<HTMLInputElement> | React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const submission = {
      ...user,
      [e.target.name]: e.target.value
    };
    delete submission.id;
    delete submission.uuid;
    delete submission.user_type;
    dispatch(updateUserInfo(submission));
  }

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const submission = {
      ...user,
      [e.target.name]: (e.target as HTMLInputElement).checked ? true : false
    };
    setUserInfo(submission as User)
    delete submission.id;
    delete submission.uuid;
    delete submission.user_type;
    dispatch(updateUserInfo(submission));
  }

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const submission = {
      ...user,
      [e.target.name]: e.target.name === "timezone" ? parseInt(e.target.value) : e.target.value
    };
    setUserInfo(submission as User);
    delete submission.id;
    delete submission.uuid;
    delete submission.user_type;
    dispatch(updateUserInfo(submission));
  }

  const openEmailModal = (e) => {
    e.preventDefault();
    if (user.email !== userInfo.email && !passwordModal) {
      setPasswordModal(true);
    }
  }

  const closeEmailModal = (e) => {
    e.preventDefault();
    setPasswordModal(false);
    setUserInfo({ ...user, email: user.email })
  }

  if (userLoading || timezonesLoading || languagesLoading) return <DashboardContent><LoadingPage /></DashboardContent>;
  if (!user) return null;
  return (
    <>
      <DashboardTitleBar h1="Edit Profile" />
      <DashboardContent>
        <HarmoniaPaper rounded className={paper}>
          <div className={labelInput}>
            <label htmlFor="email">Email</label>
            <input
              name="email"
              type="email"
              value={userInfo.email}
              onChange={handleUserInputChange}
              onKeyUp={handleInputEnter}
              onBlur={openEmailModal}
            />
          </div>
          
          
          <Modal isOpen={passwordModal}>
            <form className={form} onSubmit={handleEmailSubmit}>
              <div className={labelInput}>
                <label htmlFor="email">Email</label>
                <input
                  name="email"
                  type="email"
                  value={userInfo.email}
                  onChange={handleUserInputChange}
                />
              </div>
              <div className={labelInput}>
                <label htmlFor="current_password">Please Enter Current Password to Change Email</label>
                <input
                  name="current_password"
                  type="password"
                  placeholder="Current Password"
                />
                <input type='submit' className={hide}/>
              </div>
              <div className={buttonWrapper}>
                <HarmoniaButton variant="secondary" dest="app" onClick={closeEmailModal}>
                  <FormattedMessage
                    id="close-modal"
                    defaultMessage="Cancel"
                  />
                </HarmoniaButton>
                <HarmoniaButton type='submit' variant="primary" dest="app">
                  <FormattedMessage
                    id="submit-email"
                    defaultMessage="Submit"
                  />
                </HarmoniaButton>
              </div>
            </form>
          </Modal>
          
          <div className={labelInput}>
            <label htmlFor="first_name">First Name</label>
            <input
              name="first_name"
              id="first_name"
              type="text"
              value={userInfo.first_name}
              onChange={handleUserInputChange}
              onBlur={handleInputSubmit}
              onKeyUp={handleInputEnter}
              className="first_name"
            />
          </div>
          

          <div className={labelInput}>
            <label htmlFor="last_name">Last Name</label>
            <input
              name="last_name"
              id="last_name"
              type="text"
              value={userInfo.last_name}
              onChange={handleUserInputChange}
              onBlur={handleInputSubmit}
              onKeyUp={handleInputEnter}
              className="last_name"
            />
          </div>

          
          <div className={labelInput}>
            <label htmlFor="timezone">Timezone</label>
            <select name="timezone" value={userInfo.timezone} onChange={handleSelectChange} u>
              {timezones?.map(({ id, timezone: timezoneString }) => (
                <option key={id} value={id}>{timezoneString}</option>
              ))}
            </select>
          </div>
          
          <div className={labelInput}>
            <label htmlFor="language">Language</label>
            <select name="language" value={userInfo.language} onChange={handleSelectChange}>
              {languages?.map(({ abbreviation, language: languageString }) => (
                <option key={abbreviation} value={abbreviation}>{languageString}</option>
              ))}
            </select>
          </div>
          

          <div className={noti}>
            <div className={labelInput}>
              <label htmlFor="will_receive_notification_emails" className={checkBox}>Receive Notification Emails</label>
              <input
                name="will_receive_notification_emails"
                type="checkbox"
                checked={userInfo.will_receive_notification_emails}
                onChange={handleCheckboxChange}
              />
            </div>
            <div className={labelInput}>
              <label htmlFor="will_receive_marketing_emails" className={checkBox}>Receive Marketing Emails</label>
              <input
                name="will_receive_marketing_emails"
                type="checkbox"
                checked={userInfo.will_receive_marketing_emails}
                onChange={handleCheckboxChange}
              />
            </div>
          </div>


          <Link to="/my-profile">
            <HarmoniaButton variant="primary" dest="app" iconPos='left' icon='back'>
              <FormattedMessage
                id="user.profile"
                defaultMessage="Back to Profile"
              />
            </HarmoniaButton>
          </Link>
        </HarmoniaPaper>
      </DashboardContent>
    </>
  );
}
