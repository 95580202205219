import React, {useState} from 'react';
import { useHarmoniaDispatch, acceptCourseEnrollmentRequests, rejectCourseEnrollmentRequests } from '../../redux';
import {
  HarmoniaButton,
  HarmoniaInput,
  HarmoniaPaper,
  Text,
  useToggle
} from "@harmonia-front-end/shared";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import {faCheckCircle,faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { CourseEnrollmentRequest} from '../../types';
import style from '../../pages/course/PeoplePage.module.scss';
const {coursePeopleContainer, peopleTop, coursePeople, coursePeopleItem, emailName, coursePeopleIcons, peopleIcon, accept, remove} = style;

export type CourseEnrollmentRequestsProps = {
  enrollmentRequests: CourseEnrollmentRequest[],
  courseID: number,
  isTA: boolean
}

const CourseEnrollmentRequests = (props: CourseEnrollmentRequestsProps) => {
  const {courseID, enrollmentRequests, isTA} = props;

  // grouping all IDs together for "Accept All" functionality
  const allRequestIDs = enrollmentRequests.map(request => request.id);

  const dispatch = useHarmoniaDispatch();

  // ACCEPTING ENROLLMENT REQUESTS

  const acceptEnrollmentRequests = (requestIDs: number[]) => () => {
    dispatch(acceptCourseEnrollmentRequests({requestIDs, courseID}))
  }

  // REJECTING ENROLLMENT REQUESTS

  // local state for opening/closing reject modal
  const [rejectDialogOpen, toggleRejectDialog] = useToggle();
  // local state for setting request to reject as local state. We do this to have direct access to the relevant request ID and first/last name outside of our map function in our render, which we need for the vertification process with the modal for rejecting a request.
  const [requestToDelete, setRequestToDelete] = useState({}) as [CourseEnrollmentRequest, React.Dispatch<React.SetStateAction<{}>>];

  // const name = requestToDelete.student.first_name;

  // function to show reject modal. opens the modal and sets local state for rejecting with relevant requestID.
  const showRejectModal = (request: CourseEnrollmentRequest) => {
    toggleRejectDialog();
    setRequestToDelete(request);
  }
  // handler for rejecting enrollment request: closes reject modal, makes API request and resets local state for selected requestID.
  const confirmRejectEnrollmentRequest = (request: CourseEnrollmentRequest) => {
    toggleRejectDialog();
    dispatch(rejectCourseEnrollmentRequests([request])).finally(() => setRequestToDelete({}));
  }
  return (
    <>
    <HarmoniaPaper className={coursePeopleContainer}>
      <div className={peopleTop}>
        <Text color="harmoniaGray" variant="dashboardContentHeading">Enrollment Requests</Text>
                  {(!isTA && <HarmoniaButton variant="primary" dest="app" icon="check" iconPos="left" onClick={acceptEnrollmentRequests(allRequestIDs)} >Accept All Requests</HarmoniaButton>)}
      </div>
      <div className={coursePeople}>
        {enrollmentRequests?.map((request, index) => {
          const {first_name, last_name, email} = request.student;
          return (
            <div key={index} className={coursePeopleItem} data-cy="enrollment-request">
              <div className={emailName}>
              <Text color="dark">
                {`${last_name}, ${first_name}`}
              </Text>
              </div>
              { !isTA && (
              <div className={coursePeopleIcons}>
                <div
                  className={`${peopleIcon} ${accept}`} onClick={acceptEnrollmentRequests([request.id])}
                  data-cy="accept-request">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  <Text>Accept</Text>
                </div>
                <div
                  className={`${peopleIcon} ${remove}`}
                  onClick={() => showRejectModal(request)} data-cy="reject-request">
                  <FontAwesomeIcon icon={faTimesCircle} />
                  <Text>Decline</Text>
                </div>
              </div>
              )}
            </div>
          )
        })}
      </div>
    </HarmoniaPaper>
    {/* REJECT ENROLLMENT REQUEST MODAL */}
    <Modal isOpen={rejectDialogOpen}>
      <ModalHeader>Decline Enrollment Request</ModalHeader>
      <ModalBody>
          <Text color="dark">
            {
              Object.keys(requestToDelete).length &&
            `Are you sure you want to decline ${requestToDelete.student.first_name} ${requestToDelete.student.last_name}'s request to enroll in this course?`
            }
          </Text>
      </ModalBody>
      <ModalFooter>
        <HarmoniaButton variant="secondary" dest="app" onClick={toggleRejectDialog}>Cancel</HarmoniaButton>
        <HarmoniaButton variant="primary" dest="app" onClick={ ()=> {confirmRejectEnrollmentRequest(requestToDelete)}} data-cy="confirm-reject" >Decline Request</HarmoniaButton>
      </ModalFooter>
    </Modal>
    </>
  )
};
export default CourseEnrollmentRequests;
