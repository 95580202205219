/* eslint-disable react/jsx-props-no-spreading,react/button-has-type */
import React from 'react';
import { Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import {
  faDownload, faUpload, faArrowRight, faArrowLeft, faPlus, faTrashAlt, faEye,
  faEdit, faSearch, faCopy, faEnvelope, faTimes, faKey, faBars, faCheck, faPlayCircle, faVolumeUp
} from '@fortawesome/free-solid-svg-icons';
import HarmoniaButtonStyle from './HarmoniaButton.module.scss';

const {
  button, primary, secondary, app, web, launch, loading, iconClass, iconButton, circle, disabled
} = HarmoniaButtonStyle;

type HarmoniaButtonProps = {
  /**
   * Where the button is for. It controls the button color.
   * 'app' is for Harmonia Cloud Dashboard, and 'web' is for other sites.
   */
  dest: 'app' | 'web';


  disabled?: boolean;

  /**
   * Button variant. It controls the button display style.
   * To use the last four variants: 'warning', 'error', 'info', and 'success',
   * Bootstrap stylesheet is required.
   */
  variant: 'primary' | 'secondary' | 'icon' | 'warning' | 'error' | 'info' | 'success' | 'loading' | 'launch' | 'disabled';

  /** Font Awesome icon display. */
  icon?: 'download' | 'upload' | 'next' | 'back' | 'add-to' | 'delete'
  | 'preview' | 'edit' | 'search' | 'duplicate' | 'email' | 'close' | 'key' | 'bars' | 'check' | 'activate' | 'listen' | undefined;

  /** Size of the icon. Can only be applied to icon button variants. */
  iconSize?: SizeProp;

  /** Whether the icon is at the left or right. */
  iconPos?: 'left' | 'right';

  /** Shape of the button. Optional (if not specified is regular button shape) */

  shape?: 'circle' | 'regular';

} & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

// TODO: carefully condition button styles to match bootstrap in the dev playground.

/**
 * Button component that complies HarmoniaCloud prototype.
 * Expected to use this button component instead of HTML intrinsic button.
 *
 * All HTML button props can be passed through and applied.
 */
function HarmoniaButton(props: HarmoniaButtonProps) {
  const {
    dest, variant, icon, iconPos, className, children, iconSize, shape, ...rest
  } = props;

  const faIcon = (() => {
    if (icon === 'add-to') return faPlus;
    if (icon === 'back') return faArrowLeft;
    if (icon === 'delete') return faTrashAlt;
    if (icon === 'download') return faDownload;
    if (icon === 'duplicate') return faCopy;
    if (icon === 'edit') return faEdit;
    if (icon === 'email') return faEnvelope;
    if (icon === 'next') return faArrowRight;
    if (icon === 'preview') return faEye;
    if (icon === 'search') return faSearch;
    if (icon === 'upload') return faUpload;
    if (icon === 'close') return faTimes;
    if (icon === 'key') return faKey;
    if (icon === 'bars') return faBars;
    if (icon === 'check') return faCheck;
    if (icon === 'activate') return faPlayCircle;
    if (icon === 'listen') return faVolumeUp;
    return undefined;
  })();

  const classList = className ? className.split(' ') : [];
  classList.push(button);
  classList.push(dest === 'app' ? app : web);

  if(shape === 'circle') classList.push(circle)

  if (variant === 'primary') classList.push(primary);
  if (variant === 'secondary') classList.push(secondary);
  if (variant === 'warning') classList.push('btn-warning'); // Follow Bootstrap config
  if (variant === 'error') classList.push('btn-danger'); // Follow Bootstrap config
  if (variant === 'info') classList.push('btn-info'); // Follow Bootstrap config
  if (variant === 'success') classList.push('btn-success'); // Follow Bootstrap config
  if (variant === 'loading') classList.push(loading);
  if (variant=== 'launch') classList.push(launch);
  if (variant === 'disabled') classList.push(disabled);


  if (variant === 'icon')
  {
    return (
      <button className={iconButton} {...rest}>
        {faIcon && <FontAwesomeIcon icon={faIcon} size={iconSize} />}
      </button>
    );
  }

  return (
    <button className={classList.join(' ')} {...rest}>
      {iconPos === 'left' && faIcon && <FontAwesomeIcon icon={faIcon} className={iconClass} />}
      {children}
      {iconPos === 'right' && faIcon && <FontAwesomeIcon icon={faIcon} className={iconClass} />}
    </button>
  );
}

HarmoniaButton.defaultProps = {
  icon: undefined,
  iconPos: 'right',
  iconSize: '1x',
};

export default HarmoniaButton;
