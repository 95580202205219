// import libraries
import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { push } from "connected-react-router";
import { HarmoniaButton } from "@harmonia-front-end/shared/component";
import { useParams } from "react-router-dom";
import {
  useHarmoniaSelector,
  useHarmoniaDispatch,
  getCourseDetail, activateCourse
} from "../../redux";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { DashboardTitleBar, DashboardContent } from "../../components/template";
import { HarmoniaPaper, useToggle, Text } from "@harmonia-front-end/shared";
import { Link } from "react-router-dom";
import { CourseDetailedInfo } from "../../types";
import { showDateAsFormattedString } from "../../utilities";
import LoadingPage from "../../components/LoadingPage";
import CoursePagesStyle from './CoursePages.module.scss';
import { useUser, useRedirect } from '../../hooks';

type ActivateCourseProps = {
  show: boolean,
  toggleModal: () => void,
  courseID: number,
  courseTitle: string,
  getUserInfo?: any
}

const ActivateCourse = ({show, toggleModal, courseID, courseTitle, getUserInfo}: ActivateCourseProps) => {
  const dispatch = useHarmoniaDispatch()
  const confirmActivateCourse = (courseID: number) => () => {
    dispatch(activateCourse(courseID)).finally(() => {
      toggleModal();
      // activating course from SingleCourse component in courses list.
      if (getUserInfo) {
        dispatch(getUserInfo())
      }
    })
  }
  return (
    <>
    <HarmoniaButton
      variant="launch"
      dest="app"
      icon="activate"
      iconPos="left"
      onClick={toggleModal}>
      Activate Course
    </HarmoniaButton>
    <Modal isOpen={show}>
      <ModalHeader>
        <Text>Activate Course</Text>
      </ModalHeader>
      <ModalBody>
        <div>
          <Text>
            The following course attributes cannot be changed after activation:
          </Text>
          <ul>
            <li>School</li>
            <li>Academic Term</li>
            <li>Fee Paid By</li>
            <li>Start Date</li>
            <li>End Date</li>
          </ul>
        </div>
        <Text fontStyle="bold">
          {`Do you wish to activate ${courseTitle}?`}
        </Text>
      </ModalBody>
      <ModalFooter>
        <HarmoniaButton variant="secondary" dest="app" onClick={toggleModal}>
          Cancel
        </HarmoniaButton>
        <HarmoniaButton variant="primary" dest="app" onClick={confirmActivateCourse(courseID)}>
          Activate
        </HarmoniaButton>
      </ModalFooter>
    </Modal>
    </>
  )
}
export default ActivateCourse;
