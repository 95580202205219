import React, {useState, useEffect} from 'react';
import { useHarmoniaDispatch, deleteSubmissionOnAssignmentDocTable, deleteSubmissionByIDOnAssignmentDocTable} from '../../../redux';
import { HarmoniaButton, Text, useToggle } from "@harmonia-front-end/shared";
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import GradeBookPageStyle from './GradeBookPage.module.scss';
import DocumentGradeBookTableEntry from './DocumentGradeBookTableEntry';
import { Submission, Assignment, Document, CourseStudent } from '../../../types';
import { EverySubmissionView } from './StudentGradeBookTable';
import {formatStudentName} from '../../../utilities';
const {gradeBookTableContainer, gradeBookTable, gradeBookTableHeader, gradeBookTableBody, row, documentTableRow } = GradeBookPageStyle;

type DocumentGradeBookTableProps = {
  selectedDocument: Document,
  students: CourseStudent[],
  submissionsByAssignment: Submission[],
  getDocumentSubmissions: (studentID: number, docID: number, submissions: Submission[]) => Submission[],
  courseID: number,
  exportDocCSV: (selectedDocument:Document, students:CourseStudent[], submissionsByAssignment:Submission[], getDocumentSubmissions:any, collapsed:boolean) => void
}

const DocumentGradeBookTable = (props: DocumentGradeBookTableProps) => {
  const dispatch = useHarmoniaDispatch();
  const {selectedDocument, students, submissionsByAssignment, getDocumentSubmissions, courseID, exportDocCSV} = props;

  const [removeSubmissionModalOpen, toggleRemoveSubmissionModal] = useToggle();
  const [removeSubmissionByIDModalOpen, toggleRemoveSubmissionByIDModal] = useToggle();

  const [submissionToRemove, setSubmissionToRemove] = useState({studentName: '', studentID: 0, submissionID: -1, indexToRemove: -1});
  // local state for expanding or collapsing all submission entries at once; passed down as prop to each DocumentGradeBookEntry.
  const[expandAll, toggleExpandAll] = useToggle();
  // changing viewing all submissions as expanded (ALL) or collapsed (HIGHEST)
  const changeAllSubmissionsView = (ev:any) => {
    const viewBy = ev.currentTarget.value;
    if(expandAll === false && viewBy == EverySubmissionView.ALL){
      toggleExpandAll();
    }
    if(expandAll === true && viewBy == EverySubmissionView.HIGHEST) {
      toggleExpandAll();
    }
  }

  const showSubmissionRemoveModal = (studentName: string, studentID: number) => {
    toggleRemoveSubmissionModal();
    setSubmissionToRemove({studentName, studentID, submissionID: -1, indexToRemove: -1});
  };

  // closes removeSubmissionModal, makes API call and resets submissionToRemove state to clear way for another student to be selected.
  const confirmRemoveSubmission = (name: string, id: number) => {
    toggleRemoveSubmissionModal();
    dispatch(deleteSubmissionOnAssignmentDocTable({user: {name: name, id: id}, document: selectedDocument.id})).finally(() => setSubmissionToRemove({studentName: '', studentID: 0, submissionID: -1, indexToRemove: -1}));
  };

  const showSubmissionRemoveByIDModal = (studentName: string, studentID: number, submissionID: number, indexToRemove: number) => {
    toggleRemoveSubmissionByIDModal();
    setSubmissionToRemove({studentName, studentID, submissionID, indexToRemove});
  };

  // closes removeSubmissionModal, makes API call and resets submissionToRemove state to clear way for another student to be selected.
  const confirmRemoveSubmissionByID = (studentName: string, submissionID: number) => {
    toggleRemoveSubmissionByIDModal();
    dispatch(deleteSubmissionByIDOnAssignmentDocTable({username: studentName, submissionID: submissionID})).finally(() => setSubmissionToRemove({studentName: '', studentID: 0, submissionID: -1, indexToRemove: -1}));
  };

  return (
    <>
    <div>
      <div style={{marginTop: '8px'}}>
        <label htmlFor="viewAll">View All Submissions (Sorted by Grade)</label>
        <input name="expandCollapseAll" type="radio" value={EverySubmissionView.ALL} onChange={changeAllSubmissionsView}/>
        <label htmlFor="viewSingle">View Highest Submission</label>
        <input name="expandCollapseAll" type="radio" value={EverySubmissionView.HIGHEST} checked={expandAll === false} onChange={changeAllSubmissionsView}/>
      </div>
    </div>
    <HarmoniaButton
      style={{marginTop: '8px'}}
      variant="primary"
      dest="app"
      icon="download"
      iconPos="left"
      onClick={() => exportDocCSV(selectedDocument, students, submissionsByAssignment, getDocumentSubmissions, !expandAll)}>
      Download CSV File
    </HarmoniaButton>
    {/* table */}
    <div className={gradeBookTableContainer}>
      <div className={gradeBookTable}>
        {/* header */}
        <section className={`${gradeBookTableHeader} ${row} ${documentTableRow}`}>
          <section>
            <article>Name</article>
            <article>Submissions</article>
          </section>
          <section>
            <article>Submission Time</article>
            <article>Score</article>
            <article>Percent | Grade</article>
            <article>File | Action</article>
          </section>
        </section>
        {/* body */}
        <div className={gradeBookTableBody}>
          {students?.map((student, studentIndex) => {
            const submissions = getDocumentSubmissions(student.user_id, selectedDocument.id, submissionsByAssignment);
            return <DocumentGradeBookTableEntry student={student} key={`${studentIndex} ${student.first_name}`} submissions={submissions} showSubmissionRemoveModal={showSubmissionRemoveModal}
                    showSubmissionRemoveByIDModal={showSubmissionRemoveByIDModal} expandAll={expandAll} courseID={courseID} />
          })}
        </div>
      </div>
    </div>

     {/* REMOVE SUBMISSION MODAL */}
     <Modal isOpen={removeSubmissionModalOpen}>
        <ModalBody>
          <Text color="dark">
            {`Are you sure to want to remove ${submissionToRemove.studentName}'s submission for ${selectedDocument.title}?`}
          </Text>
        </ModalBody>
        <ModalFooter>
          <HarmoniaButton variant="primary" dest="app" onClick={() => {confirmRemoveSubmission(submissionToRemove.studentName, submissionToRemove.studentID)}} >Remove Submission</HarmoniaButton>
          <HarmoniaButton variant="secondary" dest="app" onClick={toggleRemoveSubmissionModal}>Cancel</HarmoniaButton>
        </ModalFooter>
       </Modal>
       <Modal isOpen={removeSubmissionByIDModalOpen}>
         <ModalBody>
           <Text color="dark">
           {`Are you sure to want to remove ${submissionToRemove.studentName}'s submission #${submissionToRemove.indexToRemove} for ${selectedDocument.title}?`}
           </Text>
         </ModalBody>
         <ModalFooter>
         <HarmoniaButton variant="primary" dest="app" onClick={() => { confirmRemoveSubmissionByID(submissionToRemove.studentName, submissionToRemove.submissionID) }} >Remove Submission</HarmoniaButton>
           <HarmoniaButton variant="secondary" dest="app" onClick={toggleRemoveSubmissionByIDModal}>Cancel</HarmoniaButton>
         </ModalFooter>
       </Modal>
    </>
  )
}
export default DocumentGradeBookTable;
