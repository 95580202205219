import React from 'react';
import DatePicker from 'react-datepicker';
import { updateCourseSettings, useHarmoniaDispatch } from '../../redux';
import { UpdatePlanningCoursePayload, UpdateActiveCoursePayload, CourseDetailedInfo, CourseEditableFields } from '../../types';
import {calculateMaxTimeForEnrollmentEndDate} from '../../utilities';


// Typing for component props.
type CourseDatePickerProps = {
  dateType: 'start_date' | 'end_date' | 'enrollment_end_date',
  coursePayload: UpdatePlanningCoursePayload | UpdateActiveCoursePayload,
  course: CourseDetailedInfo
}

const CourseDatePicker = ({dateType, coursePayload, course}: CourseDatePickerProps) => {
  const dispatch = useHarmoniaDispatch();
  // Entire course is passed in as a prop separately than the course update payload because we need to have 2 separate payloads to comply with API (one for a planning course, one for active course), and the active payload does NOT include start and end dates which we need in all cases to calculate the proper min and max dates. Otherwise, typing errors.

  // Initialize date variables. All 3 are used in date picker, but these dates will have different values depending on the dateType prop.
  let selectedDate: Date | undefined;
  let minDate: Date | undefined;
  let maxDate: Date | undefined;


/** START DATE */
  if(dateType === 'start_date' && course.status === 'PLANNING'){
    selectedDate = new Date(course.start_date * 1000);
    // The minimum date for course start_date picker is always the current day (course can never start in past).
    minDate = new Date();
    // Calculate the maximum date for the start_date picker, based on the end_date. The maximum date for start_date will never be greater than the current end_date. For now, it is set for one day (-86,400 seconds) BEFORE the end_date.
    const maxTimeBeforeEnd = (course.end_date - 86400) * 1000;
    maxDate = new Date(maxTimeBeforeEnd);
  }
  /** END DATE */
  if(dateType === 'end_date' && course.status === 'PLANNING'){
    selectedDate = new Date(course.end_date * 1000);
    // Calculate the minimum date on the end_date picker, based on the start date. The minimum date for end_date will never be less than the current start_date. For now, it is set for one day(+86,400 seconds) AFTER start_date.
    const minTimeafterStart = (course.start_date + 86400) * 1000;
    minDate = new Date(minTimeafterStart);
    // Calculate max date for end_date picker, based on the start_date. Max date for the end_date is set for one year after start_date. We don't use seconds here because of leap years, so instead we create a date object copy based on the start_date and set the year to be one after.
    maxDate = new Date(course.start_date * 1000);
    maxDate.setFullYear(maxDate.getFullYear() + 1);
  }
  /** ENROLLMENT END DATE */
  if(dateType === 'enrollment_end_date'){
    // If there is no set enrollment_end_date, it should default to the course's start_date and use that for the selected date on the datePicker.
    const enrollmentEndDateTime = course.enrollment_end_date ? (course.enrollment_end_date * 1000) : (course.start_date * 1000)
    selectedDate = new Date(enrollmentEndDateTime);
    minDate = new Date(course.start_date * 1000);
    // The maximum length for the student free access (enrollment_end_date) is the minimum of: 2 weeks after the course_start_date vs. 1/3 of the course length.
    const maxEnrollmentEndDate = Math.floor(calculateMaxTimeForEnrollmentEndDate(course.start_date, course.end_date));
    maxDate = new Date(maxEnrollmentEndDate);
  }

  // Handler for making API call to change date.
  const handleDateChange = (date: Date) => {
    /* When typing in the datePicker input, it will apparently accept only 2 types of values which will trigger its handleChange function: either a proper date (the format is dependent upon what is specified as a prop on the datePicker component itself) or null/empty, which is accomplished by simply deleting the entire date input.

    (There appears to be some built-in validation on the datePicker input to not trigger the handleChange if there are values in the input but the date isn't formatted/typed according to the specification, so for now it appears we only need to worry about null/empty values).

    Therefore, we check if the date passed into this function is not null/empty, so that we prevent errors by ensuring API calls are only made with valid dates.
    */
    if(date){
      // Convert date to timestamp and then dispatch proper payload to change date.
      let timestamp = date.getTime() / 1000;
      let updatedCourse;
      if(dateType === 'start_date' && course.status === 'PLANNING'){
        const ct = Math.floor(new Date().getTime() / 1000);
        if (timestamp < ct)
            timestamp = ct;
        // find how many days after start date for enrollment_end_date and adjust it accordingly when start_date is changed.
        const enrollmentAfterStart = course.enrollment_end_date - course.start_date;
        let updatedEnrollmentEndDate = timestamp + enrollmentAfterStart;
        // if new course start_date causes the maximum possible enrollment_end_date to be earlier than what the enrollment_end_date would be (based on the current difference with the start date), we update that as well by setting the current enrollment_end_date to the new maximum.
        const maxEnrollmentEndDate = Math.floor(calculateMaxTimeForEnrollmentEndDate(timestamp, course.end_date) / 1000);
        if (updatedEnrollmentEndDate > maxEnrollmentEndDate) {
          updatedEnrollmentEndDate = maxEnrollmentEndDate;
        }
        updatedCourse = {...coursePayload, start_date: timestamp, enrollment_end_date: updatedEnrollmentEndDate}
        dispatch(updateCourseSettings({ course: updatedCourse, field: CourseEditableFields.START_DATE}));
      }
      if(dateType === 'end_date' && course.status === 'PLANNING'){
        const st = course.start_date + 60;
        if (timestamp < st)
            timestamp = st;
        let enrollmentEndDate = course.enrollment_end_date;
        // if new course end_date causes the maximum possible enrollment_end_date to be earlier than the current enrollment_end_date, we also update it along with end_date.
        const maxEnrollmentEndDate = Math.floor(calculateMaxTimeForEnrollmentEndDate(course.start_date, timestamp) / 1000);
        if(enrollmentEndDate > maxEnrollmentEndDate ) {
          enrollmentEndDate = maxEnrollmentEndDate;
        }
        updatedCourse = {...coursePayload, end_date: timestamp, enrollment_end_date: enrollmentEndDate};
        dispatch(updateCourseSettings({course: updatedCourse, field: CourseEditableFields.END_DATE}));
      }
      if(dateType === 'enrollment_end_date'){
        const mt = Math.floor(maxDate!.getTime() / 1000);
        if (timestamp > mt)
            timestamp = mt;
        const st = course.start_date;
        if (timestamp < st)
            timestamp = st;
        updatedCourse = {...coursePayload, enrollment_end_date: timestamp}
        dispatch(updateCourseSettings({ course: updatedCourse, field: CourseEditableFields.ENROLLMENT_END_DATE}))
      }

    // Convert date to timestamp and then dispatch proper payload to change date.
    // const timestamp = date.getTime() / 1000;
    // let updatedCourse;
    // if(dateType === 'start_date' && course.status === 'PLANNING'){
    //   updatedCourse = {...coursePayload, start_date: timestamp}
    //   dispatch(updateCourseSettings({ course: updatedCourse, field: CourseEditableFields.START_DATE}))
    // }
    // if(dateType === 'end_date' && course.status === 'PLANNING'){
    //   updatedCourse = {...coursePayload, end_date: timestamp}
    //   dispatch(updateCourseSettings({ course: updatedCourse, field: CourseEditableFields.END_DATE}))
    // }
    // if(dateType === 'enrollment_end_date'){
    //   updatedCourse = {...coursePayload, enrollment_end_date: timestamp}
    //   dispatch(updateCourseSettings({ course: updatedCourse, field: CourseEditableFields.ENROLLMENT_END_DATE}))
    // }

  }
}

  return (
    <div>
      <DatePicker
      selected={selectedDate as Date}
      minDate={minDate as Date}
      maxDate={maxDate as Date}
      onChange={handleDateChange}
      showTimeSelect
      dateFormat="Pp"
      fixedHeight
      filterTime={(t: any) => { return (minDate === undefined || t >= minDate) && (maxDate === undefined || t <= maxDate); }}
      />
    </div>
  )
}
export default CourseDatePicker;
