import React from 'react';
import { injectIntl } from 'react-intl';
import { API, HarmoniaCookies } from '../../utilities';
import { SwitchLocaleContext } from '../../Dashboard';

class LanguageSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languages: [],
      locale: HarmoniaCookies.locale.get(),
      offset: 16,
      position: 'fixed'
    };
  }

  componentDidMount() {
    API.get('/api/languages').then((response) => {
      this.setState({ languages: response.data.data });
    }, () => { console.error("can't retrieve languages"); });

    const onScroll = () => {
      if (window.innerWidth > 767) {
        this.setState((state) => {
          return { position: "fixed" }
        })
        if (window.pageYOffset < 110) {
          this.setState((state) => {
            return { offset: window.pageYOffset }
          });
        } else {
          this.setState((state) => {
            return { offset: 110 }
          });
        }
      } else {
        this.setState((state) => {
          return { position: "absolute", offset: 16 }
        })
      }
    }
    window.removeEventListener("scroll", onScroll)
    window.addEventListener("scroll", onScroll, { passive: true })
    return () => window.removeEventListener("scroll", onScroll)
  }


  render() {
    // get intl in order to access formatMessage function.
    const { intl } = this.props;
    return (
      <SwitchLocaleContext.Consumer>
        {(switchLocale) => (
          <div id="pre-auth-language-select"
            // style={{
            //   position: this.state.position,
            //   top: 136 - this.state.offset,
            //   left: '24px'
            // }}
          >
            <select
              value={this.state.locale}
              onChange={(event) => {
                switchLocale(event);
                this.setState({ locale: event.target.value });
              }}
            >
              {this.state.languages.map((item) => {
                const key = item.abbreviation.replace('_', '-');
                const text = intl.formatMessage({ id: `languages.${key}` });
                return (<option value={key} key={key}>{text}</option>);
              })}
            </select>
          </div>
        )}
      </SwitchLocaleContext.Consumer>
    );
  }
}

// option cannot have any html tag in there, but <FormattedMessage> return <span>
// so we have to bypass that and use raw "injectIntl"
export default injectIntl(LanguageSelect);
