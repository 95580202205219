import React from 'react';
import { HarmoniaButton, Severity } from '@harmonia-front-end/shared';
import { DashboardContent, DashboardTitleBar } from '../components/template';

const severities: Severity[] = ['error', 'warning', 'info', 'success'];

export default function Dev() {
  const buttonStyle: React.CSSProperties = {
    display: 'flex',
    columnGap: '4px',
    alignItems: 'center',
  };

  return (
    <>
      <DashboardTitleBar h1="Developer Playground" />
      <DashboardContent>
        <div style={buttonStyle}>
          {severities.map((s) => (
            <HarmoniaButton variant={s} dest="app">{s}</HarmoniaButton>
          ))}
          <HarmoniaButton variant="primary" dest="app">
            Primary App
          </HarmoniaButton>
          <HarmoniaButton variant="secondary" dest="app">
            Secondary App
          </HarmoniaButton>
          <HarmoniaButton variant="primary" dest="web">
            Primary Web
          </HarmoniaButton>
          <HarmoniaButton variant="secondary" dest="web">
            Secondary Web
          </HarmoniaButton>
        </div>
      </DashboardContent>
    </>
  );
}
