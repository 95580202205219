
export type Document = {
  assignment: number,
  delay_showing_grade: true | false,
  doc_key: number,
  gradable: true | false,
  id: number,
  incremental_grading: true | false,
  multiple_submissions: true | false,
  practice: true | false,
  show_grade_on_date: number | null,
  submission_count: number,
  timed: true | false,
  timed_time_seconds: number | null,
  title: string,
  weight: number,
  downloadURL?: string | undefined
  previewURL?: string | undefined
}
export type Assignment = {
  course: number,
  documents?: Document[]
  due_at: number,
  id: number,
  released_at: number,
  set_key: string,
  show_after_due: true | false,
  title: string,
  weight: number
}
export type DueDateExtension = {
  assignment: number,
  extension_due_at: number,
  first_name: string,
  last_name: string,
  student: number
}
export type AssignmentInitialState = {
  assignmentDetail?: Assignment | null,
  assignmentDocuments?: Document[] | null,
  dueDateExtensions?: DueDateExtension[] | null
}
export enum AssignmentDateType {
  ASSIGNED, DUE
}
// Editable Fields
export enum AssignmentEditableFields {
  TITLE,
  DUE_AT,
  RELEASED_AT
}
export enum DueDateExtensionAction {
  CREATE,
  UPDATE
}
export enum DocumentEditableFields {
  TITLE,
  PRACTICE,
  MULTI_SUB,
  INCREMENT,
  DELAY_SHOW_GRADE,
  DELAY_DATE,
  TIMED_BOOL,
  TIMED_SET
}
