import React, { useState, useRef } from 'react';
import { useHarmoniaDispatch, createCourseInvitation, getCourseInvitations } from '../../redux';
import {
  HarmoniaButton,
  HarmoniaInput,
  Text,
} from "@harmonia-front-end/shared";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import style from '../../pages/course/PeoplePage.module.scss';
import {PersonnelData} from './CoursePeople';

const {inviteEmail, emailTag, removeEmail, inputError} = style;

type CreateCourseInvitationModalProps = {
  personnelData: PersonnelData,
  invitationEmails: string[],
  setInvitationEmails: React.Dispatch<React.SetStateAction<string[]>>,
  courseID: number,
  createInvitationModalOpen: boolean,
  toggleCreateInvitationModal: () => void
}

// local state & function for invitationEmails needs to be on CoursePeople and passed down to this Modal

// all email validation/input handler functions should be defined on this Modal

// submitCourseInvitations should be defined on this modal

// props should be:



const CreateCourseInvitationModal = ({personnelData, invitationEmails, setInvitationEmails, courseID,  createInvitationModalOpen, toggleCreateInvitationModal}: CreateCourseInvitationModalProps) => {

  const dispatch = useHarmoniaDispatch();

  /* LOCAL STATE: for setting value of input field, and for any input errors */

  const [invitationInput, setInvitationInput] = useState('');
  const [invitationError, setInvitationError] = useState(null) as [string | null, React.Dispatch<React.SetStateAction<string | null>>];

  const [invitePressed, setInvitePressed] = useState(false);

  // removing an email from invitationEmails state

  const removeInvitationEmail = (emailToRemove: string) => () => {
    const invitationEmailsWithoutDeleted = invitationEmails.filter(email => email!== emailToRemove);
    setInvitationEmails(invitationEmailsWithoutDeleted);
  };

  /* EMAIL VALIDATIONS */

  // helper to check if input entered is an actual email
  const isActualEmail = (email:string) => {
    return /[\w\d\.+-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  }

  // validating input in email: not already added and properly formatted. Also sets error if not valid.

  const isValidEmail = (email: string) => {
    // user already added email to queue
    let error = null;
    if (invitationEmails.includes(email)) {
      error = `${email} has already been added.`;
    }
    // email is not an email
    if(!isActualEmail(email)) {
      error = `${email} is not a valid email address.`;
    }
    // set error if something with with email input
    if(error) {
      setInvitationError(error);
      return false;
    }
    return true;
  };

  /* INPUT HANDLERS */

  // setting/updating local state for invitation email input. when a user has changed value of input field if there is an error it should go away.
  const handleInvitationInput: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setInvitationInput(event.currentTarget.value);
    setInvitationError(null);
  };

  // keydownhandler that will listen for user to type in "Enter" and add email to invitationEmails state if it passes validations
  const triggerKeys = ['Enter', 'Tab', ',', ' '];
  const handleKeyDown:React.KeyboardEventHandler<HTMLInputElement> = (ev) => {
    if(triggerKeys.includes(ev.key)) {
      ev.preventDefault();
      ev.currentTarget.blur();
      // get rid of any white space
      var newEmail = invitationInput.trim();
      if(newEmail && isValidEmail(newEmail)) {
        setInvitationEmails(emails => [...emails, newEmail]);
        // reset input field and focus it after successful add to queue so user can easily add another
        setInvitationInput('');
        ev.currentTarget.focus();
      }
    }
  };

  const eMailInput = useRef<HTMLInputElement>(null);
  const handleAddClick = () => {
      if (eMailInput) {
      eMailInput.current?.blur();
      // get rid of any white space
      var newEmail = invitationInput.trim();
      if(newEmail && isValidEmail(newEmail)) {
        setInvitationEmails(emails => [...emails, newEmail]);
        // reset input field and focus it after successful add to queue so user can easily add another
        setInvitationInput('');
        eMailInput.current?.focus();
      }
      }
  };

  // paste handler that will only allow users to paste in a proper email. They can paste in more than one (separated by commas or spaces for example) and it will add them to invitationEmails state. If incorrect paste, no error: simply won't let it be pasted in.
  const handlePaste:React.ClipboardEventHandler<HTMLInputElement> = (ev) => {
    ev.preventDefault();
    var paste = ev.clipboardData.getData('text');
    var emails = paste.match(/[\w\d\.+-]+@[\w\d\.-]+\.[\w\d\.-]+/g);
    if (emails) {
      const emailsToAdd = emails.filter(email => !invitationEmails.includes(email));
      setInvitationEmails([...invitationEmails, ...emailsToAdd]);
    }
  };


  /* SUBMITTING INVITATION(S) */

  // function that will make API call to create course invitations with emails from invitationEmails state and then reset all relevant invitation state and grab course invitations again to display recently created.

  const submitInvitations = (role: 'instructor' | 'ta' | 'student') => () => {
    setInvitePressed(true);
    dispatch(createCourseInvitation({role, emails: invitationEmails, course: courseID})).finally(() => {
      setInvitationInput('');
      setInvitationEmails([]);
      toggleCreateInvitationModal();
      dispatch(getCourseInvitations(courseID));
    });
  };


  // making dynamic class for input field depending on if there is an error with entering email
  const showError = invitationError && inputError;

  return (
    <Modal isOpen={createInvitationModalOpen}>
      <ModalHeader>
        <Text>{`Invite ${personnelData.displayRole}(s) to Join Course`}</Text>
      </ModalHeader>
      <ModalBody>
        <>
          {
            invitationEmails.length > 0 &&
            <div className="emailList">
              {
                invitationEmails.map((email, index) => {
                  return (
                    <div key={index} className={emailTag}>
                      {email}
                      <FontAwesomeIcon className={removeEmail} icon={faTimesCircle} onClick={removeInvitationEmail(email)}/>
                    </div>
                  )
                })
              }
            </div>
          }
          <HarmoniaInput
            ref={eMailInput}
            className={`${inviteEmail} ${showError}`}
            placeholder="Type or paste email addresses and press 'Enter.'"
            onChange={handleInvitationInput}
            onKeyDown={handleKeyDown}
            onPaste={handlePaste}
            value={invitationInput}
          >
          </HarmoniaInput>
          {/* show error message if error */}
            <HarmoniaButton variant="secondary" dest="app" onClick={handleAddClick}>
             Add
            </HarmoniaButton>
          <Text>You can paste a list of e-mail addresses into the text box.</Text>
          {invitationError && <Text color="red">{invitationError}</Text>}
        </>
      </ModalBody>
      <ModalFooter>
          <HarmoniaButton variant="secondary" dest="app" onClick={toggleCreateInvitationModal}>
            Cancel
          </HarmoniaButton>
          {
            (!invitationEmails.length || invitePressed) &&
            <HarmoniaButton variant="disabled" dest="app" disabled>
              Invite
            </HarmoniaButton>
          }
          {
          invitationEmails.length > 0 && !invitePressed &&
            <HarmoniaButton variant="primary" dest="app" onClick={submitInvitations(personnelData.personnelRole)}>
             Invite
            </HarmoniaButton>
          }
      </ModalFooter>
    </Modal>
  )



}
export default CreateCourseInvitationModal;
