import type {ReactNode} from 'react';

// TODO: provide ReactNode return for multi-language support.

// kludge to turn api error messages into front end error messages
// we need to get rid of this and use server error codes
function transformAPIErrorMessageWithCode(code: any, msg: any): string {
    // console.log("ERROR: code = " + code + ", msg = \"" + msg + "\"");
    if (!msg)
        return 'Unknown error.';
    return msg.endsWith('.') ? msg : `${msg}.`;
}

// kludge to turn api error messages into front end error messages
// we need to get rid of this and use server error codes
function transformAPIErrorMessageWithField(field: any, msg: any): string {
    // console.log("ERROR: field = " + field + ", msg = \"" + msg + "\"");
    if (!msg)
        return 'Unknown error.';
    if (field === 'start_date' && /^The start date must be greater than or equal \d+.$/.test(msg))
        return `The start time cannot be set to a time in the past.`
    if (field === 'enrollment_end_date' && msg === 'Value Not valid')
        return 'The course duration cannot be greater than 1 year.';
    if (field === 'email' && msg === 'The email has already been taken.')
        return 'This email has already been taken.';
    return msg.endsWith('.') ? msg : `${msg}.`;
}

/**
 * Generic function to generate error message based on API error response object.
 * Simply pass in the response body without modification,
 * and the message generators will handle it for good.
 *
 * This function only returns string, unlike other specific error message generators
 * which return a ReactNode.
 *
 * @param err API response body.
 */
// export function errorMessage(err: any): string {
//   const { errors } = err;
//   const errorStringList: string[] = [];
//   for (let i = 0; i < errors.length; i += 1) {
//     const error = errors[i];
//     if (error.code) errorStringList.push(`${error.code}: ${error.message}.`);
//     else if (error.field) errorStringList.push(`${error.field}: ${error.messages.join('; ')}.`);
//   }
//   return errorStringList.join('\n');
// }
export function errorMessage(err: any): string {
    const {errors} = err;
    if (!errors)
        return "";
    const errorStringList: string[] = [];
    for (let i = 0; i < errors.length; i += 1) {
        const error = errors[i];
        if (error.code)
            errorStringList.push(transformAPIErrorMessageWithCode(error.code, error.message));
        else if (error.field)
            errorStringList.push(error.messages.map((msg: any) => {
                return transformAPIErrorMessageWithField(error.field, msg);
            }).join('; '));
    }
    return errorStringList.join('\n');
}

export function signInErrorMessage(err: any): ReactNode {
    const {errors} = err;
    const error = errors[0];
    if (error.code === 401) return 'Email or password is not correct.';
    if (error.code === 409) return 'You\'ve already signed in.';
    if (error.code === 429) return 'Too many requests. Please wait and try again later.';
    return errorMessage(err);
}

export function signInWithTokenErrorMessage(err: any): ReactNode {
    const {errors} = err;
    const error = errors[0];
    if (error.code === 401) return 'This link is no longer valid.';
    if (error.code === 409) return 'You\'ve already signed in.';
    return errorMessage(err);
}

export function passwordResetErrorMessage(err: any): ReactNode {
    const {errors} = err;
    const error = errors[0];
    if (error.field === 'email') return 'Requested email address is invalid.';
    return errorMessage(err);
}

export function signUpErrorMessage(err: any): ReactNode {
    const {errors} = err;
    if (errors[0].code === 409) return 'You\' ve already signed in.';
    return errorMessage(err);
}
