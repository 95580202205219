import React, {useState, useEffect} from 'react';
import { useHarmoniaSelector, getCourseDetail, useHarmoniaDispatch, getCourseStudents} from '../../../redux';
// import CoursePagesStyle from '../../../pages/course/CoursePages.module.scss';
import GradeBookPageStyle from './GradeBookPage.module.scss';
import { Submission, Assignment, Document, CourseStudent } from '../../../types';
import {ViewSubmissionByType} from './SubmissionByAssignment';
import {renderAssignmentDocNum} from '../../../utilities';

type AssignmentGradeBookTableProps = {
  selectedAssignments: Assignment[],
  courseStudents: CourseStudent[]
  submissionsByAssignment: Submission[] | null | undefined,
  getDocumentSubmissions: (studentID: number, docID: number, submissions: Submission[]) => Submission[]
  viewSubmissionBy: ViewSubmissionByType,
  assignments: Assignment[]
}

const {gradeBookTableContainer, gradeBookTable, gradeBookTableHeader, gradeBookTableBody} = GradeBookPageStyle;

const AssignmentGradeBookTable = (props: AssignmentGradeBookTableProps) => {
  const { courseStudents, selectedAssignments, submissionsByAssignment, getDocumentSubmissions, viewSubmissionBy, assignments} = props;

  if(!submissionsByAssignment) return null;

  return (
    <>
    <div className={gradeBookTableContainer} style={{overflowX: 'auto'}}>
      {selectedAssignments.length !== 0 &&
          <table className={gradeBookTable} style={{width: (600 + 150 * selectedAssignments.length) + 'px'}}>
              <thead>
                <tr className={gradeBookTableHeader}>
                  <>
                  <th>Name</th>
                  {
                      selectedAssignments.map((a:Assignment) => {
                          const ai = assignments?.findIndex(assignment => assignment.id === a.id);
                          return a.documents?.map((doc, docIndex) => {
                              return <th key={docIndex}>{renderAssignmentDocNum(ai, docIndex)}</th>
                          })
                      }).flat()
                  }
                  </>
                </tr>
              </thead>
              <tbody className={gradeBookTableBody}>
                {selectedAssignments.length !== 0 &&
                  courseStudents?.map((student, studentIndex) => {
                    const studentName = `${student.last_name}, ${student.first_name}`;
                    return (
                      <tr key={studentIndex}>
                        <td>{studentName}</td>
                        {
                                // Loop through all documents
                                selectedAssignments.map((a: Assignment) => {
                                    return a.documents?.map((doc:Document, docIndex) => {
                                        // grab a student's submission(s)
                                        const submissions = getDocumentSubmissions(student.user_id, doc.id, submissionsByAssignment);

                                        let points;
                                        let percent;
                                        let grade;
                                        // if they have any submissions display the values from one with highest score (the first)
                                        if (submissions.length) {
                                            const topSub = submissions[0];
                                            points = topSub.points;
                                            percent = `${topSub.percent}%`;
                                            grade = topSub.grade;
                                            // otherwise show empty values
                                        } else {
                                            points = '';
                                            percent = '';
                                            grade = '';
                                        }
                                        return <td key={docIndex}>
                                            {viewSubmissionBy == ViewSubmissionByType.SCORE && points}
                                            {viewSubmissionBy == ViewSubmissionByType.PERCENT && percent}
                                            {viewSubmissionBy == ViewSubmissionByType.GRADE && grade}
                                        </td>
                                    });
                                }).flat()
                        }
                    </tr>
                    )
                  })
                }
              </tbody>
          </table>
        }
      </div>
      </>
  )
}
export default AssignmentGradeBookTable;
