import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { MiniTemplate, Text} from '@harmonia-front-end/shared';


export default function ConfirmSignUpPage() {

  return (
    <MiniTemplate>
      <div>
        <Text variant="h1" color="grey" align="center" gutterBottom>
          Confirm Email Address
        </Text>
        <Text style={{paddingLeft: '12px', paddingRight: '12px', paddingBottom: '8px'}} variant="dashboardContentHeading" color="dark" align="center">
          <FormattedMessage id="user.confirmation_email_sent" defaultMessage="An email has been sent to the email address you provided. Please follow the link in the email to complete the registration process."/>
        </Text>
      </div>
    </MiniTemplate>
  );
}
