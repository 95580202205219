import React, {useState, useEffect, useRef} from 'react';
import { useHarmoniaSelector, getCourseDetail, useHarmoniaDispatch, getCourseStudents, deleteSubmissionOnStudentTable} from '../../../redux';
import { HarmoniaButton, Text, useToggle } from "@harmonia-front-end/shared";
import { Modal, ModalBody, ModalFooter, Collapse } from 'reactstrap';
// import CoursePagesStyle from '../../../pages/course/CoursePages.module.scss';

import { Submission, Assignment, Document, CourseStudent } from '../../../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCaretUp, faCaretDown} from '@fortawesome/free-solid-svg-icons';
import {formatStudentName} from '../../../utilities';
import StudentGradeBookEntry from './StudentGradeBookTableEntry';
import CoursePagesStyle from './GradeBookPage.module.scss';
const {gradeBookTableContainer, gradeBookTable, gradeBookTableHeader, gradeBookTableBody, row, tableEntry, topSub, remove, subCollapse, collapsibleSub, collapsibleCell } = CoursePagesStyle;

const DummyEntry = () => {
  const [isExpanded, toggleExpanded] = useToggle();
  const icon = isExpanded ? faCaretUp : faCaretDown;
  return (
    <div className={tableEntry}>
            <div className={`${topSub} ${row}`}>
              <div>1.3</div>
              <div>Modal Mixture Analysis #3</div>
              <div>2022-02-04 7:59 am</div>
              <div>49.52</div>
              <div>99.04%</div>
              <div>N</div>
              <div>4
              <FontAwesomeIcon icon={icon} onClick={toggleExpanded}/>
              </div>
              <div>View</div>
              <div>
              <HarmoniaButton id={remove}  variant="primary" dest="app" icon="delete">Remove Record</HarmoniaButton>
              </div>
            </div>
            <Collapse isOpen={isExpanded} className={subCollapse}>
              <div className={`${collapsibleSub} ${row}`}>
                <div></div>
                <div></div>
                <div className={collapsibleCell}>2022-02-03 8:18 pm</div>
                <div className={collapsibleCell}>7</div>
                <div className={collapsibleCell}>100</div>
                <div className={collapsibleCell}>N</div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div className={`${collapsibleSub} ${row}`}>
                <div></div>
                <div></div>
                <div className={collapsibleCell}>2022-02-03 8:18 pm</div>
                <div className={collapsibleCell}>7</div>
                <div className={collapsibleCell}>100</div>
                <div className={collapsibleCell}>N</div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </Collapse>
          </div>
  )
}
const dummySubEntries = [];
for(let i = 0; i < 1; i++){
  const dummySub = <DummyEntry/>;
  dummySubEntries.push(dummySub);
}

const DummyTable = () => {
  const [isExpanded, toggleExpanded] = useToggle();
  const [isExpanded2, toggleExpanded2] = useToggle();
  const icon = isExpanded ? faCaretUp : faCaretDown;
  return (
    <>
    <div className={gradeBookTableContainer}>
      <div className={gradeBookTable}>
        <div className={`${gradeBookTableHeader} ${row}`}>
          <div></div>
          <div>Document</div>
          <div>Submission Time</div>
          <div>Score</div>
          <div>Percent</div>
          <div>Letter Grade</div>
          <div>Submissions</div>
          <div>File</div>
          <div>Action</div>
        </div>
        <div className={gradeBookTableBody}>
          <div className={tableEntry}>
            <div className={`${topSub} ${row}`}>
              <div>1.3</div>
              <div>Modal Mixture Analysis #3</div>
              <div>2022-02-04 7:59 am</div>
              <div>49.52</div>
              <div>99.04%</div>
              <div>N</div>
              <div>4
              <FontAwesomeIcon icon={icon} onClick={toggleExpanded}/>
              </div>
              <div>View</div>
              <div>
              <HarmoniaButton id={remove}  variant="primary" dest="app" icon="delete">Remove Record</HarmoniaButton>
              </div>
            </div>
            <Collapse isOpen={isExpanded} className={subCollapse}>
              <div className={`${collapsibleSub} ${row}`}>
                <div></div>
                <div></div>
                <div className={collapsibleCell}>2022-02-03 8:18 pm</div>
                <div className={collapsibleCell}>7</div>
                <div className={collapsibleCell}>100</div>
                <div className={collapsibleCell}>N</div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </Collapse>
          </div>
          {dummySubEntries.map(sub => {
            return sub;
          })}

        </div>
      </div>
    </div>
    </>
  )
}
// const DummyTable2 = () => {
//   const [isExpanded, toggleExpanded] = useToggle();
//   const [height, setHeight] = useState(0);
//   const ref = useRef(null);
//   const expandOrCollapseRow = isExpanded ? expandedRow : collapsedRow;
//   const expandOrCollapseCol = isExpanded ? expandedCol : collapsedCol;
//   const rowSpan = 3;
//   const colSpan = 2;
//   return (
//   <div className={gradeBookTableContainer}>
//     <table className={gradeBookTable}>
//       <thead>
//         <tr className={gradeBookTableHeader}>
//           {/* <th className={num}></th>
//           <th className={docTitle}>Document</th>
//           <th className={submission}>Submission Time</th>
//           <th className={score}>Score</th>
//           <th>Submissions</th> */}
//           <th onClick={toggleExpanded}>Col 1</th>
//           <th>Col 2</th>
//           <th>Col 3</th>
//           <th>Col 4</th>
//         </tr>
//       </thead>
//       <tbody className={gradeBookTableBody}>
//         <tr>
//           <td>Row 1</td>
//           <td>Row 1</td>
//           <td>Row 1</td>
//           <td>Row 1</td>
//           {/* <td rowSpan={rowSpan}>1.3</td>
//           <td className={expandOrCollapseCol} rowSpan={rowSpan} onClick={toggleExpanded}>LESSON: Modal Mixture</td>
//           <td>2022-02-04 7:59 am</td>
//           <td className={score}>32.53</td>
//           <td rowSpan={rowSpan}>3</td> */}
//         </tr>
//         <tr>
//           <td id={tableInner}colSpan={4}>
//           <span>Click me</span>
//             <Collapse isOpen={isExpanded}>
//               <table>
//                 <tbody>
//                   <tr>
//                     <td>Row 2</td>
//                     <td>Row 2</td>
//                     <td>Row 2</td>
//                     <td>Row 2</td>
//                     {/* <td className={submission}><div>2022-01-23 7:41 am</div></td>
//                     <td><div>Row 2</div></td> */}
//                   </tr>
//                   <tr>
//                     <td>Row 3</td>
//                     <td>Row 3</td>
//                     <td>Row 3</td>
//                     <td>Row 3</td>
//                   </tr>
//                 </tbody>
//               </table>
//             </Collapse>
//           </td>
//         </tr>
//       </tbody>
//     </table>
//   </div>
//   )
// }

// const DummyTable3 = () => {
//   const [isExpanded, toggleExpanded] = useToggle();
//   const expandOrCollapseRow = isExpanded ? expandedRow : collapsedRow;
//   const icon = isExpanded ? faCaretUp : faCaretDown;
//   return (
//     <>
//   <div className={gradeBookTableContainer}>
//     <table className={gradeBookTable}>
//       <thead>
//         <tr className={gradeBookTableHeader}>
//           <th className={num}></th>
//           <th className={docTitle}>Document</th>
//           <th className={time}>Submission Time</th>
//           <th className={score}>Score</th>
//           <th className={percent}>Percent</th>
//           <th className={grade}>Letter Grade</th>
//           <th className={submissions}>Submissions</th>
//           <th className={file}>File</th>
//           <th className={action}>Action</th>
//         </tr>
//       </thead>
//       <tbody className={gradeBookTableBody}>
//         <tr>
//           <td>1.3</td>
//           <td>LESSON: Modal Mixture</td>
//           <td>2022-02-04 7:59 am</td>
//           <td>32.53</td>
//           <td>43.5%</td>
//           <td>F</td>
//           <td>
//             3
//             <FontAwesomeIcon icon={icon} onClick={toggleExpanded}/>
//           </td>
//           <td>View</td>
//           <td>
//             <HarmoniaButton variant="primary" dest="app" icon="delete">Remove Record</HarmoniaButton>
//           </td>
//         </tr>
//         <tr className={expandOrCollapseRow}>
//           <td id={innerData}colSpan={9}>
//             <Collapse isOpen={isExpanded}>
//               <table className={innerTable}>
//                 <tbody>
//                   <tr>
//                     <td className={num}><div>2.3</div></td>
//                     <td className={docTitle}><div>LESSON: Modal Mixture</div></td >
//                     <td className={time}>2021-02-04 7:14 am</td >
//                     <td className={score}>55.8</td>
//                     <td className={percent}>71.6%</td>
//                     <td className={grade}>C</td>
//                     <td className={submissions}><div>3</div></td>
//                     <td className={file}><div>View</div></td>
//                     <td className={action}><div>Empty Value</div></td>
//                   </tr>
//                   <tr>
//                     <td className={num}><div>2.3</div></td>
//                     <td className={docTitle}><div>LESSON: Modal Mixture</div></td >
//                     <td className={time}>2021-02-04 7:14 am</td >
//                     <td className={score}>55.8</td>
//                     <td className={percent}>71.6%</td>
//                     <td className={grade}>C</td>
//                     <td className={submissions}><div>3</div></td>
//                     <td className={file}><div>View</div></td>
//                     <td className={action}><div>Empty Value</div></td>
//                   </tr>
//                 </tbody>
//               </table>
//             </Collapse>
//           </td>
//         </tr>
//       </tbody>
//     </table>
//   </div>
//   </>
//   )
// }
export default DummyTable;
