// API.ts
/** This script creates and exports an API instance to access the backend. */
import axios from 'axios';
import HarmoniaCookies from './HarmoniaCookies';

const baseURL = (() => {
  if (process.env.REACT_APP_HARMONIA_ENV === 'production') return 'https://api.harmonia.illiacsoftware.com';
  if (process.env.REACT_APP_HARMONIA_ENV === 'staging') return 'https://api.staging.harmonia.illiacsoftware.com';
  return 'http://localhost:8000';
})();

const API = axios.create({
  baseURL,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
    'X-CSRF-TOKEN': document.getElementById('csrf-token')?.getAttribute('value'),
  },
});

API.interceptors.request.use(
  (config) => {
    const xAccessTokenCookies = HarmoniaCookies['x-access-token'].get();
    if (xAccessTokenCookies) {
    // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${xAccessTokenCookies}`;
    }
    return config;
  },
);

API.interceptors.response.use(
  (response) => {
    const xAccessToken = response.headers['x-access-token'];
    if (xAccessToken) {
      // Successful API response will simultaneously set two separate cookies: "x-access-token" (used to verify logged in status and requests) and "token-expire-time" (so we can have access to when it will expire).
      const cookieExpireTime = response.headers['x-access-token-ttl'] * 1000;
      const cookieExpireDate = new Date(cookieExpireTime);
      HarmoniaCookies['x-access-token'].set(xAccessToken, {
        expires: cookieExpireDate,
      });
      HarmoniaCookies['token-expire-time'].set(`${cookieExpireTime}`, {expires: cookieExpireDate})
    }
    return response;
  },
  (error) => Promise.reject(error.response),
);

export default API;
