import {useEffect} from "react";
import {useHistory, useLocation} from "react-router";
import {useFlag} from "@harmonia-front-end/shared";
import {
    ContentLibrary,
    Language,
    MediaCollectionWithFiles,
    Timezone,
    User,
    CourseDetailedInfo,
    Document,
    Assignment,
    AssignmentInitialState,
    CourseStudent,
    DueDateExtension,
    School,
    Term
} from "../types";
import {
    fetchLanguages,
    fetchTimezones,
    fetchSchools,
    fetchTerms,
    getAllMediaCollectionsAndFiles,
    getEntireContentLibrary,
    getUserInfo,
    getCourseStudents,
    getAssignmentDocuments,
    listAssignmentDueDateExtensions,
    useHarmoniaDispatch,
    useHarmoniaSelector,
    Notification,
    pushNotification,
    harmoniaDispatch,
} from "../redux";
import {HarmoniaCookies} from "../utilities";

/**
 * Helper hook to acquire the right Harmonia User info.
 * If necessary, it will call API to fetch info.
 * Fetched information would automatically be stored in Redux after a successful API call.
 *
 * @example
 * // Should use this React hook like this
 * const [user, loading] = useHarmoniaUser();
 * if (loading) return loadingComponent // User is loading.
 * if (!user) return null // No user existed.
 * return normalComponent // return normally.
 */
export function useUser(): [User | null | undefined, boolean] {
    const dispatch = useHarmoniaDispatch();
    const {user} = useHarmoniaSelector((store) => store.user);
    const [loading, setLoadingOn, setLoadingOff] = useFlag(true);
    useEffect(() => {
        if (user === undefined && HarmoniaCookies["x-access-token"].get()) {
            dispatch(getUserInfo()).finally(setLoadingOff);
        } else
            setLoadingOff();
    }, [user]);
    return [user, loading];
}

/**
 * Helper hook to acquire all the user's media collections.
 * If necessary, it will call API to fetch info.
 * Fetched information would automatically be stored in Redux after a successful API call.
 */
export function useUserMediaCollectionsAndFiles(): [
    MediaCollectionWithFiles[] | null | undefined,
    boolean
] {
    const dispatch = useHarmoniaDispatch();
    const {mediaCollections} = useHarmoniaSelector((store) => store.media);
    const [loading, setLoadingOn, setLoadingOff] = useFlag(true);
    useEffect(() => {
        // setLoadingOn();
        if (
            mediaCollections === undefined &&
            HarmoniaCookies["x-access-token"].get()
        ) {
            dispatch(getAllMediaCollectionsAndFiles()).finally(setLoadingOff);
        } else setLoadingOff();
    }, [mediaCollections /* , dispatch, setLoadingOn, setLoadingOff */]);
    return [mediaCollections, loading];
}

/**
 * Helper hook to acquire the entire Harmonia Cloud sample contents.
 * If necessary, it will call API to fetch info.
 * Fetched information would automatically be stored in Redux after a successful API call.
 */
export function useContentLibrary(): [
    ContentLibrary | null | undefined,
    boolean
] {
    const dispatch = useHarmoniaDispatch();
    const {contentLibrary} = useHarmoniaSelector(
        (store) => store.contentLibrary
    );
    const [loading, setLoadingOn, setLoadingOff] = useFlag(true);
    useEffect(() => {
        // setLoadingOn();
        if (
            contentLibrary === undefined &&
            HarmoniaCookies["x-access-token"].get()
        ) {
            dispatch(getEntireContentLibrary()).finally(setLoadingOff);
        } else setLoadingOff();
    }, [contentLibrary /* , dispatch, setLoadingOff, setLoadingOn */]);
    return [contentLibrary, loading];
}

export function useCourseStudents(courseID: number): [CourseStudent[] | null | undefined, boolean] {
    const dispatch = useHarmoniaDispatch();
    const courseStudents = useHarmoniaSelector((store) => store.selectedCourse.students);
    const [loading, setLoadingOn, setLoadingOff] = useFlag(true);
    useEffect(() => {
        // setLoadingOn();
        if (courseStudents === undefined && HarmoniaCookies["x-access-token"].get()) {
            dispatch(getCourseStudents(courseID)).finally(setLoadingOff);
        } else setLoadingOff();

    }, [courseStudents /* , dispatch, setLoadingOff, setLoadingOn */]);
    return [courseStudents, loading];

}

export function useDueDateExtensions(assignmentID: number): [DueDateExtension[] | null | undefined, boolean] {
    const dispatch = useHarmoniaDispatch();
    const dueDateExtensions = useHarmoniaSelector((store) => store.assignments.dueDateExtensions);
    const [loading, setLoadingOn, setLoadingOff] = useFlag(true);
    useEffect(() => {
        // setLoadingOn();
        if (dueDateExtensions === undefined && HarmoniaCookies["x-access-token"].get()) {
            dispatch(listAssignmentDueDateExtensions(assignmentID)).finally(setLoadingOff);
        } else setLoadingOff();
    }, [dueDateExtensions /* , dispatch, setLoadingOff, setLoadingOn */]);
    return [dueDateExtensions, loading];
};

export function useAssignmentDocuments(assignmentID: number): [Document[] | null | undefined, boolean] {
    const dispatch = useHarmoniaDispatch();
    const documents = useHarmoniaSelector((store) => store.assignments.assignmentDocuments);
    const [loading, setLoadingOn, setLoadingOff] = useFlag(true);
    useEffect(() => {
        // setLoadingOn();
        if (documents === undefined && HarmoniaCookies["x-access-token"].get()) {
            dispatch(getAssignmentDocuments(assignmentID)).finally(setLoadingOff);
        } else setLoadingOff();
    }, [documents /* , dispatch, setLoadingOff, setLoadingOn */]);

    console.log('documents in useAssignmentDocuments is: ', documents);
    return [documents, loading];
}

// Acquire all possible schools
export function useHarmoniaSchools() {
    const dispatch = useHarmoniaDispatch();
    const {schools} = useHarmoniaSelector((store) => store.utilities);
    const [loading, setLoadingOn, setLoadingOff] = useFlag(true);
    useEffect(() => {
        // setLoadingOn();
        if (schools === undefined) dispatch(fetchSchools()).finally(
            setLoadingOff);
        else setLoadingOff();
    }, [schools /* , dispatch, setLoadingOn, setLoadingOff */]);
    return [schools, loading] as [School[], boolean];
}

// Acquire all possible terms
export function useHarmoniaTerms() {
    const dispatch = useHarmoniaDispatch();
    const {terms} = useHarmoniaSelector((store) => store.utilities);
    const [loading, setLoadingOn, setLoadingOff] = useFlag(true);
    useEffect(() => {
        // setLoadingOn();
        if (terms === undefined) dispatch(fetchTerms()).finally(setLoadingOff);
        else setLoadingOff();
    }, [terms /* , dispatch, setLoadingOn, setLoadingOff */]);
    return [terms, loading] as [Term[], boolean];

    // const { terms } = useHarmoniaSelector((store) => store.utilities);
    // useEffect(() => {
    //   dispatch(fetchTerms());
    //    console.log(dispatch(terms));
    // }, [terms, dispatch]);
    // console.log("terms in hooks", terms);
    // return terms;
}
/**
 * Helper hook to acquire all possible languages for Harmonia Cloud dashboard.
 * If necessary, it will call API to fetch info.
 * Fetched information would automatically be stored in Redux after a successful API call.
 */
export function useHarmoniaLanguages(): [
    Language[] | undefined | null,
    boolean
] {
    const dispatch = useHarmoniaDispatch();
    const {languages} = useHarmoniaSelector((store) => store.utilities);
    const [loading, setLoadingOn, setLoadingOff] = useFlag(true);
    useEffect(() => {
        // setLoadingOn();
        if (languages === undefined)
            dispatch(fetchLanguages()).finally(setLoadingOff);
        else setLoadingOff();
    }, [languages /* , dispatch, setLoadingOn, setLoadingOff */]);
    return [languages, loading];
}

/**
 * Helper hook to acquire all possible timezones for Harmonia Cloud dashboard.
 * If necessary, it will call API to fetch info.
 * Fetched information would automatically be stored in Redux after a successful API call.
 */
export function useHarmoniaTimezones(): [
    Timezone[] | undefined | null,
    boolean
] {
    const dispatch = useHarmoniaDispatch();
    const {timezones} = useHarmoniaSelector((store) => store.utilities);
    const [loading, setLoadingOn, setLoadingOff] = useFlag(true);
    useEffect(() => {
        // setLoadingOn();
        if (
            timezones === undefined &&
            HarmoniaCookies["x-access-token"].get() !== undefined
        ) {
            dispatch(fetchTimezones()).finally(setLoadingOff);
        } else setLoadingOff();
    }, [timezones /* , dispatch, setLoadingOn, setLoadingOff */]);
    return [timezones, loading];
}

/**
 * Helper hook to get the current URL query parameters.
 * It builds upon the `useLocation` hook from React-router-dom package
 * to get the real-time URL. Then it parses query parameters
 * and returns an object including all the key-value pairs in URL query parameters.
 *
 * The returned object's keys are implicitly defined.
 * A key may not exist, and its value may be undefined.
 */
export function useQueryParams(): {[k: string]: string | undefined} {
    const {search: params} = useLocation();
    const result: {[key: string]: string | undefined} = {};
    if (!params.length || params[0] !== "?") return result;
    params
        .substring(1)
        .split("&")
        .forEach((pair) => {
            const [k, v] = pair.split("=");
            result[k] = v;
        });
    return result;
}

/**
 * Helper hook to redirect to other pages.
 * Once the `redirectCondition` flag is on, redirection would be initiated.
 *
 * This hook does not return anything. It's solely an `useEffect` hook register.
 *
 * @param redirectCondition A control flag to start the redirection.
 * @param to The link to be redirected.
 * @param notification Optional. If provided, a notification would be fired while redirecting.
 *
 * @example
 * useRedirect( // No return! Don't assign any variable.
 *   user?.user_type !== 2, // Redirect condition.
 *   '/my-profile', // Redirect endpoint
 *   { // Notification object, see 'redux/notification.ts'
 *     severity: 'error',
 *     message: 'Only instructors can use this feature.',
 *   });
 */
export function useRedirect(
    redirectCondition: boolean,
    to: string,
    notification?: Notification
): void {
    const history = useHistory();
    const dispatch = useHarmoniaDispatch();
    useEffect(() => {
        if (redirectCondition) {
            if (notification) dispatch(pushNotification(notification));
            history.push(to);
        }
    }, [dispatch, history, redirectCondition, to, notification]);
}
