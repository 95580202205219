import React, {useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {useHistory} from 'react-router';
import {Link} from 'react-router-dom';
import {HarmoniaButton, HarmoniaInput, MiniTemplate, Text} from '@harmonia-front-end/shared';
import LoadingPage from '../../components/LoadingPage';
import {pushErrorNotification, useHarmoniaDispatch} from '../../redux';
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {API, signInErrorMessage, signInWithTokenErrorMessage, HarmoniaCookies, errorMessage} from '../../utilities';
import {useRedirect, useQueryParams} from '../../hooks';
import UserFormStyle from './UserAuth.module.scss';
import LanguageSelect from '../../components/pre-auth/language-select';
import { DashboardContent } from '../../components/template';

const {
    content, form, formInputs, formLinks, formButton, input, timeout, timeoutIcon
} = UserFormStyle;

/**
 * User sign in page for the Harmonia Dashboard, routed to the page '/sign-in'.
 *
 * User has two ways to sign in: with their username and password or with a one-time sign in token.
 * Usually when provided with a one-time sign in token, the user has not set a password.
 * The user would be directed to the create password page to create a password.
 *
 * For the former, the user should input email and password to sign in.
 * @see signInThunk for the sign in API request call with user email and password.
 *
 * For the latter, a token should be presented in the URL query with the key 'value'.
 * @see signInWithTokenThunk for the sign in API request call with sign in token.
 */
export default function SignInPage() {
    const {token, redirect} = useQueryParams();
    const dispatch = useHarmoniaDispatch();
    const history = useHistory();
    const [error, setError] = React.useState(false);

    const [showTimeOut, setShowTimeOut] = useState(false);

    useRedirect(!!HarmoniaCookies["x-access-token"].get() && !token, '/');

    // if user has been auto logged-out, we grab the "timed-out" cookie after window was refreshed to this page and update state to show logged out message
    useEffect(() => {
        if (HarmoniaCookies['session-timed-out'].get()) {
            setShowTimeOut(true);
        }
    }, []);

    React.useEffect(() => {
        if (showTimeOut) {
            API.get('/api/users/sign-out')
                .then(() => {
                    HarmoniaCookies['x-access-token'].remove(); // Remove Cookies
                    window.sessionStorage.removeItem('googleSession');
                })
        }
    }, [showTimeOut]);

    // if there's a token in the URL, we post to sign-in-with-token route and use token to redirect to create password page
    useEffect(() => {
        if (token) {
            API.post('/api/users/sign-in/token', {token})
                .then(() => {window.location.href = `/create-password?token=${token}`;})
                .catch((err) => {setError(true); dispatch(pushErrorNotification(signInWithTokenErrorMessage(err.data)));});
        }
    }, [dispatch, token]);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setShowTimeOut(false);
        API.post('/api/users/sign-in', new FormData(e.currentTarget))
            .then(() => {window.location.href = redirect || '/';})
            .catch((err) => {setError(true); dispatch(pushErrorNotification(signInErrorMessage(err.data)));});
    };

    if (token) return error ? <></> : <DashboardContent><LoadingPage /></DashboardContent>;

    return (
        <MiniTemplate>
            <div className={content}>
                <Text variant="h1" color="grey" align="center" gutterBottom>
                    <FormattedMessage id="user.sign_in.title" defaultMessage="Sign In" />
                </Text>
                {/* display time out message if user was redirected to this page via auto-log-out */}
                {showTimeOut &&
                    <div className={timeout}>
                        <FontAwesomeIcon icon={faExclamationTriangle} className={timeoutIcon} />
                        <Text variant="body" color="red">
                            Your session has been logged out due to inactivity. Please sign in.
                        </Text>
                    </div>
                }
                <form onSubmit={handleSubmit} className={form}>
                    <div className={formInputs}>
                        <HarmoniaInput type="email" placeholder="Email Address" name="email" className={input} />
                        <HarmoniaInput type="password" placeholder="Password" name="password" className={input} />
                    </div>
                    <div className={formLinks}>
                        <Text color="lightBlue" variant="body">
                            <Link to="/reset-password">
                                <FormattedMessage id="user.sign_in.forgot_password" defaultMessage="Forgot Password?" />
                            </Link>
                        </Text>
                        <Text color="lightBlue" variant="body">
                            <Link to="/sign-up">
                                <FormattedMessage id="user.sign_up.title" defaultMessage="Sign Up" />
                            </Link>
                        </Text>
                    </div>
                    <HarmoniaButton type="submit" icon="next" variant="primary" dest="web" className={formButton}>
                        <FormattedMessage id="user.sign_in.title" defaultMessage="Sign In" />
                    </HarmoniaButton>
                </form>
                <LanguageSelect />
            </div>
        </MiniTemplate>
    );
}
