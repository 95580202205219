import React from 'react';
import HarmoniaInputStyle from './HarmoniaInput.module.scss';

const { input, required } = HarmoniaInputStyle;

type HarmoniaInputProps = {
  /** True if this input is a honeypot field, which is not displayed to user. */
  honeypot?: boolean;
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

/**
 * Input component that complies HarmoniaCloud prototype.
 * Expected to use this input component instead of HTML intrinsic input element.
 *
 * All HTML input attributes can be passed through and applied.
 */
const HarmoniaInput = React.forwardRef(
  (props: HarmoniaInputProps, ref: React.Ref<HTMLInputElement> | undefined) => {
    const { honeypot, className, ...rest } = props;
    const classList = className ? className.split(' ') : [];
    if (honeypot) classList.push(required);
    classList.push(input);
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <input ref={ref} className={classList.join(' ')} {...rest} />;
  },
);

HarmoniaInput.defaultProps = {
  honeypot: false,
};

export default HarmoniaInput;
