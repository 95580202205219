import React, {useState, useEffect} from 'react';
import { useHarmoniaSelector, getCourseDetail, useHarmoniaDispatch, getCourseStudents, deleteSubmissionOnStudentTable, deleteSubmissionByIDOnStudentTable} from '../../../redux';
import { HarmoniaButton, Text, useToggle } from "@harmonia-front-end/shared";
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { Submission, Assignment, Document, CourseStudent } from '../../../types';
import {formatStudentName} from '../../../utilities';
import StudentGradeBookEntry from './StudentGradeBookTableEntry';
import GradeBookPageStyle from './GradeBookPage.module.scss';
// import {useQueryParams} from '../../../hooks/hooks';
const {gradeBookTableContainer, gradeBookTable, gradeBookTableHeader, gradeBookTableBody, row, studentTableRow, collapse} = GradeBookPageStyle;

type StudentGradeBookTableProps = {
  selectedStudent: {name: string, id: number},
  assignments: Assignment[] | null | undefined,
  submissionsByStudent?: Submission[] | null | undefined,
  getStudentSubmissions: (docID: number, submissions: Submission[]) => Submission[],
  exportCSV: (assignments:any, submissionsByStudent:any, getStudentSubmissions:any, collapsed:boolean) => void
}

export enum EverySubmissionView {
  ALL, HIGHEST
}

const StudentGradeBookTable = (props: StudentGradeBookTableProps) => {
  const dispatch = useHarmoniaDispatch();
  const {selectedStudent, assignments,  submissionsByStudent, getStudentSubmissions, exportCSV} = props;

  const [removeSubmissionModalOpen, toggleRemoveSubmissionModal] = useToggle();
  const [removeSubmissionByIDModalOpen, toggleRemoveSubmissionByIDModal] = useToggle();
  // local state for expanding or collapsing all submission entries at once; passed down as prop to each StudentGradeBookEntry.
  const[expandAll, toggleExpandAll] = useToggle();

  // changing viewing all submissions as expanded (ALL) or collapsed (HIGHEST)
  const changeAllSubmissionsView = (ev:any) => {
    const viewBy = ev.currentTarget.value;
    if(expandAll === false && viewBy == EverySubmissionView.ALL){
      toggleExpandAll();
    }
    if(expandAll === true && viewBy == EverySubmissionView.HIGHEST) {
      toggleExpandAll();
    }
  }
  // local state with relevant doc info so removeSubmissionModal can be used for every document.
  const [submissionToRemove, setSubmissionToRemove] = useState({documentTitle: '', documentID: 0});
  const [subIDToRemove, setSubIDToRemove] = useState({documentTitle: '', submissionID: 0, indexToRemove: 0})

  // opens removeSubmissionModal and sets relevant doc info to display in modal.
  const showSubmissionRemoveModal = (documentTitle: string, documentID: number) => {
    toggleRemoveSubmissionModal();
    setSubmissionToRemove({documentTitle, documentID});
  };
  // closes removeSubmissionModal, makes API call and resets doc state to clear way for another doc to be selected.
  const confirmRemoveSubmission = (documentID: number) => {
    toggleRemoveSubmissionModal();
    dispatch(deleteSubmissionOnStudentTable({user: selectedStudent, document: documentID})).finally(() => setSubmissionToRemove({documentTitle: '', documentID: 0}));
  };

  const showSubmissionRemoveByIDModal = (documentTitle: string, submissionID: number, indexToRemove: number) => {
    toggleRemoveSubmissionByIDModal();
    setSubIDToRemove({documentTitle: documentTitle, submissionID: submissionID, indexToRemove: indexToRemove});
  };

  const confirmRemoveSubmissionByID = (submissionID: number) => {
    toggleRemoveSubmissionByIDModal();
    dispatch(deleteSubmissionByIDOnStudentTable({ submissionID, username: selectedStudent.name })).finally(() => setSubIDToRemove({ documentTitle: '', submissionID: 0, indexToRemove: 0 }));
  };

  if(!submissionsByStudent) return null;
  return (
    <>
    <div>
      <div style={{marginTop: '8px'}}>
        <label htmlFor="viewAll">View All Submissions (Sorted by Grade)</label>
        <input name="expandCollapseAll" type="radio" value={EverySubmissionView.ALL} onChange={changeAllSubmissionsView}/>
        <label htmlFor="viewSingle">View Highest Submission</label>
        <input name="expandCollapseAll" type="radio" value={EverySubmissionView.HIGHEST} checked={expandAll === false} onChange={changeAllSubmissionsView}/>
      </div>
    </div>
        <HarmoniaButton
          style={{marginTop: '8px'}}
          variant="primary"
          dest="app"
          icon="download"
          iconPos="left"
          onClick={() => exportCSV(assignments, submissionsByStudent, getStudentSubmissions, !expandAll)}>
          Download CSV File
        </HarmoniaButton>
    {/* NEW TABLE */}
    <div className={gradeBookTableContainer}>
      <div className={gradeBookTable}>
        {/* header */}
        <section className={`${gradeBookTableHeader} ${row} ${studentTableRow}`}>
          <section>
            <article>Document</article>
            <article>Submissions</article>
            <article>Highest Grade</article>
          </section>
          
          <section>
            <article>Submission # | Submission Time</article>
            <article>Score | % | Grade</article>
            <article>Actions</article>
          </section>
        </section>
        {/* body */}
        <div className={gradeBookTableBody}>
        {assignments?.map((assignment, assIndex) => {
          return assignment.documents?.map((document, docIndex) => {
            const submissions = getStudentSubmissions(document.id, submissionsByStudent);
            const assignmentDocNum = `${assIndex + 1}.${docIndex + 1}`;
            return (
              <StudentGradeBookEntry 
                assignmentDocNum={assignmentDocNum}
                document={document} 
                submissions={submissions} 
                key={assIndex + docIndex} 
                expandAll={expandAll} 
                showSubmissionRemoveModal={showSubmissionRemoveModal}
                showSubmissionRemoveByIDModal={showSubmissionRemoveByIDModal}
                courseID={assignment.course} 
              />
            )
          })
        })}
        </div>
      </div>
    </div>

    {/* REMOVE SUBMISSION MODAL */}
      <Modal isOpen={removeSubmissionModalOpen}>
        <ModalBody>
          <Text color="dark">
            {`Are you sure to want to remove ${formatStudentName(selectedStudent.name)}'s submission for ${submissionToRemove.documentTitle}?`}
          </Text>
        </ModalBody>
        <ModalFooter>
          <HarmoniaButton variant="primary" dest="app" onClick={() => {confirmRemoveSubmission(submissionToRemove.documentID)}} >Remove Submission</HarmoniaButton>
          <HarmoniaButton variant="secondary" dest="app" onClick={toggleRemoveSubmissionModal}>Cancel</HarmoniaButton>
        </ModalFooter>
       </Modal>
      {/* REMOVE SUBMISSION BY ID MODAL */}
        <Modal isOpen={removeSubmissionByIDModalOpen}>
          <ModalBody>
            <Text color="dark">
            {`Are you sure to want to remove ${formatStudentName(selectedStudent.name)}'s submission #${subIDToRemove.indexToRemove} for ${subIDToRemove.documentTitle}?`}
            </Text>
          </ModalBody>
          <ModalFooter>
          <HarmoniaButton variant="primary" dest="app" onClick={() => { confirmRemoveSubmissionByID(subIDToRemove.submissionID) }} >Remove Submission</HarmoniaButton>
            <HarmoniaButton variant="secondary" dest="app" onClick={toggleRemoveSubmissionByIDModal}>Cancel</HarmoniaButton>
          </ModalFooter>
        </Modal>
      </>
  )
}
export default StudentGradeBookTable;
