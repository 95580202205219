/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { HarmoniaButton, Text, useFlag } from '@harmonia-front-end/shared';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { Progress } from 'reactstrap';
import { DashboardContent, DashboardTitleBar } from '../../components/template';
import LoadingPage from '../../components/LoadingPage';
import MediaCollectionDisplay from '../../components/media/MediaCollectionDisplay';
import { useUser, useUserMediaCollectionsAndFiles, useRedirect } from '../../hooks';
import { createMediaCollection, reorderMediaCollections, useHarmoniaDispatch, useHarmoniaSelector } from '../../redux';
import MediaLibraryPageStyle from './MediaLibraryPage.module.scss';

const { drag, empty } = MediaLibraryPageStyle;

function MediaLibraryPage() {
  const dispatch = useHarmoniaDispatch();
  const [mediaCollections, userMediaCollectionLoading] = useUserMediaCollectionsAndFiles();
  const [user, userLoading] = useUser();
  const [dragLoading, setDragLoadingOn, setDragLoadingOff] = useFlag();

  // local state for showing draggable text
  const [dragPromptShow, setDragPromptShow] = useState(true);

  useRedirect(!userLoading && user?.user_type !== 2, '/my-profile', {
    severity: 'error',
    // TODO: refactor this to React Intl Node.
    message: 'Only instructors can use media library feature.',
  });

  const createNewMediaCollection = () => dispatch(createMediaCollection());

  const onMediaCollectionDragEnd = (result: DropResult) => {
    setDragPromptShow(false);

    const { destination, source } = result;
    if (!destination || destination.index === source.index) return;
    if (!mediaCollections || mediaCollections.length === 0) return;

    setDragLoadingOn();
    const idList = mediaCollections.map(({ id }) => id);
    const i1 = destination.index;
    const i2 = source.index;
    [idList[i1], idList[i2]] = [idList[i2], idList[i1]];
    dispatch(reorderMediaCollections(idList)).finally(setDragLoadingOff);
  };

  if (userMediaCollectionLoading || userLoading) return <DashboardContent><LoadingPage /></DashboardContent>;

  return (
    <>
      <DashboardTitleBar h1="Media Library">
        <HarmoniaButton variant="primary" dest="app" icon="add-to" iconPos="left" onClick={createNewMediaCollection}>
          Create Media Collection
        </HarmoniaButton>
      </DashboardTitleBar>
      <DashboardContent>

      {/* Show if user does not have any media collections */}
      {!Boolean(mediaCollections?.length) && (
        <Text className={empty} variant="dashboardHeading" color="black">
          You don't have any media collections yet.
        </Text>
      )}

        <div className={drag}>
          {dragPromptShow && Boolean(mediaCollections?.length) && (
            <Text variant="dashboardList" color="dark-grey" gutterBottom>
            You can reorder media files by dragging the arrows.
            </Text>
          )}
        </div>

        {/* dragLoading && (
          <Progress color="info" animated value={100}>
            <Text>Reordering...</Text>
          </Progress>
        ) */}
        <DragDropContext onDragEnd={onMediaCollectionDragEnd}>
          <Droppable droppableId="media-collection" isDropDisabled={dragLoading}>
            {(droppableProvided) => (
              <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                {mediaCollections?.map((col, index) => (
                  <MediaCollectionDisplay mediaCollection={col} key={col.id} index={index} />
                ))}
                {droppableProvided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {/* {(mediaCollections?.length === 0) && (
          <Text variant="dashboardContentHeading" color="grey" gutterBottom>
            Click &apos;Add Collection&apos; button to create a new media collection.
          </Text>
        )} */}
      </DashboardContent>
    </>
  );
}

export default MediaLibraryPage;
