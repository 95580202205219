// import libraries
import {useState, useEffect, Fragment} from 'react';
import { useParams} from 'react-router-dom';
import {
  useHarmoniaSelector,
  useHarmoniaDispatch,
  getCourseDetail,
  getCourseAssignments,
  getSubmissionsByCourseID
} from '../../redux';
import { Assignment} from '../../types';
import { DashboardContent, DashboardTitleBar } from '../../components/template';
import LoadingPage from '../../components/LoadingPage';
import { StudentAssignmentSet } from '../../components/course-student';
import StudentAssignmentsPageStyle from './StudentAssignmentsPage.module.scss';
import { Text } from "@harmonia-front-end/shared/component";
import { useRedirect } from '../../hooks';

const { empty } = StudentAssignmentsPageStyle

const StudentAssignmentsPage =() => {
  const dispatch = useHarmoniaDispatch();

  // Because the user is optionally able to navigate to this page via the URL, we have to grab the courseId from params (and convert it to a number) and use it to grab the all the course information anytime this page renders (rather than passing it down as a prop from page with list of courses).
  const { courseId } = useParams<{courseId: string}>();
  const courseID = parseInt(courseId, 10);

  // local state for adding loading page for courseInfo on TitleBar and assignments.
  const [courseDetailsLoading, setCourseDetailsLoading] = useState(true);
  const [courseAssignmentsLoading, setCourseAssignmentsLoading] = useState(true);
  const [submissionsLoading, setSubmissionsLoading] = useState(true);

  // Determine whether or not a user is an instructor once the courses load
  const courses = useHarmoniaSelector(state => state.courses)
  let foundCourse = courses?.course_students?.find(course => course.id === courseID);
  let needToRedirect = foundCourse ? false : !Object.keys(courses).length ? false : true;

  // grabbing courseInfo and courseAssignments and setting loading.
  useEffect(() => {
    if (foundCourse) {
      dispatch(getCourseDetail(courseID)).finally(() => setCourseDetailsLoading(false));
    }
  }, [dispatch, foundCourse]);

  useEffect(() => {
    if (foundCourse) {
      dispatch(getCourseAssignments(courseID)).finally(() => setCourseAssignmentsLoading(false));
    }
  }, [dispatch, foundCourse]);

  useRedirect(needToRedirect, '/', {
    severity: "error",
    message: "You are not a student of this course."
  });

  // Grabbing selected course and then courseInfo and assignments from Redux store.
  const selectedCourse = useHarmoniaSelector(state => state.selectedCourse);
  const courseInfo = selectedCourse.courseInformation;
  const assignments = selectedCourse.assignments as Assignment[] || [];

  // Grabbing user id from Redux
  const {user} = useHarmoniaSelector(state => state.user);

  // Once we have user id we can grab submissions
  useEffect(() => {
    if(foundCourse && user?.id){
      const submission = { userID: user?.id, courseID };
      dispatch(getSubmissionsByCourseID(submission)).finally(() => setSubmissionsLoading(false));
    }
  }, [dispatch, user]);

  const {submissionsByUser} = useHarmoniaSelector(state => state.selectedCourse);


  return (
    <>
      {courseDetailsLoading && courseAssignmentsLoading && <DashboardContent><LoadingPage/></DashboardContent>}
      {!courseDetailsLoading &&
      <DashboardTitleBar h1="Grade Book" h2={courseInfo?.title} h2_no2={`${courseInfo?.academic_term} ${courseInfo?.year}`}>

      </DashboardTitleBar> }
      <DashboardContent>
        {/* Show if no assignments yet in course */}
        {Boolean(!assignments.length) && Boolean(!courseAssignmentsLoading) && (
          <Text className={empty} variant="dashboardHeading" color="black">
            This course doesn't have any assignment sets yet.
          </Text>
        )}

        {submissionsByUser &&
                <div>
                  {assignments?.map((assignment, assIndex) => {
                    return (
                      <Fragment key={assIndex}>
                        <StudentAssignmentSet courseID={courseID} assignment={assignment} studentSubmissions={submissionsByUser}/>
                      </Fragment>
                    )
                  })}
                </div>
        }
      </DashboardContent>
    </>
  );
};

export default StudentAssignmentsPage;
