// import libraries
import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import { HarmoniaButton, Text } from "@harmonia-front-end/shared/component";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import { Link } from "react-router-dom";
import { useHarmoniaSelector, getCourses, useHarmoniaDispatch } from "../../../redux";
import LoadingPage from "../../LoadingPage";
import { CourseRow } from ".";
import { DashboardContent, DashboardTitleBar } from "../../template";
import { CourseDetailedInfo } from "../../../types";
import AllCoursesStyle from './AllCourses.module.scss';

const {wrapper, switchTab, selected, unselected, drag, empty} = AllCoursesStyle;

type AllCoursesProps = {
  courseArchived: boolean,
  course_instructors: CourseDetailedInfo[],
  course_tas: CourseDetailedInfo[],
  course_students: CourseDetailedInfo[],
  setWaiting: (waiting: boolean) => void 
}
export default function AllCourses({courseArchived , course_instructors, course_tas, course_students, setWaiting}: AllCoursesProps) {

  // local state for showing draggable text
  const [dragPromptShow, setDragPromptShow] = useState(true);

  // NOTE: old way of switching between active and archived courses. Not deleting for now so we can have Formatted Message for reference.
  const switchButton = courseArchived ? (
    <Link to="/">
      <HarmoniaButton type="button" icon="back" variant="primary" dest="app">
        <FormattedMessage
          id="dashboard.see_my_active_courses"
          defaultMessage="See My Active Courses"
        />
      </HarmoniaButton>
    </Link>
  ) : (
    <Link to="/archived-courses">
      <HarmoniaButton type="button" icon="next" variant="primary" dest="app">
        <FormattedMessage
          id="dashboard.see_my_archived_courses"
          defaultMessage="See My Archived Courses"
        />
      </HarmoniaButton>
    </Link>
  );
  // tabs to switch between active and archive courses along with dynamic classnames
  const active = !courseArchived ? selected : unselected;
  const archived = courseArchived ? selected : unselected;

  const switchTabs = (
    <div className={wrapper}>
      <div className={switchTab}>
        <Link to="/">
          <span className={active}>
          Active Courses
          </span>
        </Link>
        <Link to="/archived-courses">
          <span className={archived}>
          Archived Courses
          </span>
        </Link>
      </div>
    </div>
  );
  // checking if a user has any courses
  const hasCourses = course_instructors.length > 0 || course_tas.length > 0 || course_students.length > 0;

  // helper function for filtering courses according to active/archive.
  const filteredActiveOrArchiveCourses = (courses: CourseDetailedInfo[]) => {
    return courses?.filter(course => course.archived === courseArchived);
  }

  // function to pass setDragPromptShow to CourseRow
  const removeDragPrompt = () => {
    setDragPromptShow(false);
  }

  return (
    <>
      <div data-cy='allCourses'>
        {/* Show if user does not have any courses */}
        {!hasCourses && (
          <Text className={empty} variant="dashboardHeading" color="black">
            You don't have any courses yet.
          </Text>
        )}

        {/* only show active/archived tabs if user has any courses */}
        {hasCourses && switchTabs}
        {/* {switchButton} */}

        {/* Show draggable text only if user has not dragged anything */}
        <div className={drag}>
          {
            dragPromptShow && hasCourses && (
              <Text variant="dashboardWidgetHeading" color="dark-grey" gutterBottom>
              You can reorder courses by dragging the arrows.
              </Text>
          )}
        </div>

        <CourseRow
          userCourseRole="instructor"
          courseArchived={courseArchived}
          courses={filteredActiveOrArchiveCourses(course_instructors)}
          removeDragPrompt={removeDragPrompt}
          setWaiting={setWaiting}
        />
        <CourseRow
          userCourseRole="ta"
          courseArchived={courseArchived}
          courses={filteredActiveOrArchiveCourses(course_tas)}
          removeDragPrompt={removeDragPrompt}
          setWaiting={setWaiting}
        />
        <CourseRow
          userCourseRole="student"
          courseArchived={courseArchived}
          courses={filteredActiveOrArchiveCourses(course_students)}
          removeDragPrompt={removeDragPrompt}
          setWaiting={setWaiting}
        />
      </div>
    </>
  );
}
