import React from 'react';
import { Text } from '@harmonia-front-end/shared';
import { faChevronDown, faTachometerAlt } from '@fortawesome/free-solid-svg-icons';
import { useParams} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { useUser } from '../../hooks';
import LoadingPage from '../LoadingPage';
import { UserType } from '../../types';
import style from './DashboardSidebarDropdown.module.scss';

/** Object structure to describe a link entry's metadata. */
type SidebarRoute = {
  /** ReactNode key attribute, for an array of ReactNode */
  key: string,

  /** A React Node, for intl support. */
  text: React.ReactNode,

  /** Link direction.  */
  href: string,

  /** Accessible user's list. Optional. `undefined` means all users can access. */
  access?: UserType[];
};

const {
  grey, blue, box, title, titleText, titleIcon, highlight, item,
} = style;

// TODO: change text to React INTL Node.
const dashboardRoutes: SidebarRoute[] = [
  { key: 'Courses', text: 'Courses', href: '/' },
  {
    key: 'content-library', text: 'Content Library', href: '/content-library', access: [UserType.INSTRUCTOR],
  },
  { // Media library is a instructor-only feature.
    key: 'media-library', text: 'Media Library', href: '/media-library', access: [UserType.INSTRUCTOR],
  },
  { key: 'my-profile', text: 'My Profile', href: '/my-profile' },
  { key: 'sign-out', text: 'Sign Out', href: '/sign-out' },
];

// TODO: change text to React INTL Node.
const courseRoutes = (id?: number): SidebarRoute[] => [
  { key: 'assignments', text: 'Assignments', href: `/course/${id}`, access: [UserType.INSTRUCTOR] },
  { key: 'course-info', text: 'Course Information', href: `/course/${id}/info`, access: [UserType.INSTRUCTOR] },
  // { key: 'discussion', text: 'Discussion', href: `/course/${id}/discussion`, access: [UserType.INSTRUCTOR] },
  { key: 'people', text: 'People', href: `/course/${id}/people`, access: [UserType.INSTRUCTOR] },
  { key: 'grade-book', text: 'Grade Book', href: `/course/${id}/grade-book`, access: [UserType.INSTRUCTOR] },

  { key: 'student-course-info', text: 'Course Information', href: `/student/course/${id}/info`, access: [UserType.STUDENT] },
  { key: 'student-assignments', text: 'Grade Book', href: `/student/course/${id}/`, access: [UserType.STUDENT] },
];

type DashboardSidebarDropdownProps = {
  type: 'dashboard' | 'course',
  h1?: string,
  courseID?: number,
  isTA?: boolean
};

function DashboardSidebarDropdown(props: DashboardSidebarDropdownProps) {
  const {
    type, h1, courseID, isTA
  } = props;

  const [user, loading] = useUser();
  const { pathname } = useLocation();
  // console.log('pathname in sidebarDropdown is: ', pathname);

  const titleBarClass = [title, type === 'dashboard' ? blue : grey].join(' ');


  const isValid = type === 'dashboard' ? true : (h1 && courseID);
  if (!isValid) return null;
  const faIcon = type === 'dashboard' ? faTachometerAlt : faChevronDown;
  const routes = type === 'dashboard' ? dashboardRoutes : courseRoutes(courseID);

  if (loading || !user) return <LoadingPage />;



  return (
    <div className={box}>

      <div className={titleBarClass}>
        <div className={titleText}>
          {type === 'dashboard' ? (
            <Text variant="sidebarListHeading" color="light">Dashboard</Text>
          ) : (
            <>
              <Text variant="sidebarListHeading" color="light">{h1}</Text>
            </>
          )}
        </div>
        {type === 'dashboard' && <FontAwesomeIcon icon={faTachometerAlt} className={titleIcon} size ="lg" /> }
        {/* <FontAwesomeIcon icon={faIcon} className={titleIcon}   size="lg" /> */}
      </div>

      {routes.map((route) => {
        const {
          key, text, href, access,
        } = route;
        const classList = [item];
        if (pathname === href) classList.push(highlight);
        if (!access || access.includes(isTA ? UserType.INSTRUCTOR : user.user_type)) {
          return (
            <div key={key} className={classList.join(' ')}>
              <Link to={href}>
                <Text variant="sidebarListItem" color="dark">
                  {text}
                </Text>
              </Link>
            </div>
          );
        }
        return null;
      })}

    </div>
  );
}

DashboardSidebarDropdown.defaultProps = {
  h1: '',
  h2: '',
  courseID: 0,
};

export default DashboardSidebarDropdown;
