import React from 'react';
import MainContainerStyle from './MainContainer.module.scss';

const {
  mainContainer, canvasFull, canvasPrimary, canvasMini,
} = MainContainerStyle;

type MainContainerProps = React.PropsWithChildren<{
  /** Additional HTML classes to apply to container if needed. */
  containerClassName?: 'string';

  /** Inner canvas. Different value denotes different canvas width. */
  canvasType: 'full' | 'primary' | 'mini';

  /** Additional HTML classes to apply to inner canvas if needed. */
  canvasClassName?: 'string';
}>;

/**
 * General container that complies with the Harmonia Cloud prototype.
 *
 * The external HTML div element is a grid container with 12 columns and 1% column gap width.
 * The inner HTML div element, the canvas, has its width being defined by `canvasType` prop.
 *
 * This container has a maximum width of a xx-large device.
 * Definition on how wide a xx-large device is can be found in styles definition.
 */
function MainContainer(props: MainContainerProps) {
  const {
    children, canvasType, containerClassName, canvasClassName,
  } = props;

  const containerClassList = containerClassName ? containerClassName.split(' ') : [];
  containerClassList.push(mainContainer);

  const canvasClassList = canvasClassName ? canvasClassName.split(' ') : [];
  if (canvasType === 'full') canvasClassList.push(canvasFull);
  if (canvasType === 'primary') canvasClassList.push(canvasPrimary);
  if (canvasType === 'mini') canvasClassList.push(canvasMini);

  return (
    <div className={containerClassList.join(' ')}>
      <div className={canvasClassList.join(' ')}>
        {children}
      </div>
    </div>
  );
}

export default MainContainer;
