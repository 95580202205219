import React from 'react';
import { Spinner } from 'reactstrap';
import { Text } from '@harmonia-front-end/shared';
import style from './LoadingPage.module.scss';

const { middleCenter, spinner } = style;

export default function LoadingPage() {
  return (
    <div className={middleCenter}>
      <Spinner type="grow" className={spinner} />
      <Text color="dark" variant="body">Loading...</Text>
    </div>
  );
}
