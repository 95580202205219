import {useState, useEffect} from 'react';
// import React from 'react';
import {useParams} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import {MiniTemplate, Text} from '@harmonia-front-end/shared';
import {useHarmoniaSelector, useHarmoniaDispatch, getUserInvitationCourseInfoByToken, acceptUserInvitationWithToken} from '../../redux';
import LoadingPage from '../../components/LoadingPage';
// import {CourseInvitation} from '../../types'

function AcceptInvitationPage() {
    const {token} = useParams<{token: string}>() as {token: string};

    enum InvitationState {
        WAITING_FOR_INFO,
        WAITING_FOR_ACCEPT,
        DONE
    };
    const [invitationState, setInvitationState] = useState(InvitationState.WAITING_FOR_INFO) as [InvitationState, React.Dispatch<React.SetStateAction<InvitationState>>];
    enum DisplayState {
        LOADING,
        SUCCESS,
        FAILURE,
        ERROR
    };
    const [displayState, setDisplayState] = useState(DisplayState.LOADING) as [DisplayState, React.Dispatch<React.SetStateAction<DisplayState>>];

    const dispatch = useHarmoniaDispatch();
    const courseInfo = useHarmoniaSelector(state => state.courses.invitationLink);
    const courseInfoValid = () => {
        return courseInfo && courseInfo.token === token;
    };

    useEffect(() => {
        switch (invitationState) {
            case InvitationState.WAITING_FOR_INFO:
                dispatch(getUserInvitationCourseInfoByToken(token)).finally(() => {setInvitationState(InvitationState.WAITING_FOR_ACCEPT);});
                break;
            case InvitationState.WAITING_FOR_ACCEPT:
                if (courseInfoValid()) { // got info
                    if (courseInfo?.notFound)
                        setInvitationState(InvitationState.DONE);
                    else
                        dispatch(acceptUserInvitationWithToken(token)).finally(() => {setInvitationState(InvitationState.DONE);});
                } else
                    setInvitationState(InvitationState.DONE);
                break;
            case InvitationState.DONE:
                if (courseInfoValid()) { // got info
                    setDisplayState(courseInfo?.accepted ? DisplayState.SUCCESS : courseInfo?.notFound ? DisplayState.FAILURE : DisplayState.ERROR);
                } else 
                    setDisplayState(DisplayState.ERROR);
                break;
        }
    }, [invitationState]);

    return (
        <MiniTemplate>
            <div>
                <Text variant="h1" color="grey" align="center" gutterBottom>
                    Course Invitation
                </Text>
                {displayState === DisplayState.LOADING && <LoadingPage />}
                {displayState === DisplayState.FAILURE &&
                    <Text style={{paddingLeft: '12px', paddingRight: '12px', paddingBottom: '8px'}} variant="dashboardContentHeading" color="dark" align="center">
                        <FormattedMessage id="user.invitation_not_available" defaultMessage='This course invitation is no longer available.' />
                    </Text>
                }
                {displayState === DisplayState.SUCCESS &&
                    <Text style={{paddingLeft: '12px', paddingRight: '12px', paddingBottom: '8px'}} variant="dashboardContentHeading" color="dark" align="center">
                        <FormattedMessage id="user.invitation_accepted" defaultMessage={`You have successfully joined the course "${courseInfo?.courseInfo?.course_info.title}."`} />
                    </Text>
                }
                {displayState === DisplayState.ERROR &&
                    <Text style={{paddingLeft: '12px', paddingRight: '12px', paddingBottom: '8px'}} variant="dashboardContentHeading" color="dark" align="center">
                        <FormattedMessage id="user.invitation_accepted" defaultMessage='There was an error accessing this course invitation.' />
                    </Text>
                }
            </div>
        </MiniTemplate>
    );
}

export default AcceptInvitationPage;
