import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {
  HarmoniaButton, HarmoniaInput, MiniTemplate, Text,
} from '@harmonia-front-end/shared';
import { API, signUpErrorMessage } from '../../utilities';
import { pushErrorNotification, pushNotification, useHarmoniaDispatch } from '../../redux';

import UserFormStyle from './UserAuth.module.scss';
import LanguageSelect from '../../components/pre-auth/language-select';

const {
  content, form, formInputs, formLinks, formButton, input,
} = UserFormStyle;

/**
 * User sign up page for the Harmonia Dashboard, routed to the page '/sign-up'.
 *
 * This page gathers new user info and send it to the backend.
 * When approved, the backend will send a one-time login token to allow the new user to sign in.
 * Immediately, the new user would be promoted to create a new password.
 *
 * To prevent bot attack, there are three honey pot field that should be left empty
 * for a human new user. If one of the honey pot field contains concrete data, the sign up request
 * would fail silently.
 * @see signUpThunk The workflow for new users to sign up.
 */
export default function SignUpPage() {
  const dispatch = useHarmoniaDispatch();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const newUser = {
      first_name: formData.get('first_name'),
      last_name: formData.get('last_name'),
      email: formData.get('email'),
      gender: formData.get('gender'),
      school: formData.get('school'),
      phone_number: formData.get('phone_number'),
      will_receive_marketing_emails: Boolean(formData.get('will_receive_marketing_emails')),
      will_receive_notification_emails: Boolean(formData.get('will_receive_notification_emails')),
    };
    API.post('/api/users', newUser)
      .then(() => {
         window.location.href = '/sign-up/confirmation';
      })
      .catch((err) => dispatch(pushErrorNotification(signUpErrorMessage(err.data))));
  };

  return (
    <MiniTemplate>
      <div className={content}>
        <Text variant="h1" color="grey" align="center" gutterBottom>
          <FormattedMessage id="user.sign_up.title" defaultMessage="Sign Up" />
        </Text>
        <form onSubmit={handleSubmit} className={form}>
          <div className={formInputs}>
            <HarmoniaInput className={input} type="text" placeholder="First Name" name="first_name" />
            <HarmoniaInput className={input} type="text" placeholder="Last Name" name="last_name" />
            <HarmoniaInput className={input} type="email" placeholder="Email Address" name="email" />
            <HarmoniaInput className={input} type="text" name="gender" placeholder="Gender" honeypot />
            <HarmoniaInput className={input} type="text" name="school" placeholder="School" honeypot />
            <HarmoniaInput className={input} type="text" name="phone_number" placeholder="Phone number" honeypot />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="will_receive_notification_emails">
              <HarmoniaInput type="checkbox" name="will_receive_notification_emails" />
              <FormattedMessage id="user.settings.receive_system_notifications" defaultMessage="Receive System Notifications" />
            </label>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="will_receive_marketing_emails">
              <HarmoniaInput type="checkbox" name="will_receive_marketing_emails" />
              <FormattedMessage id="user.settings.receive_promotion_emails" defaultMessage="Receive Promotion Emails" />
            </label>
          </div>

          <div className={formLinks}>
            <Link to="/sign-in">
              <FormattedMessage id="user.sign_in.title" defaultMessage="Sign In" />
            </Link>
          </div>

          <HarmoniaButton className={formButton} icon="next" variant="primary" dest="web" type="submit">
            <FormattedMessage id="user.sign_up.title" defaultMessage="Sign Up" />
          </HarmoniaButton>
        </form>

        <Text variant="body" color="dark">
          <FormattedMessage
            id="user.sign_up.tos_notification"
            defaultMessage="By submitting this form, you accept the Illiac Software Terms of service and Privacy Policy."
          />
        </Text>
        <div style={{marginTop: '8px'}}>
          <LanguageSelect/>
        </div>

      </div>
    </MiniTemplate>
  );
}
