import {
  createAction,
  createAsyncThunk,
  createReducer,createSlice,current, isRejectedWithValue
} from "@reduxjs/toolkit";

import {CourseDetailedInfo, Courses, CoursesAndUser, CoursesInfo, CourseEnrollmentRequest, CourseInvitation} from '../types';
import { API } from "../utilities";
import { pushErrorNotification, pushNotification } from "./notification";
import { errorMessage } from '../utilities';
import {getUserInfo} from './user';
import { invariantIntlContext } from "react-intl/src/utils";

type CoursesStateType = {
  course_instructors?: CourseDetailedInfo[] | null,
  course_students?: CourseDetailedInfo[] | null,
  course_tas?: CourseDetailedInfo[] | null,
  enrollmentRequests?: CourseEnrollmentRequest[] | null,
  invitations?: CourseInvitation[] | null,
  invitationLink?: {courseInfo?: CourseInvitation | null, token?: string, accepted?: boolean, notFound?: boolean} | null
};

const initialState: CoursesStateType = {};

/** ACTIONS/THUNKS */

export const createCourse = createAsyncThunk<CourseDetailedInfo, void, {rejectValue: void}>('courses/createCourse', async(_, {dispatch, rejectWithValue}) => {
  try{
    const response = await API.post(`/api/courses`);
    const course = response.data.data as CourseDetailedInfo;
    // when a new course is created, the user is automatically redirected to the settings page of said new course.
    if(response.status === 200){
      // Use URL parameters to send information about course creation to CourseInformationPage
      window.location.href = `/course/${course.id}/settings#course_created`;
    }
    return course;
  }catch(e){
    dispatch(pushErrorNotification(errorMessage(e.data)));
    return rejectWithValue();
  }
});

export const duplicateCourse =
createAsyncThunk<CourseDetailedInfo, number, {rejectValue: void}>('/courses/duplicateCourse', async(courseID, {dispatch, rejectWithValue}) => {
  try {
    // when course is successfully duplicated, user is automatically redirected to its settings page. We have to do window.location redirect and only in this function do we have immediate access to cloned course id.
    const response = await API.post(`/api/courses/${courseID}/duplicate`);
    const course = response.data.data as CourseDetailedInfo;
    if (response.status === 200) {
      window.location.href = `/course/${course.id}/settings`
    }
    return course;
  } catch(e) {
    dispatch(pushErrorNotification(errorMessage(e.data)));
    return rejectWithValue();
  }

});



// unarchiveArchive, setCourses, clearCourseInfo, deleteCourse

const clearCourseInfo = createAction('courses/clearCourseInfo');
const setCourses = createAction<Courses>('courses/setCourses');

export const getCoursesInfo = createAsyncThunk<CoursesInfo, void, {rejectValue: void}>('courses/getCoursesInfo', async (_, {dispatch, rejectWithValue}) => {
  try{
    const response = await API.get('/api/users');
    const {
      course_instructors, course_students, course_tas, invitations, enrollment_requests
    } = response.data.data as CoursesAndUser;

    const coursesInfo: CoursesInfo= {
      course_instructors, course_students, course_tas, enrollment_requests, invitations
    };
    return coursesInfo;
  }catch(e){
    dispatch(pushErrorNotification("User is not signed in."));
    dispatch(clearCourseInfo());
    return rejectWithValue();
  }
});

export const archiveUnarchiveCourse = createAsyncThunk<Courses, {course: CourseDetailedInfo, archive: boolean, userCourseRole: 'ta' | 'instructor' | 'student'}, {rejectValue: void}>('course/archiveUnarchiveCourse', async ({course, archive, userCourseRole}, {dispatch, rejectWithValue, getState}) => {

  const state = getState();

  const coursesKeyMap = {
    instructor: 'course_instructors',
    student: 'course_students',
    ta: 'course_tas'
  };
  const coursesOfRole = state.courses[coursesKeyMap[userCourseRole]] as CourseDetailedInfo[];

  const payload = {
    role: userCourseRole,
    active: [] as number[],
    archived: [] as number[]
  };
  // Construct payload without course being modified
  for(const courseOfRole of coursesOfRole){
    if(courseOfRole.id === course.id){
      continue;
    }
    courseOfRole.archived? payload.archived.push(courseOfRole.id) : payload.active.push(courseOfRole.id);
  }

  // Add the ID of the course being modified to the start of the array.
  archive ? payload.archived.unshift(course.id) : payload.active.unshift(course.id);

  // console.log('payload is: ', payload);

  try {
    // Make API call to update courses.
    const response = await API.post('/api/courses/weight', payload);
    // Deconstruct response data from API call...
    const {course_instructors, course_students, course_tas} = response.data.data as Courses;
    // ...and then reconstruct it to have structure/type of Courses state.
    const courses = {
      course_instructors, course_students, course_tas
    };

    // const messageConfirm = archive ? "archived" : "unarchived";
    // dispatch(pushNotification({
    //   severity: "success",
    //   message: `"${course.title}" ${messageConfirm}.`
    // }))

    return courses;
  }catch(e){
    dispatch(pushErrorNotification(errorMessage(e.data)));
    return rejectWithValue();
  }
});

export const updateCoursesOrder = createAsyncThunk<Courses, {newOrder: number[], archive: boolean, userCourseRole: 'instructor' | 'student' | 'ta'}, {rejectValue:void}>('courses/reorderCourses', async ({newOrder, archive, userCourseRole}, {dispatch, rejectWithValue, getState}) => {

    const state = getState();

    const coursesKeyMap = {
      instructor: 'course_instructors',
      student: 'course_students',
      ta: 'course_tas'
    };
    const coursesOfRole = state.courses[coursesKeyMap[userCourseRole]] as CourseDetailedInfo[];

    const payload = {
      role: userCourseRole,
      active: [] as number[],
      archived: [] as number[]
    };
    // Construct payload without course being modified
  for(const course of coursesOfRole){
    course.archived? payload.archived.push(course.id) : payload.active.push(course.id);
  }

  // Replace specific array with new order
  archive ? payload.archived = newOrder: payload.active = newOrder;
  try{
    // Make API call to update courses.
    const response = await API.post('api/courses/weight', payload);
    // Deconstruct response data from API call...
    const {course_instructors, course_students, course_tas} = response.data.data as Courses;
    // ...and then reconstruct it to have structure/type of Courses state.
    const courses = {course_instructors, course_students, course_tas};

    // dispatch(pushNotification({
    //   severity: 'success',
    //   message: 'New course order saved.'
    // }));

    return courses;
  }
  catch(e){
    dispatch(pushErrorNotification(errorMessage(e.data)));
    return rejectWithValue();
  }
});

export const updateCoursesOrderLocally = createAsyncThunk<CourseDetailedInfo[], {reorderedCourses: CourseDetailedInfo[], userCourseRole: 'instructor' | 'ta' | 'student'}, {rejectValue: void}>('/coursesReorderCoursesLocally', async ({reorderedCourses, userCourseRole}, {dispatch, rejectWithValue}) => {
  try {
    return reorderedCourses;
  }catch(e) {
    dispatch(pushErrorNotification(errorMessage(e.data)));
    return rejectWithValue();
  }
})

 export const deleteCourse = createAsyncThunk('courses/deleteCourse', async (courseID: number, {dispatch}) => {
    try {
      const response = await API.delete(`/api/courses/${courseID}`);
      dispatch(pushNotification({
        severity: 'success',
        message: 'Course deleted.'
      }))
      console.log("response in deleteCourse", response);
    }catch(e){
      dispatch(pushErrorNotification(errorMessage(e.data)));
    }
});

/** ENROLLMENT REQUESTS */

export const getUserCourseEnrollmentRequests = createAsyncThunk<CourseEnrollmentRequest[], void, {rejectValue: void}>(
  'courses/getUserCourseEnrollmentRequests', async (_, {dispatch, rejectWithValue}) => {
  try{
    const response = await API.get('/api/course-enrollment-requests');
    console.log('response is: ', response);
    const enrollmentRequests = response.data.data as CourseEnrollmentRequest[];
    return enrollmentRequests;
  }catch(e){
    dispatch(pushErrorNotification(errorMessage(e.data)));
    return rejectWithValue();
  }
});

export const createUserCourseEnrollmentRequest = createAsyncThunk<CourseEnrollmentRequest, string, {rejectValue: void}>(
  'courses/createUserCourseEnrollmentRequest', async (invitation_code, {dispatch, rejectWithValue}) => {
  try{
    const response = await API.post('/api/course-enrollment-requests',{invitation_code});
    const enrollmentRequest = response.data.data as CourseEnrollmentRequest;
    dispatch(pushNotification({
      severity: 'success',
      message: 'Enrollment request submitted!'
    }))
    return enrollmentRequest;
  }catch(e){
    // dispatch(pushErrorNotification(errorMessage(e.data)));
    dispatch(pushErrorNotification(e.status === 409 ? "Enrollment request already submitted." : "Couldn't submit enrollment request."));
    return rejectWithValue();
  }
});

export const deleteUserCourseEnrollmentRequest = createAsyncThunk<void, CourseEnrollmentRequest, {rejectValue: void}>(
  'courses/deleteUserCourseEnrollmentRequest', async (enrollmentRequest, {dispatch, rejectWithValue}) => {
  try{
    const response = await API.delete(`/api/course-enrollment-requests/${enrollmentRequest.id}`);
    const courseTitle = enrollmentRequest.course.title;
    dispatch(pushNotification({
      severity: 'success',
      message: `Request to enroll in "${courseTitle} cancelled."`
    }))
    console.log('response in deleteUserCourseEnrollmentRequest', response);
    return response.data.data
  }catch(e){
    dispatch(pushErrorNotification(errorMessage(e.data)));
    return rejectWithValue();
  }
});

/** COURSE INVITATIONS */

export const acceptUserCourseInvitation = createAsyncThunk<void, CourseInvitation, {rejectValue: void}>(
  'courses/acceptUserCourseInvitation', async (invitation, {dispatch, rejectWithValue}) => {
  try{
    const response = await API.patch(`/api/course-invitations/${invitation.id}`);
    const courseTitle = invitation.course_info.title;
    dispatch(getCoursesInfo());
    dispatch(pushNotification({
      severity: "success",
      message: `Joined "${courseTitle}"!`
    }))
    console.log('response in acceptInvitation: ', response)
  }catch(e){
    dispatch(pushErrorNotification(errorMessage(e.data)));
    return rejectWithValue();
  }
});

export const deleteUserCourseInvitation = createAsyncThunk<void, CourseInvitation, {rejectValue: void}>(
  'courses/deleteUserCourseInvitation', async (invitation, {dispatch, rejectWithValue}) => {
  try{
    const response = await API.delete(`/api/course-invitations/${invitation.id}`);
    const courseTitle = invitation.course_info.title
    dispatch(pushNotification({
      severity: 'success',
      message: `Invitation to join "${courseTitle}" declined.`
    }))
    console.log('response in deleteInvitation: ', response);
    return response.data.data
  }catch(e){
    dispatch(pushErrorNotification(errorMessage(e.data)));
    return rejectWithValue();
  }
});

export const getUserInvitationCourseInfoByToken = createAsyncThunk<CourseInvitation, string, {rejectValue: boolean}>(
  'courses/getUserInvitationCourseInfoByToken', async (token: string, {dispatch, rejectWithValue}) => {
  try {
    const response = await API.get(`/api/course-invitations/${token}`);
    return response.data.data
  } catch(e) {
    return rejectWithValue(e.status === 404);
  }
});

export const acceptUserInvitationWithToken = createAsyncThunk<void, string, {rejectValue: boolean}>(
  'courses/acceptUserInvitationWithToken', async (token: string, {dispatch, rejectWithValue}) => {
  try {
    const response = await API.patch(`/api/course-invitations/${token}`);
  } catch(e) {
    let s = e.status;
    return rejectWithValue(s === 404 || s === 409 || s === 403);
  }
});

const coursesSlice = createSlice({
  name: 'courses',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(getUserInfo.fulfilled, (state, {payload}) => {
      state.course_instructors = payload.course_instructors;
      state.course_students = payload.course_students;
      state.course_tas = payload.course_tas;
      state.enrollmentRequests = payload.enrollment_requests;
      state.invitations = payload.invitations;
    })
    .addCase(getCoursesInfo.fulfilled, (state, {payload}) => {
      state.course_instructors = payload.course_instructors;
      state.course_students = payload.course_students;
      state.course_tas = payload.course_tas;
      state.enrollmentRequests = payload.enrollment_requests;
      state.invitations = payload.invitations;
    })
    .addCase(createCourse.fulfilled, (state, {payload}) =>{
      if(!state.course_instructors) state.course_instructors = [];
      state.course_instructors = [...state.course_instructors, payload];
    })
    .addCase(duplicateCourse.fulfilled, (state, {payload}) => {
      if(!state.course_instructors) state.course_instructors = [];
      state.course_instructors = [...state.course_instructors, payload];
    })
    .addCase(archiveUnarchiveCourse.fulfilled, (state, {payload}) => {
      state.course_instructors = payload.course_instructors;
      state.course_students = payload.course_students;
      state.course_tas = payload.course_tas;
    })
    .addCase(updateCoursesOrder.fulfilled, (state, {payload}) => {
      state.course_instructors = payload.course_instructors;
      state.course_students = payload.course_students;
      state.course_tas = payload.course_tas;
    })
    .addCase(updateCoursesOrderLocally.fulfilled, (state, {payload, meta}) => {
      if(meta.arg.userCourseRole === 'instructor'){
        state.course_instructors = payload;
      }
      if(meta.arg.userCourseRole === 'ta'){
        state.course_tas = payload;
      }
      if(meta.arg.userCourseRole === 'student'){
        state.course_students = payload;
      }
    })
    .addCase(deleteCourse.fulfilled, (state, {meta}) => {
       const coursesWithoutDeleted = state.course_instructors?.filter(course => course.id !== meta.arg);
       state.course_instructors = coursesWithoutDeleted;
    })
    .addCase(getUserCourseEnrollmentRequests.fulfilled, (state, {payload}) => {
      state.enrollmentRequests = payload;
    })
    .addCase(createUserCourseEnrollmentRequest.fulfilled, (state, {payload}) => {
      if(!state.enrollmentRequests) state.enrollmentRequests = [];
      state.enrollmentRequests = [...state.enrollmentRequests, payload];
    })
    .addCase(deleteUserCourseEnrollmentRequest.fulfilled,(state, {meta}) => {
      if(!state.enrollmentRequests) state.enrollmentRequests = [];
      const requestsWithoutDeleted = state.enrollmentRequests.filter(req => req.id !== meta.arg.id);
      state.enrollmentRequests = requestsWithoutDeleted;
    })
    .addCase(acceptUserCourseInvitation.fulfilled, (state, {meta}) => {
      if(!state.invitations) state.invitations = [];
      const invitationsWithoutAccepted = state.invitations.filter(invitation => invitation.id !== meta.arg.id);
      state.invitations = invitationsWithoutAccepted;
    })
    .addCase(deleteUserCourseInvitation.fulfilled, (state, {meta}) => {
      const invitationsWithoutDeleted = state.invitations?.filter(invitation => invitation.id !== meta.arg.id);
       state.invitations = invitationsWithoutDeleted;
    })
    .addCase(getUserInvitationCourseInfoByToken.fulfilled, (state, {payload, meta}) => {
       state.invitationLink = {courseInfo: payload, token: meta.arg, accepted: false, notFound: false};
    })
    .addCase(getUserInvitationCourseInfoByToken.rejected, (state, {payload, meta}) => {
       if (payload) // true if "not found"
           state.invitationLink = {token: meta.arg, accepted: false, notFound: true};
    })
    .addCase(acceptUserInvitationWithToken.fulfilled, (state, {}) => {
       if (state.invitationLink) {
           state.invitationLink.accepted = true;
       }
    })
    .addCase(acceptUserInvitationWithToken.rejected, (state, {payload}) => {
       if (payload && state.invitationLink) // payload is true if "not found" or "user already in course" or "course not active"
         state.invitationLink.notFound = true;
    })
    .addCase(clearCourseInfo, () => initialState)
  }
});

export default coursesSlice.reducer;

// archiveUnarchiveCourseThunk
// reorderCoursesThunk
// getCourseThunk

