// import libraries
import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';

import { Assignment, Submission, Document } from '../../types';
import StudentAssignmentsPageStyle from '../../pages/course-student/StudentPages.module.scss';
import { HarmoniaPaper} from "@harmonia-front-end/shared/component";
import { showDateAsFormattedString } from '../../utilities';

const {setContainer,setTitle, setTable, setTableHeader} = StudentAssignmentsPageStyle;

type StudentAssignmentSetProps = {
  courseID: number,
  assignment: Assignment,
  studentSubmissions: Submission[]
}

const StudentAssignmentSet = (props: StudentAssignmentSetProps) => {
  const {courseID, assignment, studentSubmissions} = props;

  // function to grab top submission (if any) for a document.
  const getSubmission = (docID: number, submissions:Submission[]) => {
    // find student's submissions for that document
    const docSubmissions = submissions?.filter(sub => sub.document === docID);
    // return early if no submissions
    if(docSubmissions.length === 0) return undefined;
    // if there are any submissions, find the one with the highest score and add formatted time
    const sub = docSubmissions.reduce((max, sub) => max.points > sub.points ? max : sub);
    const formattedTime = showDateAsFormattedString(sub.submit_time, 'YYYY-MM-DD hh:mm');
    return {...sub, formattedTime};
  };

  // function that will render either a submission entry row or empty row (if no submission) in the assignment set table
  const renderSubmissionEntry = (document: Document, submission: Submission | undefined) => {
    const emptyRow = (
      <tr>
        <td>{document.title}</td>
        <td colSpan={5}>No submission</td>
        {/* <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td> */}
      </tr>
    );
    const submissionRow = (
      <tr>
        <td>{submission?.document_title}</td>
        <td>{submission?.formattedTime}</td>
        <td>{submission?.points}</td>
        <td>{submission?.percent}</td>
        <td>{submission?.grade}</td>
        <td>
          <div>
            <Link to={`/course/${courseID}/submission/${submission?.id}`}>View</Link>
          </div>
        </td>
      </tr>
    );
    return submission ? submissionRow : emptyRow;
  };

  return (
    <HarmoniaPaper className={setContainer}>
      <div className={setTitle}>{assignment.title}</div>
      <div>
        <table className={setTable}>
          <thead>
            <tr className={setTableHeader}>
              <th>Document</th>
              <th>Submission Time</th>
              <th>Score</th>
              <th>Percent</th>
              <th>Letter Grade</th>
              <th>File</th>
            </tr>
          </thead>
          <tbody>
            {assignment.documents?.map((doc, docIndex) => {
              const submission = getSubmission(doc.id, studentSubmissions);
              return (
                <Fragment key={docIndex}>
                  {renderSubmissionEntry(doc, submission)}
                </Fragment>
              )
            })}
          </tbody>
        </table>
      </div>
    </HarmoniaPaper>
  )
};

export default StudentAssignmentSet;
