import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Language, Timezone } from "../types";
import { API } from "../utilities";

type UtilitiesInitialState = {
  /**
   * An array of Harmonia Cloud language metadata.
   *
   * If the value is undefined, it means that no language metadata have been pulled from the API.
   * If the value is null, it means that API has been called, but failed.
   * Otherwise, the `languages` attribute would be an concrete language metadata array.
   *
   * Such value settings on `null` and `undefined` is prepared
   * in light of the `useHarmoniaLanguages` React hooks in the `hooks` folder.
   * Check the `useHarmoniaLanguages` React hook for details.
   */
  languages?: Language[] | null;

  /**
   * An array of Harmonia Cloud timezone metadata.
   *
   * If the value is undefined, it means that no timezone metadata have been pulled from the API.
   * If the value is null, it means that API has been called, but failed.
   * Otherwise, the `timezones` attribute would be an concrete language metadata array.
   *
   * Such value settings on `null` and `undefined` is prepared
   * in light of the `useHarmoniaTimezones` React hooks in the `hooks` folder.
   * Check the `useHarmoniaTimezones` React hook for details.
   */
  timezones?: Timezone[] | null;
};

const initialState: UtilitiesInitialState = {};

/**
 * Redux async thunk to request for all possible Harmonia Cloud languages metadata from the API.
 *
 * It will call the API and return the response as an array of Harmonia Cloud languages metadata.
 * If the API call succeeded, the language setting will be set to the state.
 * Otherwise, the language setting would be set to null, indicating a failed API call.
 */
export const fetchLanguages = createAsyncThunk(
  "utilities/fetchLanguages",
  async () => {
    const languages: Language[] = (await API.get("/api/languages")).data.data;
    return languages;
  }
);

/**
 * Redux async thunk to request for all possible Harmonia Cloud timezones metadata from the API.
 *
 * It will call the API and return the response as an array of Harmonia Cloud timezones metadata.
 * If the API call succeeded, the timezone setting will be set to the state.
 * Otherwise, the timezone setting would be set to null, indicating a failed API call.
 */
export const fetchTimezones = createAsyncThunk(
  "utilities/fetchTimezones",
  async () => {
    const timezones: Timezone[] = (await API.get("/api/timezones")).data.data;
    return timezones;
  }
);

// Redux async thunk to request for all possible schools
export const fetchSchools = createAsyncThunk(
  "utilities/fetchSchools",
  async () => {
    const schools = (await API.get("/api/schools")).data.data;
    return schools;
  }
);

// Redux async thunk to request for all academic terms
export const fetchTerms = createAsyncThunk("utilities/fetchTerms", async () => {
  const terms = (await API.get("/api/academic-terms")).data.data;
  return terms;
});

const utilitiesSlice = createSlice({
  name: "utilities",
  initialState,
  reducers: {
    setLanguages: (state, action: PayloadAction<Language[]>) => ({
      ...state,
      languages: action.payload,
    }),
    clearLanguages: (state) => ({ ...state, languages: undefined }),
    setTimezones: (state, action: PayloadAction<Timezone[]>) => ({
      ...state,
      timezones: action.payload,
    }),
    clearTimezones: (state) => ({ ...state, timezones: undefined }),
    setSchools: (state, action) => ({
      ...state,
      schools: action.payload,
    }),
    setTerms: (state, action) => ({
      ...state,
      terms: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLanguages.fulfilled, (state, { payload }) => ({
        ...state,
        languages: payload,
      }))
      .addCase(fetchLanguages.rejected, (state) => ({
        ...state,
        languages: null,
      }))
      .addCase(fetchTimezones.fulfilled, (state, { payload }) => ({
        ...state,
        timezones: payload,
      }))
      .addCase(fetchTimezones.rejected, (state) => ({
        ...state,
        timezones: null,
      }))
      .addCase(fetchSchools.fulfilled, (state, { payload }) => ({
        ...state,
        schools: payload,
      }))
      .addCase(fetchSchools.rejected, (state) => ({
        ...state,
        schools: null,
      }))
      .addCase(fetchTerms.fulfilled, (state, { payload }) => ({
        ...state,
        terms: payload,
      }))
      .addCase(fetchTerms.rejected, (state) => ({
        ...state,
        terms: null,
      }));
  },
});

export const {
  setLanguages,
  clearLanguages,
  setTimezones,
  clearTimezones,
  setSchools,
} = utilitiesSlice.actions;

export default utilitiesSlice.reducer;
