import React, {useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import { HarmoniaButton, HarmoniaPaper, Text } from '@harmonia-front-end/shared';
import style from "./HarmoniaModal.module.scss";

const {backdrop, modal, header} = style;

type HarmoniaModalProps = {
  show: boolean,
  open: () => () => void,
  close: () => () => void
};

/**
 * Reusable pop-up modal component that will have more compliant and customizable styling than the standard Reactstrap components. Pop-up modals should blur background when open (by using separate root div than main body) and have some kind of slide-in/out animation.
 *  This component is in "testing" phase right now and instead Reactstrap modal components should be used while we are finishing core functionality. In order to prevent having to pass in tons of props, Harmonia modals will probably be refactored to consist of separate components (modal, header, body, footer) that render children similar to Reactstrap.
 */

const HarmoniaModal = ({show, open, close}: HarmoniaModalProps) => {
  // const [showModal, setShowModal] = useState(false);


  const openModal = () => () => {
    // add blur to elements in body behind modal. using cssText property because we need to pass in several blur properties at once to be more cross-browser-compliant.
    const bodyRoot = document.getElementById('root') as HTMLDivElement;
    const addBlur = `
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
    `;
    // "modal-open" class is a quick and easy way to prevent any scrolling when modal is open
    bodyRoot.classList.add('modal-open');
    bodyRoot.style.cssText = addBlur;
  }
  const closeModal = () => () => {
    // removing blur from body elements via same method above.
    const bodyRoot = document.getElementById('root') as HTMLDivElement;
    const removeBlur = `
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -o-filter: blur(0px);
    -ms-filter: blur(0px);
    filter: blur(0px);
    `;
    bodyRoot.classList.remove('modal-open');
    bodyRoot.style.cssText = removeBlur;
  };

  // modal component(s) rendered on separate root div than body so blur effect can be added.
  const modalRoot = document.getElementById('modal-root') as HTMLDivElement;

  return ReactDOM.createPortal(

      <div className={backdrop} onClick={close()}>
        <HarmoniaPaper className={modal}>
          <div className={header}>
            Session Timeout
          </div>
          <div className="body">
            <Text color="dark">
            Your session will expire in 60 seconds due to inactivity.
            </Text>

          </div>
          <div className="footer">
            <HarmoniaButton dest="app" variant="secondary">
              Log Out
            </HarmoniaButton>
            <HarmoniaButton dest="app" variant="primary">
              Continue
            </HarmoniaButton>
          </div>
        </HarmoniaPaper>
        {/* <button onClick={close()}>Close Modal</button> */}
      </div>,
  modalRoot
  )
}
export default HarmoniaModal;
