import {
  createAction,
  createAsyncThunk,
  createReducer,current, createSlice
} from "@reduxjs/toolkit";
import API from '../utilities/API';
import { pushErrorNotification, pushNotification } from './notification';
import { Submission, Urls, StudentDocumentInfo } from '../types';
import { errorMessage, showDateAsFormattedString } from '../utilities';

/* Defining and creating initial state for Submission reducer */
type SelectedSubmissionStateType = {
  submissions?: Submission[],
  urls?: Urls,
  studentDocumentInfo?: StudentDocumentInfo
}

const initialState: SelectedSubmissionStateType = {};

/******** ACTIONS/THUNKS *********/

// Grabbing submission(s) as well as relevant student and document info (names and title)
export const getDocumentSubmissions = createAsyncThunk<{submissions: Submission[], studentDocumentInfo: StudentDocumentInfo}, {courseID: number, submissionID: number}, {rejectValue: void}>('submission/getDocumentSubmissionsAndNeededInfo', async({courseID, submissionID}, {dispatch, rejectWithValue}) => {
  try{
    // grab submission(s) array from API, sort it by descending score (for now) and add in formatted time
    const response = await API.get(`/api/courses/${courseID}/submissions/${submissionID}`);
    const data = response.data.data as Submission[];
    const submissions = data.sort((a, b) => {
      return b.points - a.points
    }).map(sub => {
      const formattedTime = showDateAsFormattedString(sub.submit_time, 'YYYY-MM-DD hh:mm');
      return {...sub, formattedTime}
    })
    // grab student and document info from submission to store as separate key on selectedSubmission state object
    const {student_first_name, student_last_name, document_title} = submissions[0];
    const studentDocumentInfo = {student_first_name, student_last_name, document_title};
    return {submissions, studentDocumentInfo};
  }catch(e){
    dispatch(pushErrorNotification(errorMessage(e.data)));
    return rejectWithValue();
  }
});

// grabbing file URLs for submission
export const getSubmissionFileURLs = createAsyncThunk<Urls, number, {rejectValue: void}>('submission/getSubmissionFileURLs', async(submissionID, {dispatch, rejectWithValue}) => {
  try{
    const response = await API.get(`/api/submissions/${submissionID}/file-urls`);
    const {grade_report_url, har_url, png_url} = response.data.data;
    return {grade_report_url, har_url, png_url};
  }catch(e){
    dispatch(pushErrorNotification(errorMessage(e.data)));
    return rejectWithValue();
  }
});

const selectedSubmissionSlice = createSlice({
  name: 'selectedSubmission',
  initialState,
  reducers: {},
  extraReducers:(builder) => {
    builder
    .addCase(
      getDocumentSubmissions.fulfilled, ((state, {payload}) => {
        state.submissions = payload.submissions;
        state.studentDocumentInfo = payload.studentDocumentInfo;
      })
    )
    .addCase(
      getSubmissionFileURLs.fulfilled, ((state, {payload}) => {
        state.urls = payload;
      })
    )
  }
})
export default selectedSubmissionSlice.reducer;
