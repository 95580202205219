import React, {useState, useEffect, useRef} from 'react';
import ReactDOM from 'react-dom';
import {useHistory} from 'react-router';
import {Modal, ModalBody, ModalHeader, ModalFooter} from 'reactstrap';
import {HarmoniaButton, Text} from '@harmonia-front-end/shared';
import {useHarmoniaDispatch, pushErrorNotification} from '../redux';
import {API, HarmoniaCookies} from '.';

/**
 * Component that manages user session by informing users  when session will expire (giving them a choice to end or continue their session) via modal and will auto log-out user due to inactivity. It does this via counting an interval every second that checks if access-token cookie is present and calculates the time remaining for the cookie in order to display remaining time left to user.
 * @returns modal displaying countdown with "Log Out" and "Continue" buttons (when 60 seconds or less remaining).
 */

const SessionManagement = () => {

    const dispatch = useHarmoniaDispatch();
    const history = useHistory();


    /* LOCAL STATE AND REF(S) */

    const [showModal, setShowModal] = useState(false);
    // defaulting timeRemaining to big number; doesn't matter as it will get set to proper number as soon as cookie time remaining reaches 60 seconds.
    const [timeRemaining, setTimeRemaining] = useState(999999);

    // We set the value of this ref to be the ID of our setInterval function so we can compartmentalize our logic beforehand inside the autoLogOut function while also being able to clear the interval that is defined in a later useEffect.
    const intervalRef: {current: NodeJS.Timeout | null} = useRef(null);


    /* HELPER FUNCTIONS */

    // Function that compares cookie expiration time vs. current time. It calculates and returns the seconds remaining before the cookie will expire.
    const calculateTimeRemaining = () => {
        const nowDate = new Date();
        const nowTime = nowDate.getTime();
        // the expiration timestamp of the cookie is stored as a string so we must convert it to a number first for our calculations.
        const tokenExpireTime = Number(HarmoniaCookies['token-expire-time'].get());
        const totalSeconds = Math.floor((tokenExpireTime - nowTime) / 1000);

        return totalSeconds;
    }

    // Function that will hit explicit API endpoint to renew access token and then close pop-up modal. TO DO: change placeholder error.
    const stayLoggedIn = () => () => {
        API.get('/api/users/renew-access-token')
            .then(() => {
                setShowModal(false);
            })
            .catch((e) => {
                dispatch(pushErrorNotification('Error attempting to stay logged in'));
            });
    };

    // Function that closes modal and then redirects to SignOutPage where user will be logged out like normal.
    const logOut = () => () => {
        setShowModal(false);
        history.push('/sign-out')
    };

    // Function that auto logs-out user by clearing the interval and refreshing to SignInPage (with a redirect to whatever page user was visiting at the time). It also sets a new "session-timed-out" cookie which we check on the SignInPage to display auto logged out message (we use a cookie for this because it will persist after the refresh).
    const autoLogOut = () => {
        // cookie expire time set to 5 seconds because refreshing to sign-in may take a couple seconds but we also want cookie to clear fairly soon.
        const time = new Date();
        time.setSeconds(time.getSeconds() + 5)
        HarmoniaCookies['session-timed-out'].set('session-timed-out', {expires: time});
        window.location.href = `/sign-in?redirect=${window.location.pathname}`;
        clearInterval(intervalRef.current as NodeJS.Timeout);
    };

    // Function that handles rendering of countdown text depending on if seconds left is > 1, 1 or 0.
    const displayCountdownText = () => {
        const isPlural = timeRemaining > 1 ? 's' : '';
        const sessionCountdown = `Your session will expire in ${timeRemaining} second${isPlural} due to inactivity.`;
        const beingLoggedOut = 'You are being logged out...';
        return timeRemaining > 0 ? sessionCountdown : beingLoggedOut;
    };

    /* COUNTDOWN TIMER */

    // Starting our interval when the component mounts (i.e. just after user has successfully signed in). Our interval function checks the existence of the log-in cookie every second. If the cookie exists, when there is 1 minute remaining it will show modal with countdown displayed with local state; otherwise, it will execute our autoLogOut function as cookie has expired.
    useEffect(() => {
        const interval = setInterval(() => {
            const cookie = HarmoniaCookies['x-access-token'].get();
            if (cookie) {
                const secondsLeft = calculateTimeRemaining();
                if (secondsLeft <= 0) {
                    autoLogOut();
                } else if (secondsLeft <= 60) {
                    // it is unnecessary to call setTimeRemaining until it is needed.
                    setTimeRemaining(secondsLeft);
                    if (!showModal) {
                        setShowModal(true);
                    }
                }
            }
            if (!cookie) {
                autoLogOut();
            }
        }, 1000);
        intervalRef.current = interval;

        return () => clearInterval(interval);
    }, []);


    /* RENDERING MODAL */

    // Modal should exist outside normal body root so we can later add background blur effect to body (when we restyle modals) without also blurring modal itself.
    const modalRoot = document.getElementById('modal-root') as HTMLDivElement;

    return ReactDOM.createPortal(
        <div>
            {showModal &&
                <Modal isOpen={showModal}>
                    <ModalHeader>Session Timeout</ModalHeader>
                    <ModalBody>
                        <Text color="dark">
                            {displayCountdownText()}
                        </Text>
                    </ModalBody>
                    {/* when being auto-logged-out, user should not be given option to manually log out or renew session as cookie is expiring */}
                    <ModalFooter>
                        <HarmoniaButton variant="secondary" dest="app" onClick={logOut()}>
                            Log Out
                        </HarmoniaButton>
                        {timeRemaining > 0 &&
                            <HarmoniaButton variant="primary" dest="app" onClick={stayLoggedIn()}>
                                Continue
                            </HarmoniaButton>
                        }
                    </ModalFooter>
                </Modal>
            }
        </div>,
        modalRoot
    )
}
export default SessionManagement;
