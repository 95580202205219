import React from 'react';
import {
  faArrowsAlt,
  faArrowsAltH,
  faArrowsAltV,
  faCaretUp,
  faCaretDown,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HarmoniaPaper from './HarmoniaPaper';
import styles from './Order.module.scss';
import Text from './Text';

const { order, left, moveIcon, orderArrows } = styles;

type OrderProps = {
  /**
   * Direction of the top-left arrow icon.
   * 'v' means vertical directions, 'h' means horizontal directions,
   * and 'all' means all four directions.
   */
  arrowDirection?: 'v' | 'h' | 'all';

  /** Additional caption to display on the left of the component. */
  caption?: React.ReactNode;

  className?: string;

  // eslint-disable-next-line react/require-default-props
  children?: React.ReactNode;

};

const Order = React.forwardRef(
  (props: OrderProps, ref?: React.Ref<HTMLDivElement> | undefined) => {
    const {children, arrowDirection, caption, className, ...rest} = props;

    const faIcon = (() => {
      if (arrowDirection === 'all') return faArrowsAlt;
      if (arrowDirection === 'h') return faArrowsAltH;
      return faArrowsAltV;
    })();

    const classList = className ? className.split(' ') : [];
    classList.push(order);

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <HarmoniaPaper ref={ref} className={classList.join(' ')} {...rest}>
        <div className={orderArrows}>
          <FontAwesomeIcon icon={faCaretUp} />
          <FontAwesomeIcon icon={faCaretDown} />
        </div>
        <div className={left}>
          {/* <FontAwesomeIcon icon={faIcon} size="2x" className={moveIcon} /> */}
          {caption && (
            <Text color="dark" variant="body">
              {caption}
            </Text>
          )}
        </div>
        {children}
      </HarmoniaPaper>
    );
  }
);

Order.defaultProps = {
  arrowDirection: 'v',
  caption: '',
  className: '',
};

Order.displayName = 'Order';

export default Order;
