import React, {useState} from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import SingleCourse from '../single-course/SingleCourse';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import {useHarmoniaDispatch, updateCoursesOrder, updateCoursesOrderLocally, deleteCourse } from '../../../redux';
import {Text, useFlag, useToggle, HarmoniaButton } from '@harmonia-front-end/shared';
import { CourseDetailedInfo } from '../../../types';

import CourseRowStyle from './AllCourses.module.scss';

const {heading} = CourseRowStyle;

type CourseRowProps = {
  userCourseRole: 'student' | 'instructor' | 'ta',
  courses: CourseDetailedInfo[],
  courseArchived: boolean,
  removeDragPrompt: () => void,
  setWaiting: (waiting: boolean) => void
}

export default function CourseRow({ userCourseRole, courses, courseArchived, removeDragPrompt, setWaiting }: CourseRowProps) {
  const dispatch = useHarmoniaDispatch();
  // for some reason formattedmessage doesn't recognize variable as id...  need to fix later
  const messageTitleKey = `dashboard.course_row_title.${userCourseRole}`;

  // const [dragLoading, setDragLoadingOn, setDragLoadingOff] = useFlag();

  /*
    Deleting a course: state and handlers for opening delete modal with proper course info and dispatching actions for confirming delete.
  */
  const [deleteCourseModalOpen, toggleDeleteCourseModalOpen] = useToggle();
  const [courseToDelete, setCourseToDelete] = useState({} as CourseDetailedInfo);

  const showDeleteCourseModal = (course: CourseDetailedInfo) => {
    toggleDeleteCourseModalOpen();
    setCourseToDelete(course);
  };

  const confirmDeleteCourse = (courseID: number) => () => {
    dispatch(deleteCourse(courseID)).finally(() => {
      toggleDeleteCourseModalOpen();
      setCourseToDelete({} as CourseDetailedInfo);
    })
  }



  // handler for reordering courses after dragging
  const onCourseListingDragEnd = (result: DropResult) => {
    removeDragPrompt();
    const { destination, source, draggableId } = result;
    if (!destination || destination.index === source.index) return;
    // setDragLoadingOn();


    const originalOrder = courses.map((course) => (course.id));
    const newOrder = Array.from(originalOrder) as number[];
    console.log('newOrder length: ', newOrder);
    newOrder.splice(source.index, 1);
    newOrder.splice(destination.index, 0, Number(draggableId));
    // NOTE: React drag n' drop "reverts" very briefly to previous order before correcting itself after successful API call and Redux update. To make the reordering totally smooth, we first update the order locally by sending the reordered courses into a separate dispatch action (with no API calls) and then make the API call.
    const reorderedCourses = [];
    for (let i = 0; i < newOrder.length; i++) {
      for (let j = 0; j < courses.length; j++) {
        if(newOrder[i] === courses[j].id) {
          reorderedCourses.push(courses[j]);
        }
      }
    }
    dispatch(updateCoursesOrderLocally({reorderedCourses, userCourseRole}));
    dispatch(updateCoursesOrder(
      {newOrder, archive: courseArchived, userCourseRole}));
      // .finally(setDragLoadingOff);

  }

  return (
    <>
      {courses.length !== 0 && (
      <>
      <div className={heading}>
        <Text variant="dashboardHeading">
        <FormattedMessage
          id={messageTitleKey}
          defaultMessage={userCourseRole}
        />
        </Text>
      </div>
      <div className="coursesContainer">
        <DragDropContext onDragEnd={onCourseListingDragEnd}>
          <Droppable droppableId="courses" /*isDropDisabled={dragLoading} */>
          {(droppableProvided) => (
                <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                  {courses.map((course, index) => (
                    <SingleCourse key={course.id} course={course} userCourseRole={userCourseRole} index={index} showDeleteCourseModal={showDeleteCourseModal} setWaiting={setWaiting} />
                  ))}
                  {droppableProvided.placeholder}
                </div>
              )}
          </Droppable>
        </DragDropContext>
      </div>
      {/* delete modal should only render if a user is an instructor of course and has clicked "delete" already to set local delete course state */}
      {userCourseRole === 'instructor' && courseToDelete.id > 0 &&
        <Modal isOpen={deleteCourseModalOpen}>
          <ModalHeader>Request to Delete Course</ModalHeader>
          <ModalBody>
            <Text color="dark">
              {`Are you sure you want to delete ${courseToDelete.title} at ${courseToDelete.school}?`}
            </Text>
            <Text color="dark">This action cannot be undone.</Text>
          </ModalBody>
          <ModalFooter>
            <HarmoniaButton variant="secondary" dest="app" onClick={toggleDeleteCourseModalOpen}>
                Cancel
            </HarmoniaButton>
            <HarmoniaButton variant="primary" dest="app" onClick={confirmDeleteCourse(courseToDelete.id)}>
                Confirm Delete Course
            </HarmoniaButton>
          </ModalFooter>
        </Modal>
      }
      </>
      )}
    </>
  );
};
