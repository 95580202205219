/* eslint-disable no-param-reassign */
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {GoogleCoursesData, CoursesAndUser, User, CourseInvitation, CourseEnrollmentRequest} from '../types';
import {pushErrorNotification, pushNotification} from './notification';
import {API, errorMessage, HarmoniaCookies} from '../utilities';

type UserInitialState = {
    /**
     * The user variable stores a Harmonia user's metadata.
     * This optional value can be a concrete User object, null, or undefined.
     *
     * If the value is undefined, it means that no user info have been pulled from the API.
     * If the value is null, it means that API has been called, but failed.
     * Otherwise, the `user` attribute would be an concrete User object.
     *
     * Such value settings on `null` and `undefined` is prepared in light of the `useUser` React hooks
     * in the `hooks` folder. Check the `useUser` React hook for details.
     */
    user?: User | null,
    courseInvitations?: CourseInvitation[],
    courseEnrollmentRequests?: CourseEnrollmentRequest[],
    googleInfo?: GoogleCoursesData,
};

/**
 * The initial state of the user state.
 * `user` attribute remains undefined.
 */
const initialState: UserInitialState = {};

/**
 * Redux async thunk to pull the Harmonia user's info.
 *
 * It will call the API and, if succeeded, a `User` instance would be returned.
 * Otherwise, the async thunk would reject with nothing returned,
 * and the API error response would be dispatched as an error notification.
 *
 * This async thunk does not receive any parameters.
 */

/** NOTE: This function was changed to return the entire User object from API call rather than just user info. This is because we are dividing the entire user return into 2 separate reducers: user personal info goes on user reducer while the list of courses, invitations and enrollment requests goes on courses reducer. There is a separate addCase listener for this action in the courses reducer to faciltate this  */
export const getUserInfo = createAsyncThunk<CoursesAndUser, void, {rejectValue: void}>(
    'user/getCoursesAndUser',
    async (_, {dispatch, rejectWithValue}) => {
        if (!HarmoniaCookies["x-access-token"].get())
            return rejectWithValue();
        try {
            const response = await API.get('/api/users');
            return response.data.data as CoursesAndUser;
        } catch (e: any) {
            dispatch(pushErrorNotification(errorMessage(e.data)));
            return rejectWithValue();
        }
    },
);


/**
 * Redux async thunk to request for updating the Harmonia user's info.
 *
 * The async thunk receives an updated user info.
 * It will call the API with the updated user info as the HTTP request body.
 * If the request is succeeded, the async thunk would return a new `User` instance
 * with updated data.
 *
 * Otherwise, the async thunk would reject with nothing returned,
 * and the API error response would be dispatched as an error notification.
 *
 * Please check the corresponded API endpoint documentation
 * for details about the request body.
 */
export const updateUserInfo = createAsyncThunk<User, any, {rejectValue: void}>(
    'user/updateUserInfo',
    async (body, {dispatch, rejectWithValue}) => {
        try {
            const user = (await API.put('/api/users', body)).data.data as User;
            // dispatch(pushNotification({
            //   severity: 'success',
            //   message: 'User info updated!', // TODO: change this to Intl Node.
            // }));
            return user;
        } catch (e: any) {
            dispatch(pushErrorNotification(errorMessage(e.data)));
            return rejectWithValue();
        }
    },
);

// export const createUserCourseEnrollmentRequest = createAsyncThunk<CourseEnrollmentRequest, string, {rejectValue: void}>('user/createUserCourseEnrollmentRequest', async(invitation_code, {dispatch, rejectWithValue}) => {
//   try{
//     const response = await API.post('/api/course-enrollment-requests',{invitation_code});
//     const enrollmentRequest = response.data.data as CourseEnrollmentRequest;
//     return enrollmentRequest;
//   }catch(e){
//     dispatch(pushErrorNotification(errorMessage(e.data)));
//     return rejectWithValue();
//   }
// });

export const getUserCourseEnrollmentRequests = createAsyncThunk<CourseEnrollmentRequest[], void, {rejectValue: void}>(
    'courseManagement/getCourseEnrollmentRequests', async (_, {dispatch, rejectWithValue}) => {
        try {
            const response = await API.get('/api/course-enrollment-requests');
            const enrollmentRequests = response.data.data as CourseEnrollmentRequest[];
            return enrollmentRequests;
        } catch (e: any) {
            dispatch(pushErrorNotification(errorMessage(e.data)));
            return rejectWithValue();
        }
    });

export const getGoogleCourseInfo = createAsyncThunk<GoogleCoursesData, string, {rejectValue: void}>(
    "user/getGoogleCourseInfo",
    async (id, {dispatch, rejectWithValue}) => {
        try {
            const response = await API.post('/api/gcinfo', {id: id});
            return response.data.data as GoogleCoursesData;
        } catch (e: any) {
            return ('data' in e.data) ? e.data.data as GoogleCoursesData : {error: -2};
        }
    }
);

export const submitGoogleConfig = createAsyncThunk<void, [string, number, string, string, string], {rejectValue: boolean}>(
    "user/submitGoogleConfig",
    async ([course, doc, title, desc, id], {dispatch, rejectWithValue}) => {
        try {
            const response = await API.post('/api/gcsubmit', {course: course, doc: doc, title: title, desc: desc, id: id});
            return;
        } catch (e: any) {
            dispatch(pushErrorNotification("There was an error submitting this assignment."));
            return rejectWithValue(true);
        }
    }
);

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(
                getUserInfo.fulfilled, (state, {payload}) => {
                    const {
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        course_instructors, course_students, course_tas, invitations, enrollment_requests, ...user
                    } = payload;
                    state.user = user;
                },
            )
            .addCase(
                getUserInfo.rejected, (state) => {
                    state.user = null;
                },
            )
            .addCase(
                updateUserInfo.fulfilled, (state, {payload}) => {
                    state.user = payload;
                },
            )
            .addCase(
                getGoogleCourseInfo.fulfilled, (state, {payload}) => {
                    state.googleInfo = payload;
                }
            )
            .addCase(
                submitGoogleConfig.pending, (state, {payload, meta}) => {
                    if (state.googleInfo) {
                        if (!state.googleInfo.disabled)
                            state.googleInfo.disabled = [];
                        state.googleInfo.disabled.push(meta.arg[1]);
                    }
                }
            )
            .addCase(
                submitGoogleConfig.rejected, (state, {payload, meta}) => {
                    if (payload && state.googleInfo && state.googleInfo.disabled)
                        var id = meta.arg[1];
                        state.googleInfo!.disabled = state.googleInfo?.disabled?.filter(doc => doc != id);
                }
            )
    },
});

export default userSlice.reducer;
