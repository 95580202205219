import React from "react";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArchive,
    faBars,
    faBookOpen,
    faCog,
    faCopy,
    faEnvelopeOpenText,
    faInfoCircle,
    faTrash,
    faUser,
    faEdit
} from "@fortawesome/free-solid-svg-icons";
import {FormattedMessage, FormattedHTMLMessage} from "react-intl";
import {Link} from "react-router-dom";
import {CourseDetailedInfo} from "../../../types";
import styles from "./SingleCourse.module.scss";
const {dropdownToggle, dropdownMenu, dropdownItem, dropdownIcon} = styles;

type SingleCourseDropdownProps = {
    course: CourseDetailedInfo
    userCourseRole: 'instructor' | 'ta' | 'student',
    showDeleteCourseModal: (course: CourseDetailedInfo) => void,
    handleArchiveUnarchive: ({course, archive, userCourseRole}: {
        course: CourseDetailedInfo;
        archive: boolean;
        userCourseRole: 'instructor' | 'ta' | 'student';
    }) => () => void,
    toggleDuplicateModalOpen: () => void
}

const SingleCourseDropdown = ({course, userCourseRole, showDeleteCourseModal, handleArchiveUnarchive, toggleDuplicateModalOpen}: SingleCourseDropdownProps) => {

    // local state for keeping track of and setting dropdown open
    const [dropdownOpen, setDropdownOpen] = React.useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    // deconstruct relevant course info
    const {archived, status} = course;

    // what to render in for archive/unarchive dropdown item depending on archived status
    const archiveUnarchiveID = archived ? 'dashboard.course_action.unarchive' : 'dashboard.course_action.archive';
    const archiveUnarchiveMessage = archived ? 'Unarchive' : 'Archive';
    const archiveUnarchiveIcon = archived ? faEnvelopeOpenText : faArchive;

    const courseLink = userCourseRole === 'student' ? `/student/course/${course.id}` : `/course/${course.id}`;

    return (
        <Dropdown direction="left" isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle id={dropdownToggle}>
                <FontAwesomeIcon icon={faBars} />
            </DropdownToggle>
            <DropdownMenu id={dropdownMenu}>
                <Link to={userCourseRole === 'student' ? `/student/course/${course.id}/info` : `/course/${course.id}/info`}>
                    <DropdownItem className={dropdownItem} data-cy="course-info">
                        <FontAwesomeIcon icon={faInfoCircle} className={dropdownIcon} />
                        <span>Info</span>
                    </DropdownItem>
                </Link>
                {/* only instructors in courses in 'PLANNING' or 'ACTIVE' stages should be shown settings link */}
                {userCourseRole === 'instructor' && (status === 'PLANNING' || status === 'ACTIVE') &&
                    <Link to={`/course/${course.id}/settings`}>
                        <DropdownItem className={dropdownItem}>
                            <FontAwesomeIcon icon={faCog} className={dropdownIcon} />
                            <span>
                                <FormattedMessage
                                    id="dashboard.course_action.settings"
                                    defaultMessage="Settings"
                                />
                            </span>
                        </DropdownItem>
                    </Link>
                }
                {/* editing link */}
                {userCourseRole === 'instructor' && (status === 'PLANNING' || status === 'ACTIVE') &&
                    <Link to={courseLink}>
                        <DropdownItem className={dropdownItem}>
                            <FontAwesomeIcon icon={faEdit} className={dropdownIcon} />
                            <span>
                                <FormattedMessage
                                    id="dashboard.course_action.edit"
                                    defaultMessage="Edit"
                                />
                            </span>
                        </DropdownItem>
                    </Link>
                }
                {/* only instructors can access people page */}
                {userCourseRole === 'instructor' &&
                    <Link to={`/course/${course.id}/people`}>
                        <DropdownItem className={dropdownItem} data-cy="course-people">
                            <FontAwesomeIcon icon={faUser} className={dropdownIcon} />
                            <span>People</span>
                        </DropdownItem>
                    </Link>
                }
                <DropdownItem className={dropdownItem} onClick={handleArchiveUnarchive({course: course, archive: !archived as boolean, userCourseRole})} data-cy="course-archive">
                    <FontAwesomeIcon icon={archiveUnarchiveIcon} className={dropdownIcon} />
                    <span>
                        <FormattedMessage
                            id={archiveUnarchiveID}
                            defaultMessage={archiveUnarchiveMessage}
                        />
                    </span>
                </DropdownItem>
                <Link to={userCourseRole === 'student' ? `/student/course/${course.id}` : `/course/${course.id}/grade-book`}>
                    <DropdownItem className={dropdownItem}>
                        <FontAwesomeIcon icon={faBookOpen} className={dropdownIcon} />
                        <span>Grade Book</span>
                    </DropdownItem>
                </Link>
                {/* only instructors can duplicate course */}
                {userCourseRole === 'instructor' &&
                    <DropdownItem className={dropdownItem} onClick={() => {toggleDuplicateModalOpen()}}>
                        <FontAwesomeIcon icon={faCopy} className={dropdownIcon} />
                        <span>Duplicate</span>
                    </DropdownItem>
                }

                {/* only instructors in courses in 'PLANNING' stage can delete a course */}
                {userCourseRole === 'instructor' && ((archived && status === 'ENDED') || status === 'PLANNING') &&
                    <DropdownItem className={dropdownItem} onClick={() => {showDeleteCourseModal(course)}}>
                        <FontAwesomeIcon icon={faTrash} className={dropdownIcon} />
                        <span>
                            <FormattedMessage
                                id="dashboard.course_action.delete"
                                defaultMessage="Delete"
                            />
                        </span>
                    </DropdownItem>
                }
            </DropdownMenu>
        </Dropdown>


    )

}
export default SingleCourseDropdown;
