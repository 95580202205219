import { CourseInvitation, CourseEnrollmentRequest } from "./course-management";

export type CourseStatus = 'PLANNING' | 'ACTIVE' | 'ENDED';
export type Cadential64 = 'V' | 'I';
export type Neapolitan6 = 'N' | 'II';

export type CourseStudent = {
  email: string,
  first_name: string,
  last_name: string,
  payment_id: number | null,
  payment_type: number,
  ta_user_id: number | null,
  user_id: number
}
// the object for instructor and TA is identical except for section name field (which is on TA only). it's made optional on this type so it can be used for both course roles.
export type CourseInstructorTA = {
  email: string,
  first_name: string,
  last_name: string,
  section_name?: string,
  user_id: number
}

export type GoogleCourseInfo = {
    id: string,
    name: string
}

export type GoogleCoursesData = {
    courses?: GoogleCourseInfo[],
    error: number,
    disabled?: number[]
}

export type CourseDetailedInfo = {
  academic_term: string,
  academic_term_id: number,
  cadential_64: Cadential64,
  course_product: string,
  course_product_id: number,
  course_product_price: number,
  end_date: number,
  enrollment_end_date: number,
  fastspring_product_path: string,
  id: number,
  instructors?: CourseInstructorTA[],
  invitation_code: string,
  neapolitan_6: Neapolitan6,
  payment_type: number, // TODO: payment type annotation.
  payment_id: number,
  school: string,
  school_id: number,
  start_date: number,
  status: CourseStatus,
  ta: CourseInstructorTA | null
  title: string,
  user_course_role: string,
  uuid: string,
  year: number,
  weight?: number,
  archived?: boolean,
  assignments_ids?: number[],
  lms_type?: number
};
// API call to update a course has validation that checks if course is in planning vs. active stage and thus accepts two different payloads depending on planning_status of a course. We create types for both payloads here, and our updateCourseSettings action/thunk can accept either of them.
// The fields for cad64 and nea6 are specified here to have the option of being a 'string' (even though they will only ever be what is specified above) in order to avoid an incredibly stupid and annoying type error in the handleChange function.
export type UpdatePlanningCoursePayload = {
  academic_term: number,
  cadential_64: Cadential64 | string,
  end_date: number,
  enrollment_end_date: number | null,
  id: number,
  neapolitan_6: Neapolitan6 | string,
  payment_type: number,
  school: number,
  start_date: number,
  title: string,
  lms_type: number
}
export type UpdateActiveCoursePayload = {
  cadential_64: Cadential64 | string,
  enrollment_end_date: number | null,
  id: number,
  neapolitan_6: Neapolitan6 | string,
  title: string,
  lms_type: number
}

export type Courses = {
  course_instructors: CourseDetailedInfo[];
  course_tas: CourseDetailedInfo[];
  course_students: CourseDetailedInfo[];
};

export type AllCourses = {
  courses_as_instructor: CourseDetailedInfo[];
  courses_as_ta: CourseDetailedInfo[];
  courses_as_student: CourseDetailedInfo[];
};

export type CourseDetails = {
  courseInformation?: CourseDetailedInfo
}

export enum UserType {
  STUDENT = 1,
  INSTRUCTOR = 2
}

export type UserTypeInfo = {
  userType: UserType,
  text: string
}

export type User = {
  id: number,
  user_type: UserType,
  first_name: string;
  last_name: string;
  email: string;
  will_receive_notification_emails: boolean;
  will_receive_marketing_emails: boolean;
  has_pending_teacher_account_request: boolean;
  timezone: number,
  language: string,
  uuid: string,
};
type Invitations = {
  invitations: CourseInvitation[]
}
type EnrollmentRequests = {
  enrollment_requests: CourseEnrollmentRequest[]
}
// Typing for the user return object from API call, which includes all the User info as well as attaching their courses, invitations and enrollment requests.
export type CoursesAndUser = Courses & User & Invitations & EnrollmentRequests;
// All the stuff above without the user info; this is typing of return value of getCourses action after we grab the API user info.
export type CoursesInfo = Courses & Invitations & EnrollmentRequests;

// Editable Fields
export enum CourseEditableFields {
  TITLE,
  TERM,
  SCHOOL,
  PAYMENT_TYPE,
  ENROLLMENT_END_DATE,
  START_DATE,
  END_DATE,
  NEO_6,
  CAD_64,
  LMS_TYPE
}
