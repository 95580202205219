import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import userReducer from './user';
// import { courseReducerOLD, courseDetailReducerOLD } from './coursesOLD';
// import { assignmentReducer } from './assignments';
import notificationReducer from './notification';
import utilitiesReducer from './utilities';
import mediaReducer from './media';
import  selectedAssignmentReducer  from './selectedAssignment';
import selectedCourseReducer from './selectedCourse';
import contentLibraryReducer from './content-library';
import courseManagementReducer from './course-management';
import coursesReducer from './courses';
import selectedSubmissionReducer from './selectedSubmission';
import { historyRouter, historyMiddleware } from './history';

const isProduction = process.env.HARMONIA_ENV === 'production' || process.env.REACT_APP_HARMONIA_ENV === 'production';
const isStaging = process.env.HARMONIA_ENV === 'staging' || process.env.REACT_APP_HARMONIA_ENV === 'staging';


const store = configureStore({
  reducer: {
    user: userReducer,
    courses: coursesReducer,
    // coursesOLD: courseReducerOLD,
    selectedAssignment: selectedAssignmentReducer,
    // courseDetailsOLD: courseDetailReducerOLD,
    selectedCourse: selectedCourseReducer,
    notification: notificationReducer,
    utilities: utilitiesReducer,
    media: mediaReducer,
    contentLibrary: contentLibraryReducer,
    router: historyRouter,
    // courseManagement: courseManagementReducer,
    selectedSubmission: selectedSubmissionReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(historyMiddleware),
  devTools: !isProduction && !isStaging,
});


/** Bare Redux store dispatcher, intended for non-React context use. */
export const harmoniaDispatch = store.dispatch;

/** Typed version of `useSelector` specially conditioned for our Redux store. */
const useHarmoniaSelector: TypedUseSelectorHook<ReturnType<typeof store.getState>> = useSelector;

/** Typed version of `useDispatch` specially conditioned for our Redux store. */
const useHarmoniaDispatch = () => useDispatch<typeof store.dispatch>();

export default store;
export { useHarmoniaSelector, useHarmoniaDispatch };

// export * from './coursesOLD';
export * from './notification';
export * from './user';
export * from './selectedAssignment';
export * from './utilities';
export { history } from './history';
export * from './media';
export * from './content-library';
export * from './selectedCourse';
export * from './courses';
// export * from './course-management';
export * from './selectedSubmission';
