import React, {useEffect, useState, useRef} from 'react';
import {useParams} from 'react-router-dom';
import {
    useHarmoniaSelector,
    getCourseDetail,
    getAssignmentAndDocuments,
    updateAssignmentDocumentOrder,
    updateAssignmentDocumentOrderLocally, getDocumentPreviewURL,
    useHarmoniaDispatch,
    getCourseAssignments,
    getCourseStudents,
    listAssignmentDueDateExtensions, getEntireContentLibrary, deleteDocument, pushNotification,
    getGoogleCourseInfo, submitGoogleConfig
} from '../../redux';
import {DashboardContent, DashboardTitleBar} from '../../components/template';
import {DocumentListingItem, AddDocFromContentLibModal, DocImagePreviewModal, AssignmentInfo} from '../../components/assignment';
import {useToggle, Text, HarmoniaButton} from '@harmonia-front-end/shared';
import {useModal} from '../../components/course/modal';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {Document, Assignment, CourseStudent, DueDateExtension, GoogleCourseInfo, GoogleCoursesData} from '../../types';
import AssignmentDetailPageStyle from './AssignmentDetailPage.module.scss';
import {DragDropContext, Droppable, DropResult} from 'react-beautiful-dnd';
import LoadingPage from '../../components/LoadingPage';
import {useRedirect} from '../../hooks';
// import {v4 as uuidv4} from '../../../../node_modules/uuid';
import API from '../../utilities/API';
// import {useQueryParams} from '../../hooks/hooks';

const {assignmentAndDocsContainer, documentsContainer, drag, empty} = AssignmentDetailPageStyle;

const AssignmentDetailPage = () => {
    const dispatch = useHarmoniaDispatch();
    /**  PARAMS */

    // grab and convert to numbers (for typing purposes)
    const {courseId, assignmentId} = useParams<{courseId: string, assignmentId: string}>();
    const courseID = parseInt(courseId, 10);
    const assignmentID = parseInt(assignmentId, 10);


    /** LOCAL STATE VARIABLES */

    // loading (we may not use them all but setting up for now)
    // const [courseDetailsLoading, setCourseDetailsLoading] = useState(true);
    // const [courseStudentsLoading, setCourseStudentsLoading] = useState(true);
    // const [courseAssignmentsLoading, setCourseAssignmentsLoading] = useState(true);
    const [assignmentAndDocumentsLoading, setAssignmentAndDocumentsLoading] = useState(true);
    // const [dueDateExtensionsLoading, setDueDateExtensionsLoading] = useState(true);
    // const [contentLibraryLoading, setContentLibraryLoading] = useState(true);
    const [urlLoading, setURLLoading] = useState(false);

    // ID for use with memoized selectedDoc for preview images (see below)
    const [docID, setDocID] = useState(-1);

    // modals

    // NOTE: useModal() hook is only for the preview image modals (documents and content library) as their styling is completely custom. For delete pop-up modal we use regular Reactstrap components.
    const [showAddDocModal, openAddDocModal, closeAddDocModal] = useModal();
    const [showDocPreviewModal, openDocPreviewModal, closeDocPreviewModal] = useModal();
    const [deleteDocModalOpen, toggleDeleteDocModalOpen] = useToggle();

    // document to delete
    const [documentToDelete, setDocumentToDelete] = useState({} as Document);

    // local state for showing draggable text
    const [dragPromptShow, setDragPromptShow] = useState(true);


    /** API CALLS/REDUX DISPATCHES
     *
     * When this page loads, we need to grab the following data from Redux store after API calls:
     *
     * Selected Course:
     *    courseInformation: for titlebar and also to use dates for setting range for due-date-extension and delay-showing-grade
     *    students: for adding due-date-extension(s)
     *    assignments: so we know the weight of this assignment to add proper numbering on each document item
     *
     * Selected Assignment
     *    assignment: passed down to AssignmentInfo component
     *    documents: to list document items
     *    due-date-extensions: to list any current extensions in AssignmentInfo
     *
     * ContentLibrary
     *    contentLibrary: to allow for adding a document to assignment set
     */


    // Determining whether or not a user is an instructor once the courses load
    const courses = useHarmoniaSelector(state => state.courses)
    let foundCourse = courses?.course_instructors?.find(course => course.id === courseID);
    let foundAssignment: number | undefined;
    let assignmentNotFound: boolean = false;
    let needToRedirect = foundCourse ? false : !Object.keys(courses).length ? false : true;

    // API dispatches are only made if courseID URL param matches ID of a course in list of instructor courses

    useEffect(() => {
        foundAssignment = foundCourse?.assignments_ids?.find(id => id == assignmentID);
        assignmentNotFound = foundAssignment ? false : !Object.keys(courses).length ? false : true;
        if (foundCourse) {
            dispatch(getCourseDetail(courseID)); //.finally(() => {
            // setCourseDetailsLoading(false);
            // });
        }
    }, [foundCourse]);
    useEffect(() => {
        if (foundAssignment) {
            dispatch(getCourseStudents(courseID)); //.finally(() => {
            // setCourseStudentsLoading(false);
            // });
        }
    }, [foundCourse]);

    // grabbing courseAssignments array so we can use the index + 1 to display a document set's position in the overall set of assignments
    useEffect(() => {
        if (foundAssignment) {
            dispatch(getCourseAssignments(courseID)); //.finally(() => {
            // setCourseAssignmentsLoading(false);
            // });
        }
    }, [foundCourse]);
    useEffect(() => {
        if (foundAssignment) {
            dispatch(getAssignmentAndDocuments(assignmentID)).finally(() => {
                setAssignmentAndDocumentsLoading(false);
            });
        }
    }, [foundCourse]);
    useEffect(() => {
        if (foundAssignment) {
            dispatch(listAssignmentDueDateExtensions(assignmentID)); //.finally(() => {
            // setDueDateExtensionsLoading(false);
            // });
        }
    }, [foundCourse]);
    useEffect(() => {
        if (foundAssignment) {
            dispatch(getEntireContentLibrary()); //.finally(() => {
            // setContentLibraryLoading(false);
            // });
        }
    }, [foundCourse]);

    // Grab hash from url and push notification
    useEffect(() => {
        // Check if assignment just created
        const hash = window.location.hash;
        if (hash == "#assignment_created") {
            dispatch(pushNotification({
                severity: 'success',
                message: `Assignment set created!`,
            }))
        }
    }, []);


    useRedirect(needToRedirect, '/', {
        severity: "error",
        message: "You are not an instructor of this course."
    });

    useRedirect(assignmentNotFound, '/', {
        severity: "error",
        message: "Assignment not found in selected course."
    })

    // useEffect(() => {
    //   return () => {
    //     dispatch(clearAssignmentInfo());
    //   }
    // }, [])


    /** GRABBING NEEDED DATA FROM REDUX STORE */

    const selectedCourse = useHarmoniaSelector(state => state.selectedCourse, (prev, next) => {
        return prev === next;
    });

    const selectedAssignment = useHarmoniaSelector(state => state.selectedAssignment, (prev, next) => {
        return prev === next;
    });

    const contentLibrary = useHarmoniaSelector(state => state.contentLibrary, (prev, next) => {
        return prev === next;
    });

    // Grabbing everything from selectedAssignment reducer.
    const assignment = selectedAssignment.assignment as Assignment || {};
    const dueDateExtensions = selectedAssignment.dueDateExtensions as DueDateExtension[];
    const documents = selectedAssignment.documents || [] as Document[];

    // Grabbing everything from selectedCourse reducer.
    const courseAssignments = selectedCourse.assignments as Assignment[] || [];
    const courseStudents = selectedCourse.students || [] as CourseStudent[];
    // we will pass in the courseEndDate to AssignmentInfo/ dueDateExtension as well as to document-listing-item so it can be used as the maxDate in the respective DatePickers.
    const courseStartDate = selectedCourse.courseInformation?.start_date;
    const courseEndDate = selectedCourse.courseInformation?.end_date;
    const courseLMSType = !!selectedCourse.courseInformation?.lms_type;

    // Finding index of current assignment (to display proper numbers i.e. "1.1", "2.3" on a document)
    const assignmentIndex = courseAssignments.findIndex(a => a.id === assignment.id);


    /** PREVIEWING A DOCUMENT */

    // memomized selected document value. use the local state (docID) to select that document, which is used to load the imageURL. useMemo optimizes performance (each time a image loads, the document info is cached so it loads faster if selected again). see: https://reactjs.org/docs/hooks-reference.html#usememo

    const selectedDoc = React.useMemo(() => documents?.find(
        (doc: Document) => doc.id === docID
    ), [docID, documents]);




    // the functionality of getting the document image preview is similar to that of content library doc preview but with one small twist: using an index number to select the doc at that point in the array of documents. we use the index because we want to be able to cycle through the array of documents by adding/subtracting from the index (which is done in the modal component).

    const getDocumentImagePreview = (index: number) => {
        const id = documents[index].id;
        setDocID(id);
        setURLLoading(true);
        dispatch(getDocumentPreviewURL(id))
            .finally(() => setURLLoading(false));
    };

    // Constructing assignmentDocuments array for preview modal. We create it this way so it has identical structure to array passed in to same modal on AssignmentsPage.
    const assignmentWithDocs = [{...assignment, documents}];


    /** DRAG N' DROP */

    const onDragEnd = (result: DropResult) => {
        setDragPromptShow(false);
        const {destination, source, draggableId} = result;
        if (!destination) return;
        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        )
            return;
        const originalOrder = documents.map((document: Document) => document.id);
        const newOrder: number[] = Array.from(originalOrder);
        newOrder.splice(source.index, 1);
        newOrder.splice(destination.index, 0, Number(draggableId.slice(9)));
        const newDocuments = [];
        for (let i = 0; i < newOrder.length; i++) {
            for (let j = 0; j < documents.length; j++) {
                if (newOrder[i] === documents[j].id) {
                    newDocuments.push(documents[j]);
                }
            }
        }
        // the first dispatch properly updates front-end (otherwise React D n' D causes switch delay when getting back-end response)
        dispatch(updateAssignmentDocumentOrderLocally({reorderedDocs: newDocuments}));
        dispatch(
            updateAssignmentDocumentOrder({assignmentID, newOrder})
        );
    };


    /** DELETING A DOCUMENT */

    const showDeleteDocumentModal = (document: Document) => {
        toggleDeleteDocModalOpen();
        setDocumentToDelete(document);
    };

    const confirmDeleteDocument = (document: Document) => () => {
        dispatch(deleteDocument(document)).finally(() => {
            toggleDeleteDocModalOpen();
            setDocumentToDelete({} as Document);
        })
    };

    /** GOOGLE CLASSROOM */

    const userData = useHarmoniaSelector(state => state.user);
    const userId = userData.user?.id;

    type GoogleSessionData = {
        assignmentId: string,
        userId: number,
        id: string,
        docOpen: boolean[],
        shouldOpen: boolean,
        selected: string | undefined,
        googleDocId: number,
        googleDocOrigTitle: string,
        googleDocTitle: string,
        googleDocDesc: string
    }

    const googleData = useRef(undefined as GoogleSessionData | undefined);
    useEffect(() => {
        if (!googleData.current) {
            var s = window.sessionStorage.getItem('googleSession');
            if (s) {
                googleData.current = JSON.parse(s);
                window.sessionStorage.setItem('googleSession', JSON.stringify({
                    ...googleData.current,
                    docOpen: [],
                    shouldOpen: false
                }));
            }
        }
        if (!googleData.current || googleData.current.assignmentId != assignmentId) {
            googleData.current = {
                assignmentId: assignmentId,
                userId: userId as number,
                id: googleData.current ? googleData.current.id : userId?.toString() + '.' + crypto.randomUUID(),
                docOpen: [],
                shouldOpen: false,
                selected: undefined,
                googleDocId: -1,
                googleDocOrigTitle: "",
                googleDocTitle: "",
                googleDocDesc: ""
            }
            window.sessionStorage.setItem('googleSession', JSON.stringify(googleData.current));
        }
    }, [assignmentId]);

    const googleInfo = userData.googleInfo as GoogleCoursesData | undefined;

    const [gotGoogleInfo, setGotGoogleInfo] = useState(!!googleInfo);
    const [waitingForGoogleInfo, setWaitingForGoogleInfo] = useState(!gotGoogleInfo);
    useEffect(() => {
        if (!gotGoogleInfo) {
            dispatch(getGoogleCourseInfo(btoa(googleData.current!.id) as string)).finally(() => {
                setWaitingForGoogleInfo(false);
            })
        }
    }, [gotGoogleInfo]);
    useEffect(() => {
        if (googleInfo && googleInfo.error >= 0) {
            setGotGoogleInfo(true);
        }
    }, [googleInfo]);

    const [googleClassroomModalShouldOpen, setGoogleClassroomModalShouldOpen] = useState(false);
    useEffect(() => {
        if (googleData.current?.shouldOpen) {
            setGoogleClassroomModalShouldOpen(true);
        }
    }, [googleData.current?.shouldOpen]);

    const [googleClassroomModalOpen, setGoogleClassroomModalOpen] = useState(false);
    useEffect(() => {
        if (googleClassroomModalShouldOpen && !googleClassroomModalOpen) {
            if (gotGoogleInfo) {
                if (!googleData.current!.selected) {
                    var c = googleInfo!.courses;
                    if (c && c.length)
                        googleData.current!.selected = c[0].id;
                }
                setGoogleClassroomModalOpen(true);
            } else if (!waitingForGoogleInfo && !(new URLSearchParams(window.location.search)).get('gc')) {
                window.sessionStorage.setItem('googleSession', JSON.stringify(googleData.current));
                window.location.href = API.defaults.baseURL + '/gc/config?re=' + btoa(location.protocol + '//' + location.host + location.pathname + '?gc=1') + '&id=' + btoa(googleData.current!.id);
            }
        }
    }, [googleClassroomModalShouldOpen, googleClassroomModalOpen, gotGoogleInfo, waitingForGoogleInfo]);

    const showGoogleModal = (document: Document) => {
        googleData.current = {
            ...googleData.current as GoogleSessionData,
            googleDocId: document.id,
            googleDocOrigTitle: document.title,
            googleDocTitle: document.title,
            googleDocDesc: "",
            shouldOpen: true
        };
        setGoogleClassroomModalShouldOpen(true);
    }

    const updateGoogleTitle = (event: any) => {
        googleData.current!.googleDocTitle = event.target.value;
    };

    const updateGoogleDesc = (event: any) => {
        googleData.current!.googleDocDesc = event.target.value;
    };

    const shouldForceOpen = (index: number) => {
        var s = googleData.current!.docOpen;
        return index < s.length && s[index];
    };

    const disableGoogle = (id: number): boolean => {
        return !!(googleInfo?.disabled && googleInfo.disabled.indexOf(id) != -1);
    };

    const setDocOpenState = (index: number, isOpen: boolean) => {
        var s = googleData.current!.docOpen;
        if (index >= s.length)
            googleData.current!.docOpen = s = [...s, ...Array(index + 1 - s.length).fill(false)];
        s[index] = isOpen;
    };

    const googleCourseSelected = (event: any) => {
        googleData.current!.selected = event.target.value;
    };

    const hideGoogleModal = () => {
        setGoogleClassroomModalOpen(false);
        setGoogleClassroomModalShouldOpen(false);
    };

    const addDocumentToGoogle = () => {
        dispatch(submitGoogleConfig([googleData.current!.selected!, googleData.current!.googleDocId, googleData.current!.googleDocTitle, googleData.current!.googleDocDesc, btoa(googleData.current!.id)])).finally(() => {
            setWaitingForGoogleInfo(false);
            setGotGoogleInfo(true);
        });
        hideGoogleModal();
    };

    const googlePast = assignment.due_at <= Date.now() / 1000 + 60

    return (
        <>
            {/* TITLE BAR */}
            {assignment?.title &&
                <DashboardTitleBar h1={`Edit Assignment Set`} h2={selectedCourse.courseInformation?.title} h2_no2={`${selectedCourse.courseInformation?.academic_term} ${selectedCourse.courseInformation?.year}`}
                h2_no3={`${assignment.title}`}/>
            }
            {/* CONTENT */}
            <DashboardContent>
                {/* ASSIGNMENT INFO */}
                {assignmentAndDocumentsLoading && <LoadingPage />}
                {!assignmentAndDocumentsLoading &&
                    <>
                        <AssignmentInfo assignment={assignment} courseStudents={courseStudents} dueDateExtensions={dueDateExtensions} courseStartDate={courseStartDate as number} courseEndDate={courseEndDate as number} openAddDocModal={openAddDocModal} courseID={courseID} />
                        {/* ADD DOC FROM CONTENT LIB MODAL */}

                        <AddDocFromContentLibModal contentLibrary={contentLibrary.contentLibrary} closeModal={closeAddDocModal}
                            assignment={assignment} show={showAddDocModal} />

                        {/* DOCUMENT IMAGE PREVIEW MODAL */}
                        {
                            selectedDoc &&
                            <DocImagePreviewModal
                                selectedDoc={selectedDoc} closeDocPreviewModal={closeDocPreviewModal} show={showDocPreviewModal} assignmentDocuments={assignmentWithDocs}
                                getDocumentImagePreview={getDocumentImagePreview}
                                urlLoading={urlLoading} page="AssignmentDetail" />
                        }
                        {/* ASSIGNMENT DOCUMENTS */}
                        <div className={documentsContainer}>

                            {/* Show empty prompt if user does not have any assignments */}
                            {!Boolean(documents.length) && (
                                <Text className={empty} variant="dashboardHeading" color="black">
                                    This assignment set doesn't have any documents yet.
                                </Text>
                            )}

                            {/* Show draggable text only if user has not dragged anything yet */}
                            <div className={drag}>
                                {
                                    dragPromptShow && Boolean(documents.length) && (
                                        <Text variant="dashboardList" color="dark-grey" gutterBottom>
                                            You can reorder documents by dragging the arrows.
                                        </Text>
                                    )}
                            </div>

                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="document-list">
                                    {(provided) => (
                                        <div ref={provided.innerRef} {...provided.droppableProps}>
                                            {documents?.map((document: Document, index: number) => (
                                                <DocumentListingItem
                                                    assignmentIndex={assignmentIndex}
                                                    index={index}
                                                    singleDoc={document}
                                                    courseEndDate={courseEndDate}
                                                    key={document.id}
                                                    assignment={assignment}
                                                    openDocPreviewModal={openDocPreviewModal}
                                                    getDocumentImagePreview={getDocumentImagePreview}
                                                    modalShow={showDocPreviewModal}
                                                    showDeleteDocumentModal={showDeleteDocumentModal}
                                                    showGoogleModal={showGoogleModal}
                                                    forceOpen={shouldForceOpen(index)}
                                                    setOpenDocState={setDocOpenState}
                                                    googleButton={courseLMSType}
                                                    disableGoogle={disableGoogle(document.id)}
                                                />
                                            ))}{' '}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                    </>
                }
            </DashboardContent>
            {/* Deleting document modal - only render if a document has been selected to delete */}
            {
                documentToDelete.id > -1 &&
                <Modal isOpen={deleteDocModalOpen} data-cy="delete-document-modal">
                    <ModalHeader>
                        Delete Document from Assignment Set
                    </ModalHeader>
                    <ModalBody data-cy="delete-document-title" >
                        <Text color="dark" >
                            {`Are you sure you want to delete ${documentToDelete.title} from ${assignment.title}?`}
                        </Text>
                        <Text>This action cannot be undone</Text>
                    </ModalBody>
                    <ModalFooter>
                        <HarmoniaButton variant="secondary" dest="app" onClick={toggleDeleteDocModalOpen} data-cy="cancel-document-delete">Cancel</HarmoniaButton>
                        <HarmoniaButton variant="primary" dest="app" onClick={confirmDeleteDocument(documentToDelete)} data-cy="confirm-document-delete">
                            Delete Document
                        </HarmoniaButton>
                    </ModalFooter>
                </Modal>
            }
            {/* Google Classroom Dialogue */}
            <Modal isOpen={googleClassroomModalOpen} data-cy="google-classroom-modal">
                <ModalHeader>
                    Add To Google Classroom
                </ModalHeader>
                <ModalBody data-cy="google-classroom-modal-title">
                    {!gotGoogleInfo || googleInfo?.error! > 2 ? (
                        <Text color="dark">
                            There was an error accessing your Google Classroom information.
                        </Text>
                    ) : googleInfo?.error == 2 ? (
                        <Text color="dark">
                            You do not have the correct permissions to access your Google Classroom information.
                        </Text>
                    ) : googleInfo?.error == 1 ? (
                        <Text color="dark">
                            There are no active Google Classroom courses available.
                        </Text>
                    ) : googlePast ? (
                        <Text color="dark">
                            The assignment due date cannot be in the past.  Please set it to a time in the future.
                        </Text>
                    ) : (<>
                        <Text color="dark">
                            {`Add assigment "${googleData.current ? googleData.current.googleDocOrigTitle : ""}" to Google Classroom course:`}
                        </Text>
                        <div style={{height: "40px"}}>
                            <select style={{minWidth: "33%", height: "24px"}} onChange={googleCourseSelected} defaultValue={googleData.current?.selected} >
                                {googleInfo?.courses?.map((course: GoogleCourseInfo, index: number) => (
                                    <option className="goosel" value={course.id} id={`goo${course.id}`}>{course.name}</option>
                                ))}
                            </select>
                        </div>
                        <div style={{height: "65px"}}>
                            <label htmlFor="gootitle">Assignment Title:</label>
                            <input style={{width: "100%"}} id="gootitle" type="text" defaultValue={googleData.current?.googleDocTitle} onChange={updateGoogleTitle} />
                        </div>
                        <div style={{height: "60px"}}>
                            <label htmlFor="goodesc">Description:</label>
                            <input style={{width: "100%"}} id="goodesc" type="text" onChange={updateGoogleDesc} />
                        </div>
                    </>)}
                </ModalBody>
                <ModalFooter>
                    {gotGoogleInfo && googleInfo?.error! < 1 && !googlePast ? (<>
                        <HarmoniaButton variant="secondary" dest="app" onClick={hideGoogleModal} data-cy="hide-google-modal">Cancel</HarmoniaButton>
                        <HarmoniaButton variant="primary" dest="app" onClick={addDocumentToGoogle} data-cy="google-add-to-course">Add to Course</HarmoniaButton>
                    </>) : (
                        <HarmoniaButton variant="primary" dest="app" onClick={hideGoogleModal} data-cy="hide-google-modal">Ok</HarmoniaButton>
                    )}
                </ModalFooter>
            </Modal>
        </>
    );
}
export default AssignmentDetailPage;

