import React, {useState, useEffect} from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { useHarmoniaSelector, getCourseDetail, useHarmoniaDispatch, getDocumentSubmissions,  getSubmissionFileURLs, } from '../../redux';
import { DashboardContent, DashboardTitleBar } from '../../components/template';
import { Submission, CourseStudent, UserType } from '../../types';
import LoadingPage from '../../components/LoadingPage';
import { useUser } from '../../hooks';
import { HarmoniaButton, HarmoniaInput, HarmoniaPaper, Text } from "@harmonia-front-end/shared";
import SubmissionViewPageStyle from './SubmissionViewPage.module.scss';
import { historyRouter } from '../../redux/history';
import { ViewGradeReportModal } from '../../components/assignment';

const {submissionViewContainer,submissionInfo, infoTop, infoBottom, imgCommentsContainer, imgContainer, subImg, commentsContainer} = SubmissionViewPageStyle
// let pageRenders = 0;

const SubmissionViewPage = () => {
  const [user, userLoading] = useUser();
  const dispatch = useHarmoniaDispatch();
  const history = useHistory();
  // grab course and submission ids from params...
  const{courseId, submissionId} = useParams<{courseId: string, submissionId: string}>();
  //... and convert to num to avoid typing errors.
  const courseID = parseInt(courseId, 10);
  const submissionID = parseInt(submissionId, 10);
  // local state for tracking submission, course details and url loading
  const [submissionsLoading, setSubmissionsLoading] = useState(false);
  const [courseDetailsLoading, setCourseDetailsLoading] = useState(false);
  const[urlsLoading, setUrlsLoading] = useState(false);

  // grabbing course details
  useEffect(() => {
    setCourseDetailsLoading(true);
    dispatch(getCourseDetail(courseID)).finally(() => setCourseDetailsLoading(false));
  }, [dispatch]);
  // grabbing submissions
  useEffect(() => {
    setSubmissionsLoading(true);
    dispatch(getDocumentSubmissions({courseID, submissionID})).finally(() => {
      setSubmissionsLoading(false);
    });
  }, [dispatch]);
  // grabbing submission file urls
  useEffect(() => {
    setUrlsLoading(true);
    dispatch(getSubmissionFileURLs(submissionID)).finally(() => setUrlsLoading(false));
  }, [dispatch, submissionID]);

  // useEffect(() => {
  //   return () => {
  //     console.log('cleaned up!');
  //   }
  // }, [])

  // Grabbing all selected course and selected submission stuff from Redux;
  const {selectedSubmission, selectedCourse} = useHarmoniaSelector(state => state);
  const {courseInformation} = selectedCourse;
  const {submissions, studentDocumentInfo, urls} = selectedSubmission;

  // setting up text for the title bar
  const studentName = `${studentDocumentInfo?.student_first_name} ${studentDocumentInfo?.student_last_name}`;
  const titleBarText = `Submissions for ${studentDocumentInfo?.document_title} by ${studentName}`;

  // finding currently selected submission (for displaying its info) based on submissionID parameter in URL
  const selectedSub = submissions?.find(sub => sub.id === submissionID);
  // handler for selecting a different submission to view
  const changeSubmissionView:React.ChangeEventHandler<HTMLSelectElement> = (ev) => {
    const selectedSubmissionID = parseInt(ev.currentTarget.value, 10);
    const newURL = `/course/${courseID}/submission/${selectedSubmissionID}`;
    history.push(newURL);;
  };
  // const getNextOrPrevGradeReport = (id: number) => {
  //   if (!submissions || !submissions.length)
  //       return;
  //   let l = submissions.length;
  //   id = (id % l + l) % l; // wrap to [0, l)
  //   ...
  // };
  // pageRenders++;
  // console.log('pageRenders: ', pageRenders);

  // if (userLoading) return <LoadingPage />;

  const useModal =() => {
    const [modalShow, setModal] = useState(false);
    const openModal = () => {
      setModal(true);
      document.body.classList.add('modal-open');
    };
    const closeModal = () => {
      setModal(false);
      document.body.classList.remove('modal-open');
    };
    return [modalShow, openModal, closeModal];
  }
  const [showingGradeReportModal, openGradeReportModal, closeGradeReportModal] = useModal() as [boolean, () => {}, () => {}];

  const viewGradeReport = () => {
      if (!showingGradeReportModal)
          openGradeReportModal();
  };

  const getSelectedReportIndex = () => {
      let id = selectedSub?.id;
      let r = submissions?.findIndex((s: Submission) => { return s.id === id; });
      return r === undefined || r < 0 ? undefined : r + 1;
  };

  return (
    <>
    {/* Title Bar */}
    {courseInformation &&
      <DashboardTitleBar h1={titleBarText} h2={courseInformation.title} h2_no2={courseInformation.year}>
      </DashboardTitleBar>
    }
    {/* Content */}
    <DashboardContent>
      <HarmoniaPaper className={submissionViewContainer}>
        <>
        {/* Selecting Submission */}
        <div className="selectSubmission">
          {submissions &&
            <select value={submissionID} onChange={changeSubmissionView}>
            {submissions && submissions.map((sub, index) => {
              return <option value={sub.id} key={index}>{`Submission #${index + 1}`}</option>
            })}
            </select>
          }
        </div>
        {/* Submission Info & Image/Comments */}
        {!submissionsLoading && urlsLoading && <LoadingPage/>}
        {!submissionsLoading && !urlsLoading && submissions &&
          <div className={submissionInfo}>
            <div className={infoTop}>
              <Text variant="dashboardContentHeading">{`Score: ${selectedSub?.points}`}</Text>
              <Text variant="dashboardContentHeading">{`Percent: ${selectedSub?.percent}%`}</Text>
              <Text variant="dashboardContentHeading">{`Grade: ${selectedSub?.grade}%`}</Text>
              <Text variant="dashboardContentHeading">{`Submission Time: ${selectedSub?.formattedTime} `}</Text>
            </div>
            <div className={infoBottom}>
              {/* buttons here */}
              {!userLoading && user?.user_type !== UserType.STUDENT &&
               <a href={urls?.har_url} download>
                 <HarmoniaButton variant="primary" dest="app" icon="download" iconPos="left">
                 Download HAR File
                 </HarmoniaButton>
               </a>}
               <HarmoniaButton variant="primary" dest="app" icon="preview" iconPos="left" onClick={viewGradeReport}>
               View Grade Report
               </HarmoniaButton>
            </div>
          </div>
        }
        <div>
        </div>
        <div className={imgCommentsContainer}>
          <div className={imgContainer}>
            <img src={urls?.png_url} className={subImg}/>
          </div>
          {/*<div className={commentsContainer}>
            <div>{urls && 'Comments here?'}</div> 
          </div>*/}
        </div>
        </>
      </HarmoniaPaper>
    </DashboardContent>
    <ViewGradeReportModal closeGradeReportModal={closeGradeReportModal} show={showingGradeReportModal} url={urls?.grade_report_url} reportIndex={getSelectedReportIndex()}/>
    </>
  )
}

export default SubmissionViewPage;
