/* eslint-disable react/jsx-props-no-spreading */
import React, {useState} from 'react';
import { Spinner, Modal, ModalBody} from 'reactstrap';
import {
  HarmoniaButton,
  Text
} from '@harmonia-front-end/shared';
import {useSelector} from 'react-redux';
import { addDocumentsFromContentLibrary, getContentLibraryDocumentPreviewURL, useHarmoniaDispatch } from '../../redux';
import ModalDocCollection from './ModalDocCollection';
import LoadingPage from '../LoadingPage';
import AddDocFromContentLibModalStyle  from './AddDocFromContentLibModal.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useContentLibrary } from '../../hooks';
import { Document, Assignment, CourseDetailedInfo, CourseDetails, ContentLibrary } from '../../types';

const {modalContainer, modalContent, modalImagePreview, modalDocumentSidebar, sidebarDocContainer, sidebarDocCollection, singleDocCollection, docCollectionTitle, rightAdd, docCollectionCollapse, sidebarAdd, addToAssignmentSet, addDocTitle, cancel, docImageTitle, docPreviewImageWrapper, clickOnDoc, docPreviewImage, addDocsModal, docsModalBody, modalContent2} = AddDocFromContentLibModalStyle;

type AddDocModalProps = {
  contentLibrary: ContentLibrary | null | undefined
  isOpen: boolean,
  setModal: React.Dispatch<React.SetStateAction<boolean>>,
  assignment: Assignment
}
const AddDocModal = (props: AddDocModalProps) => {
  const {contentLibrary,isOpen, setModal, assignment} = props;


  // local state used for selecting a document for image preview
  const [docID, setDocID] = React.useState(0);
  // local state to have loading animation while awaiting api call for image preview
  const [urlLoading, setURLLoading] = React.useState(false);
  // local state to have loading animation on button while awaiting api call for adding documents
  const [addDocsLoading, setDocsLoading] = React.useState(false);
  // closing/opening the modal

  const dispatch = useHarmoniaDispatch();
  // local state for array of selected documents to add to assignment set. actions include adding single doc, set of docs (array), removing a single doc and removing all docs (to be done after api call)
  const initialDocsToAdd:Document[] = [];
  const [documentsToAdd, setDocumentsToAdd] = useState(initialDocsToAdd);
  console.log('documentsToAdd: ', documentsToAdd);

  // const assignment = useSelector(state => state.assignments.assignmentDetail);

  // function to select a new document into array of documents to add to assignment set. using concat so original array is not mutated per rules of React state.
  const selectDocumentToAdd = (newDoc: Document) => {
    // checking if the document has already been selected to add
    if(!addDocsLoading){
     if (documentsToAdd.filter((doc: Document) => doc.id === newDoc.id).length === 0){
       const newDocsToAdd: Document[] = [...documentsToAdd, newDoc];
       setDocumentsToAdd(newDocsToAdd)
     }
    }
   }
   // function to select all documents in a set to add.
  const selectDocumentSetToAdd = (documents: Document[]) => {
    if(!addDocsLoading){
      const newDocsToAdd = [...documentsToAdd, ...documents];
      setDocumentsToAdd(newDocsToAdd)
    }
  }
// function to remove a single document from set to add. using filter to not mutate original array.
  const removeDocumentToAdd = (removedDoc: Document) => {
    if(!addDocsLoading){
      const documents = documentsToAdd.filter(doc => doc.id !== removedDoc.id)
      setDocumentsToAdd(documents);
    }
  }
  // this function fires after api call (after a doc/docs is added to assignment set, the array to add should be cleared )
  const removeAllDocumentsToAdd = () => {
    if(!addDocsLoading){
      setDocumentsToAdd([]);
    }
  }
  // function to make api call to add all selected documents from content library to assignment set.
  const addSelectedDocumentsToAssignment = () => {
    if(!addDocsLoading){
    if(documentsToAdd.length > 0){
      setDocsLoading(true);
      dispatch(addDocumentsFromContentLibrary({assignmentID: assignment.id,newDocuments: documentsToAdd} )).finally(() =>{
        removeAllDocumentsToAdd();
        setDocsLoading(false);
        setModal(false);
      });
      ;
    }
  }
  }

  // getting document image preview URL
  const getDocumentImagePreview = (id: number) => () => {
    setDocID(id);
    setURLLoading(true);
    dispatch(getContentLibraryDocumentPreviewURL(id))
    .finally(() => setURLLoading(false));
  }
  const selectedDoc = React.useMemo(() => contentLibrary?.find(
    (lib) => lib.documents.some((doc) => doc.id === docID),
  )?.documents.find((doc) => doc.id === docID), [docID, contentLibrary]);

  // (MIGHT NOT BE NEEDED?) dynamically setting the height of the container for selected documents to add. when there are no docs selected, height is 0 (and container is "hidden"). the max-height of the container will be 120px (for now).
  let addContainerHeight = documentsToAdd.length * 30;
  if (documentsToAdd.length > 4) addContainerHeight = 120;
// dynamically setting overflow for add container. there will only be overflow/scrolling if there are more than 4 docs in container (for now).
  let addContainerOverflow = 'hidden';
 addContainerOverflow = documentsToAdd.length <= 4 ? 'hidden' : 'auto';

 if(!isOpen || !contentLibrary) return null

 return(
   <Modal className={addDocsModal} isOpen={isOpen}>
<div className={modalContent2}>
  <div className={modalDocumentSidebar}>
    <div className={`${sidebarDocContainer} ${sidebarDocCollection}`}>
      {/* go through contentLibrary to render each doc collection */}
      {contentLibrary.map(docCollection => {
        return (
          /* this component includes all the info ( doc collection title and a collapse that shows all the documents in each set) for each document collection, as well as the buttons to add docs. It is a separate component b/c each one needs to have its own local state that controls its collapse (setting individual states via a map function is a no-go in React) . */
          <ModalDocCollection docCollection={docCollection} selectDocumentToAdd={selectDocumentToAdd} selectDocumentSetToAdd={selectDocumentSetToAdd} getDocumentImagePreview={getDocumentImagePreview} />
        )
      })}
    </div>
    {documentsToAdd.length > 0 && <div className={`${sidebarDocContainer} ${sidebarAdd}`} style={{height: addContainerHeight, overflow: addContainerOverflow}}>
      {documentsToAdd.map(doc => {
        return (
          <div className={docCollectionTitle}>
            <span>{doc.title}</span>
            <FontAwesomeIcon onClick={() => {removeDocumentToAdd(doc)}} icon={faTrashAlt}/>
          </div>
        )
      })}
      </div>}
      <HarmoniaButton variant={addDocsLoading ? 'loading' : 'primary'} dest="app" icon="add-to" iconPos="right" className={addToAssignmentSet} onClick={() => {addSelectedDocumentsToAssignment()}}>
        {addDocsLoading && <span><Spinner size="sm"/></span>}
        <span>Add to Assignment Set</span>
      </HarmoniaButton>
      <div>
        {!addDocsLoading && <HarmoniaButton variant="primary" dest="app" icon="back" iconPos="left" className={cancel} onClick={() => {setModal(false)}}>Cancel</HarmoniaButton>}
      </div>
  </div>
  <div className={modalImagePreview}>
    <div className={docImageTitle}>
      <div>
        {selectedDoc ? selectedDoc.title : ''}
      </div>
      <div>
        <FontAwesomeIcon icon={faTimes} onClick={() => {setModal(false)}}/>
      </div>
    </div>
    <div className={docPreviewImageWrapper}>
      {urlLoading && <LoadingPage/>}
      {!urlLoading && !selectedDoc && <div className={clickOnDoc}>Click on a Document to Display Image Preview</div>}
      {!urlLoading && selectedDoc && (!selectedDoc.previewURL ? (
            <>
            <Text variant="body" color="dark" inline>
              Failed to load image preview. Please try again.
            </Text>
            <HarmoniaButton variant="primary" dest="app" onClick={getDocumentImagePreview(docID)}>
                Reload
              </HarmoniaButton>
              </>
          ) : (<img src={selectedDoc.previewURL} alt={selectedDoc.title} className={docPreviewImage}/>)
          )}
    </div>
  </div>
</div>
   </Modal>
 )

}

export default AddDocModal;
