/* eslint-disable @typescript-eslint/naming-convention, react/jsx-props-no-spreading */
import React, {useEffect, useState} from 'react';
import {
    HarmoniaButton, HarmoniaInput, HarmoniaPaper, Text, useToggle,
} from '@harmonia-front-end/shared';
import {
    faArrowRight,
    faCaretUp,
    faCaretDown,
    faTrashAlt,
    faFileAudio,
    faVolumeUp
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Draggable} from 'react-beautiful-dnd';
import {
    Modal, ModalBody, ModalFooter, ModalHeader, Progress,
} from 'reactstrap';
import {MediaFile} from '../../types';
import {
    deleteMediaFileByID, pushErrorNotification, showMediaFileByID, updateMediaFileTitleByID,
    useHarmoniaDispatch,
} from '../../redux';
import {API} from '../../utilities';
import styles from './MediaCollectionDisplay.module.scss';

const {mediaItem, transcoding, mediaItemTop, itemTopLeft, arrows, mediaTitle, titleInput, deleteMedia, mediaFileBottom, progress, inProgress} = styles;

type MediaFileDisplayProps = {
    mediaFile: MediaFile;
    index: number,
};

function MediaFileDisplay({mediaFile, index}: MediaFileDisplayProps) {
    const {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        id, file_key, title, transcode_status,
    } = mediaFile;
    const fileTitleRef = React.useRef<HTMLInputElement>(null);
    const dispatch = useHarmoniaDispatch();

    const [downloadLink, setDownloadLink] = React.useState('');
    const [deleteDialogOpen, toggleDeleteDialog] = useToggle();
    const [fetchIntervalID, setFetchIntervalID] = React.useState(0);
    const [fileTitle, setFileTitle] = useState(title);



    // handler for text input in collection title field
    const handleTextInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setFileTitle(e.currentTarget.value);
    };

    const deleteMediaFile = () => {
        toggleDeleteDialog();
        dispatch(deleteMediaFileByID(id));
    };
    const changeFileNameOnBlur: React.FocusEventHandler<HTMLInputElement> = (ev) => {
        ev.preventDefault();
        const trimmedNewTitle = ev.currentTarget.value.trim();
        if (trimmedNewTitle && trimmedNewTitle !== title) {
            dispatch(updateMediaFileTitleByID({mediaFileID: id, newTitle: trimmedNewTitle}));
        }
    };
    const changeFileNameOnReturn: React.KeyboardEventHandler<HTMLInputElement> = (ev) => {
        if (ev.key === 'Enter') ev.currentTarget.blur();
    };
    const openDownloadLink = () => {if (downloadLink) window.open(downloadLink, '_blank');};

    const transcodeBarInnerText = (() => {
        if (transcode_status === 'UNSTARTED') return 'Waiting to process...';
        if (transcode_status === 'IN_PROGRESS') return 'Processing...';
        if (transcode_status === 'FAILED') return 'Processing failed';
        return 'Transcode completed';
    })();
    const transcodeBarColor = (() => {
        if (transcode_status === 'UNSTARTED') return 'warning';
        if (transcode_status === 'IN_PROGRESS') return 'info';
        if (transcode_status === 'FAILED') return 'danger';
        return 'success';
    })();
    const fileTranscodeUnfinished = transcode_status === 'UNSTARTED' || transcode_status === 'IN_PROGRESS';

    // useEffect(() => {
    //   if (fileTitleRef.current) fileTitleRef.current.value = title;
    // }, [title]);

    const bottomClass = transcode_status === 'COMPLETED' ? mediaFileBottom : `${mediaFileBottom} ${inProgress}`;

    useEffect(() => {
        if (transcode_status === 'COMPLETED') {
            API.get(`/api/media-files/${file_key}/file-url`)
                .then((response) => setDownloadLink(response.data.data.url))
                .catch(() => dispatch(pushErrorNotification(`Cannot acquire file ${title} download link`)));
        }
    }, [dispatch, file_key, title, transcode_status]);
    React.useEffect(() => {
        if ((transcode_status === 'UNSTARTED' || transcode_status === 'IN_PROGRESS') && !fetchIntervalID) {
            const intervalID = setInterval(() => dispatch(showMediaFileByID(id)), 2000);
            setFetchIntervalID(intervalID as unknown as number);
        } else if ((transcode_status === 'FAILED' || transcode_status === 'COMPLETED') && fetchIntervalID) {
            clearInterval(fetchIntervalID);
            setFetchIntervalID(0);
        }
    }, [dispatch, fetchIntervalID, id, transcode_status]);

    const width = title.length > 40 ? `${title.length}%` : '40%';
    // if local state transcoding is "in progress" and file transcode is now "COMPLETED", dispatch notification.

    const mediaItemClass = transcode_status === 'COMPLETED' ? mediaItem : `${mediaItem} ${transcoding}`;


    return (
        <>
            <Draggable draggableId={`${id}`} index={index} isDragDisabled={fileTranscodeUnfinished}>
                {(draggableProvided) => (
                    <HarmoniaPaper
                        className={mediaItemClass}
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                        {...draggableProvided.dragHandleProps}
                    >
                        <div className="content">
                            <div className={mediaItemTop}>
                                <div className={itemTopLeft}>
                                    <div className={arrows}>
                                        <FontAwesomeIcon icon={faCaretUp} />
                                        <FontAwesomeIcon icon={faCaretDown} />
                                    </div>
                                    <div className={mediaTitle}>
                                        <label>
                                            <Text color="dark">File Title:</Text>
                                        </label>
                                        <input
                                            style={{'width': width}}
                                            type="text"
                                            value={fileTitle}
                                            name="media-file-title"
                                            onChange={handleTextInput}
                                            onBlur={changeFileNameOnBlur}
                                            onKeyDown={changeFileNameOnReturn}
                                            className={titleInput}
                                        />
                                    </div>
                                </div>
                                <div className={deleteMedia} onClick={toggleDeleteDialog}>
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                </div>
                            </div>
                            <div className={bottomClass}>
                                {
                                    transcode_status === 'COMPLETED' && downloadLink &&
                                    /* <HarmoniaButton variant="primary" dest="app" icon="listen" iconPos="left" onClick={openDownloadLink}>
                                        Preview File
                                    </HarmoniaButton> */
                                   <audio controls style={{height: '20px'}}>
                                        <source src={downloadLink} type="audio/mpeg"/>
                                        Your browser cannot play this audio file.
                                   </audio>
                                }
                                {
                                    fileTranscodeUnfinished &&
                                    <Progress animated={fileTranscodeUnfinished} color={transcodeBarColor} value="100" className={progress}>
                                        {transcodeBarInnerText}
                                    </Progress>
                                }

                            </div>
                            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}

                            {/* <label>
                File title:
                <HarmoniaInput
                  ref={fileTitleRef}
                  type="text"
                  name="media-file-title"
                  onBlur={changeFileNameOnBlur}
                  onKeyDown={changeFileNameOnReturn}
                />
              </label> */}

                            {/* {transcode_status === 'COMPLETED' && (
                <HarmoniaButton variant="icon" dest="app" icon="download" onClick={openDownloadLink} />
              )} */}


                        </div>


                    </HarmoniaPaper>
                )}
            </Draggable>

            <Modal isOpen={deleteDialogOpen} toggle={toggleDeleteDialog}>
                <ModalHeader>
                    <Text color="dark" variant="h2">Delete Media File</Text>
                </ModalHeader>
                <ModalBody>
                    <Text color="dark" variant="body">
                        {`Are you sure you want to delete ${title}?`}
                    </Text>
                    <Text color="dark" variant="body">
                        This action cannot be undone.
                    </Text>
                </ModalBody>
                <ModalFooter>
                    <HarmoniaButton variant="secondary" dest="app" onClick={toggleDeleteDialog}>
                        Cancel
                    </HarmoniaButton>
                    <HarmoniaButton variant="primary" dest="app" onClick={deleteMediaFile} title="Delete media files">
                        Delete
                    </HarmoniaButton>
                </ModalFooter>
            </Modal>
        </>

    );
}

export default MediaFileDisplay;
