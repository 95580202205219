import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import {
  HarmoniaButton,
  HarmoniaPaper,
  Text,
} from '@harmonia-front-end/shared';
import { DashboardContent, DashboardTitleBar } from '../../components/template';
import { userTypeInfo } from '../../utilities';
import LoadingPage from '../../components/LoadingPage';
import { useHarmoniaTimezones, useUser } from '../../hooks';
import styles from './UserInfo.module.scss';

const { paper, table: tableClass, title } = styles;

export default function MyProfilePage() {
  const [user, loading] = useUser();
  const [timezones] = useHarmoniaTimezones();

  const timezoneString = timezones?.find(({ id }) => id === user?.timezone)
    ?.timezone;
  const userTypeString = userTypeInfo.find((userTypeInfo) => userTypeInfo.userType === user?.user_type)
    ?.text;

  if (loading) return <DashboardContent><LoadingPage /></DashboardContent>;
  if (!user) return null;

  return (
    <>
      <DashboardTitleBar h1="Profile" />
      <DashboardContent>
        <HarmoniaPaper rounded className={paper}>
          <div className={title}>
            <Text color="dark" variant="h2" gutterBottom>
              {`${user.first_name} ${user.last_name}`}
            </Text>
            <div style={{ flexGrow: 1 }} />
            <Link to="/my-profile/edit">
              <HarmoniaButton icon="edit" variant="primary" dest="app">
                <FormattedMessage
                  id="user.profile.edit_settings"
                  defaultMessage="Edit Settings"
                />
              </HarmoniaButton>
            </Link>
            <Link to="/change-password">
              <HarmoniaButton icon="key" variant="secondary" dest="app">
                <FormattedMessage
                  id="user.profile.change_password"
                  defaultMessage="Change Password"
                />
              </HarmoniaButton>
            </Link>
          </div>
          <table className={tableClass}>
            <tbody>
              <tr>
                <td>
                  <Text
                    style={{ fontWeight: 'bold' }}
                    color="dark"
                    variant="body"
                  >
                    First Name:
                  </Text>
                </td>
                <td>
                  <Text color="dark" variant="body">
                    {user.first_name}
                  </Text>
                </td>
              </tr>
              <tr>
                <td>
                  <Text
                    style={{ fontWeight: 'bold' }}
                    color="dark"
                    variant="body"
                  >
                    Last Name:
                  </Text>
                </td>
                <td>
                  <Text color="dark" variant="body">
                    {user.last_name}
                  </Text>
                </td>
              </tr>
              <tr>
                <td>
                  <Text
                    style={{ fontWeight: 'bold' }}
                    color="dark"
                    variant="body"
                  >
                    ID/Email
                  </Text>
                </td>
                <td>
                  <Text color="dark" variant="body">
                    {user.email}
                  </Text>
                </td>
              </tr>
              <tr>
                <td>
                  <Text
                    style={{ fontWeight: 'bold' }}
                    color="dark"
                    variant="body"
                  >
                    Timezone:
                  </Text>
                </td>
                <td>
                  <Text color="dark" variant="body">
                    {timezoneString}
                  </Text>
                </td>
              </tr>
              <tr>
                <td>
                  <Text 
                    style={{ fontWeight: 'bold' }}
                    color="dark"
                    variant="body"
                  >
                    Language: 
                  </Text>
                </td>
                <td>
                  <Text color="dark" variant="body">
                    {user.language}
                  </Text>
                </td>
              </tr>
              <tr>
                <td>
                  <Text
                    style={{ fontWeight: 'bold' }}
                    color="dark"
                    variant="body"
                  >
                    Receive Notification Emails:
                  </Text>
                </td>
                <td>
                  <Text color="dark" variant="body">
                    {user.will_receive_notification_emails ? "Yes" : "No"}
                  </Text>
                </td>
              </tr>
              <tr>
                <td>
                  <Text
                    style={{ fontWeight: 'bold' }}
                    color="dark"
                    variant="body"
                  >
                    Receive Marketing Emails:
                  </Text>
                </td>
                <td>
                  <Text color="dark" variant="body">
                    {user.will_receive_marketing_emails ? "Yes" : "No"}
                  </Text>
                </td>
              </tr>
              <tr>
                <td>
                  <Text
                    style={{ fontWeight: 'bold' }}
                    color="dark"
                    variant="body"
                  >
                    Account Type:
                  </Text>
                </td>
                <td>
                  <Text color="dark" variant="body">
                    {userTypeString}
                  </Text>
                </td>
              </tr>
            </tbody>
          </table>
          {user.user_type === 1 && (
            user.has_pending_teacher_account_request? (
              <HarmoniaButton variant="secondary" dest="app" variant="secondary" disabled>
                Teacher Account Request Pending
              </HarmoniaButton>
            ):(
              <Link to="/request-teacher-account">
                <HarmoniaButton icon="email" variant="secondary" dest="app">
                  <FormattedMessage
                    id="user.request_teacher_account.title"
                    defaultMessage="Request Teacher Account"
                  />
                </HarmoniaButton>
              </Link>
            )
          )}
        </HarmoniaPaper>
      </DashboardContent>
    </>
  );
}
