import { useEffect, useState } from 'react';
// import style from '../../dashboard/src/components/assignment/DocImagePreviewModal.module.scss';

// const {blur, unblur, background, show, hide, hidden} = style;
// import { isConstructorDeclaration } from 'typescript';

/**
 * Helper React hook to return a stateful boolean and a toggle function.
 * Returning tuple's first value is a stateful boolean
 * and the second value is a function to alter the boolean value.
 *
 * @param initialFlag the initial value of the stateful boolean.
 * By default it's value is `false`
 * @example
 * const [open, toggleOpen] = useToggle();
 */

export function useToggle(initialFlag: boolean = false): [boolean, () => void] {
  const [flag, setFlag] = useState(initialFlag);
  return [flag, () => setFlag((p) => !p)];
}
/**
 * Helper React hook to return a stateful boolean and two true or false setter.
 * Returning tuple's first value is a stateful boolean
 * and the second and third value are functions to set the boolean to be true or false.
 *
 * @param initialFlag the initial value of the stateful boolean.
 * By default it's value is `false`
 * @example
 * const [isTrue, setTrue, setFalse] = useFlag();
 */
export function useFlag(initialFlag: boolean = false): [boolean, () => void, () => void] {
  const [flag, setFlag] = useState(initialFlag);
  return [flag, () => setFlag(true), () => setFlag(false)];
}

/**
 * Helper React hook to return the current CSS media query state.
 * Returning boolean is the current CSS media query state,
 * which is stateful and always up-to-date.
 *
 * @param query A CSS media query string.
 * It must be a valid CSS media query string or the behavior is undefined.
 * @example
 * useMediaQuery('(prefers-color-scheme: dark)');
 * useMediaQuery('(max-width: 768px)');
 */
export function useMediaQuery(query: string): boolean {
  const [matches, setMatches] = useState(false);
  const listener = (e: MediaQueryListEvent) => setMatches(e.matches);

  useEffect(() => {
    const media = window.matchMedia(query);
    setMatches(media.matches);
    media.addEventListener('change', listener);
    return () => media.removeEventListener('change', listener);
  }, [matches, query]);

  return matches;
}

/** Helper React hook to enable opening and closing of any modal component.
 * Returns an array of 3 items:
 * 1. a boolean variable to be used as state for showing modal
 * 2. a function to open the modal
 * 3. a function to close the modal
 *
 * Functions dynamically style anything in background of modal to prevent scrolling and add blur.
 */
export function useModal() {

  const [modalShow, setModal] = useState(false);

  const backgroundRoot = document.getElementById('background-root') as HTMLDivElement;
  const bodyRoot = document.getElementById('root') as HTMLDivElement;
  const openModal = () => {
    setModal(true);
    // add blur to elements in body behind modal. using cssText property because we need to pass in several blur properties at once to be more cross-browser-compliant.


    document.body.classList.add('modal-open');
    // bodyRoot.classList.add(blur);
    // backgroundRoot.classList.remove(hide, hidden);
    // backgroundRoot.classList.add(background, show);
  };
  const closeModal = () => {
    setModal(false);
    // setTimeout(() => {
    //   setModal(false);
    //   backgroundRoot.classList.add(hidden)
    // }, 500)
    setTimeout(() => {
      // backgroundRoot.classList.add(hidden);
    }, 500)
    // bodyRoot.classList.add(unblur);
    // bodyRoot.classList.remove(blur);
    // document.body.classList.remove('modal-open', show);
    // backgroundRoot.classList.add(hide);
  };
  return [modalShow, openModal, closeModal] as [boolean,() => void, () => void ];
}
